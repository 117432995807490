import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { Button, Input, message } from 'antd';
import { datadogRum } from '@datadog/browser-rum';

import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getGetStudyPlanCounter, getUserProfile } from 'components/ReducerContext/selectors';
import { setGetStudyPlanCounter } from 'components/ReducerContext/Actions/UserActions';

import ConfirmationOverlay from '../ConfirmationOverlay';

import styles from './AddQuestion.module.scss';
import { deriveGetStudyPlanMessage, GetStudyPlanTitle } from './constants';

const cx = classnames.bind(styles);
const { TextArea } = Input;

type AddQuestionProps = {
    goBack: () => void;
    createThread: (title: string, message: string, isGamePlan: boolean) => Promise<void>;
    isLoading: boolean;
    handleRefetch: () => void;
};

const AddQuestion: React.FC<AddQuestionProps> = ({
    goBack,
    createThread,
    isLoading,
    handleRefetch,
}) => {
    const [inputTitle, setInputTitle] = useState('');
    const [inputMessage, setInputMessage] = useState('');
    const [isAskUserConfirm, setIsAskUserConfirm] = useState(false);

    const { dispatch, state } = useReducerContext();
    const getStudyPlanCounter = useMemo(() => getGetStudyPlanCounter(state), [state]);
    const profile = useMemo(() => getUserProfile(state), [state]);

    const handleGoBack = () => {
        setInputTitle('');
        setInputMessage('');

        // reset counter to prevent onGetStudyPlan from firing
        // when this component is not triggered by Get Study View button
        setGetStudyPlanCounter(dispatch, 0);

        goBack();
    };

    const handleAskUserConfirm = () => {
        if (!inputMessage && !inputTitle) {
            handleGoBack();
            return;
        }

        setIsAskUserConfirm(true);
    };

    const handleSubmit = async () => {
        if (!inputTitle) {
            message.error('Title cannot be empty');
            return;
        }

        if (!inputMessage) {
            message.error('Message cannot be empty');
            return;
        }

        try {
            await createThread(inputTitle, inputMessage, getStudyPlanCounter > 0);

            datadogRum.addAction('submitNewAskAQuestion');

            message.success('Question submitted successfully');

            // reset counter to prevent onGetStudyPlan from firing
            // when this component is not triggered by Get Study View button
            setGetStudyPlanCounter(dispatch, 0);

            handleRefetch();
            handleGoBack();
        } catch {
            message.error('Something went wrong. Please try again later');
        }
    };

    const onGetStudyPlan = () => {
        setInputTitle(GetStudyPlanTitle);
        setInputMessage(deriveGetStudyPlanMessage(profile.userCohort));
    };

    useEffect(() => {
        if (getStudyPlanCounter === 0) {
            return;
        }

        onGetStudyPlan();
    }, [getStudyPlanCounter]);

    return (
        <div className={cx('container')}>
            <div className={cx('title-bar')}>
                <div className={cx('back')} onClick={handleAskUserConfirm}>
                    Back
                </div>

                <div>New Question</div>
            </div>

            <div className={cx('content')}>
                <Input
                    placeholder="Title"
                    value={inputTitle}
                    onChange={(e) => setInputTitle(e.target.value)}
                />
                <TextArea
                    placeholder="Message"
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    rows={14}
                />
                <div className={cx('button-container')}>
                    <Button
                        className={cx('submit-button')}
                        onClick={handleSubmit}
                        disabled={isLoading}
                        loading={isLoading}
                    >
                        Submit
                    </Button>
                </div>
            </div>

            {isAskUserConfirm && (
                <ConfirmationOverlay
                    onCancel={() => setIsAskUserConfirm(false)}
                    onConfirm={() => handleGoBack()}
                />
            )}
        </div>
    );
};

export default AddQuestion;
