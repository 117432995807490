import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { Prompt } from 'react-router-dom';

import QuizViewer from './QuizViewer';
import { DANGER_TIME_LEFT_IN_SECONDS, TIME_PER_QUESTION_IN_SECONDS } from './constants';
import { EQuizActionResult, POSTQuizChallengeSubmitPayload } from './types';
import { QuizChallengeQuestion, RecommendedContent } from '../types';
import { defaultNavigateAwayMessage, NAVIGATION_BAR_HEIGHT } from '../constants';
import { useBlockTabClose } from '../hooks';

import styles from './GameView.module.scss';
const cx = classnames.bind(styles);

type GameViewProps = {
    onEnd: (
        recommendedContent: RecommendedContent,
        lastSelectedQuizAnswerId: number,
        explanationNotes: string,
        correctAnswerId: number
    ) => void;
    currentQuiz: QuizChallengeQuestion;
    submitAnswer: (answerId: number) => Promise<POSTQuizChallengeSubmitPayload>;
    submitSkip: () => Promise<POSTQuizChallengeSubmitPayload>;
    isLoading: boolean;
    onSubmitCorrectAnswerSuccess: (nextQuiz: QuizChallengeQuestion) => void;
    onSkipSuccess: (nextQuiz: QuizChallengeQuestion) => void;
    score: number;
    totalSkips: number;
};

const GameView: React.FC<GameViewProps> = ({
    onEnd,
    currentQuiz,
    submitAnswer,
    submitSkip,
    isLoading,
    onSubmitCorrectAnswerSuccess,
    onSkipSuccess,
    score,
    totalSkips,
}) => {
    const [timerLeftInSeconds, setTimerLeftInSeconds] = useState(TIME_PER_QUESTION_IN_SECONDS);

    useBlockTabClose();

    // behavior: 'smooth' fails to scroll to top sometimes for unknown reason so leaving it out is intended
    const scrollToTop = () => {
        window.scrollTo({ top: NAVIGATION_BAR_HEIGHT });
    };

    const resetTimer = () => {
        setTimerLeftInSeconds(TIME_PER_QUESTION_IN_SECONDS);
    };

    const onSubmitAnswer = async (answerId: number) => {
        try {
            scrollToTop();

            const res = await submitAnswer(answerId);

            if (res.actionResult !== EQuizActionResult.CONT) {
                const recommendedContent = {
                    contentType: res.recommendedContentType,
                    url: res.recommendedContentUrl,
                    title: res.recommendedContentTitle,
                };

                onEnd(recommendedContent, answerId, res.explanationNotes, res.correctAnswerId);

                return;
            }

            onSubmitCorrectAnswerSuccess(res.actionPayload);
            resetTimer();
        } catch {
            throw Error();
        }
    };

    const onSkip = async () => {
        try {
            scrollToTop();

            const res = await submitSkip();

            onSkipSuccess(res.actionPayload);
            resetTimer();
        } catch {
            throw Error();
        }
    };

    useEffect(() => {
        if (timerLeftInSeconds === 0) {
            onSubmitAnswer(0);
            return;
        }

        const timer = setTimeout(() => {
            setTimerLeftInSeconds((prevState) => prevState - 1);
        }, 1000);

        return () => clearTimeout(timer);
    }, [timerLeftInSeconds]);

    return (
        <div className={cx('container')}>
            <Prompt message={defaultNavigateAwayMessage} />
            <div className={cx('content-container')}>
                <div className={cx('score-card')}>
                    <div>Streak: {score}</div>
                    <div
                        className={cx('timer', {
                            danger: timerLeftInSeconds <= DANGER_TIME_LEFT_IN_SECONDS,
                        })}
                    >
                        {timerLeftInSeconds}
                    </div>
                    <div>Total Skips: {totalSkips}</div>
                </div>

                <QuizViewer
                    isLoading={isLoading}
                    currentQuiz={currentQuiz}
                    onSubmitAnswer={onSubmitAnswer}
                    onSkip={onSkip}
                />
            </div>
        </div>
    );
};

export default GameView;
