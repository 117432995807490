import React, { useCallback, useEffect } from 'react';
import { message } from 'antd';
import { datadogRum } from '@datadog/browser-rum';

import { TCSFileShape } from 'common/types';
import { deriveDatadogRumDataFromFile } from 'common/utils';
import { EThumbsUpDownState } from '../types';
import { useLikedContent } from '../hooks';

import PdfViewer from './PdfViewer';

const errMsg =
    'Oops! It seems like theres some trouble with liked content at the moment. Regular use may be interrupted. Please try again later!';

const getStatus = (isLoading: boolean, active = false) => {
    if (isLoading) return EThumbsUpDownState.DISABLED;

    return active ? EThumbsUpDownState.ACTIVE : EThumbsUpDownState.INACTIVE;
};

type PdfViewerContainerProps = {
    isNotesPanelOpen: boolean;
    setIsNotesPanelOpen: (open: boolean) => void;
    file: TCSFileShape;
    handleGoBack: () => void;
};

const PdfViewerContainer: React.FC<PdfViewerContainerProps> = ({
    file,
    handleGoBack,
    isNotesPanelOpen,
    setIsNotesPanelOpen,
}) => {
    const { status, content, setters } = useLikedContent(file);
    const { likedDetails } = content;
    const { isLoading, hasError } = status;
    const { likeActiveFile, dislikeActiveFile } = setters;

    const handleLike = useCallback(() => {
        try {
            likeActiveFile(!likedDetails?.liked || false);

            datadogRum.addAction('User thumbs up', deriveDatadogRumDataFromFile(file));
        } catch {
            message.error('Oops! Looks like there was an error. Please try again later!');
        }
    }, [likedDetails]);

    const handleDislike = useCallback(() => {
        try {
            dislikeActiveFile(!likedDetails?.disliked || false);

            datadogRum.addAction('User thumbs down', deriveDatadogRumDataFromFile(file));
        } catch {
            message.error('Oops! Looks like there was an error. Please try again later!');
        }
    }, [likedDetails]);

    const thumbsUpStatus = getStatus(isLoading, likedDetails?.liked);
    const thumbsDownStatus = getStatus(isLoading, likedDetails?.disliked);

    useEffect(() => {
        if (hasError) {
            message.error(errMsg);
        }
    }, []);

    return (
        <PdfViewer
            file={file}
            thumbsUpStatus={thumbsUpStatus}
            thumbsDownStatus={thumbsDownStatus}
            isNotesPanelOpen={isNotesPanelOpen}
            setIsNotesPanelOpen={setIsNotesPanelOpen}
            handleGoBack={handleGoBack}
            handleThumbsUp={handleLike}
            handleThumbsDown={handleDislike}
        />
    );
};

export default PdfViewerContainer;
