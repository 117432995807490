import React, { useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { Button, Image, message, Spin } from 'antd';

import TypographySubtitle from 'components/TypographySubtitle';
import OptionCard from 'components/OptionCard';
import { QuizChallengeQuestion } from '../../types';
import { MAX_SKIPS } from './constants';

import styles from './QuizViewer.module.scss';

const cx = classnames.bind(styles);

type QuizSetViewerProps = {
    isLoading: boolean;
    currentQuiz: QuizChallengeQuestion;
    onSubmitAnswer: (answerId: number) => Promise<void>;
    onSkip: () => Promise<void>;
};

const QuizViewer: React.FC<QuizSetViewerProps> = ({
    isLoading,
    currentQuiz,
    onSubmitAnswer,
    onSkip,
}) => {
    const [playerSkips, setPlayerSkips] = useState(0);
    const [selectedQuizAnswerId, setSelectedQuizAnswerId] = useState(0);

    const currentQuizAnswers = useMemo(
        () => currentQuiz.quizAnswers.sort((a, b) => (a.id > b.id ? 1 : -1)),
        [currentQuiz]
    );

    const handleOptionCardClick = async (quizAnswerId: number) => {
        setSelectedQuizAnswerId(quizAnswerId);
    };

    const reset = () => {
        setSelectedQuizAnswerId(0);
        setPlayerSkips(0);
    };

    const handleSubmitAnswer = async () => {
        try {
            await onSubmitAnswer(selectedQuizAnswerId);
        } catch {
            message.error('Something went wrong. Please try again later.');
        } finally {
            reset();
        }
    };

    const handleSkip = async () => {
        try {
            await onSkip();
            setPlayerSkips((prevState) => prevState + 1);
        } catch {
            message.error('Something went wrong, please try again.');
        }
    };

    const renderContent = () => {
        if (isLoading) {
            return (
                <div className={cx('spin-container')}>
                    <Spin size="large" />
                </div>
            );
        }

        return (
            <div>
                <div className={cx('content-container')}>
                    <TypographySubtitle level={1} className={cx('question')}>
                        {currentQuiz.questionText}
                    </TypographySubtitle>

                    {currentQuiz.questionImage !== '' && (
                        <div className={cx('image-container')}>
                            <Image src={currentQuiz.questionImage} width={595} preview={false} />
                        </div>
                    )}

                    {currentQuizAnswers.map((quizAnswer) => (
                        <OptionCard
                            key={quizAnswer.id}
                            name={quizAnswer.answerText}
                            onClick={() => handleOptionCardClick(quizAnswer.id)}
                            isSelected={selectedQuizAnswerId === quizAnswer.id}
                        />
                    ))}
                </div>

                <div className={cx('button-container')}>
                    <Button
                        className={cx('skip')}
                        onClick={handleSkip}
                        disabled={playerSkips >= MAX_SKIPS}
                    >
                        Skip ({playerSkips}/{MAX_SKIPS})
                    </Button>
                    <Button
                        className={cx('submit')}
                        onClick={handleSubmitAnswer}
                        disabled={selectedQuizAnswerId === 0}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <div className={cx('container')}>
            <div className={cx('content-container')}>{renderContent()}</div>
        </div>
    );
};

export default QuizViewer;
