import React, { useContext } from 'react';
import { useAuthHelper } from './hooks';
import AuthMethods from './AuthMethods';
import { IAuthContext } from './types';
import { defaultAuthContext } from './utils';

const AuthContext = React.createContext<IAuthContext>(defaultAuthContext);

export const AuthContextProvider: React.FC = ({ children }) => {
    const {
        isLoggedIn,
        userIsChecked,
        token,
        setToken,
        userAttr,
        onSignInCallback,
        onSignOutCallback,
    } = useAuthHelper();

    const {
        signUp,
        confirmEmail,
        resendEmailConfirmationCode,
        changePassword,
        signIn,
        signOut,
        forgotPassword,
        resetPassword,
    } = AuthMethods;

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                userIsChecked,
                token,
                setToken,
                userAttr,
                signIn: signIn(onSignInCallback),
                signUp,
                signOut: signOut(onSignOutCallback),
                changePassword,
                confirmEmail,
                resendEmailConfirmationCode,
                forgotPassword,
                resetPassword,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): IAuthContext => {
    const authContext = useContext(AuthContext);

    if (authContext === undefined) {
        throw new Error('useAuth must be within a AuthContextProvider');
    }

    return authContext;
};
