export const monthOptions = [
    {
        value: 'MAY',
        text: 'May',
    },
    {
        value: 'NOV',
        text: 'November',
    },
];
