import React, { useState } from 'react';
import * as sentry from '@sentry/react';

import { useAxiosInstance } from 'components/AxiosContext';

import { GETMistakesQuizPayload, QuizAnswer } from './types';
import QuizSetViewer from './QuizSetViewer';

type QuizSetViewerContainerProps = {
    quizzes: GETMistakesQuizPayload[];
    onSubmitAnswer: (answer: QuizAnswer) => void;
    onEndQuiz: () => void;
};

const QuizSetViewerContainer: React.FC<QuizSetViewerContainerProps> = ({
    quizzes,
    onSubmitAnswer,
    onEndQuiz,
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const axios = useAxiosInstance();

    const submitMistakesQuiz = async (payload: number[]) => {
        try {
            setIsLoading(true);

            await axios.post(`/private/v2/quiz-challenge/practice/submit`, {
                correctQuizIdList: payload,
            });
        } catch (err) {
            sentry.captureException(err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <QuizSetViewer
            isLoading={isLoading}
            mistakesQuiz={quizzes}
            submitMistakesQuiz={submitMistakesQuiz}
            onSubmitAnswer={onSubmitAnswer}
            onEndQuiz={onEndQuiz}
        />
    );
};

export default QuizSetViewerContainer;
