export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost';

export const AWS_REGION = process.env.REACT_APP_AWS_REGION || '';

export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID || '';
export const COGNITO_WEB_CLIENT_ID = process.env.REACT_APP_COGNITO_WEB_CLIENT_ID || '';
export const COGNITO_IDENTITY_POOL_ID = process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID || '';

export const AWS_PINPOINT_ID = process.env.REACT_APP_AWS_PINPOINT_ID || '';

export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY || '';
export const STRIPE_INDIVIDUAL_PLAN_PRICE_ID =
    process.env.REACT_APP_STRIPE_INDIVIDUAL_PLAN_PRICE_ID || '';
export const STRIPE_FAMILY_PLAN_PRICE_ID = process.env.REACT_APP_STRIPE_FAMILY_PLAN_PRICE_ID || '';

export const BUILD_VERSION = process.env.REACT_APP_BUILD_VERSION || '';

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || '';
export const BUILD_ENV = process.env.REACT_APP_BUILD_ENV || 'dev';

export const DATADOG_APPLICATION_ID = process.env.REACT_APP_DATADOG_APPLICATION_ID || '';
export const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '';
export const DATADOG_ENV = process.env.REACT_APP_DATADOG_ENV || '';
export const DATADOG_SAMPLE_RATE =
    parseInt(process.env.REACT_APP_DATADOG_SAMPLE_RATE || '100', 10) || 100;

// whitelist for list of allowed subjects that we want to display for QuestionBank
export const QNA_SUBJECT_WHITELIST: Record<string, boolean> = {
    'HL Economics': true,
    'SL Economics': true,
    'HL Chemistry': true,
    'SL Chemistry': true,
    'HL Math AA': true,
    'SL Math AA': true,
    'HL Biology': true,
    'SL Biology': true,
};

export const QUIZZES_SUBJECT_WHITELIST: Record<string, boolean> = {
    'HL Economics': true,
    'SL Economics': true,
    'HL Chemistry': true,
    'SL Chemistry': true,
    'HL Biology': true,
    'SL Biology': true,
};

export const SELF_STUDY_WHITELIST: Record<string, boolean> = {
    'HL Economics': true,
    'SL Economics': true,
    'HL Chemistry': true,
    'SL Chemistry': true,
    'HL Math AA': true,
    'SL Math AA': true,
    'SL Physics': true,
};
