import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';
import { useAxiosInstance } from 'components/AxiosContext';

import FamilyMemberMySubscription from './FamilyMemberMySubscription';
import { GETV2FamilyMemberProfileSubscriptionInfo } from './types';
import { deriveSubscriptionPlanName } from '../utils';

const MySubscriptionContainer: React.FC = () => {
    const axios = useAxiosInstance();
    const { state } = useReducerContext();

    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [subscriptionInfo, setSubscriptionInfo] =
        useState<GETV2FamilyMemberProfileSubscriptionInfo | null>(null);

    const { subscriptionDetails = null } = useMemo(() => getUserProfile(state), [state]);

    const subscriptionPlanName = useMemo(
        () => deriveSubscriptionPlanName(subscriptionInfo?.subscriptionPlanType),
        [subscriptionInfo]
    );

    const fetchSubscriptionInfo = useCallback(async () => {
        try {
            setIsLoading(true);
            setHasError(false);

            const { data } = await axios.get<GETV2FamilyMemberProfileSubscriptionInfo>(
                `/private/v2/users/profile/subscription-info/family-member?subscriptionId=${subscriptionDetails?.subscriptionId}`
            );

            setSubscriptionInfo(data);
        } catch {
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    }, [subscriptionDetails]);

    useEffect(() => {
        fetchSubscriptionInfo();
    }, []);

    return (
        <FamilyMemberMySubscription
            isLoading={isLoading}
            hasError={hasError}
            cancelAtPeriodEnd={subscriptionInfo?.cancelAtPeriodEnd || false}
            subscriptionEnd={subscriptionInfo?.subscriptionEnd || 0}
            subscriptionPlanName={subscriptionPlanName}
        />
    );
};

export default MySubscriptionContainer;
