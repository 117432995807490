import React from 'react';
import { Redirect } from 'react-router-dom';

type RedirectWrapperProps = {
    shouldRedirect: boolean;
    redirectPath: string;
};

const RedirectWrapper: React.FC<RedirectWrapperProps> = ({
    shouldRedirect,
    redirectPath,
    children,
}) => {
    if (shouldRedirect) {
        return <Redirect to={redirectPath} />;
    }

    return <>{children}</>;
};

export default RedirectWrapper;
