import React from 'react';
import classnames from 'classnames/bind';
import { Modal } from 'antd';

import TCSButton from 'components/TCSButton';

import styles from './RedirectCanceled.module.scss';
import TypographyBody from '../../../components/TypographyBody';
const cx = classnames.bind(styles);

type RedirectSuccessProps = {
    visible: boolean;
    onClose: () => void;
};

const RedirectCanceled: React.FC<RedirectSuccessProps> = ({ visible, onClose }) => {
    const footer = <TCSButton onClick={onClose}>Close</TCSButton>;

    return (
        <Modal
            visible={visible}
            footer={footer}
            maskClosable={false}
            closable={false}
            onCancel={onClose}
            wrapClassName={cx('container')}
        >
            <TypographyBody level={1} className={cx('content-container')}>
                The purchase has been canceled.
            </TypographyBody>
        </Modal>
    );
};

export default RedirectCanceled;
