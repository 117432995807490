import { deriveCohortDisplay } from 'common/utils';

export const GetStudyPlanTitle = 'IB Game Plan';
export const deriveGetStudyPlanMessage = (userCohort: string | undefined): string => {
    if (!userCohort) {
        return 'Hello Cloudscore Team, I need a game plan for getting a 7 for Economics, can you guys help?';
    }

    const userCohortDisplay = deriveCohortDisplay(userCohort);

    return `Hello Cloudscore Team, I am taking my IBs in ${userCohortDisplay} and I need a game plan for getting a 7 for Economics, can you guys help?`;
};

export enum EAskAQuestionMessageTypes {
    'DASHBOARD_GAMEPLAN' = 'DASHBOARD_GAMEPLAN',
    'STUDY_GAMEPLAN' = 'STUDY_GAMEPLAN',
    'CLIENT_RESPONSE' = 'CLIENT_RESPONSE',
}
