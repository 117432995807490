import React from 'react';
import classnames from 'classnames/bind';
import { CheckOutlined } from '@ant-design/icons';

import TypographyBody from 'components/TypographyBody';

import styles from './SubscriptionPerk.module.scss';
const cx = classnames.bind(styles);

type SubscriptionPerkProps = {
    isDisabled: boolean;
};

const SubscriptionPerk: React.FC<SubscriptionPerkProps> = ({ isDisabled, children }) => (
    <div className={cx('container')}>
        <CheckOutlined className={cx('check-outlined', { 'is-disabled': isDisabled })} />
        <TypographyBody level={1}>{children}</TypographyBody>
    </div>
);

export default SubscriptionPerk;
