import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { Typography, Input, Button, Divider, message, Image } from 'antd';
// import GoogleButton from 'react-google-button';
import { Link } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';

import ErrorMessageWrapper from 'components/ErrorMessageWrapper';
import TypographyBody from 'components/TypographyBody';
import TypographySubtitle from 'components/TypographySubtitle';
import logo from 'static/logo_v2.png';

import { isValidEmail, isMinimumLength, areTheSame } from './utils';
import AuthenticationCard from './AuthenticationCard';
import {
    validFieldMessage,
    minimumLengthMessage,
    sameFieldsMessage,
    requiredFieldMessage,
} from './errorMessages';

import styles from './Authentication.scss';

const cx = classnames.bind(styles);
const { Text, Link: AntdLink } = Typography;

enum ESignUpFormField {
    FIRST_NAME = 'FIRST_NAME',
    LAST_NAME = 'LAST_NAME',
    EMAIL = 'EMAIL',
    PASSWORD = 'PASSWORD',
    CONFIRM_PASSWORD = 'CONFIRM_PASSWORD',
}

const signUpFormValidations = {
    [ESignUpFormField.FIRST_NAME]: (firstName: string) => !isMinimumLength(firstName, 1),
    [ESignUpFormField.LAST_NAME]: (lastName: string) => !isMinimumLength(lastName, 1),
    [ESignUpFormField.EMAIL]: (email: string) => !isValidEmail(email),
    [ESignUpFormField.PASSWORD]: (password: string) => !isMinimumLength(password, 12),
    [ESignUpFormField.CONFIRM_PASSWORD]: (password1: string, password2: string) =>
        !areTheSame(password1, password2),
};

type SignUpFormProps = {
    isLoading: boolean;
    handleSignUp: (
        email: string,
        password: string,
        firstName: string,
        lastName: string
    ) => Promise<void>;
    // handleGoogleSignIn: () => Promise<void>;
    goToLogin: () => void;
};

const SignUpForm: React.FC<SignUpFormProps> = ({
    isLoading,
    handleSignUp,
    // handleGoogleSignIn,
    goToLogin,
}) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [formErrors, setFormErrors] = useState<Record<string, null | string>>({
        [ESignUpFormField.FIRST_NAME]: null,
        [ESignUpFormField.LAST_NAME]: null,
        [ESignUpFormField.EMAIL]: null,
        [ESignUpFormField.PASSWORD]: null,
        [ESignUpFormField.CONFIRM_PASSWORD]: null,
    });

    const validateForm = () => {
        const firstNameError = signUpFormValidations[ESignUpFormField.FIRST_NAME](firstName);
        const lastNameError = signUpFormValidations[ESignUpFormField.LAST_NAME](lastName);
        const emailError = signUpFormValidations[ESignUpFormField.EMAIL](email);
        const passwordError = signUpFormValidations[ESignUpFormField.PASSWORD](password);
        const confirmPasswordError = signUpFormValidations[ESignUpFormField.CONFIRM_PASSWORD](
            password,
            confirmPassword
        );

        setFormErrors({
            [ESignUpFormField.FIRST_NAME]: firstNameError
                ? requiredFieldMessage('first name')
                : null,
            [ESignUpFormField.LAST_NAME]: lastNameError ? requiredFieldMessage('last name') : null,
            [ESignUpFormField.EMAIL]: emailError ? validFieldMessage('email') : null,
            [ESignUpFormField.PASSWORD]: passwordError
                ? minimumLengthMessage('password', 12)
                : null,
            [ESignUpFormField.CONFIRM_PASSWORD]: confirmPasswordError
                ? sameFieldsMessage('passwords')
                : null,
        });

        return (
            !firstNameError &&
            !lastNameError &&
            !emailError &&
            !passwordError &&
            !confirmPasswordError
        );
    };

    const onSignUpButtonClick = async () => {
        if (validateForm()) {
            try {
                await handleSignUp(email, password, firstName, lastName);

                datadogRum.addAction('User registered', { email, firstName, lastName });
            } catch (error) {
                if (error.message === 'USERNAME_EXISTS') {
                    message.error('An account with the given email address already exists');
                    return;
                }
                message.error('Oops! There was an error when trying to sign up. Please try again.');
            }
        } else {
            message.error('Please fix the form error');
        }
    };

    return (
        <div className={cx('sign-up-form-container')}>
            <div className={cx('sign-up-form-banner')}>
                <Image preview={false} src={logo} width={132} height={32} />

                <TypographySubtitle level={1} className={cx('sign-up-form-subtitle')}>
                    Create an account and get started on a free trial! No credit card required.
                </TypographySubtitle>

                <br />

                <TypographyBody level={1}>
                    Cloudscore offers a wealth of distinction-grade resources, crafted by an
                    experienced team of IB teachers to get you that elusive 7.
                </TypographyBody>
            </div>

            <div className={cx('sign-up-form-auth-card-container')}>
                <AuthenticationCard title="Sign up to continue">
                    <ErrorMessageWrapper errorMessage={formErrors[ESignUpFormField.FIRST_NAME]}>
                        <Input
                            placeholder="First Name"
                            value={firstName}
                            name={ESignUpFormField.FIRST_NAME}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFirstName(e.target.value)
                            }
                        />
                    </ErrorMessageWrapper>

                    <ErrorMessageWrapper errorMessage={formErrors[ESignUpFormField.LAST_NAME]}>
                        <Input
                            placeholder="Last Name"
                            value={lastName}
                            name={ESignUpFormField.LAST_NAME}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setLastName(e.target.value)
                            }
                        />
                    </ErrorMessageWrapper>

                    <ErrorMessageWrapper errorMessage={formErrors[ESignUpFormField.EMAIL]}>
                        <Input
                            placeholder="Email"
                            value={email}
                            name={ESignUpFormField.EMAIL}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setEmail(e.target.value)
                            }
                        />
                    </ErrorMessageWrapper>

                    <ErrorMessageWrapper errorMessage={formErrors[ESignUpFormField.PASSWORD]}>
                        <Input.Password
                            placeholder="Password"
                            value={password}
                            name={ESignUpFormField.PASSWORD}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setPassword(e.target.value)
                            }
                        />
                    </ErrorMessageWrapper>

                    <ErrorMessageWrapper
                        errorMessage={formErrors[ESignUpFormField.CONFIRM_PASSWORD]}
                    >
                        <Input.Password
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setConfirmPassword(e.target.value)
                            }
                        />
                    </ErrorMessageWrapper>

                    <Button type="primary" block loading={isLoading} onClick={onSignUpButtonClick}>
                        Sign Up
                    </Button>

                    <div className={cx('authentication__text')}>
                        <Text>
                            By signing up, you agree to our{' '}
                            <Link to="/terms-of-service" component={AntdLink}>
                                terms & conditions
                            </Link>{' '}
                            and acknowledge our{' '}
                            <Link to="/privacy-policy" component={AntdLink}>
                                privacy policy
                            </Link>
                            .
                        </Text>
                    </div>

                    <Divider />

                    {/* 
      Temporarily hiding google login until auth is sorted and stable

      <GoogleButton
        style={{ width: '100%' }}
        type="light"
        onClick={handleGoogleSignIn}
      /> */}

                    <Button block onClick={goToLogin} data-cy="sign-up-form__i-have-an-account-cta">
                        I Have An Account
                    </Button>
                </AuthenticationCard>
            </div>
        </div>
    );
};

export default SignUpForm;
