import React, { useCallback, useState } from 'react';

import { useAxiosInstance } from '../AxiosContext';

import PromoCodeInput from './PromoCodeInput';
import { EPromoCodeInput, POSTPaymentsPromoCodeValidate } from '../CreditCardModalV2/types';

type PromoCodeInputContainerProps = {
    productId: string;
    onValidateSuccess: (couponId: string) => void;
};

// TODO: evaluate possibility to replace PromoCodeInput in CreditCardModalV2 with this component
const PromoCodeInputContainer: React.FC<PromoCodeInputContainerProps> = ({
    productId,
    onValidateSuccess,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [promoCodeStatus, setPromoCodeStatus] = useState<EPromoCodeInput>(EPromoCodeInput.NULL);

    const axios = useAxiosInstance();

    const validatePromoCode = useCallback(async (promoCode: string) => {
        if (promoCode === '') return;

        try {
            setIsLoading(true);

            const payload = {
                code: promoCode,
                productId: productId,
            };

            const { data } = await axios.post<POSTPaymentsPromoCodeValidate>(
                '/private/v2/payments/product/promo-code/validate',
                payload
            );

            if (!data.active) {
                setPromoCodeStatus(EPromoCodeInput.INVALID);
                return;
            }

            setPromoCodeStatus(EPromoCodeInput.VALID);
            onValidateSuccess(data.couponId);
        } catch {
            setPromoCodeStatus(EPromoCodeInput.INVALID);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const handlePromoCodeReset = () => {
        setPromoCodeStatus(EPromoCodeInput.NULL);
    };
    return (
        <PromoCodeInput
            validatePromoCode={validatePromoCode}
            isLoading={isLoading}
            resetStatus={handlePromoCodeReset}
            validationStatus={promoCodeStatus}
        />
    );
};

export default PromoCodeInputContainer;
