import React, { useState } from 'react';
import classNames from 'classnames/bind';

import TypographyHeader from 'components/TypographyHeader';
import TypographySubtitle from 'components/TypographySubtitle';
import SubscriptionBox from 'components/SubscriptionBox';

import CreditCardModalV2 from './CreditCardModalV2';
import BulkPurchaseModal from './BulkPurchaseModal';
import FreeTrialModalV2 from './FreeTrialModalV2';
import { EProductType } from './types';
import {
    bulkPurchasePerks,
    deriveFriendsAndFamilyPerks,
    deriveIndividualPerks,
    trialPerks,
} from './constants';

import { STRIPE_INDIVIDUAL_PLAN_PRICE_ID, STRIPE_FAMILY_PLAN_PRICE_ID } from 'config';

import styles from './SubscriptionsV2.module.scss';

const cx = classNames.bind(styles);

type SubscriptionsV2Props = {
    eligibleForFreeTrial: boolean;
};

const SubscriptionsV2: React.FC<SubscriptionsV2Props> = ({ eligibleForFreeTrial }) => {
    const [priceId, setPriceId] = useState('');
    const [price, setPrice] = useState(0);
    const [productType, setProductType] = useState<EProductType>(EProductType.INDIVIDUAL);
    const [showCreditCardModal, setShowCreditCardModal] = useState(false);
    const [showFreeTrialModal, setShowFreeTrialModal] = useState(false);
    const [showBulkPurchaseModal, setShowBulkPurchaseModal] = useState(false);

    const handleOnIndividualClick = () => {
        setPriceId(STRIPE_INDIVIDUAL_PLAN_PRICE_ID);
        setPrice(15);
        setProductType(EProductType.INDIVIDUAL);
        setShowCreditCardModal(true);
    };

    const handleOnFriendsFamilyClick = () => {
        setPriceId(STRIPE_FAMILY_PLAN_PRICE_ID);
        setPrice(20);
        setProductType(EProductType.FAMILY);
        setShowCreditCardModal(true);
    };

    const handleBulkPurchaseClick = () => {
        setShowBulkPurchaseModal(true);
    };

    return (
        <>
            <div className={cx('container')}>
                <div className={cx('content')}>
                    <div className={cx('header-container')}>
                        <TypographyHeader level={3}>Welcome to Cloudscore!</TypographyHeader>
                    </div>

                    <div className={cx('sub-header-container')}>
                        <TypographySubtitle level={1}>Our Subscription Plan</TypographySubtitle>
                    </div>

                    <div className={cx('subscription-plans-container')}>
                        <SubscriptionBox
                            handleSelect={handleOnIndividualClick}
                            title="Individual"
                            perks={deriveIndividualPerks()}
                            pricePerMonth={15}
                        />

                        <SubscriptionBox
                            handleSelect={handleOnFriendsFamilyClick}
                            title="Friends & Family"
                            perks={deriveFriendsAndFamilyPerks(eligibleForFreeTrial)}
                            pricePerMonth={20}
                        />

                        <SubscriptionBox
                            handleSelect={handleBulkPurchaseClick}
                            title="Bulk Purchase"
                            perks={bulkPurchasePerks}
                            buttonText="Enquire"
                        />

                        <SubscriptionBox
                            handleSelect={() => setShowFreeTrialModal(true)}
                            title="Free 1-Day Trial"
                            perks={trialPerks}
                            buttonText="Get Your Free Trial"
                            isBoxDisabled={!eligibleForFreeTrial}
                            dataCy="free-trial-cta"
                        />
                    </div>
                </div>
            </div>

            <CreditCardModalV2
                isVisible={showCreditCardModal}
                price={price}
                priceId={priceId}
                productType={productType}
                handleOnCancel={() => setShowCreditCardModal(false)}
            />

            <FreeTrialModalV2
                isVisible={showFreeTrialModal}
                handleOnCancel={() => setShowFreeTrialModal(false)}
            />

            <BulkPurchaseModal
                isVisible={showBulkPurchaseModal}
                onCancel={() => setShowBulkPurchaseModal(false)}
            />
        </>
    );
};

export default SubscriptionsV2;
