import React from 'react';
import classnames from 'classnames/bind';
import { Button } from 'antd';

import TypographyHeader from '../TypographyHeader';
import TypographySubtitle from '../TypographySubtitle';
import SubscriptionPerk from './SubscriptionPerk';
import { SubscriptionPlanPerk } from './types';

import styles from './SubscriptionBox.module.scss';

const cx = classnames.bind(styles);

type SubscriptionsV2Props = {
    handleSelect: () => void;
    title: string;
    perks: SubscriptionPlanPerk[];
    pricePerMonth?: number;
    buttonText?: string;
    isBoxDisabled?: boolean;
    dataCy?: string;
};

const SubscriptionBox: React.FC<SubscriptionsV2Props> = ({
    handleSelect,
    title,
    perks,
    pricePerMonth,
    buttonText = 'Select',
    isBoxDisabled = false,
    dataCy = '',
}) => {
    return (
        <div className={cx('subscription-box', { 'is-disabled': isBoxDisabled })}>
            <TypographyHeader className={cx('subscription-box-header')} level={3}>
                {title}
            </TypographyHeader>

            {pricePerMonth ? (
                <TypographySubtitle className={cx('subscription-box-header')} level={1}>
                    USD ${pricePerMonth}{' '}
                    <span className={cx('subscription-box-header-span')}>/month</span>
                </TypographySubtitle>
            ) : (
                <div className={cx('price-spacer')} />
            )}

            <div className={cx('subscription-box-perks-container')}>
                {perks.map((perk, index) => (
                    <SubscriptionPerk key={index} isDisabled={perk.isDisabled}>
                        {perk.content}
                    </SubscriptionPerk>
                ))}
            </div>

            <Button
                onClick={handleSelect}
                type="primary"
                block
                className={cx('subscription-box-cta')}
                disabled={isBoxDisabled}
                data-cy={`subscription-box__${dataCy}`}
            >
                {buttonText}
            </Button>
        </div>
    );
};

export default SubscriptionBox;
