export enum EPromoCodeInput {
    'NULL',
    'VALID',
    'INVALID',
}

export type POSTPaymentsPromoCodeValidate = {
    /** if promo code is active */
    active: boolean;
    /**  amount off in cents */
    amountOff: number;
    /** the promo code id - leftover from initial implementation */
    id: string;
    /** the "human readable" promo code */
    code: string;
    /** if promo code is valid forever */
    validForever: true;
    /** couponId - the underlying coupon code id that we want to send to stripe */
    couponId: string;
};

export type POSTPaymentsPlansPriceIdSubscribe = {
    subscriptionId: string;
    expiry: number; // UNIX timestamp
};
