import React from 'react';
import moment from 'moment';
import classnames from 'classnames/bind';
import { DatePicker } from 'antd';

import TypographyBody from 'components/TypographyBody';

import styles from './IBDatePicker.module.scss';

const cx = classnames.bind(styles);

type MonthOption = {
    value: string;
    text: string;
};

type IBDatePickerProps = {
    monthOptions: MonthOption[];
    selectedMonth: string;
    selectedYear: number;
    onMonthOptionClick: (value: string) => void;
    onYearChange: (value: number) => void;
};

const IBDatePicker: React.FC<IBDatePickerProps> = ({
    monthOptions,
    selectedMonth,
    selectedYear,
    onMonthOptionClick,
    onYearChange,
}) => {
    return (
        <div className={cx('container')}>
            <div className={cx('month-row')}>
                <TypographyBody level={1} className={cx('label')}>
                    Month:
                </TypographyBody>

                {monthOptions.map((option) => (
                    <TypographyBody level={1} key={option.value}>
                        <div
                            className={cx('option', {
                                selected: selectedMonth === option.value,
                            })}
                            onClick={() => onMonthOptionClick(option.value)}
                        >
                            {option.text}
                        </div>
                    </TypographyBody>
                ))}
            </div>

            <div className={cx('year-row')}>
                <TypographyBody level={1} className={cx('label')}>
                    Year:
                </TypographyBody>

                <DatePicker
                    onChange={(_, date) => onYearChange(parseInt(date, 10))}
                    picker="year"
                    value={selectedYear ? moment(selectedYear, 'yyyy') : null}
                    dropdownClassName={cx('calendar-dropdown')}
                />
            </div>
        </div>
    );
};

export default IBDatePicker;
