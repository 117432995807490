import React from 'react';
import classnames from 'classnames/bind';
import { InfoCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import TypographyBody from '../TypographyBody';

import styles from './InfoBox.module.scss';

const cx = classnames.bind(styles);

type InfoBoxProps = {
    className?: string;
    type?: 'info' | 'error';
};

const InfoBox: React.FC<InfoBoxProps> = ({ className, children, type = 'info' }) => {
    const renderIcon = (type: string) => {
        switch (type) {
            case 'error':
                return <CloseCircleOutlined />;
            case 'info':
            default:
                return <InfoCircleOutlined />;
        }
    };

    return (
        <div className={cx('container', className, type)}>
            <div className={cx('icon-container')}>{renderIcon(type)}</div>
            <TypographyBody level={2} className={cx('text-container')}>
                {children}
            </TypographyBody>
        </div>
    );
};

export default InfoBox;
