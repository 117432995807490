import React, { useCallback, useMemo, useState } from 'react';

import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';
import { useAxiosInstance } from 'components/AxiosContext';

import InviteUser from './InviteUser';
import { ERROR_NOT_FOUND, ERROR_USER_ALREADY_SUBSCRIBED } from './constants';

type InviteUserContainerProps = {
    refetchFriendsAndFamilyMembers: () => void;
};

const InviteUserContainer: React.FC<InviteUserContainerProps> = ({
    refetchFriendsAndFamilyMembers,
}) => {
    const axios = useAxiosInstance();
    const { state } = useReducerContext();

    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const user = useMemo(() => getUserProfile(state), []);

    const inviteUser = useCallback(
        async (email: string) => {
            try {
                setIsLoading(true);
                setHasError(false);

                const payload = {
                    inviteeEmail: email,
                };

                await axios.post('/private/v2/users/friends-and-family/member', payload);
            } catch (err) {
                if (err?.response?.data?.code === ERROR_NOT_FOUND) {
                    throw { message: ERROR_NOT_FOUND };
                }

                if (err?.response?.data?.code === ERROR_USER_ALREADY_SUBSCRIBED) {
                    throw { message: ERROR_USER_ALREADY_SUBSCRIBED };
                }

                setHasError(true);
                throw Error();
            } finally {
                setIsLoading(false);
            }
        },
        [user]
    );

    const onSuccess = () => {
        refetchFriendsAndFamilyMembers();
    };

    return (
        <InviteUser
            isLoading={isLoading}
            inviteUser={inviteUser}
            hasError={hasError}
            onSuccess={onSuccess}
        />
    );
};

export default InviteUserContainer;
