import React, { useMemo, useCallback, useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';

import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';
import { useAxiosInstance } from 'components/AxiosContext';

import ShortVideos from './ShortVideos';
import { ShortVideosResponse } from './types';

const ShortVideosContainer: React.FC = () => {
    const client = useAxiosInstance();
    const { state } = useReducerContext();

    const profile = useMemo(() => getUserProfile(state), [state]);

    const [myVideos, setMyVideos] = useState<ShortVideosResponse[]>([]);

    const fetchUserVideos = useCallback(async () => {
        try {
            const { data } = await client.get<ShortVideosResponse[] | null>(
                '/private/v2/sv/requests',
                {
                    params: {
                        user_id: profile.cognitoId || '',
                    },
                }
            );

            setMyVideos(data || []);
        } catch (e) {
            Sentry.captureException(e);
        }
    }, [profile]);

    useEffect(() => {
        fetchUserVideos();
    }, [fetchUserVideos]);

    return (
        <div>
            <ShortVideos myVideos={myVideos} />
        </div>
    );
};

export default ShortVideosContainer;
