import React from 'react';
import classnames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import { SelectOutlined } from '@ant-design/icons';
import { Editor } from 'react-draft-wysiwyg';
import { format } from 'date-fns';

import { fileTypeColours } from 'pages/SelfStudyV2/ContentSelector/constants';
import { getFileTypeName } from 'pages/SelfStudyV2/ContentSelector/ContentSelector';
import { TCSFileTypeShape } from 'common/types';
import { DraftJSEditorStateNotes } from 'pages/MyNotes/types';
import TypographyBody from 'components/TypographyBody';
import TypographySubtitle from 'components/TypographySubtitle';

import styles from './NotesCard.module.scss';

const cx = classnames.bind(styles);

type NotesItemProps = {
    notes: DraftJSEditorStateNotes;
    fileTypes: TCSFileTypeShape[];
};

const constructUrlParams = (notes: DraftJSEditorStateNotes): string => {
    const params = new URLSearchParams();
    params.append('subject_id', notes.subjectId.toString());
    params.append('topic_id', notes.topicId.toString());
    params.append('file_key', notes.fileKey);
    params.append('is_notes_panel_open', 'true');

    return `?${params.toString()}`;
};

const NotesCard: React.FC<NotesItemProps> = ({ notes, fileTypes }) => {
    const url = () => {
        const params = constructUrlParams(notes);
        return `/self-study${params}`;
    };

    return (
        <div className={cx('container')}>
            <div className={cx('topic-container')}>
                <TypographySubtitle level={1} className={cx('header')}>
                    <Link to={url} target="_blank" rel="noopener noreferrer">
                        {notes.fileName}
                        <SelectOutlined />
                    </Link>
                </TypographySubtitle>

                <Tag color={fileTypeColours[notes.fileTypeId]}>
                    {getFileTypeName(fileTypes, notes.fileTypeId)}
                </Tag>
            </div>

            <TypographyBody level={2} className={cx('notes')}>
                <Editor readOnly={true} editorState={notes.notesTaken} toolbarHidden={true} />
            </TypographyBody>

            <TypographyBody level={2} className={cx('last-updated')}>
                Notes last updated on {format(notes.lastUpdated, 'dd/MM/yyyy')}
            </TypographyBody>
        </div>
    );
};

export default NotesCard;
