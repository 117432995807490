import React, { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';

import { useAxiosInstance, useCachedAxiosInstance } from 'components/AxiosContext';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';

import { GETQuizSetPayload } from '../types';
import QuizSetViewer from './QuizSetViewer';
import { deriveAttemptedQuizSetPayload, deriveInitialAttemptedQuizzes } from './utils';
import { POSTAttemptedQuizSetPayload, POSTQuizzes } from './types';

type QuizSetViewerContainerProps = {
    handleGoBack: () => void;
    quizSetId: number;
    handleRefetch: () => void;
    handleRedirectToPastAttemptedResult: (submittedDate: number) => void;
};

const QuizSetViewerContainer: React.FC<QuizSetViewerContainerProps> = ({
    handleGoBack,
    quizSetId,
    handleRefetch,
    handleRedirectToPastAttemptedResult,
}) => {
    const { state } = useReducerContext();
    const { user } = state;
    const cachedAxios = useCachedAxiosInstance();
    const axios = useAxiosInstance();

    const [isLoading, setIsLoading] = useState(false);

    const [activeQuizSet, setActiveQuizSet] = useState<GETQuizSetPayload | null>(null);
    const [attemptedQuizzes, setAttemptedQuizzes] = useState<POSTQuizzes[]>([]);

    const fetchQuizSet = useCallback(async () => {
        try {
            setIsLoading(true);

            const { data } = await cachedAxios.get<GETQuizSetPayload>(
                `/private/v2/quiz/set?quizSetId=${quizSetId}`
            );

            data.quizzes.sort((a, b) => (a.id > b.id ? 1 : -1));
            setActiveQuizSet(data);
            setAttemptedQuizzes(deriveInitialAttemptedQuizzes(data.quizzes));
        } catch {
            message.error('Something went wrong. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    }, []);

    const updateAttemptedQuizzes = (updated: POSTQuizzes[]) => {
        setAttemptedQuizzes(updated);
    };

    const submitAttemptedQuizzes = async (submittedDate: number) => {
        if (!activeQuizSet || !user?.cognitoId) {
            return;
        }

        const payload = deriveAttemptedQuizSetPayload(
            activeQuizSet,
            attemptedQuizzes,
            user.cognitoId,
            submittedDate
        );

        try {
            setIsLoading(true);

            await axios.post<POSTAttemptedQuizSetPayload>('/private/v2/quiz/set', payload);
        } catch {
            throw Error();
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchQuizSet();
    }, []);

    return (
        <QuizSetViewer
            isLoading={isLoading}
            handleGoBack={handleGoBack}
            quizSet={activeQuizSet}
            attemptedQuizzes={attemptedQuizzes}
            updateAttemptedQuizzes={updateAttemptedQuizzes}
            submitAttemptedQuizzes={submitAttemptedQuizzes}
            handleRefetch={handleRefetch}
            handleRedirectToPastAttemptedResult={handleRedirectToPastAttemptedResult}
        />
    );
};

export default QuizSetViewerContainer;
