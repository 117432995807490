import React from 'react';
import ReactPlayer from 'react-player';
import classnames from 'classnames/bind';

import styles from './ArcVideoPlayer.module.scss';

const cx = classnames.bind(styles);

type ArcVideoPlayerProps = {
    videoUrl: string;
    videoWidth?: string;
    videoHeight?: string;
    onStart?: () => void;
};

const ArcVideoPlayer: React.FC<ArcVideoPlayerProps> = ({
    videoUrl,
    videoWidth,
    videoHeight,
    onStart,
}) => {
    return (
        <div className={cx('container')}>
            <ReactPlayer
                className={cx('react-player')}
                url={videoUrl}
                controls
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                onContextMenu={(e: React.MouseEvent) => e.preventDefault()}
                width={videoWidth || '1080px'}
                height={videoHeight || '675px'}
                onStart={onStart}
            />
        </div>
    );
};

export default ArcVideoPlayer;
