import React, { useEffect, useMemo, useState } from 'react';

import { useAxiosInstance } from 'components/AxiosContext';
import { useAuth } from 'components/AuthContextProvider';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';

import QuizChallenge from './QuizChallenge';
import { POSTQuizChallengeStartPayload } from './types';
import { generateUuidForUnregisteredUser } from './utils';

const QuizChallengeContainer: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [userUuid, setUserUuid] = useState('');

    const axios = useAxiosInstance();
    const { userAttr } = useAuth();

    const { state } = useReducerContext();
    const profile = useMemo(() => getUserProfile(state), [state]);

    const startGame = async (
        topicIdList: number[],
        subjectId: number
    ): Promise<POSTQuizChallengeStartPayload> => {
        try {
            setIsLoading(true);

            const payload = {
                userUuid: userUuid,
                email: userAttr.email || 'UnregisteredUser',
                firstName: userAttr.firstName || 'UnregisteredUser',
                lastName: userAttr.lastName || 'UnregisteredUser',
                selectedTopicIdList: topicIdList,
                subjectId,
            };

            const { data } = await axios.post<POSTQuizChallengeStartPayload>(
                '/api/v2/quiz-challenge/start',
                payload
            );

            return data;
        } catch {
            throw Error();
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (userUuid) {
            return;
        }

        if (!profile.cognitoId) {
            const unregisteredUserUuid = generateUuidForUnregisteredUser();
            setUserUuid(unregisteredUserUuid);
            return;
        }

        setUserUuid(profile.cognitoId);
    }, [profile, userUuid]);

    return (
        <QuizChallenge
            startGame={startGame}
            isLoading={isLoading}
            userUuid={userUuid}
            onResetGame={() => setUserUuid('')}
        />
    );
};

export default QuizChallengeContainer;
