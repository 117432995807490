import { message } from 'antd';
import React, { useEffect, useState } from 'react';

import { useAxiosInstance } from 'components/AxiosContext';

import PreviewLessons from './PreviewLessons';
import { PreviewRecordedLesson, PurchasedRecordedLesson } from '../types';

type PreviewLessonsContainerProps = {
    purchasedLessons: PurchasedRecordedLesson[];
};
const PreviewLessonsContainer: React.FC<PreviewLessonsContainerProps> = ({ purchasedLessons }) => {
    const [recordedLessons, setRecordedLessons] = useState<PreviewRecordedLesson[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const axios = useAxiosInstance();

    const fetchActivePreviewRecordedLessons = async () => {
        try {
            setIsLoading(true);

            const { data } = await axios.get('/private/v2/recorded-lessons/all');
            setRecordedLessons(data);
        } catch {
            message.error('Something went wrong, please try again later');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchActivePreviewRecordedLessons();
    }, []);

    return (
        <PreviewLessons
            previewLessons={recordedLessons}
            isLoading={isLoading}
            purchasedLessons={purchasedLessons}
        />
    );
};

export default PreviewLessonsContainer;
