import { add } from 'date-fns';

import { UserProfile } from 'components/ReducerContext/state';

import { EProductType } from '../types';

export const ERR_GENERIC = 'Oops! Looks like something went wrong. Please try again later';
export const ERR_PROCESS_PAYMENT =
    'Oops! Looks like there was an error processing your payment. Please try again later';

export const deriveAmountOff = (amountOff: number): string => {
    return safelyConvertCentsToDisplayDollars(amountOff);
};

const safelyConvertCentsToDisplayDollars = (cents: number): string => {
    const centsArray = cents.toString().split('');
    if (centsArray.length === 1) return `0.0${cents}`;
    if (centsArray.length === 2) return `0.${cents}`;

    centsArray.splice(-2, 0, '.');
    return centsArray.join('');
};

/**
 * @param amountOff - amountOff in CENTS
 * @param price - price in DOLLARS
 */
export const deriveGrandTotal = (amountOff: number, price: number): string => {
    const priceInCents = price * 100;
    const nett = priceInCents - amountOff;
    return safelyConvertCentsToDisplayDollars(nett);
};

export const getFullname = (user: UserProfile): string => {
    const { firstName = 'User', lastName = '' } = user;

    if (lastName === '') return firstName;

    return `${firstName} ${lastName}`;
};

export const derive30DaysFromNow = (): number => {
    const expiry = add(new Date(), { days: 30 });

    // returns UNIX timestamp
    return Math.floor(expiry.getTime() / 1000);
};

export const deriveZuluTime = (unixTimestamp: number): string => {
    const date = new Date(unixTimestamp * 1000);
    if (date.toString() === 'Invalid Date') return '';

    return date.toISOString();
};

export const derivePromoCodePayload = (code: string, productType: EProductType) => ({
    code,
    productType,
});
