import React from 'react';
import classNames from 'classnames/bind';

import TypographyHeader from 'components/TypographyHeader';
import TypographySubtitle from 'components/TypographySubtitle';

import styles from './FreeTrialModalV2.module.scss';

const cx = classNames.bind(styles);

const FreeTrialSuccess: React.FC = () => (
    <div className={cx('success-container')}>
        <div className={cx('header-container')}>
            <TypographyHeader level={3}>Success!</TypographyHeader>
        </div>

        <div className={cx('subtitle-container')}>
            <TypographySubtitle level={1}>
                You have successfully redeemed your free trial
            </TypographySubtitle>

            <br />

            <TypographySubtitle level={1}>Please wait while we redirect you...</TypographySubtitle>
        </div>
    </div>
);

export default FreeTrialSuccess;
