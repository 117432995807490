import Auth, { CognitoUser } from '@aws-amplify/auth';

const signIn = (cb: (u: CognitoUser) => void) => async (username: string, password: string) => {
    const user = (await Auth.signIn(username, password)) as CognitoUser;
    cb(user);
};

const signOut = (cb: () => void) => async () => {
    await Auth.signOut();
    cb();
};

const signUp = async (username: string, password: string, firstName: string, lastName: string) => {
    await Auth.signUp({
        username,
        password,
        attributes: {
            email: username,
            given_name: firstName,
            family_name: lastName,
        },
    });
};

const confirmEmail = async (email: string, code: string) => {
    await Auth.confirmSignUp(email, code);
};

const resendEmailConfirmationCode = async (email: string) => {
    await Auth.resendSignUp(email);
};

const changePassword = async (oldPw: string, newPw: string) => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPw, newPw);
};

const forgotPassword = async (email: string) => {
    await Auth.forgotPassword(email);
};

const resetPassword = async (email: string, code: string, newPassword: string) => {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
};

export default {
    signIn,
    signOut,
    confirmEmail,
    signUp,
    resendEmailConfirmationCode,
    changePassword,
    forgotPassword,
    resetPassword,
};

// Might reinstate when we add back google auth
// export const googleSignIn = async () => {
//     await Auth.federatedSignIn({
//         provider: CognitoHostedUIIdentityProvider.Google,
//     });
// };
