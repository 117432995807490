import React from 'react';
import classnames from 'classnames/bind';

import style from './ProfileIcon.module.scss';

const cx = classnames.bind(style);

type ProfileIconProps = {
    userInitial: string;
    className?: string;
};

const ProfileIcon: React.FC<ProfileIconProps> = ({ userInitial, className }) => {
    return (
        <div className={cx('avatar-container', className)}>
            <div className={cx('initial-placeholder')}>{userInitial}</div>
        </div>
    );
};

export default ProfileIcon;
