import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import * as Sentry from '@sentry/react';

import { sleep } from 'common/utils';
import { ESubscriptionPlanType } from 'common/types';
import { IUserAttributes } from 'components/AuthContextProvider/types';
import {
    EFreeTrialStatus,
    GETProfilesSubscriptionInfoPayload,
    UserProfile,
} from 'components/ReducerContext/state';

/**
 * for the endpoint:
 * /private/v2/users/profile
 */
export type GETUsersProfilePayload = {
    id: number;
    authId: string;
    paymentId: string;
    subscriptionId: string;
    firstName: string;
    lastName: string;
    subscriptionStatus: string; // 'active' | 'expired' -> full list found here https://stripe.com/docs/api/subscriptions/object#subscription_object-status
    subscriptionExpiry: number; // unix timestamp
    subscriptionPlanType: ESubscriptionPlanType;
    freeTrialStatus: EFreeTrialStatus;
    freeTrialExpiry: number; // unix timestamp
    userCohort: string;
};

// full list found here https://stripe.com/docs/api/subscriptions/object#subscription_object-status
enum ESubscriptionStatus {
    ACTIVE = 'active',
}

const unixToUTC = (unix: number): string => {
    return new Date(unix * 1000).toUTCString();
};

export const refreshCognitoJWT = async () => {
    let cognitoUserName = '';
    let token = '';

    try {
        const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
        cognitoUserName = cognitoUser?.getUsername();

        const currentSession = await Auth.currentSession();
        const refreshToken = currentSession.getRefreshToken();

        cognitoUser.refreshSession(refreshToken, (err, session) => {
            if (err) {
                throw Error(err);
            }

            // leaving this here so that we know the available tokens
            // that the session returns
            // eslint-disable-next-line
            const { idToken, refreshToken, accessToken } = session;
            token = idToken;
        });
    } catch (e) {
        // console.log('Unable to refresh Token', e);
        Sentry.captureException(`[LOG]: Unable to refresh token`, {
            contexts: {
                refreshContext: {
                    error: e,
                    user: cognitoUserName,
                },
            },
        });
    }

    return token;
};

export const deriveUserProfile = (user: GETUsersProfilePayload): UserProfile => ({
    id: user.id,
    cognitoId: user.authId,
    stripeId: user.paymentId,
    firstName: user.firstName,
    lastName: user.lastName,
    userCohort: user.userCohort,
    subscriptionDetails: {
        subscriptionId: user.subscriptionId,
        isSubscribed: user.subscriptionStatus === ESubscriptionStatus.ACTIVE,
        // temporarily store as UTC, should not be a problem
        // eventually should just store as unix
        subscriptionExpiry: unixToUTC(user.subscriptionExpiry),
        freeTrialStatus: user.freeTrialStatus,
        freeTrialExpiry: unixToUTC(user.freeTrialExpiry),
        subscriptionPlanType: user.subscriptionPlanType,
    },
});

export const deriveCreateUserPayload = (userAttr: IUserAttributes) => ({
    authId: userAttr.cognitoId || '',
    email: userAttr.email || '',
    firstName: userAttr.firstName || '',
    lastName: userAttr.lastName || '',
});

export const handleFetchUserFail = async (logout: () => Promise<void>) => {
    await sleep(5000);
    await logout();
};

export enum ELoadingMessage {
    DEFAULT = 'Please wait while we retrieve your profile...',
    FIRST_TIME = 'This is your first time logging in! This will take awhile...',
    ERROR = 'Oops! Something went wrong when trying to fetch your profile. We will be logging you out shortly... If this error persists, please reach out to our support team!',
}

export const isFreeTrialOrSubscriber = (profile?: GETProfilesSubscriptionInfoPayload): boolean => {
    if (profile?.isSubscribed) return true;
    if (profile?.freeTrialStatus === EFreeTrialStatus.ACTIVE) return true;

    return false;
};
