import React from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames/bind';
import { Image } from 'antd';

import QuizChallengeScreenshot from 'static/landing/quiz-challenge-screenshot.png';
import { scrollToTop } from 'common/utils';
import CTAButton from 'components/CTAButton';

import styles from './QuizChallengeCTASection.module.scss';
const cx = classnames.bind(styles);

const QuizChallengeCTASection: React.FC = () => {
    const history = useHistory();
    const pushToQuizChallenge = () => {
        scrollToTop();
        history.push('/quiz-challenge');
    };

    return (
        <div className={cx('container')}>
            <div className={cx('section-title')}>
                Still undecided? Play our free Quiz Challenge and get a sneak peak of our resources!
            </div>
            <div className={cx('image-container')}>
                <Image src={QuizChallengeScreenshot} width={600} preview={false} />
            </div>
            <div className={cx('button-container')}>
                <CTAButton onClick={pushToQuizChallenge}>Play Now</CTAButton>
            </div>
        </div>
    );
};

export default QuizChallengeCTASection;
