import React, { useMemo } from 'react';
import { useLocation } from 'react-router';

import { useReducerContext } from '../ReducerContext/ReducerContext';
import { getUserProfile } from '../ReducerContext/selectors';
import { EFreeTrialStatus } from '../ReducerContext/state';

import AskAQuestion from './AskAQuestion';

const AskAQuestionContainer: React.FC = () => {
    const { state } = useReducerContext();
    const profile = useMemo(() => getUserProfile(state), [state]);

    const location = useLocation();

    const eligibleForAskAQuestion = useMemo(
        () =>
            profile.subscriptionDetails?.isSubscribed ||
            profile.subscriptionDetails?.freeTrialStatus === EFreeTrialStatus.ACTIVE,
        [profile]
    );

    const isOnQuizChallengeRoute = useMemo(
        () => location.pathname === '/quiz-challenge',
        [location.pathname]
    );

    // we show Ask A Question only if the user is logged in and subscribed
    if (!eligibleForAskAQuestion || isOnQuizChallengeRoute) {
        return <div />;
    }

    return <AskAQuestion />;
};

export default AskAQuestionContainer;
