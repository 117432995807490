import { EIconStatus } from './types';

export const deriveIndicatorStatus = (
    current: number,
    index: number,
    isAnswered: boolean
): EIconStatus => {
    if (current === index) {
        return EIconStatus.CURRENT;
    }

    if (isAnswered) {
        return EIconStatus.ANSWERED;
    }

    return EIconStatus.UNANSWERED;
};
