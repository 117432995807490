import React from 'react';
import classnames from 'classnames/bind';
import { Button, Tooltip, Input } from 'antd';

import styles from './MessageBox.module.scss';
const cx = classnames.bind(styles);

const { TextArea } = Input;

type MessageBoxProps = {
    inputMessage: string;
    handleMessageInput: (input: string) => void;
    handleSubmit: () => Promise<void>;
    isDisabled: boolean;
};

const MessageBox: React.FC<MessageBoxProps> = ({
    inputMessage,
    handleMessageInput,
    handleSubmit,
    isDisabled,
}) => {
    const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault(); // to prevent additional input after using the Shift + Enter shortcut
            handleSubmit();
        }
    };

    return (
        <div className={cx('container')}>
            <TextArea
                placeholder="Message"
                value={inputMessage}
                onChange={(e) => handleMessageInput(e.target.value)}
                rows={3}
                onKeyPress={handleKeyPress}
                disabled={isDisabled}
            />

            <div className={cx('button-container')}>
                <Tooltip title="Shift + Enter">
                    {/*Tooltip causes issue with the button CSS when it's disabled, we need to wrap the button*/}
                    {/*with a div to circumvent the issue*/}
                    {/*REF: https://github.com/ant-design/ant-design/issues/18842*/}
                    <div>
                        <Button
                            className={cx('submit-button')}
                            onClick={handleSubmit}
                            disabled={isDisabled}
                        >
                            Submit
                        </Button>
                    </div>
                </Tooltip>
            </div>
        </div>
    );
};

export default MessageBox;
