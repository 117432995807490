import React from 'react';
import classnames from 'classnames/bind';
import { Modal, Button, Image } from 'antd';
import { add } from 'date-fns';

import advertisingImage from 'static/advertising-popup/tmcc_ap.jpg';

import styles from './AdvertisingPopup.module.scss';

const cx = classnames.bind(styles);

type AdvertisingPopupProps = {
    showModal: boolean;
    closeModal: () => void;
    takeMeThere: () => void;
};

const AdvertisingPopup: React.FC<AdvertisingPopupProps> = ({
    showModal,
    closeModal,
    takeMeThere,
}) => {
    const handleOnClose = () => {
        const doNotShowUntil = add(new Date(), { days: 7 });
        const doNotShowUntilUNIX = Math.floor(doNotShowUntil.getTime() / 1000);
        window.localStorage.setItem('__arclrn.doNotShowUntil', doNotShowUntilUNIX.toString());

        closeModal();
    };

    const handleTakeMeThere = () => {
        takeMeThere();
        closeModal();
    };

    return (
        <Modal
            visible={showModal}
            footer={null}
            destroyOnClose
            closable={false}
            onCancel={handleOnClose}
            bodyStyle={{ padding: 0 }}
            width={800}
        >
            <div className={cx('background-container')}>
                <div className={cx('container')}>
                    <div className={cx('title-container')}>
                        <h1>Have You Tried It Yet?</h1>
                    </div>

                    <div className={cx('image-container')}>
                        <Image
                            src={advertisingImage}
                            height={360}
                            width={640}
                            alt="advertising"
                            preview={false}
                        />
                    </div>

                    <div className={cx('footer-container')}>
                        <div>
                            <Button
                                type="default"
                                onClick={handleOnClose}
                                style={{ marginRight: '10px' }}
                            >
                                Hide for a week
                            </Button>

                            <Button type="primary" onClick={handleTakeMeThere}>
                                Take Me There!
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default AdvertisingPopup;
