import React from 'react';
import classnames from 'classnames/bind';
import {
    ExclamationCircleOutlined,
    ExclamationCircleTwoTone,
    LoadingOutlined,
} from '@ant-design/icons';

import { EThisIsHardState } from 'pages/QuestionBank/types';

import styles from './Tooltips.module.scss';

const cx = classnames.bind(styles);

type ThisIsHardProps = {
    callback: () => void;
    status: string;
};

const ThisIsHard: React.FC<ThisIsHardProps> = ({ callback, status }) => {
    const handleOnClick = () => {
        if (status === EThisIsHardState.LOADING) {
            return;
        }

        callback();
    };

    const handleRender = () => {
        if (status === EThisIsHardState.ACTIVE) {
            return (
                <ExclamationCircleTwoTone
                    style={{ fontSize: '28px' }}
                    className={cx('this-is-hard-active')}
                />
            );
        }

        if (status === EThisIsHardState.INACTIVE) {
            return <ExclamationCircleOutlined style={{ fontSize: '28px' }} />;
        }

        return <LoadingOutlined style={{ fontSize: '28px' }} />;
    };

    return (
        <div className={cx('this-is-hard')} onClick={handleOnClick}>
            {handleRender()}
            <div className={cx('text', { 'active-text': status === EThisIsHardState.ACTIVE })}>
                This is Hard
            </div>
        </div>
    );
};

export default ThisIsHard;
