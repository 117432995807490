import produce from 'immer';

import DefaultState, { ReducerStateType } from './state';
import { ECommonActions } from './Actions/CommonActions';
import {
    EUserActions,
    SetUserFreeTrialInfoPayload,
    SetUserSubscriptionInfoPayload,
} from './Actions/UserActions';

type UnionActions = EUserActions | ECommonActions;

export type Action = {
    type: UnionActions;
    payload: any;
};

const reducer = (state: ReducerStateType = DefaultState, action: Action) => {
    switch (action.type) {
        case EUserActions.setUserProfile:
            return produce(state, (draft) => {
                draft.user = action.payload;
                return draft;
            });

        case EUserActions.setUserSubscriptionInfo:
            return produce(state, (draft) => {
                if (draft.user) {
                    if (!draft.user?.subscriptionDetails) {
                        draft.user.subscriptionDetails = {};
                    }
                    const { subId, subExpiry, isSubbed } =
                        action.payload as SetUserSubscriptionInfoPayload;

                    draft.user.subscriptionDetails.subscriptionId = subId;
                    draft.user.subscriptionDetails.subscriptionExpiry = subExpiry;
                    draft.user.subscriptionDetails.isSubscribed = isSubbed;
                }
                return draft;
            });

        case EUserActions.setUserFreeTrialInfo:
            return produce(state, (draft) => {
                if (draft.user && draft.user.subscriptionDetails) {
                    const { status, expiry } = action.payload as SetUserFreeTrialInfoPayload;
                    draft.user.subscriptionDetails.freeTrialStatus = status;
                    draft.user.subscriptionDetails.freeTrialExpiry = expiry;
                }

                return draft;
            });

        case EUserActions.setLandedOnProtectedRoute:
            return produce(state, (draft) => {
                draft.landedOnProtectedRoute = action.payload;

                return draft;
            });

        case EUserActions.setUserCohort:
            return produce(state, (draft) => {
                if (draft.user) {
                    draft.user.userCohort = action.payload;
                }

                return draft;
            });

        case EUserActions.setGetStudyPlanCounter:
            return produce(state, (draft) => {
                draft.getStudyPlanCounter = action.payload;

                return draft;
            });

        case ECommonActions.resetStore:
            return DefaultState;

        case ECommonActions.set401Error:
            return produce(state, (draft) => {
                draft.config.is401Error = action.payload;
                return draft;
            });

        case ECommonActions.loadSubjects:
            return produce(state, (draft) => {
                draft.subjects = action.payload;
                return draft;
            });

        case ECommonActions.loadTopics:
            return produce(state, (draft) => {
                draft.topics = action.payload;
                return draft;
            });

        case ECommonActions.loadFileTypes:
            return produce(state, (draft) => {
                draft.fileTypes = action.payload;
                return draft;
            });

        default:
            return state;
    }
};

export default reducer;
