import React, { useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { Button, Image, Spin } from 'antd';

import FloatingToolbar from 'components/FloatingToolbar';
import GoBack from 'components/FloatingToolbar/Tooltips/GoBack';
import TypographyHeader from 'components/TypographyHeader';
import TypographySubtitle from 'components/TypographySubtitle';
import ProgressIndicator from 'components/ProgressIndicator';
import OptionCard from 'components/OptionCard';
import Summary from './Summary';
import { useQuizzesHelper } from './hooks';
import { GETQuizSetPayload } from '../types';
import { POSTQuizzes } from './types';

import styles from './QuizSetViewer.module.scss';

const cx = classnames.bind(styles);

type QuizSetViewerProps = {
    isLoading: boolean;
    handleGoBack: () => void;
    quizSet: GETQuizSetPayload | null;
    attemptedQuizzes: POSTQuizzes[];
    updateAttemptedQuizzes: (updated: POSTQuizzes[]) => void;
    submitAttemptedQuizzes: (currentDate: number) => Promise<void>;
    handleRefetch: () => void;
    handleRedirectToPastAttemptedResult: (submittedDate: number) => void;
};

const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

const QuizSetViewer: React.FC<QuizSetViewerProps> = ({
    isLoading,
    quizSet,
    handleGoBack,
    attemptedQuizzes,
    updateAttemptedQuizzes,
    submitAttemptedQuizzes,
    handleRefetch,
    handleRedirectToPastAttemptedResult,
}) => {
    const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
    const [showSummary, setShowSummary] = useState(false);

    const currentQuiz = useMemo(
        () => quizSet?.quizzes[currentQuizIndex],
        [currentQuizIndex, quizSet?.quizzes]
    );

    const currentQuizAnswers = useMemo(
        () => currentQuiz?.quizAnswers.sort((a, b) => (a.id > b.id ? 1 : -1)) || [],
        [currentQuiz]
    );

    const { isFirstQuiz, isLastQuiz } = useQuizzesHelper(currentQuizIndex, quizSet?.quizzes || []);

    const handleOptionCardClick = (quizAnswerId: number) => {
        const updated = [...attemptedQuizzes];

        const isCurrentAnswerSelected =
            attemptedQuizzes[currentQuizIndex].selectedAnswerId &&
            attemptedQuizzes[currentQuizIndex].selectedAnswerId === quizAnswerId;

        updated[currentQuizIndex].selectedAnswerId = isCurrentAnswerSelected ? 0 : quizAnswerId;

        updateAttemptedQuizzes(updated);
    };

    const handlePreviousQuestion = () => {
        if (isFirstQuiz) {
            return;
        }

        setCurrentQuizIndex((prevState) => prevState - 1);
        scrollToTop();
    };

    const handleNextQuestion = () => {
        if (isLastQuiz) {
            return;
        }

        setCurrentQuizIndex((prevState) => prevState + 1);
        scrollToTop();
    };

    const handleStepsOnChange = (current: number) => {
        setCurrentQuizIndex(current);
    };

    const handleReviewQuiz = (current: number) => {
        setShowSummary(false);
        setCurrentQuizIndex(current);
    };

    const reset = () => {
        setShowSummary(false);
        setCurrentQuizIndex(0);
        updateAttemptedQuizzes([]);
    };

    const handleSubmitQuizOnSuccess = () => {
        reset();
        handleRefetch();
    };

    const isAnsweredArray = useMemo(
        () => attemptedQuizzes.map((quiz) => quiz.selectedAnswerId !== 0),
        [attemptedQuizzes]
    );

    const renderContent = () => {
        if (isLoading) {
            return (
                <div className={cx('spin-container')}>
                    <Spin size="large" />
                </div>
            );
        }

        if (attemptedQuizzes.length === 0 || !currentQuiz) {
            return <div>The current quiz set is empty. </div>;
        }

        return (
            <div>
                <div className={cx('progress-indicator')}>
                    <ProgressIndicator
                        currentStep={currentQuizIndex}
                        onChange={handleStepsOnChange}
                        isAnsweredArray={isAnsweredArray}
                    />
                </div>

                <TypographySubtitle level={1} className={cx('subtitle')}>
                    Q{currentQuizIndex + 1}. {currentQuiz.questionText}
                </TypographySubtitle>

                {currentQuiz.questionImage !== '' && (
                    <div className={cx('image-container')}>
                        <Image src={currentQuiz.questionImage} width={595} preview={false} />
                    </div>
                )}
                <div>
                    {currentQuizAnswers.map((quizAnswer) => (
                        <OptionCard
                            key={quizAnswer.id}
                            name={quizAnswer.answerText}
                            onClick={() => handleOptionCardClick(quizAnswer.id)}
                            isSelected={
                                attemptedQuizzes[currentQuizIndex].selectedAnswerId ===
                                quizAnswer.id
                            }
                        />
                    ))}
                </div>

                <div className={cx('buttons-footer')}>
                    <Button onClick={handlePreviousQuestion} disabled={isFirstQuiz}>
                        Previous Question
                    </Button>

                    {isLastQuiz ? (
                        <Button
                            onClick={() => setShowSummary(true)}
                            type="primary"
                            className={cx('view-summary-button')}
                        >
                            View Summary
                        </Button>
                    ) : (
                        <Button onClick={handleNextQuestion}>Next Question</Button>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className={cx('container')}>
            {showSummary ? (
                <Summary
                    quizzes={attemptedQuizzes}
                    handleGoBack={() => setShowSummary(false)}
                    handleQuizCardClick={handleReviewQuiz}
                    onSubmitSuccess={handleSubmitQuizOnSuccess}
                    submitAttemptedQuizzes={submitAttemptedQuizzes}
                    isLoading={isLoading}
                    handleRedirectToPastAttemptedResult={handleRedirectToPastAttemptedResult}
                />
            ) : (
                <div className={cx('content-container')}>
                    <TypographyHeader className={cx('title')} level={3}>
                        {quizSet?.name}
                    </TypographyHeader>

                    {renderContent()}
                </div>
            )}
            <FloatingToolbar tooltips={[<GoBack key="go-back" callback={handleGoBack} />]} />
        </div>
    );
};

export default QuizSetViewer;
