import { CognitoUser } from '@aws-amplify/auth';
import { IAuthContext, IUserAttributes } from './types';

export const defaultAuthContext: IAuthContext = {
    isLoggedIn: false,
    userIsChecked: false,
    token: '',
    setToken: () => {},
    userAttr: {},
    signIn: async () => {},
    signUp: async () => {},
    signOut: async () => {},
    changePassword: async () => {},
    confirmEmail: async () => {},
    resendEmailConfirmationCode: async () => {},
    forgotPassword: async () => {},
    resetPassword: async () => {},
};

export const deriveUserAttr = (user: CognitoUser): IUserAttributes => {
    const newUserAttrs: IUserAttributes = {};

    user.getUserAttributes((_, attr) => {
        attr?.forEach((a) => {
            switch (a.Name) {
                case 'sub':
                    newUserAttrs.cognitoId = a.Value;
                    break;
                case 'email':
                    newUserAttrs.email = a.Value;
                    break;
                case 'given_name':
                    newUserAttrs.firstName = a.Value;
                    break;
                case 'family_name':
                    newUserAttrs.lastName = a.Value;
                    break;
            }
        });
    });

    return newUserAttrs;
};
