import React from 'react';
import { Row, Col, Spin } from 'antd';
import classnames from 'classnames/bind';

import TypographyBody from 'components/TypographyBody';

import { displaySubscriptionDate } from '../utils';

import styles from './FamilyMemberMySubscription.module.scss';

const cx = classnames.bind(styles);

type MySubscriptionProps = {
    isLoading: boolean;
    hasError: boolean;
    cancelAtPeriodEnd: boolean;
    subscriptionEnd: number;
    subscriptionPlanName: string;
};

const FamilyMemberMySubscription: React.FC<MySubscriptionProps> = ({
    isLoading,
    hasError,
    cancelAtPeriodEnd,
    subscriptionEnd,
    subscriptionPlanName,
}) => {
    if (isLoading) {
        return (
            <div className={cx('container')}>
                <Spin size="large" />
            </div>
        );
    }

    if (hasError) {
        return (
            <div className={cx('error-container')}>
                <TypographyBody className={cx('title')} level={1}>
                    Oops, an error has occurred when trying to retrieve your subscription
                </TypographyBody>
            </div>
        );
    }

    return (
        <div className={cx('container')}>
            <Row gutter={[10, 16]}>
                <Col span={6}>Subscription Plan:</Col>
                <Col span={18}>{subscriptionPlanName}</Col>

                <Col span={6}>Plan Renewal Date:</Col>
                <Col span={18}>
                    <span>{displaySubscriptionDate(subscriptionEnd)} </span>

                    {/** TODO: decide on "Expiring On" copy & style */}
                    <span>{cancelAtPeriodEnd ? '(Expiring On)' : '(Auto Renew)'}</span>
                </Col>
            </Row>
        </div>
    );
};

export default FamilyMemberMySubscription;
