import { ERecommendedContentType } from '../constants';
import { QuizChallengeQuestion } from '../types';

export enum ESubmitQuizAction {
    SKIP = 'SKIP',
    ANSWER = 'ANSWER',
}

export enum EQuizActionResult {
    CONT = 'CONTINUE',
    END = 'END',
}

export type POSTQuizChallengeSubmitPayload = {
    actionResult: EQuizActionResult;
    actionPayload: QuizChallengeQuestion;
    recommendedContentUrl: string;
    recommendedContentType: ERecommendedContentType;
    recommendedContentTitle: string;
    explanationNotes: string;
    correctAnswerId: number;
};
