import React from 'react';
import { Route, Switch } from 'react-router-dom';

import RedirectWrapper from './RedirectWrapper';

import LandingV2 from 'pages/LandingV2';
import AboutUs from 'pages/AboutUs';
import Login from 'pages/Login';
import MyAccountV2 from 'pages/MyAccountV2';
import SubscriptionsV2 from 'pages/SubscriptionsV2';
import SubscriptionsSuccess from 'pages/SubscriptionSuccess';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import TermsOfService from 'pages/TermsOfService';
import FAQV2 from 'pages/FAQV2/FAQV2';
import SubscriptionInfoV2 from 'pages/SubscriptionInfoV2';
import ContactUsV2 from 'pages/ContactUsV2';
import MyDashboard from 'pages/MyDashboard';
import SelfStudyV2 from 'pages/SelfStudyV2';
import QuestionBank from 'pages/QuestionBank';
import Quizzes from 'pages/Quizzes';
import MyFavourites from 'pages/MyFavourites';
import MyNotes from 'pages/MyNotes';
import IBEcons from 'pages/IBEcons';
import QuizChallenge from 'pages/QuizChallenge';
import RecordedLesson from 'pages/RecordedLesson';
import StudyBot from 'pages/StudyBot';
import ShortVideos from 'pages/ShortVideos';

type RoutesComponentProps = {
    isLoggedIn?: boolean;
    isSubscriber?: boolean;
};

export const RoutesComponent: React.FC<RoutesComponentProps> = ({
    isLoggedIn = false,
    isSubscriber = false,
}) => {
    return (
        <Switch>
            <Route exact path="/">
                <RedirectWrapper shouldRedirect={isLoggedIn} redirectPath="/my-dashboard">
                    <LandingV2 />
                </RedirectWrapper>
            </Route>

            <Route exact path="/about-us">
                <AboutUs />
            </Route>

            <Route exact path="/login">
                <RedirectWrapper shouldRedirect={isLoggedIn} redirectPath="/my-dashboard">
                    <Login />
                </RedirectWrapper>
            </Route>

            <Route exact path="/my-dashboard">
                <RedirectWrapper shouldRedirect={!isSubscriber} redirectPath="/my-account">
                    <MyDashboard />
                </RedirectWrapper>
            </Route>

            <Route exact path="/crash-courses">
                <RedirectWrapper shouldRedirect={!isLoggedIn} redirectPath="/login">
                    <ShortVideos />
                </RedirectWrapper>
            </Route>

            <Route exact path="/quiz-challenge">
                <QuizChallenge />
            </Route>

            <Route exact path="/self-study">
                <RedirectWrapper shouldRedirect={!isSubscriber} redirectPath="/my-account">
                    <SelfStudyV2 />
                </RedirectWrapper>
            </Route>

            <Route exact path="/question-bank">
                <RedirectWrapper shouldRedirect={!isSubscriber} redirectPath="/my-account">
                    <QuestionBank />
                </RedirectWrapper>
            </Route>

            <Route exact path="/quizzes">
                <RedirectWrapper shouldRedirect={!isSubscriber} redirectPath="/my-account">
                    <Quizzes />
                </RedirectWrapper>
            </Route>

            <Route exact path="/my-liked-content">
                <RedirectWrapper shouldRedirect={!isSubscriber} redirectPath="/my-account">
                    <MyFavourites />
                </RedirectWrapper>
            </Route>

            <Route exact path="/notes">
                <RedirectWrapper shouldRedirect={!isSubscriber} redirectPath="/my-account">
                    <MyNotes />
                </RedirectWrapper>
            </Route>

            <Route exact path="/my-account">
                <RedirectWrapper shouldRedirect={!isLoggedIn} redirectPath="/">
                    <MyAccountV2 />
                </RedirectWrapper>
            </Route>

            <Route exact path="/subscriptions">
                <RedirectWrapper
                    shouldRedirect={isSubscriber || !isLoggedIn}
                    redirectPath="/my-account"
                >
                    <SubscriptionsV2 />
                </RedirectWrapper>
            </Route>

            <Route exact path="/subscriptions/success">
                <SubscriptionsSuccess />
            </Route>

            <Route exact path="/subscriptions/info">
                <RedirectWrapper shouldRedirect={isLoggedIn} redirectPath="/subscriptions">
                    <SubscriptionInfoV2 />
                </RedirectWrapper>
            </Route>

            <Route exact path="/recorded-lessons">
                <RedirectWrapper shouldRedirect={!isLoggedIn} redirectPath="/login">
                    <RecordedLesson />
                </RedirectWrapper>
            </Route>

            <Route exact path="/privacy-policy">
                <PrivacyPolicy />
            </Route>

            <Route exact path="/terms-of-service">
                <TermsOfService />
            </Route>

            <Route exact path="/contact-us">
                <ContactUsV2 />
            </Route>

            <Route exact path="/faqs">
                <FAQV2 />
            </Route>

            <Route exact path="/ibecons">
                <IBEcons />
            </Route>

            <Route exact path="/study-bot">
                <StudyBot />
            </Route>
        </Switch>
    );
};

export default RoutesComponent;
