import React from 'react';
import classNames from 'classnames/bind';
import { Input, Button } from 'antd';

import TypographySubtitle from 'components/TypographySubtitle';

import styles from './FreeTrialModalV2.module.scss';

const cx = classNames.bind(styles);

type FreeTrialFormProps = {
    isLoading: boolean;
    code: string;
    handleOnCancel: () => void;
    setCode: (c: string) => void;
    handleOnClick: () => Promise<void>;
};

const FreeTrialForm: React.FC<FreeTrialFormProps> = ({
    isLoading,
    code,
    handleOnCancel,
    setCode,
    handleOnClick,
}) => (
    <div className={cx('container')}>
        <div className={cx('header-container')}>
            <TypographySubtitle level={1}>Free trial coupon code</TypographySubtitle>
        </div>

        <div className={cx('input-container')}>
            <Input
                placeholder="Enter your promo code here"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                data-cy="free-trial-form__promo-code-input"
            />
        </div>

        <div className={cx('cta-container')}>
            <Button className={cx('cancel-cta')} onClick={handleOnCancel} loading={isLoading}>
                Cancel
            </Button>

            <Button
                type="primary"
                onClick={handleOnClick}
                loading={isLoading}
                data-cy="free-trial-form__submit-cta"
            >
                Submit
            </Button>
        </div>
    </div>
);

export default FreeTrialForm;
