import { parseQueryParam, validateID } from 'common/utils';
import { QuizzesQuery, RawQuizzesQuery } from './types';
import { TCSSubject } from 'common/types';
import { QUIZZES_SUBJECT_WHITELIST } from 'config';

// TODO: currently the functions in utils are very similar to solutions in Self Study
// to evaluate in the future if we need to refactor if they're indeed similar in functions

/**
 * Determines whether the given query param for self
 * study is valid or not
 * Ensures that dependent keys are present
 * i.e. if file_key is present, then topic_id & subject_id should be too
 * @param query - the self-study query param from the URL
 */
// TODO: refactor to common/utils to be reused in QuestionBank and SelfStudy
export const parseQuizzesQuery = (query: string): QuizzesQuery | null => {
    const parsedQuery = parseQueryParam(query);

    const subjectId = validateID(parsedQuery, 'subjectId');
    if (subjectId === null) return null;

    const topicId = validateID(parsedQuery, 'topicId');
    if (topicId === null) {
        return {
            subjectId,
        };
    }

    const quizSetId = validateID(parsedQuery, 'quizSetId');
    if (quizSetId === null) {
        return {
            subjectId,
            topicId,
        };
    }

    return {
        subjectId,
        topicId,
        quizSetId,
    };
};

/**
 * ensures that the query is valid. reconstructs the payload in certain instances to ensure
 * erroneous values doesn't accidentally find its way in
 * - has topic_id & file_key but no subject_id -> invalid
 * - has file_key key but value is undefined -> filtered out
 */
export const validateQuizzesQuery = (query: QuizzesQuery): QuizzesQuery | null => {
    switch (true) {
        case Boolean(query.topicId && query.subjectId):
            return query;
        case Boolean(query.subjectId):
            return {
                subjectId: query.subjectId,
            };
        default:
            return null;
    }
};

export const deriveQuizzesQueryString = (query: QuizzesQuery): string => {
    const q = validateQuizzesQuery(query);
    if (q === null) return '';

    const rawQ: RawQuizzesQuery = {};

    if (q.subjectId) {
        rawQ.subjectId = q.subjectId.toString();
    }

    if (q.topicId) {
        rawQ.topicId = q.topicId.toString();
    }

    const querystring = new URLSearchParams(rawQ as Record<string, string>).toString();
    return querystring ? `?${querystring}` : '';
};

export const queryIsSameAsRef = (query: QuizzesQuery | null, ref: QuizzesQuery | null): boolean => {
    if (query === null || ref === null) {
        return query === ref;
    }

    for (const key in query) {
        const v1 = query[key as keyof QuizzesQuery];
        const v2 = ref[key as keyof QuizzesQuery];

        if (v1 !== v2) return false;
    }

    return true;
};

export const deriveWhitelistedSubjects = (subjects: TCSSubject[]): TCSSubject[] => {
    const result: TCSSubject[] = [];

    subjects.forEach((sub) => {
        const { name = '' } = sub;
        if (QUIZZES_SUBJECT_WHITELIST[name]) {
            result.push(sub);
        }
    });

    return result;
};
