import React, { useCallback, useState } from 'react';

import { useAxiosInstance } from 'components/AxiosContext';

import RemoveUser from './RemoveUser';

type RemoveUserContainerProps = {
    refetchFriendsAndFamilyMembers: () => void;
    email: string;
    name: string;
};

const RemoveUserContainer: React.FC<RemoveUserContainerProps> = ({
    email,
    name,
    refetchFriendsAndFamilyMembers,
}) => {
    const axios = useAxiosInstance();
    const [isLoading, setIsLoading] = useState(false);

    const handleRemoveUser = useCallback(async () => {
        try {
            setIsLoading(true);

            const payload = {
                inviteeEmail: email,
            };

            await axios.delete('/private/v2/users/friends-and-family/member', {
                data: payload,
            });
        } catch {
            throw Error();
        } finally {
            setIsLoading(false);
        }
    }, [email]);

    const onSuccess = () => {
        refetchFriendsAndFamilyMembers();
    };

    return (
        <RemoveUser
            isLoading={isLoading}
            handleRemoveUser={handleRemoveUser}
            name={name}
            onSuccess={onSuccess}
        />
    );
};

export default RemoveUserContainer;
