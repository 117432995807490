import React, { useState, useRef, useEffect } from 'react';

import { useAxiosInstance } from 'components/AxiosContext';

import StudyBotConversation from './StudyBotConversation';
import { StudyBotConversation as StudyBotConv } from './types';

const POLL_DUR = 5 * 1000; // 5 seconds timeout

type StudyBotConversationContainerProps = {
    chatUuid: string;
    chatCreationTime: number;
};

const StudyBotConversationContainer: React.FC<StudyBotConversationContainerProps> = ({
    chatUuid,
    chatCreationTime,
}) => {
    const axios = useAxiosInstance();

    const pollingTimeout = useRef<NodeJS.Timeout>();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasSubmitError, setHasSubmitError] = useState(false);
    const [hasPollError, setHasPollError] = useState(false);
    const [pollErrorCount, setPollErrorCount] = useState(0);
    const [shouldPollForMessages, setShouldPollForMessages] = useState(false);
    const [conversation, setConversation] = useState<StudyBotConv[]>([]);

    const handleSubmitMessage = async (message: string) => {
        try {
            setIsSubmitting(true);
            setHasSubmitError(false);

            const payload = { uuid: chatUuid, message, timestamp: chatCreationTime.toString() };
            await axios.post('/api/v1/gpt/message/new', payload);
            setShouldPollForMessages(true);

            pollingTimeout.current = setTimeout(() => {
                handlePollForMessages();
            }, POLL_DUR);
        } catch {
            setHasSubmitError(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handlePollForMessages = async () => {
        try {
            setHasPollError(false);

            const { data } = await axios.get<StudyBotConv[]>('/api/v1/gpt/messages', {
                params: { uuid: chatUuid, timestamp: chatCreationTime },
            });

            if (data.length > conversation.length) {
                setConversation(data);
                setShouldPollForMessages(false);
                setPollErrorCount(0);
                return;
            }

            pollingTimeout.current = setTimeout(() => {
                handlePollForMessages();
            }, POLL_DUR);
        } catch {
            if (pollErrorCount >= 3) {
                setHasPollError(true);
                setShouldPollForMessages(false);
                setPollErrorCount(0);
                return;
            }

            setPollErrorCount((count) => count + 1);
            pollingTimeout.current = setTimeout(() => {
                handlePollForMessages();
            }, POLL_DUR);
        }
    };

    useEffect(() => {
        () => {
            if (pollingTimeout.current) {
                clearTimeout(pollingTimeout.current);
            }
        };
    }, []);

    useEffect(() => {
        /** conversation needs to reset if chat uuid or creation time changes */
        setConversation([]);
    }, [chatUuid, chatCreationTime]);

    return (
        <StudyBotConversation
            isSubmitting={isSubmitting}
            isPolling={shouldPollForMessages}
            submitError={hasSubmitError}
            pollError={hasPollError}
            conversation={conversation}
            submitMessage={handleSubmitMessage}
        />
    );
};

export default StudyBotConversationContainer;
