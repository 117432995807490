import React from 'react';
import classnames from 'classnames/bind';
import { Image } from 'antd';

import WorldMap from 'static/landing/world-map.jpg';

import styles from './UserBaseSection.module.scss';
const cx = classnames.bind(styles);

const UserBaseSection: React.FC = () => {
    return (
        <div className={cx('container')}>
            <div className={cx('section-title')}>Built in Singapore for the World</div>
            <div className={cx('content')}>
                <div className={cx('image-container')}>
                    <Image src={WorldMap} width={800} preview={false} />
                </div>

                <div className={cx('text-container')}>
                    <div className={cx('text')}>
                        Cloudscore is developed in Singapore, one of the most successful countries
                        in producing perfect scorers in the IB, by an international team of
                        experienced IB teachers. Our curriculum and resource banks are based on the
                        tried and tested system of top IB schools in Singapore that has proven to
                        continuously produce top IB scorers
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserBaseSection;
