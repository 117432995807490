import { TCSFileTypeShape, TCSSubject, TCSSubjectTopicMap } from 'common/types';
import { ReducerStateType, UserProfile } from './state';

export const getUserProfile = (state: ReducerStateType): UserProfile => {
    return state.user || {};
};

export const getSubjects = (state: ReducerStateType): TCSSubject[] => {
    return state.subjects;
};

export const getTopics = (state: ReducerStateType): TCSSubjectTopicMap => {
    return state.topics;
};

export const getFileTypes = (state: ReducerStateType): TCSFileTypeShape[] => {
    return state.fileTypes;
};

export const getSubjectTopicFileTypes = (
    state: ReducerStateType
): {
    subjects: TCSSubject[];
    topics: TCSSubjectTopicMap;
    fileTypes: TCSFileTypeShape[];
} => {
    const { subjects, topics, fileTypes } = state;

    return {
        subjects,
        topics,
        fileTypes,
    };
};

export const getGetStudyPlanCounter = (state: ReducerStateType): number => {
    return state.getStudyPlanCounter;
};
