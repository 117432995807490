import React from 'react';
import classNames from 'classnames/bind';
import { Modal } from 'antd';

import TypographyBody from 'components/TypographyBody';
import TypographySubtitle from 'components/TypographySubtitle';

import styles from './BulkPurchaseModal.module.scss';

const cx = classNames.bind(styles);

type BulkPurchaseModalProps = {
    isVisible: boolean;
    onCancel: () => void;
};

const BulkPurchaseModal: React.FC<BulkPurchaseModalProps> = ({ isVisible, onCancel }) => {
    return (
        <Modal visible={isVisible} onCancel={onCancel} footer={null} closable={false}>
            <div className={cx('container')}>
                <TypographySubtitle level={1} className={cx('title')}>
                    Hello!
                </TypographySubtitle>

                <br />

                <div className={cx('body')}>
                    <TypographyBody level={1}>
                        Are our existing plans not working for you? Looking to subscribe longer term
                        and was wondering if there&apos;s a bulk discount?
                    </TypographyBody>

                    <br />

                    <TypographyBody level={1}>
                        Reach out to us at{' '}
                        <span className={cx('highlight')}>admin@thecloudscore.com</span> or on
                        instagram <span className={cx('highlight')}>@thecloudscore</span> to get a
                        bespoke plan customised to your needs!
                    </TypographyBody>
                </div>
            </div>
        </Modal>
    );
};

export default BulkPurchaseModal;
