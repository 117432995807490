import React from 'react';
import classnames from 'classnames/bind';
import { List } from 'antd';

import TypographyBody from 'components/TypographyBody';

import styles from './OptionCard.module.scss';

const cx = classnames.bind(styles);

type OptionCardProps = {
    name: string;
    onClick: () => void;
    isSelected?: boolean;
    isDisabled?: boolean;
};

const OptionCard: React.FC<OptionCardProps> = ({
    name,
    onClick,
    isSelected = false,
    isDisabled = false,
}) => {
    const handleOnClick = () => {
        if (isDisabled) {
            return;
        }

        onClick();
    };

    return (
        <List.Item
            className={cx('container', { selected: isSelected, disabled: isDisabled })}
            onClick={handleOnClick}
        >
            <TypographyBody level={1} className={cx('text')}>
                {name}
            </TypographyBody>
        </List.Item>
    );
};

export default OptionCard;
