import React, { useState } from 'react';
import { Input, Button, message, Divider } from 'antd';

import ErrorMessageWrapper from 'components/ErrorMessageWrapper';

import { isValidEmail } from './utils';
import AuthenticationCard from './AuthenticationCard';
import { validFieldMessage } from './errorMessages';

enum EForgotPasswordFormField {
    EMAIL = 'EMAIL',
}

const forgotPasswordFormValidations = {
    [EForgotPasswordFormField.EMAIL]: (email: string) => !isValidEmail(email),
};

type ForgotPasswordProps = {
    isLoading: boolean;
    handleForgotPassword: (email: string) => Promise<void>;
    goToLogin: () => void;
    goToHaveVerificationCode: () => void;
};

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
    isLoading,
    handleForgotPassword,
    goToLogin,
    goToHaveVerificationCode,
}) => {
    const [email, setEmail] = useState('');
    const [formErrors, setFormErrors] = useState<Record<string, null | string>>({
        [EForgotPasswordFormField.EMAIL]: null,
    });

    const validateForm = () => {
        const emailError = forgotPasswordFormValidations[EForgotPasswordFormField.EMAIL](email);

        setFormErrors({
            [EForgotPasswordFormField.EMAIL]: emailError ? validFieldMessage('email') : null,
        });

        return !emailError;
    };

    const onSubmitButtonClick = async () => {
        if (validateForm()) {
            try {
                await handleForgotPassword(email);
            } catch (error) {
                message.error('Oops! There was an error with your request. Please try again.');
            }
        } else {
            message.error('Please fix the form error');
        }
    };

    return (
        <AuthenticationCard title="Forgot your password?">
            <ErrorMessageWrapper errorMessage={formErrors[EForgotPasswordFormField.EMAIL]}>
                <Input
                    placeholder="Email"
                    value={email}
                    name={EForgotPasswordFormField.EMAIL}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                />
            </ErrorMessageWrapper>

            <Button type="primary" loading={isLoading} block onClick={onSubmitButtonClick}>
                Submit
            </Button>

            <Divider />

            <Button type="link" loading={isLoading} block onClick={goToHaveVerificationCode}>
                I have received the email verification code
            </Button>

            <Button block onClick={goToLogin}>
                Back to login
            </Button>
        </AuthenticationCard>
    );
};

export default ForgotPassword;
