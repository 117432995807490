import React from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames/bind';
import { Typography } from 'antd';

import { scrollToTop } from 'common/utils';
import CTAButton from 'components/CTAButton';

import styles from './Jumbotron.module.scss';
const cx = classnames.bind(styles);

const Jumbotron: React.FC = () => {
    const history = useHistory();

    const pushToSubInfo = () => {
        scrollToTop();
        history.push('/subscriptions/info');
    };

    return (
        <div className={cx('container')}>
            <div className={cx('content-container')}>
                <div className={cx('content')}>
                    <Typography.Title level={1} className={cx('title')}>
                        Score Better for Just $15/month
                    </Typography.Title>

                    <div className={cx('subtitle')}>
                        Get a 7 for IB Economics without costly tutoring. Get Weekly Lessons,
                        Topical Question Banks and Notes & Videos to ace Econs in under 90 minutes
                        every week.
                    </div>

                    <div className={cx('cta-button-container')}>
                        <div className={cx('button-group')}>
                            <CTAButton onClick={pushToSubInfo}>Get Started for Free</CTAButton>
                            <div className={cx('emphasis')}>No Credit Card Needed!</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Jumbotron;
