import React from 'react';
import classnames from 'classnames/bind';
import { Steps } from 'antd';

import Icon from './Icon';
import { deriveIndicatorStatus } from './utils';

import styles from './ProgressIndicator.module.scss';

const cx = classnames.bind(styles);
const { Step } = Steps;

type ProgressIndicatorProps = {
    currentStep: number;
    onChange?: (index: number) => void;
    isAnsweredArray: boolean[];
};

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
    currentStep,
    onChange,
    isAnsweredArray,
}) => {
    return (
        <Steps
            current={currentStep}
            onChange={onChange}
            className={cx('container')}
            responsive={false}
        >
            {isAnsweredArray.map((isAnswered, index) => (
                <Step
                    key={index}
                    icon={
                        <Icon
                            step={index + 1}
                            status={deriveIndicatorStatus(currentStep, index, isAnswered)}
                        />
                    }
                />
            ))}
        </Steps>
    );
};

export default ProgressIndicator;
