import { differenceInDays } from 'date-fns';

import { convertFromUnixTimestamp, convertUserCohortToIbDate } from 'common/utils';

// deriveDaysToIB assumes that the exam date will be on the last day of the month when no examDate is provided
export const deriveDaysToIB = (userCohort: string, unixExamDate?: number): number => {
    const ibDate = convertUserCohortToIbDate(userCohort);
    if (!ibDate) return 0;

    const exam = unixExamDate ? new Date(convertFromUnixTimestamp(unixExamDate)) : ibDate;
    const today = new Date();

    return differenceInDays(exam, today);
};
