import React, { useMemo } from 'react';
import { CompassTwoTone } from '@ant-design/icons';
import classnames from 'classnames/bind';
import { datadogRum } from '@datadog/browser-rum';

import { setGetStudyPlanCounter } from 'components/ReducerContext/Actions/UserActions';
import { getGetStudyPlanCounter, getUserProfile } from 'components/ReducerContext/selectors';

import { useReducerContext } from 'components/ReducerContext/ReducerContext';

import styles from './Tooltips.module.scss';

const cx = classnames.bind(styles);

const BackToTop: React.FC = () => {
    const { dispatch, state } = useReducerContext();
    const getStudyPlanCounter = useMemo(() => getGetStudyPlanCounter(state), [state]);
    const profile = useMemo(() => getUserProfile(state), [state]);

    const handleGetStudyPlan = () => {
        setGetStudyPlanCounter(dispatch, getStudyPlanCounter + 1);

        datadogRum.addAction('requestStudyPlan', {
            authId: profile.cognitoId,
            fullName: `${profile.firstName} ${profile.lastName}`,
        });
    };

    return (
        <div className={cx('get-study-plan')} onClick={handleGetStudyPlan}>
            <CompassTwoTone style={{ fontSize: '28px' }} />
            <div className={cx('text')}>Get Study Plan</div>
        </div>
    );
};

export default BackToTop;
