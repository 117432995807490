import React from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames/bind';

import { scrollToTop } from 'common/utils';
import CTAButton from 'components/CTAButton';

import styles from './LearnMoreSection.module.scss';
const cx = classnames.bind(styles);

const LearnMoreSection: React.FC = () => {
    const history = useHistory();

    const pushToAboutUs = () => {
        scrollToTop();
        history.push('/about-us');
    };

    return (
        <div className={cx('container')}>
            <div className={cx('content-container')}>
                <h1>
                    At Cloudscore, we believe that you should not be disadvantaged just because you
                    are not paying for private tutoring. With one subscription, you get everything
                    you need to ace Economics right at your fingertips.
                </h1>
                <CTAButton onClick={pushToAboutUs}>Learn More</CTAButton>
            </div>
        </div>
    );
};

export default LearnMoreSection;
