import React, { useState } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { Button, Modal, Row, Col, message } from 'antd';
import classnames from 'classnames/bind';

import TypographySubtitle from 'components/TypographySubtitle';

import styles from './UpdatePaymentMethod.module.scss';

const cx = classnames.bind(styles);

type UpdatePaymentMethodProps = {
    isLoading: boolean;
    refetchSubscription: () => void;
    handleUpdatePaymentMethod: () => Promise<void>;
};

const UpdatePaymentMethod: React.FC<UpdatePaymentMethodProps> = ({
    isLoading,
    refetchSubscription,
    handleUpdatePaymentMethod,
}) => {
    const [visible, setVisible] = useState(false);

    const handleOnCancel = () => {
        if (isLoading) return;
        setVisible(false);
    };

    const handleSubmit = async () => {
        try {
            await handleUpdatePaymentMethod();
            refetchSubscription();
        } catch {
            message.error(
                'Oops! There was a problem with updating your payment method. Please try again!'
            );
        }
    };

    return (
        <>
            <Row style={{ marginTop: '20px' }}>
                <Col span={16}></Col>
                <Col span={8}>
                    <Button
                        type="primary"
                        className={cx('button')}
                        block
                        onClick={() => setVisible(true)}
                    >
                        Update Payment Method
                    </Button>
                </Col>
            </Row>

            <Modal
                visible={visible}
                onCancel={handleOnCancel}
                closable={false}
                footer={null}
                width="500px"
                destroyOnClose
            >
                <div className={cx('modal-container')}>
                    <TypographySubtitle className={cx('modal-title')} level={1}>
                        Update Payment Method
                    </TypographySubtitle>

                    <div className={cx('card-element-container')}>
                        <CardElement className={cx('card-element')} />
                    </div>

                    <div className={cx('cta-container')}>
                        <Button block onClick={handleOnCancel} loading={isLoading}>
                            Cancel
                        </Button>

                        <Button
                            className={cx('submit')}
                            block
                            type="primary"
                            loading={isLoading}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default UpdatePaymentMethod;
