export const deriveDefaultScale = () => {
    if (window.innerWidth >= 768) {
        return 0.7;
    }

    if (window.innerWidth >= 480 && window.innerWidth <= 768) {
        return 0.5;
    }

    return 0.4;
};
