// SelfStudyQuery defines the expected shape when extracted from the query params
export type SelfStudyQuery = {
    fileKey?: string;
    subjectId?: number;
    topicId?: number;
    isNotesPanelOpen?: string;
};

// RawSelfStudyQuery represents the Record that we can use together with URLSearchParams
export type RawSelfStudyQuery = {
    file_key?: string;
    subject_id?: string;
    topic_id?: string;
    is_notes_panel_open?: string;
};

export type GETFavouritesPayload = {
    fileKey: string;
    fileName: string;
    liked: boolean;
    disliked: boolean;
    subjectId: number;
    topicId: number;
    fileTypeId: number;
    createdAt: number; // unix timestamp
};

export type GETNotesPayload = {
    fileKey: string;
    fileName: string;
    notesTaken: string;
    lastUpdated: number;
    subjectId: number;
    topicId: number;
    fileTypeId: number;
};

export type TCSLikedContentDetail = {
    liked: boolean;
    disliked: boolean;
};

export enum EThumbsUpDownState {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    DISABLED = 'disabled',
}
