import FreeWeeklyLessons from 'static/common/free-weekly-lessons.jpeg';
import QuestionBank from 'static/common/question-bank.svg';
import NotesAndVideos from 'static/common/notes-and-videos.svg';

export const keyFeaturesDetails = [
    {
        imgSrc: FreeWeeklyLessons,
        title: 'Free Weekly Lessons',
        description:
            'Free weekly lessons cover key economic concepts and essential essay writing techniques in a step-by-step fashion that is easy to follow and easy to replicate',
    },
    {
        imgSrc: QuestionBank,
        title: 'Topical Question Bank',
        description:
            'Our students love our deep library of model essays that accompany our topical question banks that fully illustrate the essential ingredients of a top grade answer',
    },
    {
        imgSrc: NotesAndVideos,
        title: 'Notes & Short Videos',
        description:
            'Condensed notes distills key concepts into digestible pieces and reduce your revision time; watching short concept videos that are only minutes-long is faster than reading the textbook.',
    },
];
