import { format } from 'date-fns';

import {
    EFreeTrialStatus,
    GETProfilesSubscriptionInfoPayload,
} from 'components/ReducerContext/state';

import { ESubscriptionPlanType } from 'common/types';
import { EMySubscriptionStatus } from './MySubscription/types';

export const deriveSubscriptionPlanName = (
    subscriptionPlanType: ESubscriptionPlanType | undefined
): string => {
    if (!subscriptionPlanType) {
        return '';
    }

    switch (subscriptionPlanType) {
        case ESubscriptionPlanType.FAMILY_OWNER:
        case ESubscriptionPlanType.FAMILY_MEMBER:
            return 'Friends & Family';
        case ESubscriptionPlanType.SINGLE:
            return 'Individual';
        case ESubscriptionPlanType.ANNUAL:
            return 'Annual';
        case ESubscriptionPlanType.NONE:
        default:
            return '';
    }
};

export const deriveSubscriptionStatus = (
    details: GETProfilesSubscriptionInfoPayload | null
): EMySubscriptionStatus => {
    if (details === null) return EMySubscriptionStatus.NONE;

    switch (true) {
        // prioritise checking for active subscription over active free trial
        case details.isSubscribed:
            return EMySubscriptionStatus.ACTIVE;
        case details.freeTrialStatus === EFreeTrialStatus.ACTIVE:
            return EMySubscriptionStatus.FREETRIAL;
        default:
            return EMySubscriptionStatus.NONE;
    }
};

export const displaySubscriptionDate = (unixTimestamp: number): string => {
    if (unixTimestamp === 0) return '';

    const date = new Date(unixTimestamp * 1000);
    if (date.toString() === 'Invalid Date') return '';

    return format(date, 'dd MMM yyyy');
};

export const deriveUnixTimestampFromZuluTimestamp = (zuluTimestamp: string): number => {
    const date = new Date(zuluTimestamp);
    if (date.toString() === 'Invalid Date') return 0;

    const jsTimestamp = date.getTime();
    return Math.trunc(jsTimestamp / 1000);
};

export const prependDash = (str: string): string => {
    if (str === '') {
        return '';
    }

    return ` - ${str}`;
};
