import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import * as Sentry from '@sentry/react';
import { datadogRum } from '@datadog/browser-rum';

import LoadingModal from 'components/LoadingModal';
import { useAuth } from 'components/AuthContextProvider';
import { useAxiosInstance } from 'components/AxiosContext';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import {
    setLandedOnProtectedRoute,
    setUserProfile,
} from 'components/ReducerContext/Actions/UserActions';
import { getUserProfile } from 'components/ReducerContext/selectors';
import { resetStore } from 'components/ReducerContext/Actions/CommonActions';
import { isAccessingProtectedRoute, isAccessingSubscriberRoute } from 'components/Routes/utils';

import {
    deriveCreateUserPayload,
    deriveUserProfile,
    ELoadingMessage,
    handleFetchUserFail,
    GETUsersProfilePayload,
    isFreeTrialOrSubscriber,
} from './utils';
import { usePollUserProfile } from './hooks';

/**
 * UserAuth has 2 main responsibilities:
 *
 * 1. Determine if the user is accessing a Protected Route. If yes, redirect the
 * user back to the root page.
 *
 * 2. Fetch for the Arc User Profile after the user has logged in via cognito - as this
 * profile is essential for the app to function properly. In addition, UserAuth also ensures
 * that no content is rendered until after the profile has been fetched. This ensures that
 * whatever content is being loaded has the necessary attributes which it might require from the profile.
 */
const UserAuth: React.FC = ({ children }) => {
    const history = useHistory();
    const { pathname, search } = useLocation();

    const { state, dispatch } = useReducerContext();
    const { userAttr, signOut, token, isLoggedIn, userIsChecked } = useAuth();
    const axios = useAxiosInstance();

    const loginRef = useRef(isLoggedIn);

    const [startPoll, setStartPoll] = useState(false);
    const [profileIsFetched, setProfileIsFetched] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState(ELoadingMessage.DEFAULT);

    const profile = getUserProfile(state);
    const { landedOnProtectedRoute } = state;

    usePollUserProfile(startPoll);

    const handleRedirection = useCallback(async (isSubscribed: boolean) => {
        if (isSubscribed) {
            if (window.__arclrn?.memoizedPath) {
                history.push(window.__arclrn.memoizedPath);
                window.__arclrn.memoizedPath = undefined;
                return;
            } else {
                history.push('/my-dashboard');
            }
        } else {
            // we want to push unsubscribed users to the subscriptions page if they are
            // accessing subscriber route
            if (
                window.__arclrn?.memoizedPath &&
                !isAccessingSubscriberRoute(window.__arclrn?.memoizedPath)
            ) {
                history.push(window.__arclrn.memoizedPath);
                window.__arclrn.memoizedPath = undefined;
                return;
            } else {
                history.push('/subscriptions');
            }
        }
    }, []);

    const createUserProfile = useCallback(async () => {
        setLoadingMsg(ELoadingMessage.FIRST_TIME);

        try {
            const { data } = await axios.post<GETUsersProfilePayload>(
                '/private/v2/users/new',
                deriveCreateUserPayload(userAttr)
            );

            setUserProfile(dispatch, deriveUserProfile(data));

            if (isLoggedIn) {
                setStartPoll(true);
            }

            if (landedOnProtectedRoute) {
                handleRedirection(false);
            }
            setProfileIsFetched(true);
        } catch (e) {
            setLoadingMsg(ELoadingMessage.ERROR);
            await handleFetchUserFail(signOut);
        }
    }, [axios, userAttr, isLoggedIn]);

    const fetchUserProfile = useCallback(async () => {
        let isSubscribed = false;
        setLoadingMsg(ELoadingMessage.DEFAULT);

        try {
            setShowModal(true);
            const { data } = await axios.get<GETUsersProfilePayload>('/private/v2/users/profile');

            const derivedProfile = deriveUserProfile(data);
            setUserProfile(dispatch, derivedProfile);

            if (derivedProfile.subscriptionDetails?.isSubscribed) {
                datadogRum.addRumGlobalContext('isSubscriber', true);
            } else {
                datadogRum.addRumGlobalContext('isSubscriber', false);
            }

            isSubscribed = isFreeTrialOrSubscriber(derivedProfile.subscriptionDetails);

            if (landedOnProtectedRoute) {
                handleRedirection(isSubscribed);
            }
            setProfileIsFetched(true);

            if (isLoggedIn) {
                setStartPoll(true);
            }
        } catch (err) {
            // our user service returns 404 if the user cannot be found, which we assume
            // that this is the first time the user is logging in. so we will attempt to create
            // a profile for the user.
            if (err.response?.status === 404) {
                await createUserProfile();
            } else {
                setLoadingMsg(ELoadingMessage.ERROR);
                await handleFetchUserFail(signOut);
            }
        } finally {
            setShowModal(false);
        }
    }, [axios, createUserProfile, isLoggedIn]);

    const shouldRenderChildren = useMemo(() => {
        if (isLoggedIn) {
            return profileIsFetched;
        }

        return userIsChecked;
    }, [isLoggedIn, profileIsFetched, userIsChecked]);

    /**
     * This effect is for us to check when the app first mounts (after JWT is checked), whether we need
     * to redirect the user to a different part of the application as they might be accessing an authorized
     * portion of the app. userIsChecked should remain stable & unchanged throughout the lifetime
     * of the application, so this effect should be safe and would not trigger multiple times.
     */
    useEffect(() => {
        if (!userIsChecked) {
            return;
        }

        if (pathname !== '/') {
            window.__arclrn.memoizedPath = `${pathname}${search}`;
        }

        if (!isLoggedIn && isAccessingProtectedRoute(pathname)) {
            history.push('/login');
        }
    }, [userIsChecked]);

    /**
     * This effect is for us to fetch for the user profile after the user is successfully
     * authenticated. This can be either through login in, or the verification of the presence
     * of a token. We also want to ensure that the token is present, as it is required for fetching
     * the user's profile. Lastly, we want to ensure we only call this once, hence the multiple condtionals
     * in place to ensure no duplicative calls.
     */
    useEffect(() => {
        if (isLoggedIn && token && !profileIsFetched && !profile.id) {
            fetchUserProfile();
        }
    }, [token, isLoggedIn, profileIsFetched]);

    /**
     * This effect is for handling redirection after signing out
     * Signing in redirection will be handled in the fetchProfile() call
     */
    useEffect(() => {
        // if is no longer logged in & was previously logged in
        // LOGOUT SCENARIO
        if (!isLoggedIn && loginRef.current) {
            Sentry.setUser(null);

            datadogRum.removeRumGlobalContext('cognito_id');
            datadogRum.removeRumGlobalContext('user_email');
            datadogRum.removeRumGlobalContext('user_fullname');
            datadogRum.removeRumGlobalContext('isSubscriber');

            resetStore(dispatch);
            setProfileIsFetched(false);
            setStartPoll(false);
            history.push('/');
        }

        loginRef.current = isLoggedIn;
    }, [isLoggedIn]);

    /**
     * We set this state only the first time the user landed on the page.
     * It is used to determine whether we redirect the user back to the protected route they landed on
     * after they login or not
     */
    useEffect(() => {
        setLandedOnProtectedRoute(dispatch, isAccessingProtectedRoute(pathname));
    }, []);

    return (
        <>
            {shouldRenderChildren && <div>{children}</div>}
            <LoadingModal showModal={showModal} message={loadingMsg} />
        </>
    );
};

export default UserAuth;
