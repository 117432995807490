import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import { List } from 'antd';
import { format } from 'date-fns';

import TypographySubtitle from 'components/TypographySubtitle';
import TypographyBody from 'components/TypographyBody';
import { convertFromUnixTimestamp } from 'common/utils';

import styles from './PastAttemptedQuizSetCard.module.scss';

const cx = classnames.bind(styles);

type PastAttemptedQuizSetCardProps = {
    name: string;
    onClick: () => void;
    attemptedDate: number;
    score: number;
    quizSetLength: number;
};

const PastAttemptedQuizSetCard: React.FC<PastAttemptedQuizSetCardProps> = ({
    name,
    onClick,
    attemptedDate,
    score,
    quizSetLength,
}) => {
    const formattedDate = useMemo(
        () => format(convertFromUnixTimestamp(attemptedDate), 'dd/MM/yyyy'),
        []
    );

    return (
        <List.Item className={cx('container')} onClick={onClick}>
            <div className={cx('name-row')}>
                <TypographySubtitle level={1} className={cx('text')}>
                    {name}
                </TypographySubtitle>

                <div className={cx('score')}>
                    Score: {score}/{quizSetLength}
                </div>
            </div>
            <TypographyBody level={2} className={cx('attempted-date')}>
                Attempted on {formattedDate}
            </TypographyBody>
        </List.Item>
    );
};

export default PastAttemptedQuizSetCard;
