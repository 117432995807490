import React, { useCallback, useState } from 'react';
import { add } from 'date-fns';

import { useAxiosInstance } from 'components/AxiosContext';
import { setUserFreeTrialInfo } from 'components/ReducerContext/Actions/UserActions';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { EFreeTrialStatus } from 'components/ReducerContext/state';
import {
    sleep,
    sendGTagEvent,
    sendRedditEvent,
    sendTikTokEvent,
    sendCustomFbEvent,
} from 'common/utils';

import FreeTrialModalV2 from './FreeTrialModalV2';
import { parseCouponCode } from './utils';

type FreeTrialModalV2ContainerProps = {
    isVisible: boolean;
    handleOnCancel: () => void;
};

const FreeTrialModalV2Container: React.FC<FreeTrialModalV2ContainerProps> = ({
    isVisible,
    handleOnCancel,
}) => {
    const axios = useAxiosInstance();
    const { dispatch } = useReducerContext();

    const [isLoading, setIsLoading] = useState(false);

    const submitFreeTrial = useCallback(async (code: string) => {
        try {
            setIsLoading(true);

            const url = '/private/v1/coupons/redeem';
            const payload = {
                coupon_code: parseCouponCode(code),
            };

            await axios.post(url, payload);
            sendGTagEvent('freeTrial', 'adConversion', 'freeTrial');
            sendRedditEvent('Purchase');
            sendTikTokEvent('CompletePayment');
            sendCustomFbEvent('CompletePayment');
        } catch {
            throw Error();
        } finally {
            setIsLoading(false);
        }
    }, []);

    const handleOnSuccess = async () => {
        await sleep(4000);

        const expiry = add(new Date(), { days: 3 }).toISOString();
        /**
         * This relies on the react router to handle the redirection.
         * Once we update the user's free trial status - the become classified
         * as a subscriber - this causes the react router to push them out of
         * /subscriptions page and into /my-account
         */
        setUserFreeTrialInfo(dispatch, { status: EFreeTrialStatus.ACTIVE, expiry });
    };

    const onCancel = () => {
        if (isLoading) return;
        handleOnCancel();
    };

    return (
        <FreeTrialModalV2
            isLoading={isLoading}
            isVisible={isVisible}
            handleOnCancel={onCancel}
            handleOnSuccess={handleOnSuccess}
            handleSubmitFreeTrial={submitFreeTrial}
        />
    );
};

export default FreeTrialModalV2Container;
