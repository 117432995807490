import React, { useState } from 'react';
import { Button, Modal, message } from 'antd';
import classnames from 'classnames/bind';

import styles from './RemoveUser.module.scss';

const cx = classnames.bind(styles);

type RemoveUserProps = {
    isLoading: boolean;
    handleRemoveUser: () => Promise<void>;
    name: string;
    onSuccess: () => void;
};

const RemoveUser: React.FC<RemoveUserProps> = ({
    isLoading,
    handleRemoveUser,
    name,
    onSuccess,
}) => {
    const [visible, setVisible] = useState(false);

    const handleOnCancel = () => {
        if (isLoading) return;
        setVisible(false);
    };

    const handleSubmit = async () => {
        try {
            await handleRemoveUser();

            message.success('User is removed from the plan successfully');

            setVisible(false);
            onSuccess();
        } catch {
            message.error(
                'Oops! There was a problem removing user from the plan. Please try again!'
            );
        }
    };

    return (
        <>
            <Button
                danger
                type="link"
                className={cx('remove-button')}
                block
                onClick={() => setVisible(true)}
            >
                Remove User
            </Button>

            <Modal
                visible={visible}
                onCancel={handleOnCancel}
                cancelButtonProps={{ disabled: isLoading, className: cx('cancel') }}
                closable={false}
                width="500px"
                destroyOnClose
                wrapClassName={cx('modal-container')}
                onOk={handleSubmit}
                okButtonProps={{ disabled: isLoading, className: cx('confirm') }}
                okText="Confirm"
            >
                <div className={cx('text-container')}>
                    Are you sure you want to remove <span className={cx('name')}>{name}</span> from
                    your Friends & Family Plan?
                </div>
            </Modal>
        </>
    );
};

export default RemoveUser;
