import React from 'react';
import classnames from 'classnames/bind';

import ProfileIcon from 'components/ProfileIcon';
import RemoveUser from './RemoveUser';

import styles from './InviteeRow.module.scss';

const cx = classnames.bind(styles);

type InviteeRowProps = {
    name: string;
    email: string;
    refetchFriendsAndFamilyMembers?: () => void; // TODO: remove optional during integration
};

const InviteeRow: React.FC<InviteeRowProps> = ({
    name,
    email,
    refetchFriendsAndFamilyMembers = () => {}, // TODO: remove placeholder during integration
}) => {
    return (
        <div className={cx('container')}>
            <div className={cx('info')}>
                <ProfileIcon userInitial={name[0]} className={cx('icon')} />
                <div>{name}</div>
            </div>

            <RemoveUser
                email={email}
                name={name}
                refetchFriendsAndFamilyMembers={refetchFriendsAndFamilyMembers}
            />
        </div>
    );
};

export default InviteeRow;
