export const testimonials = [
    {
        name: '@yayamoudii56',
        content:
            'I just got in the website and all I can say is that it’s amazing thank you all so much for doing this especially for Econ students with the new curriculum',
    },
    {
        name: '@mjkim427',
        content: 'Cloudscore is very good! I like the concise notes and the videos a lot',
    },
    {
        name: '@tina.inhales.co3',
        content:
            'the economics videos are really good for review - it just helps my brain take info in better! I also like how it’s based on the syllabus, very organised. The examples and and practice questions are really really good as well!',
    },
    {
        name: '@vanessazvto ',
        content:
            'now I prepare for economics using the essay samples; the question bank is really helpful! I also like the notes!',
    },
    {
        name: '@c7arbear12',
        content:
            'I was studying for econs as I have a test next week and honestly the model essays are so good!!! it comes with a clear & coherent structure + the real life examples are so amazing, it’s super super helpful! ',
    },
    {
        name: '@ngoc_khue_ng',
        content:
            'Your website is so underrated. It is actually the best economics resource out there.',
    },
    {
        name: '@caitlinchidiac',
        content: 'Cloudscore helped me get a 7 in Econ!',
    },
    {
        name: '@ria_bhandari24',
        content:
            'Cloudscore is great and very very useful! Especially the model answers for economics…a real life saver',
    },
    {
        name: '@haseebgulzaar ',
        content: 'I got a 7 from revising off your notes on Cloudscore',
    },
    {
        name: '@krish.sheth',
        content: 'Lovely website really. Saved my life for Econs HL. Used it daily for revision. ',
    },
];

export const settings = {
    slidesToShow: 3,
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    speed: 500,
    arrows: true,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
                infinite: true,
                dots: true,
            },
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};
