import React from 'react';
import { VerticalAlignTopOutlined } from '@ant-design/icons';
import classnames from 'classnames/bind';

import styles from './Tooltips.module.scss';

const cx = classnames.bind(styles);

type BackToTopProps = {
    callback: () => void;
};

const BackToTop: React.FC<BackToTopProps> = ({ callback }) => {
    return (
        <div className={cx('back-to-top')} onClick={callback}>
            <VerticalAlignTopOutlined style={{ fontSize: '28px' }} />
            <div className={cx('text')}>Go to Top</div>
        </div>
    );
};

export default BackToTop;
