import React from 'react';
import classnames from 'classnames/bind';

import { keyFeaturesDetails } from './constants';
import KeyFeature from './KeyFeature';

import styles from './KeyFeaturesSection.module.scss';
const cx = classnames.bind(styles);

const KeyFeaturesSection: React.FC = () => {
    return (
        <div className={cx('container')}>
            <div className={cx('section-title')}>
                Cloudscore gives you everything you need to ace Economics
            </div>
            <div className={cx('key-features-container')}>
                {keyFeaturesDetails.map((keyFeature, index) => (
                    <KeyFeature
                        key={index}
                        imgSrc={keyFeature.imgSrc}
                        title={keyFeature.title}
                        description={keyFeature.description}
                    />
                ))}
            </div>
        </div>
    );
};

export default KeyFeaturesSection;
