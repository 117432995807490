import React, { useMemo } from 'react';
import { Row, Col } from 'antd';
import classnames from 'classnames/bind';

import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';

import { deriveUnixTimestampFromZuluTimestamp, displaySubscriptionDate } from '../utils';

import styles from './AnnualPlanSubscription.module.scss';
const cx = classnames.bind(styles);

const AnnualPlanSubscription: React.FC = () => {
    const { state } = useReducerContext();
    const profile = useMemo(() => getUserProfile(state), [state]);

    const subscriptionEnd = useMemo(
        () =>
            deriveUnixTimestampFromZuluTimestamp(
                profile.subscriptionDetails?.subscriptionExpiry || ''
            ),
        [profile]
    );

    return (
        <div className={cx('container')}>
            <Row gutter={[10, 16]}>
                <Col span={6}>Subscription Plan:</Col>
                <Col span={18}>Annual</Col>

                <Col span={6}>Plan Expires On:</Col>
                <Col span={18}>{displaySubscriptionDate(subscriptionEnd)}</Col>
            </Row>
        </div>
    );
};

export default AnnualPlanSubscription;
