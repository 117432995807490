import React, { useMemo } from 'react';
import { Image, Spin } from 'antd';
import classnames from 'classnames/bind';
import { useHistory } from 'react-router';

import { getUserProfile } from 'components/ReducerContext/selectors';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';

import TypographyHeader from 'components/TypographyHeader';
import TCSButton from 'components/TCSButton';
import TypographyBody from 'components/TypographyBody';
import PracticeMistakes from 'static/quiz-challenge/practice-mistakes.svg';
import {
    NoMistakesQuizFoundText,
    PracticeMistakesLoggedInText,
    PracticeMistakesLoggedOutText,
} from './constants';

import styles from './LandingView.module.scss';
import { GETMistakesQuizPayload } from '../QuizSetViewer/types';

const cx = classnames.bind(styles);

type LandingViewProps = {
    handleGoBack: () => void;
    handleStartPractice: () => void;
    quizzes: GETMistakesQuizPayload[];
    isLoading: boolean;
};

const LandingView: React.FC<LandingViewProps> = ({
    handleGoBack,
    handleStartPractice,
    quizzes,
    isLoading,
}) => {
    const { state } = useReducerContext();
    const profile = useMemo(() => getUserProfile(state), [state]);

    const isUserLoggedIn = useMemo(
        () => profile.cognitoId !== undefined && profile.cognitoId !== '',
        [profile]
    );

    const history = useHistory();

    const handleLoginRedirect = () => {
        history.push('/login');
    };

    if (isLoading) {
        return (
            <div className={cx('loading-container')}>
                <Spin />
            </div>
        );
    }

    const renderMistakesQuizAvailableTextContent = () => {
        if (!isUserLoggedIn) {
            return PracticeMistakesLoggedOutText;
        }

        if (quizzes.length === 0) {
            return NoMistakesQuizFoundText;
        }

        return PracticeMistakesLoggedInText;
    };

    const renderCTAButtonContent = () => {
        if (!isUserLoggedIn) {
            return (
                <TCSButton type="primary" onClick={handleLoginRedirect}>
                    Login
                </TCSButton>
            );
        }

        if (quizzes.length === 0) {
            return;
        }

        return (
            <TCSButton type="primary" onClick={handleStartPractice}>
                Start Practice
            </TCSButton>
        );
    };

    return (
        <div className={cx('container')}>
            <div className={cx('content-container')}>
                <TypographyHeader level={3} className={cx('title')}>
                    Quiz Challenge - Mistakes Practice
                </TypographyHeader>

                <div className={cx('content')}>
                    <TypographyBody level={1} className={cx('text')}>
                        {renderMistakesQuizAvailableTextContent()}
                    </TypographyBody>

                    <div className={cx('image-container')}>
                        <Image src={PracticeMistakes} width="100%" preview={false} />
                    </div>

                    <div className={cx('buttons-container')}>
                        {renderCTAButtonContent()}
                        <TCSButton type="link" onClick={handleGoBack} className={cx('back')}>
                            Back to Quiz Challenge
                        </TCSButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingView;
