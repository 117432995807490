import React from 'react';
import { Spin } from 'antd';
import classnames from 'classnames/bind';
import * as Sentry from '@sentry/react';

import SubjectSelector from 'components/SubjectSelector';
import TopicSelector from 'components/TopicSelector';
import ContentSelector from './ContentSelector';
import { useSelfStudy } from './hooks';

import { TCSFileShape, TCSSubject } from 'common/types';
import PdfViewer from './PdfViewer';
import VideoViewer from './VideoViewer';

import styles from './SelfStudyV2.module.scss';

const cx = classnames.bind(styles);

const SelfStudyV2: React.FC = () => {
    const { status, activeContent, payloads, setters } = useSelfStudy();

    const { initDone, isLoading, isNotesPanelOpen } = status;
    const { activeFile, activeSubject, activeTopic } = activeContent;
    const { subjects, topics, files, fileTypes } = payloads;
    const { setActiveSubject, setActiveTopic, setActiveFile, setIsNotesPanelOpen } = setters;

    const handleOpenInNewTab = (fileKey: string) => {
        if (!activeSubject || !activeTopic) {
            // this should never happen
            const e = `Attempting to open fileKey: ${fileKey} - but no active subject or topic was found. S: ${activeSubject}. T: ${activeTopic}`;
            Sentry.captureException(e);
            return;
        }

        const safeFileKey = encodeURIComponent(fileKey);
        const query = `subject_id=${activeSubject.id}&topic_id=${activeTopic.id}&file_key=${safeFileKey}`;
        const URL = `/self-study?${query}`;

        window.open(URL, '_blank', 'noopener noreferrer');
    };

    if (!initDone || isLoading) {
        return (
            <div className={cx('loading')}>
                <Spin size="large" />
            </div>
        );
    }

    const handleRender = () => {
        switch (true) {
            case activeFile !== null && activeFile.file_type_id === 1:
                return (
                    <VideoViewer
                        isNotesPanelOpen={isNotesPanelOpen}
                        setIsNotesPanelOpen={setIsNotesPanelOpen}
                        handleGoBack={() => setActiveFile(null)}
                        file={activeFile as TCSFileShape}
                    />
                );
            case activeFile !== null && activeFile.file_type_id !== 1:
                return (
                    <PdfViewer
                        isNotesPanelOpen={isNotesPanelOpen}
                        setIsNotesPanelOpen={setIsNotesPanelOpen}
                        handleGoBack={() => setActiveFile(null)}
                        file={activeFile as TCSFileShape}
                    />
                );
            case activeTopic !== null:
                return (
                    <ContentSelector
                        files={files || []}
                        topicName={activeTopic?.name || ''}
                        fileTypes={fileTypes}
                        handleSelectFile={setActiveFile}
                        handleGoBack={() => setActiveTopic(null)}
                        handleOpenInNewTab={handleOpenInNewTab}
                    />
                );
            case activeSubject !== null:
                return (
                    <TopicSelector
                        title={'Notes & Videos'}
                        subject={activeSubject as TCSSubject}
                        topics={topics}
                        selectTopic={setActiveTopic}
                        handleGoBack={() => setActiveSubject(null)}
                    />
                );
            default:
                return (
                    <SubjectSelector
                        title={'Notes & Videos'}
                        subjects={subjects}
                        selectSubject={setActiveSubject}
                    />
                );
        }
    };

    return <div className={cx('container')}>{handleRender()}</div>;
};

export default SelfStudyV2;
