import React, { useCallback, useMemo, useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { message } from 'antd';

import { useAxiosInstance } from 'components/AxiosContext';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';
import { setUserSubscriptionInfo } from 'components/ReducerContext/Actions/UserActions';

import CreditCardModalV2 from './CreditCardModalV2';
import {
    derive30DaysFromNow,
    deriveZuluTime,
    ERR_GENERIC,
    ERR_PROCESS_PAYMENT,
    getFullname,
    derivePromoCodePayload,
} from './utils';
import {
    EPromoCodeInput,
    EPromoCodeValidityDuration,
    POSTPaymentsPlansPriceIdSubscribe,
    POSTPaymentsPromoCodeValidate,
} from './types';
import { EProductType } from '../types';

import { sendGTagEvent } from 'common/utils';

type CreditCardModalV2ContainerProps = {
    isVisible: boolean;
    price: number;
    priceId: string;
    productType: EProductType;
    handleOnCancel: () => void;
};

const CreditCardModalV2Container: React.FC<CreditCardModalV2ContainerProps> = ({
    isVisible,
    price,
    priceId,
    productType,
    handleOnCancel,
}) => {
    const axios = useAxiosInstance();
    const history = useHistory();
    const stripe = useStripe();
    const elements = useElements();
    const { state, dispatch } = useReducerContext();

    const [isLoading, setIsLoading] = useState(false);
    const [promoCodeStatus, setPromoCodeStatus] = useState(EPromoCodeInput.NULL);
    const [promoCode, setPromoCode] = useState<POSTPaymentsPromoCodeValidate | null>(null);

    const user = useMemo(() => getUserProfile(state), [state]);

    const validatePromoCode = useCallback(
        async (promoCode: string) => {
            if (promoCode === '') return;

            try {
                setIsLoading(true);

                const payload = derivePromoCodePayload(promoCode, productType);

                const { data } = await axios.post<POSTPaymentsPromoCodeValidate>(
                    '/private/v2/payments/promo-code/validate',
                    payload
                );

                if (data.active) {
                    setPromoCodeStatus(EPromoCodeInput.VALID);
                    setPromoCode(data);
                } else {
                    setPromoCodeStatus(EPromoCodeInput.INVALID);
                    setPromoCode(null);
                }
            } catch {
                setPromoCodeStatus(EPromoCodeInput.INVALID);
                setPromoCode(null);
            } finally {
                setIsLoading(false);
            }
        },
        [productType]
    );

    const handleTCS2FA = useCallback(async (paymentIntentID: string) => {
        try {
            const url = '/private/v2/payments/plans/process-mfa';
            const payload = { paymentIntentID };
            const { data } = await axios.post<POSTPaymentsPlansPriceIdSubscribe>(url, payload);

            // expiry might be 0, but BE will not fail the call - so if expiry is 0
            // deriveZuluTime will just add 30 days to the current time
            const exp = data.expiry ? data.expiry : derive30DaysFromNow();

            setUserSubscriptionInfo(dispatch, {
                subId: data.subscriptionId,
                subExpiry: deriveZuluTime(exp), // TODO - we should move towards UNIX timestamp
                isSubbed: true,
            });

            history.push('/subscriptions/success');
        } catch (err) {
            Sentry.captureException(err);
            message.error(ERR_PROCESS_PAYMENT);
        }
    }, []);

    const handleStripe2FA = useCallback(
        async (clientSecret: string, name: string, cardElement: StripeCardElement) => {
            try {
                const result = await stripe?.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: cardElement,
                        billing_details: {
                            name,
                        },
                    },
                });

                if (result?.error) {
                    Sentry.captureException(result.error);
                    message.error(ERR_PROCESS_PAYMENT);
                    return;
                }

                if (result?.paymentIntent?.status === 'succeeded') {
                    await handleTCS2FA(result.paymentIntent?.id);
                } else {
                    message.error(ERR_PROCESS_PAYMENT);
                }
            } catch (err) {
                // we should not expect any errors to be caught here
                Sentry.captureException(err);
                message.error(ERR_PROCESS_PAYMENT);
            }
        },
        [stripe]
    );

    const handleSubmitSubscription = useCallback(async () => {
        setIsLoading(true);

        const stripeCardElement = elements?.getElement(CardElement);

        if (!stripe || !stripeCardElement) {
            Sentry.captureMessage(
                '[CreditCardModalV2/index.tsx] Stripe or Stripe Card Element could not be found',
                Sentry.Severity.Error
            );
            setIsLoading(false);
            return;
        }

        const fullName = getFullname(user);

        try {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: stripeCardElement,
                billing_details: {
                    name: fullName,
                },
            });

            if (error) {
                // References for possible errors: https://stripe.com/docs/api/errors
                if (error.type !== 'validation_error' && error.type !== 'invalid_request_error') {
                    Sentry.captureException(error);
                    throw Error();
                }
                throw new Error(error.message);
            }

            const URL = `/private/v2/payments/plans/${priceId}/subscribe`;
            const payload = {
                paymentId: user.stripeId || '',
                paymentMethodId: paymentMethod?.id || '',
                couponCode: promoCode?.couponId || '',
            };

            const { data } = await axios.post<POSTPaymentsPlansPriceIdSubscribe>(URL, payload);

            setUserSubscriptionInfo(dispatch, {
                subId: data.subscriptionId,
                subExpiry: deriveZuluTime(data.expiry), // TODO - we should move towards UNIX timestamp
                isSubbed: true,
            });

            sendGTagEvent('subscribe', 'adConversion', 'subscribe');

            history.push('/subscriptions/success');
        } catch (err) {
            if (err?.response?.data?.code === 'PAYMENT_REQUIRE_ACTION') {
                const { clientSecret = '' } = err?.response?.data?.payload;

                if (clientSecret === '') {
                    Sentry.captureMessage(
                        `Detected CODE:PAYMENT_REQUIRE_ACTION. Error No Client Secret Found. Payload: ${err?.response?.data?.payload}`
                    );

                    message.error(ERR_PROCESS_PAYMENT);
                    return;
                }

                await handleStripe2FA(clientSecret, fullName, stripeCardElement);
                return;
            }

            message.error(err.message || ERR_GENERIC);
        } finally {
            setIsLoading(false);
        }
    }, [elements, stripe, priceId, promoCode]);

    const onCancel = () => {
        if (isLoading) return;

        setPromoCodeStatus(EPromoCodeInput.NULL);
        setPromoCode(null);
        handleOnCancel();
    };

    const handlePromoCodeReset = () => {
        setPromoCodeStatus(EPromoCodeInput.NULL);
        setPromoCode(null);
    };

    return (
        <CreditCardModalV2
            isLoading={isLoading}
            isVisible={isVisible}
            promoCodeValidityDuration={
                promoCode?.promoCodeDuration || EPromoCodeValidityDuration.INVALID
            }
            promoCodeStatus={promoCodeStatus}
            amountOff={promoCode?.amountOff || 0}
            price={price}
            handleOnCancel={onCancel}
            resetStatus={handlePromoCodeReset}
            validatePromoCode={validatePromoCode}
            handleSubmitSubscription={handleSubmitSubscription}
        />
    );
};

export default CreditCardModalV2Container;
