import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useHistory } from 'react-router';

import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getSubjectTopicFileTypes } from 'components/ReducerContext/selectors';

import { TCSSubject, TCSTopic } from 'common/types';
import {
    deriveQuizzesQueryString,
    parseQuizzesQuery,
    queryIsSameAsRef,
    validateQuizzesQuery,
    deriveWhitelistedSubjects,
} from './utils';

export const useQuizzes = () => {
    const history = useHistory();

    const { state } = useReducerContext();
    const { subjects, topics, fileTypes } = getSubjectTopicFileTypes(state);
    const whitelistedSubjects = useMemo(() => deriveWhitelistedSubjects(subjects), [subjects]);
    const quizSetIdQuery = useRef<number | null>(null);

    const [query, setQuery] = useState(() => parseQuizzesQuery(location.search));

    const [initDone, setInitDone] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [activeSubject, setActiveSubject] = useState<TCSSubject | null>(null);
    const [activeTopic, setActiveTopic] = useState<TCSTopic | null>(null);

    const resetActiveContent = useCallback(() => {
        setActiveSubject(null);
        setActiveTopic(null);
    }, []);

    const resetQuizIdQuery = () => {
        quizSetIdQuery.current = null;
    };

    const syncLocationChange = useCallback(
        async (search: string) => {
            const newQuery = parseQuizzesQuery(search);

            if (!queryIsSameAsRef(newQuery, query)) {
                setQuery(newQuery);
                if (newQuery === null) {
                    resetActiveContent();
                    return;
                }

                const foundSubject = subjects.find((s) => s.id === newQuery.subjectId);
                if (!foundSubject) {
                    resetActiveContent();
                    return;
                }

                setActiveSubject(foundSubject);
                const foundTopic = topics[foundSubject.id].find((t) => t.id === newQuery.topicId);

                if (!foundTopic) {
                    setActiveTopic(null);
                    return;
                }

                setActiveTopic(foundTopic);
            }
        },
        [subjects, topics, query]
    );

    const initialiseQuizzes = useCallback(async () => {
        try {
            if (query === null) return;

            const foundSubject = subjects.find((subject) => subject.id === query.subjectId);
            if (!foundSubject) return;

            setActiveSubject(foundSubject);

            const foundTopic = topics[foundSubject.id].find((topic) => topic.id === query.topicId);
            if (!foundTopic) return;

            setActiveTopic(foundTopic);
        } catch {
            setHasError(true);
        } finally {
            setInitDone(true);
        }
    }, []);

    useEffect(() => {
        if (query?.quizSetId) {
            quizSetIdQuery.current = query.quizSetId;
        }

        initialiseQuizzes();
    }, []);

    useEffect(() => {
        if (initDone) {
            const newQuery = validateQuizzesQuery({
                subjectId: activeSubject?.id,
                topicId: activeTopic?.id,
            });

            setQuery(newQuery);

            // TODO - we probably want to improve this portion to prevent double pushing
            const q = newQuery === null ? '' : deriveQuizzesQueryString(newQuery);
            history.push({ pathname: location.pathname, search: q });
        }
    }, [activeSubject, activeTopic, initDone]);

    /**
     * this effect is meant to handle when the user clicks on the browser back button
     * to sync up the active file/topic/subject with the URL
     * however, we still rely on the state as the source of truth
     */
    useEffect(() => {
        if (initDone) {
            syncLocationChange(location.search);
        }
    }, [location.search, syncLocationChange]);

    return {
        status: {
            initDone,
            hasError,
        },
        payloads: {
            subjects: whitelistedSubjects,
            topics,
            fileTypes,
            quizSetIdQuery: quizSetIdQuery.current,
        },
        activeContent: {
            activeTopic,
            activeSubject,
        },
        setters: {
            setActiveTopic,
            setActiveSubject,
            resetQuizIdQuery,
        },
    };
};
