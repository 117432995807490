import HLEcon from 'static/self-study/HL_Economics.svg';
import SLEcon from 'static/self-study/SL_Economics.svg';
import HLBio from 'static/self-study/HL_Biology.svg';
import SLBio from 'static/self-study/SL_Biology.svg';
import HLChem from 'static/self-study/HL_Chemistry.svg';
import SLChem from 'static/self-study/SL_Chemistry.svg';
import HLPhy from 'static/self-study/HL_Physics.svg';
import SLPhy from 'static/self-study/SL_Physics.svg';
import HLMathAA from 'static/self-study/HL_Mathematics_AA.svg';
import SLMathAA from 'static/self-study/SL_Mathematics_AA.svg';
import HLMathAI from 'static/self-study/HL_Mathematics_AI.svg';
import SLMathAI from 'static/self-study/SL_Mathematics_AI.svg';

export default {
    'HL Economics': HLEcon,
    'SL Economics': SLEcon,
    'HL Biology': HLBio,
    'SL Biology': SLBio,
    'HL Chemistry': HLChem,
    'SL Chemistry': SLChem,
    'HL Physics': HLPhy,
    'SL Physics': SLPhy,
    'HL Math AA': HLMathAA,
    'SL Math AA': SLMathAA,
    'HL Math AI': HLMathAI,
    'SL Math AI': SLMathAI,
} as Record<string, string>;
