import { EAskAQuestionStatus } from '../../../types';

export const deriveTagTextFromThreadStatus = (status: string): string => {
    switch (status) {
        case EAskAQuestionStatus.ADMIN_RESPONDED:
            return 'Unread';
        case EAskAQuestionStatus.CLIENT_RESPONDED:
            return 'Open';
        case EAskAQuestionStatus.RESOLVED:
            return 'Resolved';
        default:
            return '';
    }
};

export const deriveTagColorFromThreadStatus = (status: string): string => {
    switch (status) {
        case EAskAQuestionStatus.ADMIN_RESPONDED:
            return 'red';
        case EAskAQuestionStatus.CLIENT_RESPONDED:
            return 'green';
        case EAskAQuestionStatus.RESOLVED:
        default:
            return '';
    }
};
