import { useEffect } from 'react';

export const useBlockTabClose = (shouldTrigger = true) => {
    const handleTabClose = (e: BeforeUnloadEvent) => {
        e.preventDefault();

        // most browsers now do not allow custom messages in the beforeunload prompt anymore, and they are
        // different across browsers.
        // the difference between the beforeunload prompt and the rest is intended.
        return (e.returnValue = '');
    };

    useEffect(() => {
        if (!shouldTrigger) {
            return;
        }

        window.addEventListener('beforeunload', handleTabClose);

        return () => window.removeEventListener('beforeunload', handleTabClose);
    }, [handleTabClose, shouldTrigger]);
};
