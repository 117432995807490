import { TCSFileTypeShape, TCSSubject, TCSSubjectTopicMap } from 'common/types';
import { Dispatch } from 'react';

type CommonDispatch = {
    type: ECommonActions;
    payload: any;
};

export enum ECommonActions {
    resetStore = '@common/RESET_STORE',
    set401Error = '@common/SET_401_ERROR',
    loadSubjects = '@common/LOAD_SUBJECTS',
    loadTopics = '@common/LOAD_TOPICS',
    loadFileTypes = '@common/LOAD_FILE_TYPES',
}

export const resetStore = (dispatch: Dispatch<CommonDispatch>) => {
    dispatch({
        type: ECommonActions.resetStore,
        payload: null,
    });
};

export const set401Error = (dispatch: Dispatch<CommonDispatch>, is401Error: boolean) => {
    dispatch({
        type: ECommonActions.set401Error,
        payload: is401Error,
    });
};

export const loadSubjects = (dispatch: Dispatch<CommonDispatch>, subjects: TCSSubject[]) => {
    dispatch({
        type: ECommonActions.loadSubjects,
        payload: subjects,
    });
};

export const loadTopics = (dispatch: Dispatch<CommonDispatch>, topics: TCSSubjectTopicMap) => {
    dispatch({
        type: ECommonActions.loadTopics,
        payload: topics,
    });
};

export const loadFileTypes = (
    dispatch: Dispatch<CommonDispatch>,
    fileTypes: TCSFileTypeShape[]
) => {
    dispatch({
        type: ECommonActions.loadFileTypes,
        payload: fileTypes,
    });
};
