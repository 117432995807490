import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox, Spin, Tag } from 'antd';
import classnames from 'classnames/bind';
import { datadogRum } from '@datadog/browser-rum';

import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';

import TCSButton from 'components/TCSButton';
import TypographySubtitle from 'components/TypographySubtitle';
import TypographyBody from 'components/TypographyBody';
import { GETQuizChallengeLeaderboardUserBestPayload, GETTopics } from './types';
import { MAX_NUMBER_OF_TOPICS_PER_ROW } from './constants';

import styles from './TopicSelectorView.module.scss';

const cx = classnames.bind(styles);

type TopicSelectorViewProps = {
    subject: string;
    handleGoBack: () => void;
    currentPlayer: GETQuizChallengeLeaderboardUserBestPayload | null;
    handleStartGame: (topicIdList: number[]) => Promise<void>;
    hasError: boolean;
    isCurrentPlayerLoading: boolean;
    handleShowLeaderboard: () => void;
    handleShowPracticeMistakes: () => void;
    userUuid: string;
    topics: GETTopics[];
};

const TopicSelectorView: React.FC<TopicSelectorViewProps> = ({
    subject,
    handleGoBack,
    handleStartGame,
    handleShowLeaderboard,
    handleShowPracticeMistakes,
    isCurrentPlayerLoading,
    hasError,
    currentPlayer,
    userUuid,
    topics,
}) => {
    const [selectedTopicIdList, setSelectedTopicIdList] = useState<number[]>([]);

    const { state } = useReducerContext();
    const profile = useMemo(() => getUserProfile(state), [state]);

    const topicIDList = useMemo(() => topics.map((topic) => topic.topicId), [topics]);

    const renderContent = () => {
        if (isCurrentPlayerLoading) {
            return (
                <div>
                    <Spin />
                </div>
            );
        }

        if (hasError) {
            return <div>Something went wrong. Please try again later.</div>;
        }

        if (!profile.cognitoId) {
            return (
                <div>
                    <div>If you are a paid subscriber, log in before playing</div>
                    <div>to have your scores appear on the leaderboard!</div>
                    <Link to="/login">Login here</Link>
                </div>
            );
        }

        if (!currentPlayer) {
            return (
                <div>
                    <div>You have not played this week.</div>
                    <div>Take on the challenge today!</div>
                </div>
            );
        }

        return (
            <div>
                <div>Your Best Score Is</div>
                <div>
                    Streak: {currentPlayer.totalScore} - Total Skips: {currentPlayer.totalSkips}
                </div>
            </div>
        );
    };

    const handleSelectTopic = (topicId: number) => {
        if (!selectedTopicIdList.includes(topicId)) {
            setSelectedTopicIdList((prevState) => [...prevState, topicId]);
            return;
        }

        setSelectedTopicIdList((prevState) =>
            prevState.filter((selectedTopicId) => selectedTopicId !== topicId)
        );
    };

    const handleSelectAllTopics = () => {
        if (selectedTopicIdList.length !== topics.length) {
            setSelectedTopicIdList(topicIDList);
            return;
        }

        setSelectedTopicIdList([]);
    };

    const handleViewLeaderboardOnClick = () => {
        handleShowLeaderboard();

        datadogRum.addAction('viewLeaderboard', {
            context: {
                userUuid: userUuid,
                fullName: `${profile.firstName} ${profile.lastName}`,
            },
        });
    };

    useEffect(() => {
        datadogRum.addAction('landedOnTopicSelectorView', {
            context: {
                authId: userUuid,
                fullName: `${profile.firstName} ${profile.lastName}`,
            },
        });
    }, []);

    useEffect(() => {
        setSelectedTopicIdList(topicIDList);
    }, [topics]);

    return (
        <div className={cx('container')}>
            <div className={cx('content-container')}>
                <div className={cx('tag-container')}>
                    <Tag className={cx('subject-tag')}>{subject}</Tag>
                    <TCSButton
                        onClick={handleViewLeaderboardOnClick}
                        type="link"
                        className={cx('show-leaderboard')}
                    >
                        View Leaderboard
                    </TCSButton>

                    <TCSButton onClick={handleShowPracticeMistakes}>Practice Mistakes</TCSButton>
                </div>
                <div className={cx('button-container')}>
                    <div className={cx('start-container')}>
                        <TCSButton
                            onClick={() => handleStartGame(selectedTopicIdList)}
                            className={cx('start')}
                            type="primary"
                            disabled={selectedTopicIdList.length === 0}
                        >
                            Start Playing!
                        </TCSButton>
                    </div>
                    <TCSButton type="link" onClick={handleGoBack} className={cx('back')}>
                        Back to Quiz Challenge Home
                    </TCSButton>
                </div>

                <div className={cx('title-container')}>
                    <TypographySubtitle level={1} className={cx('title')}>
                        Active Topics
                    </TypographySubtitle>
                    <div onClick={() => handleSelectAllTopics()} className={cx('option')}>
                        <Checkbox checked={selectedTopicIdList.length === topics.length} />
                        <TypographyBody level={2}>Select All</TypographyBody>
                    </div>
                </div>

                <TypographyBody level={2} className={cx('subtitle')}>
                    Toggle off topics which you have not learned!
                </TypographyBody>

                <div className={cx('option-container')}>
                    <div className={cx('row')}>
                        {topics.slice(0, MAX_NUMBER_OF_TOPICS_PER_ROW).map((topic) => (
                            <div
                                key={topic.topicId}
                                onClick={() => handleSelectTopic(topic.topicId)}
                                className={cx('option')}
                            >
                                <Checkbox checked={selectedTopicIdList.includes(topic.topicId)} />
                                {topic.topicName}
                            </div>
                        ))}
                    </div>
                    <div className={cx('row')}>
                        {topics.slice(MAX_NUMBER_OF_TOPICS_PER_ROW).map((topic) => (
                            <div
                                key={topic.topicId}
                                onClick={() => handleSelectTopic(topic.topicId)}
                                className={cx('option')}
                            >
                                <Checkbox checked={selectedTopicIdList.includes(topic.topicId)} />
                                {topic.topicName}
                            </div>
                        ))}
                    </div>
                </div>

                <div className={cx('current-player-score')}>{renderContent()}</div>
            </div>
        </div>
    );
};

export default TopicSelectorView;
