import jsPDF from 'jspdf';
import * as Sentry from '@sentry/react';

import { GETQuestionSetDetailPayload } from '../QuestionSetSelector/types';
import { EThisIsHardState } from '../types';

const A4_RATIO = 210 / 297;
const A4_WIDTH = 210;
const A4_WIDTH_IN_PX = 793;
const A4_HEIGHT = 297;
const A4_HEIGHT_IN_PX = 1122;

const addWatermark = (doc: jsPDF) => {
    doc.saveGraphicsState();
    const gs = doc.GState({ opacity: 0.5 });
    doc.setGState(gs);
    doc.setFontSize(75);
    doc.setTextColor('#7e8691');
    doc.text('www.thecloudscore.com', 20, 250, { angle: 50 });
    doc.restoreGraphicsState();
};

const loadImageSrc = async (questionSetDetail: GETQuestionSetDetailPayload): Promise<string> =>
    new Promise((resolve, reject) => {
        const img = new Image();

        img.crossOrigin = 'use-credentials';

        img.onload = () => {
            const canvasURL = buildCanvasImageURL(img);
            resolve(canvasURL);
        };

        img.onerror = (e) => {
            const msg = `loadImageSrc - onerror: ${questionSetDetail.name} - ${e}`;
            Sentry.captureException(msg);
            reject(e);
        };

        img.src = questionSetDetail.questionFile;

        setTimeout(() => {
            const msg = `loadImageSrc - TIMEOUT`;
            Sentry.captureException(msg, {
                contexts: {
                    questionSetDetail: {
                        name: questionSetDetail.name,
                        id: questionSetDetail.id,
                        questionSetId: questionSetDetail.questionSetId,
                    },
                },
            });

            reject('TIMEOUT');
        }, 1000 * 180); // give max 3 minutes to load img
    });

export const downloadImage = async (questionSetDetails: GETQuestionSetDetailPayload[]) => {
    const doc = new jsPDF();

    // remove the page first - we will add in the pages individually
    // when iterating through the image array
    doc.deletePage(1);

    try {
        const out = await Promise.all(questionSetDetails.map((detail) => loadImageSrc(detail)));

        out.forEach((img) => {
            doc.addPage();
            doc.addImage(img, 'JPEG', 0, 0, A4_WIDTH, A4_HEIGHT);

            addWatermark(doc);
        });

        doc.output('pdfobjectnewwindow');
    } catch (err) {
        throw Error();
    }
};

const buildCanvasImageURL = (image: HTMLImageElement): string => {
    const { width, height } = image;
    const ratio = width / height;

    const canvas = document.createElement('canvas');
    canvas.height = A4_HEIGHT_IN_PX;
    canvas.width = A4_WIDTH_IN_PX;

    let targetHeightInPx, targetWidthInPx;

    if (ratio > A4_RATIO) {
        // wider than a4, use width as guide
        targetHeightInPx = A4_WIDTH_IN_PX / ratio;
        targetWidthInPx = A4_WIDTH_IN_PX;
    } else {
        // longer than a4, use length as guide
        targetHeightInPx = A4_HEIGHT_IN_PX;
        targetWidthInPx = A4_HEIGHT_IN_PX * ratio;
    }

    // fill "empty space" with white background
    const ctx = canvas.getContext('2d');
    if (ctx) {
        ctx.fillStyle = '#fff'; /// set white fill style
        ctx?.fillRect(0, 0, canvas.width, canvas.height);
    }

    canvas
        .getContext('2d')
        ?.drawImage(image, 0, 0, width, height, 0, 0, targetWidthInPx, targetHeightInPx);

    return canvas.toDataURL('image/jpeg');
};

export const deriveThisIsHardStatus = (
    isLoading: boolean,
    thisIsHardCreatedAt: string
): EThisIsHardState => {
    if (isLoading) {
        return EThisIsHardState.LOADING;
    }

    if (thisIsHardCreatedAt === '') {
        return EThisIsHardState.INACTIVE;
    }

    return EThisIsHardState.ACTIVE;
};
