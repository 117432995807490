export const fileTypeColours = [
    'magenta',
    'red',
    'volcano',
    'orange',
    'gold',
    'lime',
    'green',
    'cyan',
    'blue',
    'geekblue',
    'purple',
];

export const defaultFilters = [{ id: 0, name: 'Show All' }];
