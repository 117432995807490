export enum ERecordedLessonStep {
    PREVIEW_LESSONS = 0,
    MY_PURCHASED_LESSONS = 1,
}

export type PreviewRecordedLesson = {
    id: number;
    name: string;
    description: string;
    subjectId: number;
    productId: string;
    thumbnailUrl: string;
    previewVideoUrl: string;
    priceInCent: number;
    currency: string;
    filterTags: FilterTag[];
};

type FilterTag = {
    id: number;
    name: string;
};

export type PurchasedRecordedLesson = {
    id: number;
    name: string;
    description: string;
    subjectId: number;
    productId: string;
    thumbnailUrl: string;
    notesUrl: string;
    purchasedAt: number;
    filterTags: FilterTag[];
};

export type RecordedLessonQuery = {
    success?: boolean;
    purchasedLessonId?: number;
    canceled?: boolean;
};
