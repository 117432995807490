import { TCSSubject } from 'common/types';

// subjects in state is not available to user that is not logged in,
// we will hardcode the available subjects in the mean time.
export const availableSubjects: TCSSubject[] = [
    {
        id: 1,
        name: 'HL Economics',
    },
    {
        id: 2,
        name: 'SL Economics',
    },
];
