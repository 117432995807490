import { TCSSubject } from 'common/types';
import { parseQueryParam, validateID } from 'common/utils';
import { QNA_SUBJECT_WHITELIST } from 'config';
import { QuestionBankQuery, RawQuestionBankQuery } from './types';

// TODO: currently the functions in utils are very similar to solutions in Self Study
// to evaluate in the future if we need to refactor if they're indeed similar in functions

/**
 * Determines whether the given query param for self
 * study is valid or not
 * Ensures that dependent keys are present
 * i.e. if file_key is present, then topic_id & subject_id should be too
 * @param query - the self-study query param from the URL
 */
export const parseQuestionBankQuery = (query: string): QuestionBankQuery | null => {
    const parsedQuery = parseQueryParam(query);

    const subjectId = validateID(parsedQuery, 'subjectId');
    if (subjectId === null) return null;

    const topicId = validateID(parsedQuery, 'topicId');
    if (topicId === null) {
        return {
            subjectId,
        };
    }

    const questionSetId = validateID(parsedQuery, 'questionSetId');

    if (questionSetId === null) {
        return {
            subjectId,
            topicId,
        };
    }
    const fileKey = parsedQuery['fileKey'];

    if (!fileKey) {
        return {
            subjectId,
            topicId,
            questionSetId,
        };
    }

    return {
        subjectId,
        topicId,
        fileKey,
        questionSetId,
    };
};

/**
 * ensures that the query is valid. reconstructs the payload in certain instances to ensure
 * erroneous values doesn't accidentally find its way in
 * - has topic_id & file_key but no subject_id -> invalid
 * - has file_key key but value is undefined -> filtered out
 */
export const validateQuestionBankQuery = (query: QuestionBankQuery): QuestionBankQuery | null => {
    switch (true) {
        case Boolean(query.topicId && query.subjectId && query.questionSetId):
            return query;
        case Boolean(query.topicId && query.subjectId):
            return {
                topicId: query.topicId,
                subjectId: query.subjectId,
            };
        case Boolean(query.subjectId):
            return {
                subjectId: query.subjectId,
            };
        default:
            return null;
    }
};

export const deriveQuestionBankQueryString = (query: QuestionBankQuery): string => {
    const q = validateQuestionBankQuery(query);
    if (q === null) return '';

    const rawQ: RawQuestionBankQuery = {};

    if (q.fileKey) {
        rawQ.fileKey = q.fileKey;
    }

    if (q.subjectId) {
        rawQ.subjectId = q.subjectId.toString();
    }

    if (q.topicId) {
        rawQ.topicId = q.topicId.toString();
    }

    if (q.questionSetId) {
        rawQ.questionSetId = q.questionSetId.toString();
    }

    const querystring = new URLSearchParams(rawQ as Record<string, string>).toString();
    return querystring ? `?${querystring}` : '';
};

export const queryIsSameAsRef = (
    query: QuestionBankQuery | null,
    ref: QuestionBankQuery | null
): boolean => {
    if (query === null || ref === null) {
        return query === ref;
    }

    for (const key in query) {
        const v1 = query[key as keyof QuestionBankQuery];
        const v2 = ref[key as keyof QuestionBankQuery];

        if (v1 !== v2) return false;
    }

    return true;
};

export const deriveWhitelistedSubjects = (subjects: TCSSubject[]): TCSSubject[] => {
    const result: TCSSubject[] = [];

    subjects.forEach((sub) => {
        const { name = '' } = sub;
        if (QNA_SUBJECT_WHITELIST[name]) {
            result.push(sub);
        }
    });

    return result;
};
