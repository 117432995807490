import React, { useState, useEffect, useMemo, useCallback } from 'react';
import produce from 'immer';
import { message } from 'antd';

import { TCSSubject, TCSTopic } from 'common/types';

import FavouriteItem from './FavouriteItem';
import { GETFavouritesPayload } from 'pages/SelfStudyV2/types';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getFileTypes, getUserProfile } from 'components/ReducerContext/selectors';
import { useAxiosInstance } from 'components/AxiosContext';

type FavouriteItemContainerProps = {
    activeSubject: TCSSubject;
    activeTopic: TCSTopic;
    handleGoBack: () => void;
};

const FavouriteItemContainer: React.FC<FavouriteItemContainerProps> = ({
    activeSubject,
    activeTopic,
    handleGoBack,
}) => {
    const axios = useAxiosInstance();
    const { state } = useReducerContext();
    const profile = useMemo(() => getUserProfile(state), [state]);
    const fileTypes = useMemo(() => getFileTypes(state), [state]);

    const [isLoading, setIsLoading] = useState(false);
    const [favLoading, setFavLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [favourites, setFavourites] = useState<GETFavouritesPayload[]>([]);

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);

            const { data } = await axios.get<GETFavouritesPayload[]>(
                '/private/v1/favourites/list',
                {
                    params: {
                        cognito_id: profile.cognitoId,
                        subject_id: activeSubject.id,
                        topic_id: activeTopic.id,
                    },
                }
            );

            setFavourites(data.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)));
        } catch {
            setHasError(false);
        } finally {
            setIsLoading(false);
        }
    }, [profile, activeSubject, activeTopic]);

    const likeFav = useCallback(
        async (fav: GETFavouritesPayload) => {
            try {
                setFavLoading(true);

                const payload = {
                    cognito_id: profile.cognitoId,
                    subject_id: fav.subjectId,
                    topic_id: fav.topicId,
                    file_type_id: fav.fileTypeId,
                    file_key: fav.fileKey,
                    file_name: fav.fileName,
                    liked: true,
                    disliked: false,
                };

                await axios.post('/private/v1/favourites', payload);

                const newFavs = produce(favourites, (draft) => {
                    const f = draft.find((d) => d.fileKey === fav.fileKey);
                    if (f) {
                        f.liked = true;
                    }
                });

                setFavourites(newFavs);
            } catch {
                message.error('Oops! There was an error liking the content. Please try again.');
            } finally {
                setFavLoading(false);
            }
        },
        [favourites]
    );

    const unlikeFav = useCallback(
        async (fav: GETFavouritesPayload) => {
            try {
                setFavLoading(true);

                const payload = {
                    cognito_id: profile.cognitoId,
                    subject_id: fav.subjectId,
                    topic_id: fav.topicId,
                    file_type_id: fav.fileTypeId,
                    file_key: fav.fileKey,
                    file_name: fav.fileName,
                    liked: false,
                    disliked: false,
                };

                await axios.post('/private/v1/favourites', payload);

                const newFavs = produce(favourites, (draft) => {
                    const f = draft.find((d) => d.fileKey === fav.fileKey);
                    if (f) {
                        f.liked = false;
                    }
                    return draft;
                });

                setFavourites(newFavs);
            } catch {
                message.error('Oops! There was an error un-liking the content. Please try again.');
            } finally {
                setFavLoading(false);
            }
        },
        [favourites]
    );

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <FavouriteItem
            isLoading={isLoading}
            favLoading={favLoading}
            hasError={hasError}
            topicTitle={activeTopic.name}
            favourites={favourites}
            fileTypes={fileTypes}
            handleGoBack={handleGoBack}
            likeFav={likeFav}
            unlikeFav={unlikeFav}
        />
    );
};

export default FavouriteItemContainer;
