import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAxiosInstance } from 'components/AxiosContext';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';

import { ESubscriptionPlanType } from 'common/types';
import { EMySubscriptionStatus, GETV2UsersProfileSubscriptionInfo } from './types';
import { deriveSubscriptionStatus, deriveUnixTimestampFromZuluTimestamp } from '../utils';
import MySubscription from './MySubscription';

const MySubscriptionContainer: React.FC = () => {
    const axios = useAxiosInstance();
    const { state } = useReducerContext();
    const profile = getUserProfile(state);

    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [refetchCounter, setRefetchCounter] = useState(1);

    const [subscriptionInfo, setSubscriptionInfo] =
        useState<GETV2UsersProfileSubscriptionInfo | null>(null);

    const { subscriptionDetails = null, stripeId = '' } = useMemo(
        () => getUserProfile(state),
        [state]
    );
    const subscriptionStatus = useMemo(
        () => deriveSubscriptionStatus(subscriptionDetails),
        [subscriptionDetails]
    );
    const freeTrialEnd = useMemo(
        () => deriveUnixTimestampFromZuluTimestamp(subscriptionDetails?.freeTrialExpiry || ''),
        [subscriptionDetails]
    );

    const fetchSubscriptionInfo = useCallback(async () => {
        try {
            setIsLoading(true);
            setHasError(false);

            const { data } = await axios.get<GETV2UsersProfileSubscriptionInfo>(
                `/private/v2/users/profile/subscription-info?paymentId=${stripeId}&subscriptionId=${subscriptionDetails?.subscriptionId}`
            );

            setSubscriptionInfo(data);
        } catch {
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    }, [stripeId, subscriptionDetails]);

    useEffect(() => {
        if (subscriptionStatus === EMySubscriptionStatus.ACTIVE) {
            fetchSubscriptionInfo();
        }
    }, [subscriptionStatus, refetchCounter]);

    return (
        <MySubscription
            isLoading={isLoading}
            hasError={hasError}
            cancelAtPeriodEnd={subscriptionInfo?.cancelAtPeriodEnd || false}
            subscriptionEnd={subscriptionInfo?.subscriptionEnd || 0}
            freeTrialEnd={freeTrialEnd}
            lastInvoiceURL={subscriptionInfo?.latestInvoiceUrl || ''}
            defaultPaymentMethod={subscriptionInfo?.creditCardLast4Digits || ''}
            subscriptionId={subscriptionInfo?.subscriptionId || ''}
            subscriptionStatus={subscriptionStatus}
            subscriptionPlanType={
                profile.subscriptionDetails?.subscriptionPlanType || ESubscriptionPlanType.NONE
            }
            refetchSubscription={() => setRefetchCounter((c) => c + 1)}
        />
    );
};

export default MySubscriptionContainer;
