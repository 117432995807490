import React from 'react';
import classnames from 'classnames/bind';
import { Image } from 'antd';

import styles from './KeyFeature.module.scss';
const cx = classnames.bind(styles);

type KeyFeatureProps = {
    imgSrc: string;
    title: string;
    description: string;
};

const KeyFeature: React.FC<KeyFeatureProps> = ({ imgSrc, title, description }) => {
    return (
        <div className={cx('container')}>
            <Image src={imgSrc} width={300} preview={false} />
            <div className={cx('title')}>{title}</div>
            <div className={cx('content')}>{description}</div>
        </div>
    );
};

export default KeyFeature;
