import React, { useEffect, useState } from 'react';
import { message } from 'antd';

import { useCachedAxiosInstance } from 'components/AxiosContext';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';

import PastAttemptedQuizSetViewer from './PastAttemptedQuizSetViewer';
import { GETQuizSetAttemptedPayload, PastAttemptedQuizSetParams } from '../types';

type PastAttemptedQuizSetViewerContainerProps = {
    handleGoBack: () => void;
    handleReattemptQuizSet: (quizSetId: number) => void;
    pastAttemptedQuizSetParams: PastAttemptedQuizSetParams;
};

const PastAttemptedQuizSetViewerContainer: React.FC<PastAttemptedQuizSetViewerContainerProps> = ({
    handleGoBack,
    handleReattemptQuizSet,
    pastAttemptedQuizSetParams,
}) => {
    const cachedAxios = useCachedAxiosInstance();
    const { state } = useReducerContext();
    const { user } = state;

    const [isLoading, setIsLoading] = useState(false);
    const [activePastAttemptedQuizSet, setActivePastAttemptedQuizSet] =
        useState<GETQuizSetAttemptedPayload | null>(null);

    const fetchPastAttemptedQuizSet = async () => {
        try {
            setIsLoading(true);

            const payload = {
                authId: user?.cognitoId,
                ...pastAttemptedQuizSetParams,
            };

            const { data } = await cachedAxios.get<GETQuizSetAttemptedPayload>(
                '/private/v2/quiz/set/attempted',
                {
                    params: payload,
                }
            );

            setActivePastAttemptedQuizSet(data);
        } catch {
            message.error('Something went wrong. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchPastAttemptedQuizSet();
    }, []);
    return (
        <PastAttemptedQuizSetViewer
            isLoading={isLoading}
            handleGoBack={handleGoBack}
            pastAttemptedQuizSet={activePastAttemptedQuizSet}
            handleReattemptQuizSet={handleReattemptQuizSet}
        />
    );
};

export default PastAttemptedQuizSetViewerContainer;
