import { useEffect, useState, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { useAxiosInstance, useCachedAxiosInstance } from 'components/AxiosContext';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getSubjectTopicFileTypes, getUserProfile } from 'components/ReducerContext/selectors';
import { TCSSubject, TCSTopic, TCSFileShape } from 'common/types';
import { HIDE_NOTES } from 'flags';

import {
    parseSelfStudyQuery,
    deriveSortedFiles,
    deriveSelfStudyQueryString,
    validateSelfStudyQuery,
    queryIsSameAsRef,
    deriveWhitelistedSubjects,
} from './utils';
import { GETFavouritesPayload, TCSLikedContentDetail } from './types';

const FETCH_FILE_ERROR = 'Self-Study - Fetch file by topic error';

/**
 * useSelfStudy will handle the initialisation of /self-study. this includes:
 * 1. fetching from the CORE APIs for the necessary data
 * 2. parse the query params if any - deriving the active subject/topic/file
 * 3. maintain the state of the active subject/topic/file:
 *      - whenever there is a change in selection, such as clearing of an active file
 *      - update the URL's query params. NOTE: the state will be treated as the source
 *        of truth, and the url purely for sharing purposes
 */
export const useSelfStudy = () => {
    const history = useHistory();
    const location = useLocation();
    const cachedAxios = useCachedAxiosInstance();
    const { state } = useReducerContext();

    const [query, setQuery] = useState(() => parseSelfStudyQuery(location.search));

    const [initDone, setInitDone] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState<Error | null>(null);

    const { subjects, topics, fileTypes } = useMemo(() => getSubjectTopicFileTypes(state), [state]);
    const whitelistedSubjects = useMemo(() => deriveWhitelistedSubjects(subjects), [subjects]);

    const [files, setFiles] = useState<TCSFileShape[] | null>(null);

    const [activeSubject, setActiveSubject] = useState<TCSSubject | null>(null);
    const [activeTopic, setActiveTopic] = useState<TCSTopic | null>(null);
    const [activeFile, setActiveFile] = useState<TCSFileShape | null>(null);

    const [isNotesPanelOpen, setIsNotesPanelOpen] = useState(false);

    const fetchFiles = useCallback(async (topicId: number) => {
        try {
            setIsLoading(true);

            const { data } = await cachedAxios.get<TCSFileShape[]>(
                `/private/v1/files/?topic_id=${topicId}`
            );

            setFiles(deriveSortedFiles(data));
            return {
                files: data,
            };
        } catch {
            throw Error(FETCH_FILE_ERROR);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const resetActiveContent = useCallback(() => {
        setActiveSubject(null);
        setActiveTopic(null);
        setActiveFile(null);
    }, []);

    const syncLocationChange = useCallback(
        async (search: string) => {
            const newQuery = parseSelfStudyQuery(search);
            if (!queryIsSameAsRef(newQuery, query)) {
                setQuery(newQuery);

                if (newQuery === null) {
                    resetActiveContent();
                    return;
                }

                const sub = subjects.find((s) => s.id === newQuery.subjectId);
                if (!sub) {
                    resetActiveContent();
                    return;
                }

                setActiveSubject(sub);
                const top = topics[sub.id].find((t) => t.id === newQuery.topicId);

                if (!top) {
                    setActiveTopic(null);
                    setActiveFile(null);
                    return;
                }

                setActiveTopic(top);
                const { files } = await fetchFiles(top.id);

                const fi = files.find((f) => f.key === newQuery.fileKey);
                if (!fi) {
                    setActiveFile(null);
                    return;
                }

                setActiveFile(fi);
            }
        },
        [subjects, topics, query]
    );

    /**
     * 1. initialiseSelfStudy will first start by calling the CORE APIs - which will return
     * the list of subjects & topics, together with all the possible file types
     * 2. it will then proceed to parse the query parameter to see if it should:
     *  - ignore a null query (which indicates an query that's invalid/not-found)
     *  - set an active subject
     *  - set an active topic
     *  - query for files by topic_id (if there is an active topic_id)
     *  - set an active file (if file_key is present & can be found)
     * 3. initialiseSelfStudy does not take any values into its dependency array, as a way of keeping
     * it clean as purely an initialisation function. that's also why the API callbacks used all return
     * the values to be consumed despite setting it into the state
     */
    const initialiseSelfStudy = useCallback(async () => {
        try {
            if (query === null) return;

            const sub = subjects.find((s) => s.id === query.subjectId);
            if (!sub) return;
            setActiveSubject(sub);

            const top = topics[sub.id].find((t) => t.id === query.topicId);
            if (!top) return;
            setActiveTopic(top);

            const { files: fetchedFiles } = await fetchFiles(top.id);
            if (query.fileKey) {
                const af = fetchedFiles?.find((f) => f.key === query.fileKey);
                if (af) {
                    setActiveFile(af);
                }
            }

            if (query.isNotesPanelOpen === 'true') {
                setIsNotesPanelOpen(true);
            }
        } catch (err) {
            setHasError(err);
        } finally {
            setInitDone(true);
        }
    }, []);

    useEffect(() => {
        initialiseSelfStudy();
    }, []);

    useEffect(() => {
        if (initDone && activeTopic !== null) {
            fetchFiles(activeTopic.id);
        }
    }, [activeTopic]);

    useEffect(() => {
        if (initDone) {
            // we are consciously leaving out the drawer state from the query params, as it doesn't
            // need to be shareable like how subject/topic is
            const newQuery = validateSelfStudyQuery({
                subjectId: activeSubject?.id,
                topicId: activeTopic?.id,
                fileKey: activeFile?.key,
            });

            setQuery(newQuery);

            // TODO - we probably want to improve this portion to prevent double pushing
            // of the URL onto the stack. Currently if a user presses back, the URl will be
            // double stacked -> aka user on /foo/bar -> back to /foo -> we push /foo onto the
            // stack. This might be a minor annoyance
            const q = newQuery === null ? '' : deriveSelfStudyQueryString(newQuery);
            history.push({ pathname: location.pathname, search: q });
        }
    }, [activeSubject, activeTopic, activeFile, initDone]);

    /**
     * this effect is meant to handle when the user clicks on the browser back button
     * to sync up the active file/topic/subject with the URL
     * however, we still rely on the state as the source of truth
     */
    useEffect(() => {
        if (initDone) {
            syncLocationChange(location.search);
        }
    }, [location.search, syncLocationChange]);

    return {
        status: {
            initDone,
            isLoading,
            hasError,
            isNotesPanelOpen: HIDE_NOTES ? false : isNotesPanelOpen,
        },
        activeContent: {
            activeFile,
            activeTopic,
            activeSubject,
        },
        setters: {
            setActiveFile,
            setActiveTopic,
            setActiveSubject,
            setIsNotesPanelOpen,
        },
        payloads: {
            subjects: whitelistedSubjects,
            topics,
            fileTypes,
            files,
        },
    };
};

export const useLikedContent = (file: TCSFileShape) => {
    const axios = useAxiosInstance();
    const { state } = useReducerContext();

    const profile = useMemo(() => getUserProfile(state), [state]);

    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState<Error | null>(null);

    const [likedDetails, setLikedDetails] = useState<TCSLikedContentDetail | null>(null);

    const fetchFileFavDetail = useCallback(async (file: TCSFileShape) => {
        try {
            setIsLoading(true);
            setHasError(null);

            const { data } = await axios.get<GETFavouritesPayload>('/private/v1/favourites', {
                params: {
                    cognito_id: profile.cognitoId,
                    subject_id: file.subject_id,
                    topic_id: file.topic_id,
                    file_key: file.key,
                },
            });

            setLikedDetails({ liked: data.liked, disliked: data.disliked });
        } catch (err) {
            setHasError(err);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const likeActiveFile = useCallback(async (like: boolean) => {
        try {
            setIsLoading(true);

            const payload = {
                cognito_id: profile.cognitoId,
                subject_id: file.subject_id,
                topic_id: file.topic_id,
                file_type_id: file.file_type_id,
                file_key: file.key,
                file_name: file.original_file_name,
                liked: like,
                disliked: false,
            };

            await axios.post('/private/v1/favourites', payload);
            setLikedDetails({ liked: like, disliked: false });
        } catch (err) {
            throw Error(err);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const dislikeActiveFile = useCallback(async (dislike: boolean) => {
        try {
            setIsLoading(true);

            const payload = {
                cognito_id: profile.cognitoId,
                subject_id: file.subject_id,
                topic_id: file.topic_id,
                file_type_id: file.file_type_id,
                file_key: file.key,
                file_name: file.original_file_name,
                liked: false,
                disliked: dislike,
            };

            await axios.post('/private/v1/favourites', payload);
            setLikedDetails({ liked: false, disliked: dislike });
        } catch (err) {
            throw Error(err);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchFileFavDetail(file);
    }, []);

    return {
        status: {
            isLoading,
            hasError,
        },
        content: {
            likedDetails,
        },
        setters: {
            likeActiveFile,
            dislikeActiveFile,
        },
    };
};
