import React, { useState } from 'react';
import { Alert } from 'antd';
import { useHistory } from 'react-router';

import { EFreeTrialStatus, UserProfile } from 'components/ReducerContext/state';

import TCSButton from '../TCSButton';

type BannerProps = {
    isLoggedIn: boolean;
    userProfile: UserProfile;
};

const deriveBannerCopy = (userCohort: string) => {
    if (userCohort === '') {
        return ['Fill your IB cohort to access our free weekly lessons!', 'Fill Now'];
    }

    return ['A free weekly lesson is available on your dashboard!', 'Watch Now'];
};

const deriveIsSubscribed = (userProfile: UserProfile) => {
    if (userProfile.subscriptionDetails?.freeTrialStatus === EFreeTrialStatus.ACTIVE) return true;
    if (userProfile.subscriptionDetails?.isSubscribed) return true;

    return false;
};

const Banner: React.FC<BannerProps> = ({ isLoggedIn, userProfile }) => {
    const history = useHistory();

    const [showBanner, setShowBanner] = useState(true);

    const handleRedirectToDashboard = () => {
        history.push('/my-dashboard');

        scrollTo({ top: 350, behavior: 'smooth' });
    };

    if (!isLoggedIn || !showBanner || !deriveIsSubscribed(userProfile)) return null;

    const [msg, cta] = deriveBannerCopy(userProfile.userCohort || '');

    return (
        <Alert
            type="info"
            message={msg}
            banner
            closable
            onClose={() => setShowBanner(false)}
            action={<TCSButton onClick={handleRedirectToDashboard}>{cta}</TCSButton>}
        />
    );
};

export default Banner;
