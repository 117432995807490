import React, { useEffect, useMemo, useState } from 'react';
import { message } from 'antd';
import * as sentry from '@sentry/react';

import { useAxiosInstance } from 'components/AxiosContext';
import { useAuth } from 'components/AuthContextProvider';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';

import Cohort from './Cohort';
import { GETFreeRecordedLessonsPayload } from './types';

const CohortContainer: React.FC = () => {
    const [unixExamDate, setUnixExamDate] = useState(0); // UNIX timestamp
    const [freeLesson, setFreeLesson] = useState<GETFreeRecordedLessonsPayload | null>(null);

    const axios = useAxiosInstance();
    const { userAttr } = useAuth();

    const { state } = useReducerContext();
    const profile = useMemo(() => getUserProfile(state), [state]);

    const fetchUnixExamDate = async () => {
        try {
            // TODO: fetch exam date from API, currently API not ready
            // May 15 2023 = 1684080000
            setUnixExamDate(0);
        } catch (err) {
            if (err.response?.status === 404) {
                return;
            }

            message.error('Something went wrong while fetching exam date, please try again later.');
        }
    };

    const fetchFreeLesson = async () => {
        try {
            const { data } = await axios.get<GETFreeRecordedLessonsPayload>(
                '/private/v2/recorded-lessons/free'
            );

            setFreeLesson(data);
        } catch {
            message.error('Something went wrong loading free lesson. Please try again later.');
        }
    };

    const trackFreeLessonAction = async (action: string) => {
        if (!freeLesson) {
            return;
        }

        const payload = {
            authId: userAttr.cognitoId,
            fullName: `${userAttr.firstName} ${userAttr.lastName}`,
            email: userAttr.email,
            recordedLessonId: freeLesson?.id,
            action: action,
        };

        try {
            await axios.put('/private/v2/gd/free-lesson', payload);
        } catch (e) {
            sentry.captureException(e, {
                contexts: {
                    payload,
                },
            });
        }
    };

    useEffect(() => {
        fetchUnixExamDate();
    }, []);

    useEffect(() => {
        if (!profile.userCohort) {
            return;
        }

        fetchFreeLesson();
    }, [profile.userCohort]);

    return (
        <Cohort
            examDate={unixExamDate}
            lessonName={freeLesson?.name || ''}
            freeLessonFullVideoUrl={freeLesson?.fullVideoUrl || ''}
            freeLessonNotesUrl={freeLesson?.notesUrl || ''}
            summaryNotesUrl={freeLesson?.summaryNotesUrl || ''}
            trackFreeLessonAction={trackFreeLessonAction}
            userCohort={profile.userCohort || ''}
        />
    );
};

export default CohortContainer;
