import React from 'react';
import classnames from 'classnames/bind';
import { Typography, Button } from 'antd';

import styles from './SubscriptionSuccess.module.scss';

const cx = classnames.bind(styles);

type SubscriptionSuccessProps = {
    onProceed: () => void;
};

const SubscriptionSuccess: React.FC<SubscriptionSuccessProps> = ({ onProceed }) => {
    return (
        <div className={cx('container')}>
            <div className={cx('title')}>
                <Typography.Title level={2}>Thank You!</Typography.Title>
            </div>

            <div className={cx('sub-title')}>
                <Typography.Title level={5}>Your payment has been processed</Typography.Title>
            </div>

            <div className={cx('cta')}>
                <Button onClick={onProceed}>Proceed</Button>
            </div>
        </div>
    );
};

export default SubscriptionSuccess;
