import React from 'react';
import classnames from 'classnames/bind';
import { Image, message } from 'antd';
import { PlaySquareOutlined, DownloadOutlined } from '@ant-design/icons';

import TypographySubtitle from 'components/TypographySubtitle';
import TypographyBody from 'components/TypographyBody';
import TCSButton from 'components/TCSButton';
import { PurchasedRecordedLesson } from '../../types';

import styles from './PurchasedLessonCard.module.scss';

const cx = classnames.bind(styles);

type PurchasedLessonCardProps = {
    lesson: PurchasedRecordedLesson;
    fetchSignNotesUrl: () => Promise<string>;
    onWatchVideoClick: (lesson: PurchasedRecordedLesson) => void;
};

const PurchasedLessonCard: React.FC<PurchasedLessonCardProps> = ({
    lesson,
    fetchSignNotesUrl,
    onWatchVideoClick,
}) => {
    const handleDownload = async () => {
        try {
            const signedNotesUrl = await fetchSignNotesUrl();

            window.open(signedNotesUrl, '_blank', 'noopener noreferrer');
        } catch {
            message.error('Something went wrong, please try again');
        }
    };

    return (
        <div className={cx('container')}>
            <div className={cx('thumbnail-container')}>
                <Image src={lesson.thumbnailUrl} preview={false} width={360} height={270} />
            </div>
            <div className={cx('content-container')}>
                <div className={cx('title-container')}>
                    <TypographySubtitle level={1} className={cx('title')}>
                        {lesson.name}
                    </TypographySubtitle>
                </div>

                <TypographyBody level={2} className={cx('description')}>
                    {lesson.description}
                </TypographyBody>

                <div className={cx('buttons-container')}>
                    <TCSButton
                        icon={<PlaySquareOutlined />}
                        onClick={() => onWatchVideoClick(lesson)}
                    >
                        Watch Video
                    </TCSButton>
                    <TCSButton type="primary" icon={<DownloadOutlined />} onClick={handleDownload}>
                        Download Notes
                    </TCSButton>
                </div>
            </div>
        </div>
    );
};

export default PurchasedLessonCard;
