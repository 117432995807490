import React from 'react';
import classnames from 'classnames/bind';
import { Col, Row } from 'antd';

import TypographyHeader from 'components/TypographyHeader';
import TypographySubtitle from 'components/TypographySubtitle';
import { ESubscriptionPlanType } from 'common/types';

import FamilyMemberMySubscription from './FamilyMemberMySubscription';
import AnnualPlanSubscription from './AnnualPlanSubscription';
import MySubscription from './MySubscription';
import ChangePasswordForm from './ChangePasswordForm';
import { deriveSubscriptionPlanName, prependDash } from './utils';
import MyInvitees from './MyInvitees';

import styles from './MyAccountV2.module.scss';

const cx = classnames.bind(styles);

type MyAccountV2Props = {
    email: string;
    fullName: string;
    subscriptionPlanType: ESubscriptionPlanType;
};

const MyAccountV2: React.FC<MyAccountV2Props> = ({ email, fullName, subscriptionPlanType }) => {
    const renderMySubscriptionContent = () => {
        switch (subscriptionPlanType) {
            case ESubscriptionPlanType.FAMILY_MEMBER:
                return <FamilyMemberMySubscription />;
            case ESubscriptionPlanType.ANNUAL:
                return <AnnualPlanSubscription />;
            default:
                return <MySubscription />;
        }
    };

    return (
        <div className={cx('container')}>
            <div className={cx('header-container')}>
                <TypographyHeader level={3}>My Account</TypographyHeader>
            </div>

            <div className={cx('profile-container')}>
                <TypographySubtitle className={cx('title')} level={1}>
                    My Profile
                </TypographySubtitle>

                <Row gutter={[10, 16]}>
                    <Col span={4}>Full Name:</Col>
                    <Col span={20}>{fullName}</Col>

                    <Col span={4}>Email:</Col>
                    <Col span={20}>{email}</Col>
                </Row>
            </div>

            <div className={cx('password-container')}>
                <TypographySubtitle className={cx('title')} level={1}>
                    Change Password
                </TypographySubtitle>

                <ChangePasswordForm />
            </div>

            <div className={cx('subscription-container')}>
                <TypographySubtitle className={cx('title')} level={1}>
                    My Subscription
                    {prependDash(deriveSubscriptionPlanName(subscriptionPlanType))}
                </TypographySubtitle>

                {renderMySubscriptionContent()}

                {subscriptionPlanType === ESubscriptionPlanType.FAMILY_OWNER && (
                    <>
                        <hr className={cx('divider')} />
                        <TypographySubtitle className={cx('title')} level={1}>
                            Invite
                        </TypographySubtitle>

                        <MyInvitees />
                    </>
                )}
            </div>
        </div>
    );
};

export default MyAccountV2;
