import React from 'react';
import classnames from 'classnames/bind';
import { List, Spin } from 'antd';

import FloatingToolbar from 'components/FloatingToolbar';
import GoBack from 'components/FloatingToolbar/Tooltips/GoBack';
import TypographyHeader from 'components/TypographyHeader';
import TypographySubtitle from 'components/TypographySubtitle';
import { GETQuestionSetPayload } from './types';

import styles from './QuestionSetSelector.module.scss';

const cx = classnames.bind(styles);

type QuestionSetsViewerProps = {
    title: string;
    handleGoBack: () => void;
    isLoading: boolean;
    questionSets: GETQuestionSetPayload[];
    selectQuestionSet: (questionSet: GETQuestionSetPayload) => void;
};

const QuestionSetSelector: React.FC<QuestionSetsViewerProps> = ({
    title,
    handleGoBack,
    isLoading,
    questionSets,
    selectQuestionSet,
}) => {
    const handleSelectQuestionSet = (questionSet: GETQuestionSetPayload) => {
        selectQuestionSet(questionSet);
    };

    if (isLoading) {
        return (
            <div className={cx('spin-container')}>
                <Spin size="large" />
            </div>
        );
    }

    return (
        <div className={cx('container')}>
            <TypographyHeader level={3} className={cx('header')}>
                Topic: {title}
            </TypographyHeader>

            <div className={cx('list-container')}>
                <List
                    dataSource={questionSets}
                    renderItem={(item) => (
                        <List.Item
                            className={cx('list-item-container')}
                            onClick={() => handleSelectQuestionSet(item)}
                        >
                            <TypographySubtitle level={1} className={cx('list-item-text')}>
                                {item.name}
                            </TypographySubtitle>
                        </List.Item>
                    )}
                />
            </div>

            <FloatingToolbar tooltips={[<GoBack key="go-back" callback={handleGoBack} />]} />
        </div>
    );
};

export default QuestionSetSelector;
