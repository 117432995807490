import React from 'react';
import classnames from 'classnames/bind';

import Jumbotron from './Jumbotron';
import FeaturesSection from './FeaturesSection';
import UserBaseSection from './UserBaseSection';
import CTASection from './CTASection';

import styles from './AboutUs.module.scss';
const cx = classnames.bind(styles);

const AboutUs: React.FC = () => {
    return (
        <div className={cx('container')}>
            <Jumbotron />

            <div className={cx('content-container')}>
                <UserBaseSection />

                <FeaturesSection />

                <CTASection />
            </div>
        </div>
    );
};

export default AboutUs;
