import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { Image } from 'antd';
import { PlaySquareOutlined, ShoppingOutlined } from '@ant-design/icons';

import TypographySubtitle from 'components/TypographySubtitle';
import TypographyBody from 'components/TypographyBody';
import TCSButton from 'components/TCSButton';
import PreviewVideo from './PreviewVideo';
import Checkout from './Checkout';
import { deriveCurrencySign, derivePriceInDollar } from './utils';
import { PreviewRecordedLesson } from '../../types';

import styles from './PreviewLessonCard.module.scss';

const cx = classnames.bind(styles);

type PreviewLessonCardProps = {
    lesson: PreviewRecordedLesson;
    isPurchased: boolean;
    recordPreviewLessonClick: () => Promise<void>;
};

const PreviewLessonCard: React.FC<PreviewLessonCardProps> = ({
    lesson,
    isPurchased,
    recordPreviewLessonClick,
}) => {
    const [showPreviewVideo, setShowPreviewVideo] = useState(false);
    const [showCheckout, setShowCheckout] = useState(false);

    const handlePreviewVideoClick = async () => {
        setShowPreviewVideo(true);

        await recordPreviewLessonClick();
    };

    return (
        <>
            <div className={cx('container')}>
                <div className={cx('thumbnail-container')}>
                    <Image src={lesson.thumbnailUrl} preview={false} width={360} height={270} />
                </div>
                <div className={cx('content-container')}>
                    <div className={cx('title-container')}>
                        <TypographySubtitle level={1} className={cx('title')}>
                            {lesson.name}
                        </TypographySubtitle>
                        <div className={cx('price')}>
                            {deriveCurrencySign(lesson.currency)}
                            {derivePriceInDollar(lesson.priceInCent)}
                        </div>
                    </div>

                    <TypographyBody level={2} className={cx('description')}>
                        {lesson.description}
                    </TypographyBody>

                    <div className={cx('buttons-container')}>
                        <TCSButton icon={<PlaySquareOutlined />} onClick={handlePreviewVideoClick}>
                            Preview
                        </TCSButton>
                        {isPurchased ? (
                            <TCSButton type="primary" icon={<ShoppingOutlined />} disabled={true}>
                                Purchased
                            </TCSButton>
                        ) : (
                            <TCSButton
                                type="primary"
                                icon={<ShoppingOutlined />}
                                onClick={() => setShowCheckout(true)}
                            >
                                Purchase Now
                            </TCSButton>
                        )}
                    </div>
                </div>
            </div>

            <PreviewVideo
                visible={showPreviewVideo}
                onCancel={() => setShowPreviewVideo(false)}
                title={lesson.name}
                videoUrl={lesson.previewVideoUrl}
            />

            <Checkout
                visible={showCheckout}
                onCancel={() => setShowCheckout(false)}
                productId={lesson.productId}
                lessonId={lesson.id}
            />
        </>
    );
};

export default PreviewLessonCard;
