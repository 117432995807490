import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useAxiosInstance } from 'components/AxiosContext';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { setUserProfile } from 'components/ReducerContext/Actions/UserActions';
import { useAuth } from 'components/AuthContextProvider/AuthContextProvider';

import { deriveUserProfile, GETUsersProfilePayload, refreshCognitoJWT } from './utils';

const SUBSCRIPTION_POLL = 1000 * 60 * 30; // 30 minutes;
const TOKEN_POLL = 1000 * 60 * 15; // 15 minutes;

/**
 * we poll the user's profile regularly to ensure that
 * 1. we get the latest cloudfront cookie used for viewing our content that is hosted on S3
 * 2. we can update the store with the latest user's subscription/free trial info
 * the RedirectRouter should handle the necessary stuff if the user's subscription
 * status gets changed
 * 3. we also want to get the cloudscore cookie for "active" users - active means either have
 * an active subscription or free trial - this cookie is required for calling all the endpoints
 * that are behind a paywall (self-study, quizzes, q&a etc)
 */
export const usePollUserProfile = (startPoll: boolean) => {
    const axios = useAxiosInstance();
    const { state, dispatch } = useReducerContext();
    const { setToken } = useAuth();

    const profilePollRef = useRef<NodeJS.Timeout | null>(null);
    const tokenRefreshRef = useRef<NodeJS.Timeout | null>(null);

    const userFreeTrial = useMemo(
        () => state.user?.subscriptionDetails?.freeTrialStatus || '',
        [state]
    );
    const userSubStatus = useMemo(
        () => state.user?.subscriptionDetails?.isSubscribed || '',
        [state]
    );

    const clearUserPoll = () => {
        if (profilePollRef.current) clearInterval(profilePollRef.current);
    };

    const clearTokenPoll = () => {
        if (tokenRefreshRef.current) clearTimeout(tokenRefreshRef.current);
    };

    const pollUserSubscription = useCallback(async () => {
        const { data } = await axios.get<GETUsersProfilePayload>('/private/v2/users/profile');
        const derivedProfile = deriveUserProfile(data);
        setUserProfile(dispatch, derivedProfile);
    }, [axios]);

    const refreshToken = useCallback(async () => {
        if (profilePollRef.current) {
            clearTimeout(profilePollRef.current);
        }

        const token = await refreshCognitoJWT();
        if (token) {
            setToken(token);
        }

        profilePollRef.current = setTimeout(() => {
            refreshToken();
        }, TOKEN_POLL);
    }, []);

    // basically if either free trial or sub status changes, we want to just fetch the
    // profile again - primarily to get the cloudscore cookie if they are now an active
    // user - active means either active free trial or active subscription
    useEffect(() => {
        // we still rely on the "startPoll" flag because the flag is an indicator
        // for after an active session
        // when it is false, it probably means the user has not logged in or is not
        // authenticated yet
        if (startPoll) {
            pollUserSubscription();
            refreshToken();
        }
    }, [userSubStatus, userFreeTrial]);

    useEffect(() => {
        if (startPoll) {
            if (profilePollRef.current === null) {
                profilePollRef.current = setInterval(pollUserSubscription, SUBSCRIPTION_POLL);
            }

            refreshToken();
        }

        if (!startPoll) {
            clearUserPoll();
            clearTokenPoll();
        }
    }, [startPoll]);

    useEffect(() => {
        return () => {
            clearUserPoll();
            clearTokenPoll();
        };
    }, []);
};
