import {
    AWS_PINPOINT_ID,
    AWS_REGION,
    COGNITO_IDENTITY_POOL_ID,
    COGNITO_USER_POOL_ID,
    COGNITO_WEB_CLIENT_ID,
} from 'config';

export default {
    Auth: {
        userPoolId: COGNITO_USER_POOL_ID,
        userPoolWebClientId: COGNITO_WEB_CLIENT_ID,
        identityPoolId: COGNITO_IDENTITY_POOL_ID,
        region: AWS_REGION,
    },
    Analytics: {
        disabled: false,
        AWSPinpoint: {
            appId: AWS_PINPOINT_ID,
            region: 'us-east-1',
            mandatorySignIn: false,
        },
    },
};
