import React, { useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { Typography, Spin } from 'antd';
import classnames from 'classnames/bind';
import { datadogRum } from '@datadog/browser-rum';

import { ArcPdfViewerCallbackObject } from './types';
import NavigationArrows from '../NavigationArrows';

import styles from './ArcPdfViewer.module.scss';

const cx = classnames.bind(styles);

/**
 * see issues:
 * https://github.com/wojtekmaj/react-pdf/issues/52
 * https://github.com/wojtekmaj/react-pdf/issues/321
 */
// eslint-disable-next-line
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

type ArcPdfViewerProps = {
    fileUrl: string;
    scale: number;
    isDisplayAll?: boolean;
    defaultPage?: number;
    onRight?: (payload: ArcPdfViewerCallbackObject) => void;
};

const Error = (
    <div className={cx('error-container')}>
        <Typography.Title level={4}>
            Oops! Sorry but something went wrong when trying to load the pdf. Please try again
            later!
        </Typography.Title>
    </div>
);

const Loading = (
    <div className={cx('loading-container')}>
        <Spin size="large" />
    </div>
);

const ArcPdfViewer: React.FC<ArcPdfViewerProps> = ({
    fileUrl,
    scale,
    isDisplayAll = true,
    defaultPage = 1,
    onRight,
}) => {
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(defaultPage);

    const pdfOptions = useMemo(() => {
        return {
            url: fileUrl,
            withCredentials: true,
        };
    }, [fileUrl]);

    const pdfWidth = useMemo(() => (window.innerWidth >= 1440 ? 1080 : 800), []);

    const handleOnLoadSuccess = (pdf: PDFDocumentProxy) => {
        const { numPages } = pdf;
        setTotalPages(numPages);
    };

    const handleOnLoadError = (error: Error) => {
        datadogRum.addError(error, { fileUrl });
    };

    const handleOnLeft = () => {
        setCurrentPage((prevState) => prevState - 1);
    };

    const handleOnRight = () => {
        if (onRight) {
            const payload: ArcPdfViewerCallbackObject = {
                currentPage,
                totalPages,
            };

            onRight(payload);
        }

        setCurrentPage((prevState) => prevState + 1);
    };

    const isFirstPage = useMemo(() => currentPage === 1, [currentPage]);
    const isLastPage = useMemo(() => currentPage === totalPages, [currentPage, totalPages]);

    return (
        <div className={cx('container')}>
            <Document
                file={pdfOptions}
                error={Error}
                loading={Loading}
                onLoadSuccess={handleOnLoadSuccess}
                onLoadError={handleOnLoadError}
            >
                {isDisplayAll ? (
                    Array(totalPages)
                        .fill(1)
                        .map((_, index) => (
                            <Page
                                key={index}
                                pageNumber={index + 1}
                                width={pdfWidth}
                                scale={scale}
                            />
                        ))
                ) : (
                    <Page pageNumber={currentPage} width={pdfWidth} scale={scale} />
                )}
            </Document>

            {!isDisplayAll && (
                <div className={cx('control-container')}>
                    <NavigationArrows
                        onLeft={handleOnLeft}
                        onRight={handleOnRight}
                        isLeftDisabled={isFirstPage}
                        isRightDisabled={isLastPage}
                        text={`${currentPage} / ${totalPages}`}
                    />
                </div>
            )}
        </div>
    );
};

export default ArcPdfViewer;
