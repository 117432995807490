import React from 'react';
import { ZoomInOutlined } from '@ant-design/icons';
import classnames from 'classnames/bind';

import styles from './Tooltips.module.scss';

const cx = classnames.bind(styles);

type ZoomInProps = {
    callback: () => void;
};

const ZoomIn: React.FC<ZoomInProps> = ({ callback }) => {
    return (
        <div className={cx('zoom-in')} onClick={callback}>
            <ZoomInOutlined style={{ fontSize: '28px' }} />
            <div className={cx('text')}>Zoom In</div>
        </div>
    );
};

export default ZoomIn;
