import React from 'react';
import { Modal, Spin, Typography } from 'antd';
import classnames from 'classnames/bind';

import styles from './LoadingModal.module.scss';

const cx = classnames.bind(styles);

type LoadingModalProps = {
    showModal: boolean;
    message: string;
};

const LoadingModal: React.FC<LoadingModalProps> = ({ showModal, message }) => (
    <Modal visible={showModal} closable={false} footer={null}>
        <div className={cx('modal-container')}>
            <div className={cx('spin-container')}>
                <Spin size="large" />
            </div>

            <div className={cx('text-container')}>
                {message.split('\n').map((line, index) => (
                    <Typography.Title key={index} level={5}>
                        {line}
                    </Typography.Title>
                ))}
            </div>
        </div>
    </Modal>
);

export default LoadingModal;
