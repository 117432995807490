import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import { Layout, Menu, Image } from 'antd';
import { Link } from 'react-router-dom';

import logo from 'static/logo_v2.png';
import { deriveLoggedInMenu, deriveLoggedOutMenu } from 'components/NavigationBar/menus';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';

import DropdownMenu from './DropdownMenu';
import Banner from './Banner';

import styles from './NavigationBar.module.scss';

const cx = classnames.bind(styles);

const { Header } = Layout;

type ArcNavigationBarProps = {
    isLoggedIn: boolean;
    selectedMenuKey: string[];
    handleSignOut: () => void;
};

const NavigationBar: React.FC<ArcNavigationBarProps> = ({
    isLoggedIn,
    selectedMenuKey,
    handleSignOut,
}) => {
    const { state } = useReducerContext();
    const profile = useMemo(() => getUserProfile(state), [state]);

    return (
        <div className={cx('navigation-bar-container')}>
            <Header className={cx('navigation-bar')}>
                <div className={cx('logo-container')}>
                    <Link to="/">
                        <Image preview={false} src={logo} width={132} height={32} />
                    </Link>
                </div>

                <Menu
                    selectedKeys={selectedMenuKey}
                    className={cx('menu')}
                    mode="horizontal"
                    inlineCollapsed={false}
                >
                    {isLoggedIn ? (
                        <>
                            {deriveLoggedInMenu().map((menuItem) => (
                                <Menu.Item key={menuItem.key}>
                                    <Link
                                        data-cy={`navigation-bar__${menuItem.key}`}
                                        to={menuItem.path}
                                    >
                                        {menuItem.title}
                                    </Link>
                                </Menu.Item>
                            ))}
                        </>
                    ) : (
                        <>
                            {deriveLoggedOutMenu().map((menuItem) => (
                                <Menu.Item key={menuItem.key}>
                                    <Link
                                        data-cy={`navigation-bar__${menuItem.key}`}
                                        to={menuItem.path}
                                    >
                                        {menuItem.title}
                                    </Link>
                                </Menu.Item>
                            ))}
                        </>
                    )}
                </Menu>

                {isLoggedIn && <DropdownMenu handleSignOut={handleSignOut} />}
            </Header>

            <Banner isLoggedIn={isLoggedIn} userProfile={profile} />
        </div>
    );
};

export default NavigationBar;
