import { useMemo } from 'react';
import { Quiz } from '../types';

export const useQuizzesHelper = (currentIndex: number, quizzes: Quiz[]) => {
    const isLastQuiz = useMemo(() => currentIndex === quizzes.length - 1, [currentIndex, quizzes]);
    const isFirstQuiz = useMemo(() => currentIndex === 0, [currentIndex]);

    return {
        isFirstQuiz,
        isLastQuiz,
    };
};
