import { DraftJSEditorStateNotes, GETTopicNotesPayload } from '../types';
import { convertFromUnixTimestamp, deriveNotesTaken } from 'common/utils';
import { TCSFileTypeShape } from 'common/types';

export const convertToEditorStateNotes = (
    notesList: GETTopicNotesPayload[]
): DraftJSEditorStateNotes[] => {
    return notesList.map((notes) => ({
        ...notes,
        lastUpdated: convertFromUnixTimestamp(notes.lastUpdated),
        notesTaken: deriveNotesTaken(notes.notesTaken),
    }));
};

export const deriveActiveNotesTypes = (
    fileTypes: TCSFileTypeShape[],
    notesList: DraftJSEditorStateNotes[]
): TCSFileTypeShape[] => {
    return fileTypes.filter(
        (fileType) => notesList.findIndex((notes) => notes.fileTypeId === fileType.id) > -1
    );
};
