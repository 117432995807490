import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as Sentry from '@sentry/react';
import { datadogRum } from '@datadog/browser-rum';
import awsconfig from 'config/amplify';

import { AuthContextProvider } from 'components/AuthContextProvider';
import AxiosContextProvider from 'components/AxiosContext';
import ReducerContextProvider from 'components/ReducerContext/ReducerContext';

import {
    STRIPE_API_KEY,
    SENTRY_DSN,
    BUILD_ENV,
    DATADOG_ENV,
    DATADOG_CLIENT_TOKEN,
    DATADOG_APPLICATION_ID,
    DATADOG_SAMPLE_RATE,
    BUILD_VERSION,
} from 'config';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';

Sentry.init({ dsn: SENTRY_DSN, environment: BUILD_ENV, release: BUILD_VERSION });

Amplify.configure(awsconfig);

const stripePromise = loadStripe(STRIPE_API_KEY || '');

if (window) {
    window.__arclrn = {};
}

datadogRum.init({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'tcs-client-frontend',
    env: DATADOG_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: BUILD_VERSION,
    sampleRate: DATADOG_SAMPLE_RATE,
    trackInteractions: true,
});

ReactDOM.render(
    <React.StrictMode>
        <AuthContextProvider>
            <ReducerContextProvider>
                <Elements stripe={stripePromise}>
                    <AxiosContextProvider>
                        <App />
                    </AxiosContextProvider>
                </Elements>
            </ReducerContextProvider>
        </AuthContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
