import React from 'react';
import classnames from 'classnames/bind';

import ResultQuizCard from './ResultQuizCard';
import TCSButton from 'components/TCSButton';
import { GETMistakesQuizPayload, QuizAnswer } from '../QuizSetViewer/types';

import styles from './ResultViewer.module.scss';

const cx = classnames.bind(styles);

type ResultViewerProps = {
    quizzes: GETMistakesQuizPayload[];
    selectedAnswerList: QuizAnswer[];
    handleGoBack: () => void;
};

//TODO: to reevaluate in the future whether to refactor into common components
// derived from Quizzes/PastAttemptedQuizSetViewer
const ResultViewer: React.FC<ResultViewerProps> = ({
    quizzes,
    selectedAnswerList,
    handleGoBack,
}) => {
    return (
        <div className={cx('container')}>
            <div className={cx('content-container')}>
                <div>
                    <div className={cx('score-tag-container')}>
                        <div className={cx('score-tag')}>
                            Score:{' '}
                            {selectedAnswerList.filter((answer) => answer.isCorrectAnswer).length} /{' '}
                            {quizzes.length}
                        </div>
                    </div>

                    <div className={cx('quiz-card-container')}>
                        {quizzes.map((quiz, index) => (
                            <div key={index}>
                                <ResultQuizCard
                                    quiz={quiz}
                                    index={index}
                                    isAnsweredCorrectly={selectedAnswerList[index].isCorrectAnswer}
                                    selectedAnswer={selectedAnswerList[index]}
                                />
                            </div>
                        ))}
                    </div>

                    <div className={cx('buttons-footer')}>
                        <TCSButton
                            onClick={handleGoBack}
                            type="link"
                            className={cx('back-to-quiz-challenge')}
                        >
                            Back To Quiz Challenge
                        </TCSButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResultViewer;
