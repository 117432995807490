import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAxiosInstance } from 'components/AxiosContext';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getSubjectTopicFileTypes, getUserProfile } from 'components/ReducerContext/selectors';

import { deriveActiveSubjectAndTopic } from 'pages/MyFavourites/utils';
import MyNotes from './MyNotes';
import { TCSSubject, TCSSubjectTopicMap } from 'common/types';
import { GETActiveNotesPayload } from 'pages/MyNotes/types';

const MyNotesContainer: React.FC = () => {
    const axios = useAxiosInstance();

    const { state } = useReducerContext();

    const profile = useMemo(() => getUserProfile(state), [state]);
    const { subjects, topics } = useMemo(() => getSubjectTopicFileTypes(state), [state]);

    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [activeSubjects, setActiveSubjects] = useState<TCSSubject[]>([]);
    const [activeTopics, setActiveTopics] = useState<TCSSubjectTopicMap>({});

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);

            const notesUrl = `/private/v1/notes/active?cognitoId=${profile.cognitoId}`;
            const { data } = await axios.get<GETActiveNotesPayload[]>(notesUrl);

            const { activeSubs, activeTops } = deriveActiveSubjectAndTopic(data, subjects, topics);

            setActiveSubjects(activeSubs);
            setActiveTopics(activeTops);
        } catch {
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <MyNotes
            isLoading={isLoading}
            hasError={hasError}
            subjects={activeSubjects}
            topics={activeTopics}
        />
    );
};

export default MyNotesContainer;
