import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { Badge, Button } from 'antd';

import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getGetStudyPlanCounter } from 'components/ReducerContext/selectors';

import Popup from './Popup';
import { useAskAQuestion } from './hooks';
import { EAskAQuestionStatus } from './types';

import styles from './AskAQuestion.module.scss';

const cx = classnames.bind(styles);

const AskAQuestion: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const { action, state } = useAskAQuestion();
    const { handleThreadsRefetch } = action;
    const { isLoading, hasError, threads } = state;

    const { state: reducerState } = useReducerContext();
    const getStudyPlanCounter = useMemo(() => getGetStudyPlanCounter(reducerState), [reducerState]);

    const onGetStudyPlan = () => {
        setIsOpen(true);
    };

    const adminRespondedCount = useMemo(
        () =>
            threads.reduce(
                (acc, thread) =>
                    thread.threadStatus === EAskAQuestionStatus.ADMIN_RESPONDED ? acc + 1 : acc,
                0
            ),
        [threads]
    );

    useEffect(() => {
        if (getStudyPlanCounter === 0) {
            return;
        }

        onGetStudyPlan();
    }, [getStudyPlanCounter]);

    useEffect(() => {
        const windowWidth = Math.max(
            document.documentElement.clientWidth || 0,
            window.innerWidth || 0
        );
        if (windowWidth > 768) {
            setIsOpen(true);
        }
    }, []);

    return (
        <div className={cx('container')}>
            {isOpen ? (
                <Popup
                    closePopup={() => setIsOpen(false)}
                    handleThreadsRefetch={handleThreadsRefetch}
                    isLoading={isLoading}
                    hasError={hasError}
                    threads={threads}
                    onGetStudyPlan={onGetStudyPlan}
                />
            ) : (
                <Badge count={adminRespondedCount}>
                    <Button className={cx('button')} onClick={() => setIsOpen(true)}>
                        Ask a Question
                    </Button>
                </Badge>
            )}
        </div>
    );
};

export default AskAQuestion;
