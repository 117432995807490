import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAxiosInstance } from 'components/AxiosContext';
import { getUserProfile } from 'components/ReducerContext/selectors';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';

import RequestVideos from './RequestVideos';

const getUnixTimestampNow = () => {
    return Math.floor(Date.now() / 1000);
};

type RequestVideosContainerProps = {
    handleRedirect: () => void;
};

const RequestVideosContainer: React.FC<RequestVideosContainerProps> = ({ handleRedirect }) => {
    const client = useAxiosInstance();
    const { state } = useReducerContext();

    const profile = useMemo(() => getUserProfile(state), [state]);

    const [categories, setCategories] = useState<string[]>([]);

    const categoriesMapArray = useMemo(() => {
        return categories.map((category) => ({
            label: category,
            value: category,
        }));
    }, [categories]);

    const fetchShortVideoCategories = useCallback(async () => {
        try {
            const { data } = await client.get<string[]>('/private/v2/sv/categories');
            setCategories(data);
        } catch {
            setCategories([]);
        }
    }, []);

    const submitShortVideoRequest = async (
        subjectId: number,
        question: string,
        categories: string[]
    ) => {
        const payload = {
            user_id: profile?.cognitoId || '',
            subject_id: subjectId.toString(),
            created_at: getUnixTimestampNow().toString(),
            status: '',
            question,
            categories,
        };

        try {
            await client.post('/private/v2/sv/new/request', payload);
        } catch {
            throw Error();
        }
    };

    useEffect(() => {
        fetchShortVideoCategories();
    }, [fetchShortVideoCategories]);

    return (
        <div>
            <RequestVideos
                categoriesMapArray={categoriesMapArray}
                handleRedirect={handleRedirect}
                submitRequest={submitShortVideoRequest}
            />
        </div>
    );
};

export default RequestVideosContainer;
