// QuestionBankQuery defines the expected shape when extracted from the query params
export type QuestionBankQuery = {
    fileKey?: string;
    subjectId?: number;
    topicId?: number;
    questionSetId?: number;
};

// RawQuestionBankQuery represents the Record that we can use together with URLSearchParams
export type RawQuestionBankQuery = {
    fileKey?: string;
    subjectId?: string;
    topicId?: string;
    questionSetId?: string;
};

export enum EThisIsHardState {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    LOADING = 'loading',
}
