import React from 'react';
import classnames from 'classnames/bind';
import { Image } from 'antd';
import Slider from 'react-slick';

import InstagramLogo from 'static/landing/instagram-glyph-gradient.png';
import { testimonials, settings } from './constants';

import styles from './TestimonialsSection.module.scss';
const cx = classnames.bind(styles);
const TestimonialsSection: React.FC = () => {
    return (
        <div className={cx('container')}>
            <div className={cx('section-title')}>What our users say</div>
            <div className={cx('slider-container')}>
                <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                        <div className={cx('item-container')} key={index}>
                            <div className={cx('testimonial-container')}>
                                <div className={cx('image-container')}>
                                    <Image src={InstagramLogo} width={35} />
                                </div>
                                <h3>{testimonial.name}</h3>
                                <div className={cx('testimonial')}>{testimonial.content}</div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default TestimonialsSection;
