import React from 'react';
import classnames from 'classnames/bind';
import { FileAddOutlined } from '@ant-design/icons';

import styles from './Tooltips.module.scss';

const cx = classnames.bind(styles);

type WriteNotesProps = {
    active: boolean;
    callback: () => void;
};

const WriteNotes: React.FC<WriteNotesProps> = ({ active, callback }) => {
    return (
        <div className={cx('write-notes')} onClick={callback} data-testid="tooltips-write-notes">
            <FileAddOutlined
                style={{ fontSize: '28px' }}
                className={cx({ 'notes-active': active })}
            />
            <div className={cx('text', { 'active-text': active })}>Notes</div>
        </div>
    );
};

export default WriteNotes;
