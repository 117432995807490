import React from 'react';
import classnames from 'classnames/bind';
import { List, Spin } from 'antd';

import { PreviewRecordedLesson, PurchasedRecordedLesson } from '../types';
import PreviewLessonCard from './PreviewLessonCard';

import styles from './PreviewLessons.module.scss';
const cx = classnames.bind(styles);

type PreviewLessonsProps = {
    previewLessons: PreviewRecordedLesson[];
    isLoading: boolean;
    purchasedLessons: PurchasedRecordedLesson[];
};

const PreviewLessons: React.FC<PreviewLessonsProps> = ({
    previewLessons,
    isLoading,
    purchasedLessons,
}) => {
    if (isLoading) {
        return (
            <div className={cx('loading-container')}>
                <Spin size="large" />
            </div>
        );
    }

    return (
        <div className={cx('container')}>
            <List
                dataSource={previewLessons}
                renderItem={(item) => (
                    <PreviewLessonCard
                        lesson={item}
                        isPurchased={
                            purchasedLessons.findIndex(
                                (purchasedLessons) => purchasedLessons.id === item.id
                            ) > -1
                        }
                    />
                )}
            />
        </div>
    );
};

export default PreviewLessons;
