import React from 'react';
import classnames from 'classnames/bind';
import { Image } from 'antd';

import TypographySubtitle from 'components/TypographySubtitle';
import AnswerCard from 'components/AnswerCard';
import { QuizChallengeQuestion } from '../../types';

import styles from './LastAttemptedQuiz.module.scss';

const cx = classnames.bind(styles);

type QuizSetViewerProps = {
    currentQuiz: QuizChallengeQuestion;
    lastSelectedQuizAnswerId: number;
    correctAnswerId: number;
};

const LastAttemptedQuiz: React.FC<QuizSetViewerProps> = ({
    currentQuiz,
    lastSelectedQuizAnswerId,
    correctAnswerId,
}) => {
    return (
        <div className={cx('container')}>
            <div className={cx('content-container')}>
                <div className={cx('content-container')}>
                    <TypographySubtitle level={1} className={cx('question')}>
                        {currentQuiz.questionText}
                    </TypographySubtitle>

                    {currentQuiz.questionImage !== '' && (
                        <div className={cx('image-container')}>
                            <Image src={currentQuiz.questionImage} width={595} preview={false} />
                        </div>
                    )}

                    {currentQuiz.quizAnswers.map((quizAnswer) => (
                        <AnswerCard
                            key={quizAnswer.id}
                            name={quizAnswer.answerText}
                            isSelected={lastSelectedQuizAnswerId === quizAnswer.id}
                            isCorrectAnswer={quizAnswer.id === correctAnswerId}
                            isAnsweredCorrectly={lastSelectedQuizAnswerId === correctAnswerId}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LastAttemptedQuiz;
