import React, { useState, useMemo } from 'react';
import classnames from 'classnames/bind';
import { Button, Spin, Image } from 'antd';
import { datadogRum } from '@datadog/browser-rum';

import { getUserProfile } from 'components/ReducerContext/selectors';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';

import { ShortVideosResponse } from '../types';
import ShortVideosCard from '../Components/ShortVideosCard';
import ShortVideosPlayer from '../Components/ShortVideosPlayer';

import Banner from 'static/short-videos/crashcourse_banner.jpg';

import styles from './ExploreVideos.module.scss';

const cx = classnames.bind(styles);

type ExploreVideosProps = {
    isLoading: boolean;
    hasMoreVideos: boolean;
    videos: ShortVideosResponse[];
    loadMoreVideos: () => void;
    handleOnRequest: () => void;
};

type ActiveVideo = {
    videoUrl: string;
    videoTitle: string;
};

const ExploreVideos: React.FC<ExploreVideosProps> = ({
    isLoading,
    videos,
    hasMoreVideos,
    loadMoreVideos,
    handleOnRequest,
}) => {
    const { state } = useReducerContext();
    const profile = useMemo(() => getUserProfile(state), [state]);

    const [activeVideo, setActiveVideo] = useState<ActiveVideo | null>(null);

    const handleOnClickVideo = (video: ShortVideosResponse) => {
        setActiveVideo({ videoUrl: video.video_url || '', videoTitle: video.video_title || '' });
        datadogRum.addAction('openShortVideo', {
            videoTitle: video.video_title,
            authId: profile.cognitoId,
        });
    };

    const handleOnCloseVideo = () => {
        datadogRum.addAction('closeShortVideo', {
            videoTitle: activeVideo?.videoTitle,
            authId: profile.cognitoId,
        });
        setActiveVideo(null);
    };

    return (
        <div className={cx('container')}>
            <ShortVideosPlayer
                isVisible={activeVideo !== null}
                videoTitle={activeVideo?.videoTitle || ''}
                videoUrl={activeVideo?.videoUrl || ''}
                onClose={handleOnCloseVideo}
            />

            <div className={cx('banner')}>
                <Image preview={false} src={Banner} />
            </div>

            {videos.length === 0 && isLoading && (
                <div className={cx('loading-container')}>
                    <Spin size="large" />
                </div>
            )}

            <div className={cx('videos-container')}>
                {videos.map((video) => (
                    <ShortVideosCard
                        isExplorePage
                        key={video.created_at}
                        video={video}
                        handleOnClickVideo={handleOnClickVideo}
                    />
                ))}
            </div>

            {hasMoreVideos && (
                <div className={cx('load-more-container')}>
                    <Button
                        type="primary"
                        onClick={loadMoreVideos}
                        disabled={isLoading}
                        loading={isLoading}
                    >
                        Load More
                    </Button>
                </div>
            )}

            <div className={cx('request-container')}>
                Can&apos;t find what you&apos;re looking for?{' '}
                <span className={cx('request-cta')} onClick={handleOnRequest}>
                    Request
                </span>{' '}
                for a crashcourse and we will make one for you!
                <div className={cx('disclaimer')} onClick={handleOnRequest}>
                    You may only have 1 pending request at a time.
                </div>
            </div>
        </div>
    );
};

export default ExploreVideos;
