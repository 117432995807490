import React, { useCallback, useEffect, useState } from 'react';

import { useAxiosInstance, useCachedAxiosInstance } from 'components/AxiosContext';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';

import { sortByDescending } from 'common/utils';
import QuizSetSelector from './QuizSetSelector';
import { GETQuizSetPayload, GETQuizSetAttemptedPayload } from '../types';

type QuizSetSelectorContainerProps = {
    title: string;
    topicId: number;
    subjectId: number;
    quizSetId: number | null;
    refetchCounter: number;
    resetQuizIdQuery: () => void;
    handleGoBack: () => void;
    handleQuizSetCardClick: (id: number) => void;
    handlePastAttemptedQuizSetCardClick: (quizSet: GETQuizSetAttemptedPayload) => void;
};

const QuizSetSelectorContainer: React.FC<QuizSetSelectorContainerProps> = ({
    title,
    topicId,
    subjectId,
    quizSetId,
    refetchCounter,
    resetQuizIdQuery,
    handleGoBack,
    handleQuizSetCardClick,
    handlePastAttemptedQuizSetCardClick,
}) => {
    const cachedAxios = useCachedAxiosInstance();
    const axios = useAxiosInstance();

    const { state } = useReducerContext();
    const { user } = state;

    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const [quizSets, setQuizSets] = useState<GETQuizSetPayload[]>([]);
    const [pastAttemptedQuizSets, setPastAttemptedQuizSets] = useState<
        GETQuizSetAttemptedPayload[]
    >([]);

    const fetchQuizSets = useCallback(async () => {
        try {
            const { data } = await cachedAxios.get<GETQuizSetPayload[]>(
                `/private/v2/quiz/sets?topicId=${topicId}`
            );

            const sortedData = sortByDescending(data, 'name');

            setQuizSets(sortedData);
            return sortedData;
        } catch {
            setQuizSets([]);
            return [];
        }
    }, []);

    const fetchPastAttemptedQuizzes = useCallback(async () => {
        try {
            const { data } = await axios.get<GETQuizSetAttemptedPayload[]>(
                `/private/v2/quiz/sets/attempted`,
                {
                    params: {
                        authId: user?.cognitoId,
                        subjectId: subjectId,
                        topicId: topicId,
                    },
                }
            );

            const sortedData = sortByDescending(data, 'attemptedDate');
            setPastAttemptedQuizSets(sortedData);
        } catch {
            setPastAttemptedQuizSets([]);
        }
    }, []);

    const handleFetchQuizSetsAndPastAttemptedQuizzes = async () => {
        setIsLoading(true);

        const result = await Promise.all([fetchQuizSets(), fetchPastAttemptedQuizzes()]);
        const quizSetResults = result[0];

        setIsLoading(false);
        setIsLoaded(true);

        if (quizSetId && quizSetResults.find((q) => q.id === quizSetId)) {
            handleQuizSetCardClick(quizSetId);
        }

        resetQuizIdQuery();
    };

    useEffect(() => {
        handleFetchQuizSetsAndPastAttemptedQuizzes();
    }, [refetchCounter]);

    return (
        <QuizSetSelector
            title={title}
            handleGoBack={handleGoBack}
            isLoading={isLoading}
            isLoaded={isLoaded}
            quizSets={quizSets}
            pastAttemptedQuizSets={pastAttemptedQuizSets}
            handleQuizSetCardClick={handleQuizSetCardClick}
            handlePastAttemptedQuizSetCardClick={handlePastAttemptedQuizSetCardClick}
        />
    );
};

export default QuizSetSelectorContainer;
