import React, { useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { Input } from 'antd';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';

import TCSButton from 'components/TCSButton';
import { EPromoCodeInput } from './types';

import styles from './PromoCodeInput.module.scss';

const cx = classNames.bind(styles);

type PromoCodeInputProps = {
    validationStatus: EPromoCodeInput;
    validatePromoCode: (code: string) => Promise<void>;
    resetStatus: () => void;
    isLoading: boolean;
};

const PromoCodeInput: React.FC<PromoCodeInputProps> = ({
    validationStatus,
    validatePromoCode,
    resetStatus,
    isLoading,
}) => {
    const [code, setCode] = useState('');

    const handleOnClick = () => {
        if (validationStatus === EPromoCodeInput.NULL) {
            validatePromoCode(code);
            return;
        }

        resetStatus();
        setCode('');
    };

    const handleInputOnChange = (input: string) => {
        if (code !== '') {
            resetStatus();
        }

        setCode(input);
    };
    const inputSuffix = useMemo(() => {
        if (validationStatus === EPromoCodeInput.VALID)
            return <CheckCircleFilled style={{ color: '#2EC06B' }} />;

        if (validationStatus === EPromoCodeInput.INVALID)
            return <ExclamationCircleFilled style={{ color: '#C0302E' }} />;

        return null;
    }, [validationStatus]);

    const promoCodeIsEmpty = useMemo(() => {
        return validationStatus === EPromoCodeInput.NULL;
    }, [validationStatus]);

    return (
        <div className={cx('promo-code-container')}>
            <div className={cx('promo-code-input-container')}>
                <Input
                    className={cx('promo-code-input', {
                        'has-error': validationStatus === EPromoCodeInput.INVALID,
                    })}
                    placeholder="Enter your promo code here"
                    suffix={inputSuffix}
                    value={code}
                    onChange={(e) => handleInputOnChange(e.target.value)}
                />
                {validationStatus === EPromoCodeInput.INVALID && (
                    <p className={cx('validation-error')}>This promo code is invalid.</p>
                )}
            </div>

            <TCSButton danger={!promoCodeIsEmpty} onClick={handleOnClick} loading={isLoading}>
                {promoCodeIsEmpty ? 'Apply' : 'Clear'}
            </TCSButton>
        </div>
    );
};

export default PromoCodeInput;
