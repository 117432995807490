import React from 'react';
import classnames from 'classnames/bind';
import { Spin, Typography, Tag, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';

import { TCSFileTypeShape } from 'common/types';
import { EThumbsUpDownState, GETFavouritesPayload } from 'pages/SelfStudyV2/types';
import FloatingToolbar from 'components/FloatingToolbar';
import GoBack from 'components/FloatingToolbar/Tooltips/GoBack';

import FavouriteItemThumbsUp from './FavouriteItemThumbsUp';

// TODO: shift into common file
import { fileTypeColours } from 'pages/SelfStudyV2/ContentSelector/constants';

import style from './FavouriteItem.module.scss';

const cx = classnames.bind(style);

// TODO: shift into common util
const getFileTypeName = (fileTypes: TCSFileTypeShape[], ftID: number): string => {
    const ft = fileTypes.find((f) => f.id === ftID);
    return ft ? ft.name : '';
};

export const constructURLParams = (fav: GETFavouritesPayload): string => {
    const param = new URLSearchParams();
    param.append('subject_id', fav.subjectId.toString());
    param.append('topic_id', fav.topicId.toString());
    param.append('file_key', fav.fileKey);

    return `?${param.toString()}`;
};

type FavouriteItemProps = {
    isLoading: boolean;
    favLoading: boolean;
    hasError: boolean;
    topicTitle: string;
    favourites: GETFavouritesPayload[];
    fileTypes: TCSFileTypeShape[];
    handleGoBack: () => void;
    likeFav: (f: GETFavouritesPayload) => Promise<void>;
    unlikeFav: (f: GETFavouritesPayload) => Promise<void>;
};

const FavouriteItem: React.FC<FavouriteItemProps> = ({
    isLoading,
    favLoading,
    hasError,
    topicTitle,
    favourites,
    fileTypes,
    handleGoBack,
    likeFav,
    unlikeFav,
}) => {
    const handleOnClick = (fav: GETFavouritesPayload) => {
        const params = constructURLParams(fav);
        const url = `/self-study${params}`;

        const data = {
            fileName: fav.fileName,
            subjectId: fav.subjectId,
            topicId: fav.topicId,
        };

        datadogRum.addAction('User access file through My Favourites', data);
        window.open(url, '_blank', 'noopener noreferrer');
    };

    const deriveThumbsUpStatus = (fav: GETFavouritesPayload) => {
        if (favLoading) return EThumbsUpDownState.DISABLED;

        return fav.liked ? EThumbsUpDownState.ACTIVE : EThumbsUpDownState.INACTIVE;
    };

    const derivePopconfirmProps = (fav: GETFavouritesPayload) => {
        if (fav.liked) {
            return {
                title: `Are you sure you want to un-like ${fav.fileName}?`,
                okText: 'Yes',
                cancelText: 'No',
                onConfirm: () => unlikeFav(fav),
            };
        }

        return {
            title: `Are you sure you want to re-like ${fav.fileName}?`,
            okText: 'Yes',
            cancelText: 'No',
            onConfirm: () => likeFav(fav),
        };
    };

    if (isLoading) {
        return (
            <div className={cx('spin-container')}>
                <Spin size="large" />
            </div>
        );
    }

    if (hasError) {
        return (
            <div className={cx('error-container')}>
                <Typography.Title level={4}>
                    Oops! Sorry, but it seems like we encountered an error. Please refresh the page
                    and try again.
                    <br />
                    If this issue persists, please kindly reach out to us at any one of our
                    communication channels. You can find them <Link to="/contact-us">here</Link>.
                </Typography.Title>
            </div>
        );
    }

    return (
        <div className={cx('container')}>
            <div className={cx('header')}>
                <Typography.Title level={2} data-testid="favourite-item-title">
                    {topicTitle}
                </Typography.Title>
            </div>

            <div className={cx('fav-container')}>
                {favourites.map((f) => (
                    <div key={f.fileKey} className={cx('fav-items')}>
                        <span className={cx('thumbs-up')}>
                            {favLoading ? (
                                <div>
                                    <FavouriteItemThumbsUp status={deriveThumbsUpStatus(f)} />
                                </div>
                            ) : (
                                <Popconfirm {...derivePopconfirmProps(f)}>
                                    <div>
                                        <FavouriteItemThumbsUp status={deriveThumbsUpStatus(f)} />
                                    </div>
                                </Popconfirm>
                            )}
                        </span>

                        <span
                            data-testid="favourite-item-list-item"
                            onClick={() => handleOnClick(f)}
                            className={cx('file-name')}
                        >
                            {f.fileName}
                        </span>

                        <Tag color={fileTypeColours[f.fileTypeId]}>
                            {getFileTypeName(fileTypes, f.fileTypeId)}
                        </Tag>
                    </div>
                ))}
            </div>

            <FloatingToolbar tooltips={[<GoBack key="go-back" callback={handleGoBack} />]} />
        </div>
    );
};

export default FavouriteItem;
