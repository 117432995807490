import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { Button, Empty, Spin, Image as AntImage, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import FloatingToolbar from 'components/FloatingToolbar';
import GoBack from 'components/FloatingToolbar/Tooltips/GoBack';
import GetStudyPlan from 'components/FloatingToolbar/Tooltips/GetStudyPlan';
import Download from 'components/FloatingToolbar/Tooltips/Download';
import ThisIsHard from 'components/FloatingToolbar/Tooltips/ThisIsHard';
import TypographyHeader from 'components/TypographyHeader';
import TypographySubtitle from 'components/TypographySubtitle';
import ArcVideoPlayer from 'components/ArcVideoPlayer';
import ArcPdfViewer from 'components/ArcPdfViewer';
import NavigationArrows from 'components/NavigationArrows';
import ZoomIn from 'components/FloatingToolbar/Tooltips/ZoomIn';
import ZoomOut from 'components/FloatingToolbar/Tooltips/ZoomOut';

import { GETQuestionSetDetailPayload, GETQuestionSetPayload } from '../QuestionSetSelector/types';
import { useQuestionSetHelper } from './hooks';
import { downloadImage } from './utils';

import styles from './QuestionSetViewer.module.scss';

const cx = classnames.bind(styles);

type QuestionSetViewerProps = {
    isLoaded: boolean;
    isLoading: boolean;
    questionSet: GETQuestionSetPayload;
    questionSetDetails: GETQuestionSetDetailPayload[];
    handleGoBack: () => void;
};

const QuestionSetViewer: React.FC<QuestionSetViewerProps> = ({
    isLoaded,
    isLoading,
    questionSet,
    questionSetDetails,
    handleGoBack,
}) => {
    const [scale, setScale] = useState(0.75);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [showAnswer, setShowAnswer] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const {
        isFirstQuestion,
        isLastQuestion,
        currentQuestion,
        totalQuestions,
        currentQuestionNumber,
        thisIsHardCreatedAt,
        thisIsHardStatus,
        actions,
    } = useQuestionSetHelper(questionSet, questionSetDetails, currentQuestionIndex);

    const { addThisIsHard, removeThisIsHard } = actions;

    const handleNextQuestion = () => {
        if (isLastQuestion) {
            return;
        }

        setCurrentQuestionIndex((prevState) => prevState + 1);
        setShowAnswer(false);
    };

    const handleZoomIn = () => {
        if (scale >= 3.0) return;
        setScale((scale) => scale + 0.25);
    };

    const handleZoomOut = () => {
        if (scale <= 0.5) return;
        setScale((scale) => scale - 0.25);
    };

    const handlePreviousQuestion = () => {
        if (isFirstQuestion) {
            return;
        }
        setCurrentQuestionIndex((prevState) => prevState - 1);
        setShowAnswer(false);
    };

    const toggleShowAnswer = () => {
        setShowAnswer((prevState) => !prevState);
    };

    const handleDownload = async () => {
        try {
            setIsDownloading(true);
            await downloadImage(questionSetDetails);
        } catch {
            message.error('Oops! Something went wrong. Please try again!');
        } finally {
            setIsDownloading(false);
        }
    };

    const handleToggleThisIsHard = async () => {
        if (!questionSetDetails) {
            return;
        }

        try {
            if (thisIsHardCreatedAt !== '') {
                await removeThisIsHard();
                return;
            }

            await addThisIsHard();
        } catch {
            message.error('Something went wrong. Please try again later.');
        }
    };

    const handleRenderContent = () => {
        if (isLoading) {
            return (
                <div className={cx('spin-container')}>
                    <Spin size="large" />
                </div>
            );
        }

        if (!currentQuestion) {
            return isLoaded ? <Empty /> : null;
        }

        return (
            <div>
                <div className={cx('subtitle-container')}>
                    <div className={cx('question-number-container')}>
                        <TypographySubtitle level={1} className={cx('question-number')}>
                            Question {currentQuestionNumber} out of {totalQuestions}
                        </TypographySubtitle>

                        <NavigationArrows
                            onLeft={handlePreviousQuestion}
                            onRight={handleNextQuestion}
                            isLeftDisabled={isFirstQuestion}
                            isRightDisabled={isLastQuestion}
                        />
                    </div>

                    <div className={cx('subtitle')}>{currentQuestion.name}</div>
                </div>

                <AntImage src={currentQuestion.questionFile} preview={false} />

                <div className={cx('show-answer-button-container')}>
                    <Button onClick={toggleShowAnswer}>
                        Show Answer
                        <DownOutlined className={cx({ 'reverse-arrow': showAnswer })} />
                    </Button>
                </div>

                {showAnswer && (
                    <div className={cx('answer-container')}>
                        {currentQuestion.answerVideo && (
                            <ArcVideoPlayer
                                videoUrl={currentQuestion.answerVideo}
                                videoHeight="100%"
                                videoWidth="100%"
                            />
                        )}

                        <div className={cx('pdf-container')}>
                            <ArcPdfViewer fileUrl={currentQuestion.answerFile} scale={scale} />
                        </div>
                    </div>
                )}

                <div className={cx('buttons-footer')}>
                    <Button
                        onClick={handlePreviousQuestion}
                        className={cx('previous')}
                        disabled={isFirstQuestion}
                    >
                        Previous Question
                    </Button>
                    <Button
                        onClick={handleNextQuestion}
                        className={cx('next')}
                        type="primary"
                        disabled={isLastQuestion}
                    >
                        Next Question
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <>
            {isDownloading && (
                <div className={cx('download-container')}>
                    <div className={cx('download-content')}>
                        <TypographySubtitle className={cx('title')} level={1}>
                            Please wait while we prepare your download...
                        </TypographySubtitle>

                        <div className={cx('spin')}>
                            <Spin size="large" />
                        </div>
                    </div>
                </div>
            )}

            <div className={cx('container')}>
                <div className={cx('content-container')}>
                    <TypographyHeader className={cx('title')} level={3}>
                        {questionSet.name}
                    </TypographyHeader>
                    {handleRenderContent()}
                </div>
                <FloatingToolbar
                    tooltips={[
                        <GoBack key="go-back" callback={handleGoBack} />,
                        <ZoomIn key="zoom-in" callback={handleZoomIn} />,
                        <ZoomOut key="zoom-out" callback={handleZoomOut} />,
                        <GetStudyPlan key="get-study-plan" />,
                        <Download
                            key="download"
                            title="Download as PDF"
                            callback={handleDownload}
                        />,
                        <ThisIsHard
                            key="this-is-hard"
                            callback={handleToggleThisIsHard}
                            status={thisIsHardStatus}
                        />,
                    ]}
                />
            </div>
        </>
    );
};

export default QuestionSetViewer;
