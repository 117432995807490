import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { message, Modal } from 'antd';

import { setUserCohort } from 'components/ReducerContext/Actions/UserActions';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';

import TypographySubtitle from 'components/TypographySubtitle';
import TCSButton from 'components/TCSButton';
import IBDatePicker from 'components/IBDatePicker';
import { monthOptions } from './constants';

import styles from './CohortPopup.module.scss';
const cx = classnames.bind(styles);

type CohortPopupProps = {
    visible: boolean;
    onClose: () => void;
    isLoading: boolean;
    updateCohort: (year: number, month: string) => Promise<void>;
};

const CohortPopup: React.FC<CohortPopupProps> = ({ visible, onClose, updateCohort, isLoading }) => {
    const [selectedYear, setSelectedYear] = useState(0);
    const [selectedMonth, setSelectedMonth] = useState('');

    const { dispatch } = useReducerContext();

    const onMonthOptionClick = (month: string) => {
        setSelectedMonth(month);
    };

    const onYearChange = (year: number) => {
        setSelectedYear(year);
    };

    const handleUpdateCohort = async () => {
        try {
            await updateCohort(selectedYear, selectedMonth);

            const userCohort = `${selectedYear}#${selectedMonth}`;
            setUserCohort(dispatch, userCohort);

            onClose();
            message.success('User cohort is set successfully.');
        } catch {
            message.error('Something went wrong. Please try again later');
        }
    };

    const footer = (
        <div className={cx('footer')}>
            <TCSButton onClick={onClose}>Cancel</TCSButton>
            <TCSButton type="primary" onClick={handleUpdateCohort} loading={isLoading}>
                Submit
            </TCSButton>
        </div>
    );

    return (
        <Modal wrapClassName={cx('container')} visible={visible} onCancel={onClose} footer={footer}>
            <div className={cx('content')}>
                <TypographySubtitle level={1} className={cx('header')}>
                    When are you taking the IB?
                </TypographySubtitle>
                <IBDatePicker
                    monthOptions={monthOptions}
                    selectedMonth={selectedMonth}
                    selectedYear={selectedYear}
                    onMonthOptionClick={onMonthOptionClick}
                    onYearChange={onYearChange}
                />
            </div>
        </Modal>
    );
};

export default CohortPopup;
