import React from 'react';
import classnames from 'classnames/bind';
import { PrinterOutlined } from '@ant-design/icons';

import styles from './Tooltips.module.scss';

const cx = classnames.bind(styles);

type PrintNotesProps = {
    callback?: () => void;
};

const PrintNotes: React.FC<PrintNotesProps> = ({ callback }) => {
    return (
        <div className={cx('print-notes')} onClick={callback}>
            <PrinterOutlined style={{ fontSize: '28px' }} />
            <div className={cx('text')}>Print</div>
        </div>
    );
};

export default PrintNotes;
