import React from 'react';
import classnames from 'classnames/bind';
import { Tag, Image } from 'antd';

import { ShortVideosResponse } from '../types';

import styles from './ShortVideosCard.module.scss';

const cx = classnames.bind(styles);

type ShortVideosCardProps = {
    isExplorePage?: boolean;
    video: ShortVideosResponse;
    handleOnClickVideo: (video: ShortVideosResponse) => void;
};

const ShortVideosCard: React.FC<ShortVideosCardProps> = ({
    isExplorePage = false,
    video,
    handleOnClickVideo,
}) => {
    return (
        <div
            key={video.created_at}
            className={cx('videos-card', {
                pending: video.status === 'PENDING',
                explore: isExplorePage,
            })}
            onClick={() => handleOnClickVideo(video)}
        >
            <div className={cx('question')}>
                {isExplorePage ? video.video_title : video.question}
            </div>

            <div className={cx('thumbnail-container')}>
                {video.thumbnail_url && (
                    <Image src={video.thumbnail_url} className={cx('thumbnail')} preview={false} />
                )}
            </div>

            <div className={cx('tags-container')}>
                {!isExplorePage &&
                    (video.status === 'PENDING' ? (
                        <Tag color="volcano">Pending</Tag>
                    ) : (
                        <Tag color="#6495ED">Answered</Tag>
                    ))}

                {video.categories?.map((category) => (
                    <Tag key={category} className={cx('tags')}>
                        {category}
                    </Tag>
                ))}
            </div>
        </div>
    );
};

export default ShortVideosCard;
