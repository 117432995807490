import React from 'react';
import classnames from 'classnames/bind';
import { message, Modal } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import TypographySubtitle from 'components/TypographySubtitle';
import TypographyBody from 'components/TypographyBody';
import TCSButton from 'components/TCSButton';
import PromoCodeInput from 'components/PromoCodeInput';

import styles from './Checkout.module.scss';
const cx = classnames.bind(styles);

type CheckoutProps = {
    visible: boolean;
    onCancel: () => void;
    productId: string;
    createCheckoutSession: () => Promise<string>;
    isLoading: boolean;
    onPromoCodeValidateSuccess: (couponId: string) => void;
};

const Checkout: React.FC<CheckoutProps> = ({
    visible,
    onCancel,
    productId,
    createCheckoutSession,
    isLoading,
    onPromoCodeValidateSuccess,
}) => {
    const handlePurchaseNow = async () => {
        try {
            const redirectUrl = await createCheckoutSession();
            window.location.replace(redirectUrl);
        } catch {
            message.error('Something went wrong, please try again later');
        }
    };

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            width={500}
            wrapClassName={cx('container')}
            footer={false}
            destroyOnClose
            closable={false}
        >
            <div className={cx('body')}>
                <TypographySubtitle level={1} className={cx('title')}>
                    Promo Code
                </TypographySubtitle>

                <TypographyBody level={1}>
                    First time here? DM us on Instagram{' '}
                    <a
                        href="https://www.instagram.com/thecloudscore"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        @thecloudscore
                    </a>{' '}
                    for a discount!
                </TypographyBody>

                <div className={cx('promo-code-container')}>
                    <PromoCodeInput
                        productId={productId}
                        onValidateSuccess={onPromoCodeValidateSuccess}
                    />
                </div>

                <div className={cx('button-container')}>
                    <TCSButton
                        onClick={handlePurchaseNow}
                        type="link"
                        className={cx('link-button')}
                        loading={isLoading}
                    >
                        Proceed To Payment <RightOutlined />
                    </TCSButton>
                </div>
            </div>
        </Modal>
    );
};

export default Checkout;
