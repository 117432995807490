import React from 'react';
import { VerticalAlignBottomOutlined } from '@ant-design/icons';
import classnames from 'classnames/bind';

import styles from './Tooltips.module.scss';

const cx = classnames.bind(styles);

type DownloadProps = {
    title: string;
    callback: () => void;
};

const Download: React.FC<DownloadProps> = ({ title, callback }) => {
    return (
        <div className={cx('download')} onClick={callback}>
            <VerticalAlignBottomOutlined style={{ fontSize: '28px' }} />
            <div className={cx('text')}>{title}</div>
        </div>
    );
};

export default Download;
