import React, { useEffect, useState } from 'react';
import { message } from 'antd';

import { useAxiosInstance } from 'components/AxiosContext';

import { GETQuizChallengeLeaderboardPayload } from '../../types';
import LeaderboardTable from './LeaderboardTable';

const LeaderboardTableContainer: React.FC = () => {
    const [users, setUsers] = useState<GETQuizChallengeLeaderboardPayload[]>([]);

    const axios = useAxiosInstance();

    const fetchLeaderboard = async () => {
        try {
            const { data } = await axios.get<GETQuizChallengeLeaderboardPayload[]>(
                '/api/v2/quiz-challenge/leaderboard'
            );
            setUsers(data);
        } catch {
            message.error('Something went wrong while loading the leaderboard. Please try again.');
        }
    };

    useEffect(() => {
        fetchLeaderboard();
    }, []);

    return <LeaderboardTable users={users} />;
};

export default LeaderboardTableContainer;
