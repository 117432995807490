import React from 'react';
import classnames from 'classnames/bind';
import { LikeOutlined, LikeTwoTone, LoadingOutlined } from '@ant-design/icons';

import { EThumbsUpDownState } from 'pages/SelfStudyV2/types';

import styles from './FavouriteItem.module.scss';

const cx = classnames.bind(styles);

type ThumbsUpProps = {
    status: EThumbsUpDownState;
};

const ThumbsUp: React.FC<ThumbsUpProps> = ({ status }) => {
    const handleRender = () => {
        if (status === EThumbsUpDownState.ACTIVE) {
            return <LikeTwoTone style={{ fontSize: '24px' }} className={cx('thumbs-active')} />;
        }

        if (status === EThumbsUpDownState.INACTIVE) {
            return <LikeOutlined style={{ fontSize: '24px' }} />;
        }

        return <LoadingOutlined style={{ fontSize: '24px' }} />;
    };
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            {handleRender()}
        </div>
    );
};

export default ThumbsUp;
