import React, { useCallback, useEffect, useState } from 'react';

import { useAxiosInstance } from 'components/AxiosContext';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';

import MyInvitees from './MyInvitees';
import { GETFriendsAndFamilyMembersPayload } from './types';

const MyInviteesContainer: React.FC = () => {
    const axios = useAxiosInstance();
    const { state } = useReducerContext();
    const user = getUserProfile(state);

    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [refetchCounter, setRefetchCounter] = useState(0);

    const [friendsFamilyMembers, setFriendsFamilyMembers] = useState<
        GETFriendsAndFamilyMembersPayload[]
    >([]);

    const fetchSubscriptionInfo = useCallback(async () => {
        if (!user.subscriptionDetails?.subscriptionId) {
            return;
        }

        try {
            setIsLoading(true);
            setHasError(false);

            const { data } = await axios.get<GETFriendsAndFamilyMembersPayload[]>(
                '/private/v2/users/friends-and-family/members',
                {
                    params: {
                        subscriptionId: user.subscriptionDetails?.subscriptionId,
                    },
                }
            );

            setFriendsFamilyMembers(data);
        } catch {
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    }, [user]);

    const handleRefetch = () => {
        setRefetchCounter((prevState) => prevState + 1);
    };

    useEffect(() => {
        fetchSubscriptionInfo();
    }, [refetchCounter, user]);

    return (
        <MyInvitees
            isLoading={isLoading}
            hasError={hasError}
            friendsAndFamilyMembers={friendsFamilyMembers}
            refetchFriendsAndFamilyMembers={handleRefetch}
        />
    );
};

export default MyInviteesContainer;
