import React, { useState } from 'react';
import { Row, Col, Button, Input, message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

type ChangePasswordFormProps = {
    isLoading: boolean;
    changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
};

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ isLoading, changePassword }) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const resetFields = () => {
        setOldPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
    };

    const handleChangePassword = async () => {
        if (newPassword !== confirmNewPassword) {
            message.error('The passwords do not match!');
            return;
        }

        if (newPassword.length < 12 || oldPassword.length < 12) {
            message.error('Your password needs to be at least 12 characters long!');
            return;
        }

        try {
            await changePassword(oldPassword, newPassword);

            message.success('Password successfully updated!');
            resetFields();
        } catch (err) {
            if (err.message === 'INCORRECT_PASSWORD') {
                message.error('Your password is incorrect!');
            } else {
                message.error(
                    'Oops! Something went wrong when trying to change your password. Please try again later.'
                );
            }
        }
    };

    return (
        <>
            <Row gutter={[10, 16]}>
                <Col span={6}>Current Password:</Col>
                <Col span={18}>
                    <Input.Password
                        disabled={isLoading}
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                    />
                </Col>

                <Col span={6}>New Password:</Col>
                <Col span={18}>
                    <Input.Password
                        disabled={isLoading}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                    />
                </Col>

                <Col span={6}>Confirm New Password:</Col>
                <Col span={18}>
                    <Input.Password
                        disabled={isLoading}
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                    />
                </Col>
            </Row>

            <Row style={{ marginTop: '20px' }}>
                <Col md={18} span={12}></Col>
                <Col md={6} span={12}>
                    <Button type="primary" block onClick={handleChangePassword} loading={isLoading}>
                        Update Password
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default ChangePasswordForm;
