import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { Input, Checkbox, Button, Spin, Radio, Modal } from 'antd';

import styles from './RequestVideos.module.scss';

const cx = classnames.bind(styles);

type RequestVideosProps = {
    categoriesMapArray: { label: string; value: string }[];
    handleRedirect: () => void;
    submitRequest: (subjectId: number, question: string, categories: string[]) => Promise<void>;
};

const RequestVideos: React.FC<RequestVideosProps> = ({
    categoriesMapArray,
    handleRedirect,
    submitRequest,
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showRedirectText, setShowRedirectText] = useState(false);

    const [subjectId, setSubjectId] = useState(1);
    const [question, setQuestion] = useState('');
    const [checkedCategories, setCheckedCategories] = useState<string[]>([]);

    const handleOnChange = (checkedValues: unknown) => {
        setCheckedCategories(checkedValues as string[]);
    };

    const handleOnSubmit = async () => {
        try {
            setIsModalVisible(true);
            await submitRequest(subjectId, question, checkedCategories);
            setShowRedirectText(true);
        } catch {
            // alert error message
            setIsModalVisible(false);
        }
    };

    return (
        <>
            <Modal visible={isModalVisible} closable={false} footer={null}>
                {showRedirectText ? (
                    <div className={cx('modal-container')}>
                        <div className={cx('modal-title')}>Thank you for your submission!</div>
                        <div className={cx('modal-subtitle')}>
                            We will get back to you within 24 hours. Meanwhile, you can explore
                            existing videos!
                        </div>

                        <div className={cx('modal-cta')}>
                            <Button type="primary" onClick={handleRedirect}>
                                Explore Videos
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className={cx('modal-container')}>
                        <div className={cx('spin-container')}>
                            <Spin size="large" />
                        </div>

                        <div className={cx('spin-text')}>Submitting... Please Wait...</div>
                    </div>
                )}
            </Modal>

            <div className={cx('container')}>
                <div className={cx('title')}>
                    Get a Custom 10-Minute Crash Course on anything IB Economics
                </div>

                <div className={cx('form-container')}>
                    <div className={cx('form-prompt')}>This is for:</div>
                    <div className={cx('form-subjects')}>
                        <Radio.Group
                            value={subjectId}
                            onChange={(e) => setSubjectId(e.target.value)}
                        >
                            <Radio value={1}>Economics HL</Radio>
                            <Radio value={2}>Economics SL</Radio>
                        </Radio.Group>
                    </div>

                    <div className={cx('form-prompt')}>I need a 10 minute crash course on:</div>

                    <div className={cx('form-input')}>
                        <Input.TextArea
                            required
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                            placeholder="Enter your question here"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                        />
                    </div>

                    <div className={cx('form-prompt')}>I am struggling with:</div>

                    <div className={cx('form-categories')}>
                        {categoriesMapArray.length === 0 ? (
                            <Spin />
                        ) : (
                            <Checkbox.Group
                                options={categoriesMapArray}
                                onChange={handleOnChange}
                            />
                        )}
                    </div>

                    <div className={cx('form-submit')}>
                        <Button
                            type="primary"
                            disabled={question === ''}
                            loading={isModalVisible}
                            onClick={handleOnSubmit}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RequestVideos;
