import React from 'react';
import classnames from 'classnames/bind';
import { Button } from 'antd';

import styles from './ConfirmationOverlay.module.scss';

const cx = classnames.bind(styles);

type AddQuestionProps = {
    onCancel: () => void;
    onConfirm: () => void;
};

const ConfirmationOverlay: React.FC<AddQuestionProps> = ({ onCancel, onConfirm }) => {
    return (
        <div className={cx('container')}>
            <div className={cx('text')}>
                <div>Are you sure you want to go back?</div>
                <div>You will lose the progress so far</div>
            </div>
            <div className={cx('buttons-container')}>
                <Button onClick={onCancel} className={cx('cancel')}>
                    Cancel
                </Button>
                <Button type="primary" danger onClick={onConfirm}>
                    Go Back
                </Button>
            </div>
        </div>
    );
};

export default ConfirmationOverlay;
