import React from 'react';
import classnames from 'classnames/bind';
import { DislikeOutlined, DislikeTwoTone, LoadingOutlined } from '@ant-design/icons';

import { EThumbsUpDownState } from 'pages/SelfStudyV2/types';

import styles from './Tooltips.module.scss';

const cx = classnames.bind(styles);

type ThumbsDownProps = {
    status: EThumbsUpDownState;
    callback: () => void;
};

const ThumbsDown: React.FC<ThumbsDownProps> = ({ status, callback }) => {
    const handleOnClick = () => {
        if (status === EThumbsUpDownState.DISABLED) {
            return;
        }

        callback();
    };

    const handleRender = () => {
        if (status === EThumbsUpDownState.ACTIVE) {
            return <DislikeTwoTone style={{ fontSize: '28px' }} className={cx('thumbs-active')} />;
        }

        if (status === EThumbsUpDownState.INACTIVE) {
            return <DislikeOutlined style={{ fontSize: '28px' }} />;
        }

        return <LoadingOutlined style={{ fontSize: '28px' }} />;
    };

    return (
        <div className={cx('thumbs-down')} onClick={handleOnClick}>
            {handleRender()}
            <div className={cx('text', { 'active-text': status === EThumbsUpDownState.ACTIVE })}>
                Dislike
            </div>
        </div>
    );
};

export default ThumbsDown;
