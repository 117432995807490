import React, { useCallback, useState } from 'react';
import classnames from 'classnames/bind';
import { List, Spin } from 'antd';

import FloatingToolbar from 'components/FloatingToolbar';
import GoBack from 'components/FloatingToolbar/Tooltips/GoBack';
import TypographyHeader from 'components/TypographyHeader';
import ActiveTabs from 'components/ActiveTabs';
import QuizCard from 'components/QuizCard';
import PastAttemptedQuizSetCard from './PastAttemptedQuizSetCard';
import { GETQuizSetPayload, GETQuizSetAttemptedPayload } from '../types';

import styles from './QuizSetSelector.module.scss';

const cx = classnames.bind(styles);

type QuizSetSelectorProps = {
    title: string;
    handleGoBack: () => void;
    isLoading: boolean;
    isLoaded: boolean;
    quizSets: GETQuizSetPayload[];
    pastAttemptedQuizSets: GETQuizSetAttemptedPayload[];
    handleQuizSetCardClick: (id: number) => void;
    handlePastAttemptedQuizSetCardClick: (quizSet: GETQuizSetAttemptedPayload) => void;
};

const QuizSetSelector: React.FC<QuizSetSelectorProps> = ({
    title,
    handleGoBack,
    isLoading,
    isLoaded,
    quizSets,
    pastAttemptedQuizSets,
    handleQuizSetCardClick,
    handlePastAttemptedQuizSetCardClick,
}) => {
    const [selectedOptionId, setSelectedOptionId] = useState(0);

    const selectOption = (id: number) => {
        setSelectedOptionId(id);
    };

    const checkIsAttempted = useCallback(
        (quizSetId: number, quizSetResults: GETQuizSetAttemptedPayload[]): boolean =>
            quizSetResults.findIndex((quizSetResult) => quizSetResult.quizSetId === quizSetId) > -1,
        []
    );

    const handleRender = () => {
        if (!isLoaded) {
            return null;
        }

        if (selectedOptionId === 0) {
            return (
                <List
                    dataSource={quizSets}
                    renderItem={(item) => (
                        <QuizCard
                            name={item.name}
                            isAttempted={checkIsAttempted(item.id, pastAttemptedQuizSets)}
                            onClick={() => handleQuizSetCardClick(item.id)}
                        />
                    )}
                />
            );
        }
        if (selectedOptionId === 1) {
            return (
                <List
                    dataSource={pastAttemptedQuizSets}
                    renderItem={(item) => (
                        <PastAttemptedQuizSetCard
                            name={item.quizSetName}
                            onClick={() => handlePastAttemptedQuizSetCardClick(item)}
                            attemptedDate={item.attemptedDate}
                            score={item.quizSetScore}
                            quizSetLength={item.quizSetLength}
                        />
                    )}
                />
            );
        }
    };

    if (isLoading) {
        return (
            <div className={cx('spin-container')}>
                <Spin size="large" />
            </div>
        );
    }

    return (
        <div className={cx('container')}>
            <TypographyHeader level={3} className={cx('header')}>
                Topic: {title}
            </TypographyHeader>

            <div className={cx('active-tabs-container')}>
                <ActiveTabs
                    options={[
                        { id: 0, name: 'Quizzes' },
                        { id: 1, name: 'Past Attempted Quizzes' },
                    ]}
                    selectedOptionId={selectedOptionId}
                    onTabClick={selectOption}
                />
            </div>

            {handleRender()}

            <FloatingToolbar tooltips={[<GoBack key="go-back" callback={handleGoBack} />]} />
        </div>
    );
};

export default QuizSetSelector;
