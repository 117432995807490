import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import { Spin } from 'antd';
import { datadogRum } from '@datadog/browser-rum';

import TypographySubtitle from 'components/TypographySubtitle';
import TypographyBody from 'components/TypographyBody';
import { sectionDetails } from './constants';
import SectionCard from './SectionCard';
import { GETRecommendedContentPayload } from './types';

import styles from './RecommendedContent.module.scss';
const cx = classnames.bind(styles);

type RecommendedContentProps = {
    content: GETRecommendedContentPayload | null;
    isLoading: boolean;
};

const RecommendedContent: React.FC<RecommendedContentProps> = ({ content, isLoading }) => {
    const trackRecommendedContentClick = (contentType: string) => {
        datadogRum.addAction('clickRecommendedContent', {
            contentType,
        });
    };

    const isAtLeastOneContentAvailable = useMemo(
        () =>
            content?.questionBank !== null ||
            content?.condensedNotes !== null ||
            content?.quizzes !== null ||
            content?.shortVideos !== null,
        [content]
    );

    const renderContent = () => {
        if (isLoading) {
            return (
                <div className={cx('loading-container')}>
                    <Spin />
                </div>
            );
        }

        if (!content || !isAtLeastOneContentAvailable) {
            return (
                <div className={cx('no-content-container')}>
                    <TypographyBody level={1}>
                        No recommended content right now. Please check back later!
                    </TypographyBody>
                </div>
            );
        }

        return (
            <div className={cx('cards-container')}>
                {sectionDetails.map(
                    (section, index) =>
                        content[section.contentType] && (
                            <SectionCard
                                key={index}
                                headerImg={section.imgSrc}
                                topicName={content[section.contentType]?.topicName || ''}
                                categoryName={section.categoryName}
                                contentTitle={content[section.contentType]?.title || ''}
                                url={content[section.contentType]?.url || ''}
                                onClick={() => trackRecommendedContentClick(section.contentType)}
                            />
                        )
                )}
            </div>
        );
    };

    return (
        <div className={cx('container')}>
            <TypographySubtitle level={1} className={cx('header')}>
                Popular content among your cohort this past week
            </TypographySubtitle>
            {renderContent()}
        </div>
    );
};

export default RecommendedContent;
