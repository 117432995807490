import React from 'react';
import classnames from 'classnames/bind';

import TypographyBody from 'components/TypographyBody';
import { Option } from '../types';

import styles from './TabPane.module.scss';

type TabPaneProps = {
    option: Option;
    onTabClick: (id: number) => void;
    isSelected: boolean;
};

const cx = classnames.bind(styles);

const TabPane: React.FC<TabPaneProps> = ({ option, onTabClick, isSelected }) => {
    const handleOnClick = () => {
        onTabClick(option.id);
    };

    return (
        <TypographyBody
            level={2}
            className={cx('tab-pane', { 'tab-pane-active': isSelected })}
            isBold={isSelected}
        >
            <div data-title={option.name} onClick={handleOnClick}>
                {option.name}
            </div>
        </TypographyBody>
    );
};

export default TabPane;
