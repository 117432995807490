import React from 'react';
import classnames from 'classnames/bind';
import { Image } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import TypographySubtitle from 'components/TypographySubtitle';
import AnswerCard from 'components/AnswerCard';
import { ResultQuiz } from '../../types';

import styles from './PastAttemptedQuizCard.module.scss';

const cx = classnames.bind(styles);

type PastAttemptedQuizSetCardProps = {
    resultQuiz: ResultQuiz;
    index: number;
    isAnsweredCorrectly: boolean;
};

const PastAttemptedQuizCard: React.FC<PastAttemptedQuizSetCardProps> = ({
    resultQuiz,
    index,
    isAnsweredCorrectly,
}) => {
    return (
        <div className={cx('container')}>
            <div className={cx('title-container')}>
                <TypographySubtitle level={1} className={cx('subtitle')}>
                    Q{index + 1}. {resultQuiz.questionText}
                </TypographySubtitle>
                {isAnsweredCorrectly ? (
                    <CheckOutlined style={{ color: '#2EC06B' }} />
                ) : (
                    <CloseOutlined style={{ color: '#C0302E' }} />
                )}
            </div>

            <div className={cx('image-container')}>
                <Image src={resultQuiz.questionImage} width={595} preview={false} />
            </div>

            <div className={cx('answers-container')}>
                {resultQuiz.availableAnswers.map((availableAnswer) => (
                    <AnswerCard
                        name={availableAnswer.answerText}
                        key={availableAnswer.quizAnswerId}
                        isCorrectAnswer={
                            availableAnswer.quizAnswerId === resultQuiz.correctAnswerId
                        }
                        isAnsweredCorrectly={isAnsweredCorrectly}
                        isSelected={resultQuiz.selectedAnswerId === availableAnswer.quizAnswerId}
                    />
                ))}
            </div>
        </div>
    );
};

export default PastAttemptedQuizCard;
