import React, { useState } from 'react';
import { Input, Button, Divider } from 'antd';

import ErrorMessageWrapper from 'components/ErrorMessageWrapper';

import { isValidEmail } from './utils';
import AuthenticationCard from './AuthenticationCard';
import { validFieldMessage } from './errorMessages';

enum EHaveVerificationCodeFormField {
    EMAIL = 'EMAIL',
}

const haveVerificationCodeFormValidations = {
    [EHaveVerificationCodeFormField.EMAIL]: (email: string) => !isValidEmail(email),
};

type HaveVerificationCodeProps = {
    isLoading: boolean;
    handleVerifyEmail: (email: string) => void;
    goToForgotPassword: () => void;
};

const HaveVerificationCode: React.FC<HaveVerificationCodeProps> = ({
    isLoading,
    handleVerifyEmail,
    goToForgotPassword,
}) => {
    const [email, setEmail] = useState('');
    const [formErrors, setFormErrors] = useState<Record<string, null | string>>({
        [EHaveVerificationCodeFormField.EMAIL]: null,
    });

    const validateForm = () => {
        const emailError =
            haveVerificationCodeFormValidations[EHaveVerificationCodeFormField.EMAIL](email);

        setFormErrors({
            [EHaveVerificationCodeFormField.EMAIL]: emailError ? validFieldMessage('email') : null,
        });

        return !emailError;
    };

    const onSubmitButtonClick = () => {
        if (validateForm()) {
            handleVerifyEmail(email);
        }
    };

    return (
        <AuthenticationCard title="Verify email address">
            <ErrorMessageWrapper errorMessage={formErrors[EHaveVerificationCodeFormField.EMAIL]}>
                <Input
                    placeholder="Email"
                    value={email}
                    name={EHaveVerificationCodeFormField.EMAIL}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                />
            </ErrorMessageWrapper>

            <Button type="primary" loading={isLoading} block onClick={onSubmitButtonClick}>
                Submit
            </Button>

            <Divider />

            <Button block onClick={goToForgotPassword}>
                I do not have the email verification code
            </Button>
        </AuthenticationCard>
    );
};

export default HaveVerificationCode;
