import React, { useEffect, useMemo } from 'react';
import classnames from 'classnames/bind';
import { useHistory } from 'react-router';
import { datadogRum } from '@datadog/browser-rum';

import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';

import { scrollToTop } from 'common/utils';
import TypographyHeader from 'components/TypographyHeader';
import ArcPdfViewer from 'components/ArcPdfViewer';
import TCSButton from 'components/TCSButton';
import TypographySubtitle from 'components/TypographySubtitle';
import { ArcPdfViewerCallbackObject } from 'components/ArcPdfViewer/types';
import ArcVideoPlayer from 'components/ArcVideoPlayer';
import Result from './Result';
import ExplanationNotes from 'components/ExplanationNotes';
import LastAttemptedQuiz from './LastAttemptedQuiz';
import { deriveDefaultScale } from './utils';
import { ERecommendedContentType } from '../constants';
import { UNREGISTERED_USER_PREFIX } from '../utils';
import { QuizChallengeQuestion, RecommendedContent } from '../types';

import styles from './ResultView.module.scss';

const cx = classnames.bind(styles);

type ResultViewProps = {
    score: number;
    totalSkips: number;
    resetGame: () => void;
    userUuid: string;
    recommendedContent: RecommendedContent;
    currentQuiz: QuizChallengeQuestion;
    lastSelectedQuizAnswerId: number;
    explanationNotes: string;
    correctAnswerId: number;
};

const ResultView: React.FC<ResultViewProps> = ({
    score,
    totalSkips,
    resetGame,
    userUuid,
    recommendedContent,
    currentQuiz,
    lastSelectedQuizAnswerId,
    explanationNotes,
    correctAnswerId,
}) => {
    const history = useHistory();

    const { state } = useReducerContext();
    const profile = useMemo(() => getUserProfile(state), [state]);

    const defaultScale = useMemo(() => deriveDefaultScale(), []);

    const isUserLoggedInBeforeStartingGame = useMemo(
        () => !userUuid.startsWith(UNREGISTERED_USER_PREFIX),
        [userUuid]
    );

    const isUserSubscribed = useMemo(() => profile.subscriptionDetails?.isSubscribed, [profile]);

    const handleRedirect = () => {
        scrollToTop();

        if (isUserSubscribed) {
            history.push('/my-dashboard');
            return;
        }

        if (isUserLoggedInBeforeStartingGame) {
            history.push('/subscriptions');
            return;
        }

        history.push('/subscriptions/info');
    };

    const handlePlayAgain = () => {
        resetGame();
        scrollToTop();
    };
    const trackClickNextPage = (callbackObject: ArcPdfViewerCallbackObject) => {
        datadogRum.addAction('clickNextPage', {
            source: 'recommendedCondensedNotes',
            currentPage: callbackObject.currentPage,
            totalPages: callbackObject.totalPages,
        });
    };

    const trackVideoPlay = () => {
        datadogRum.addAction('clickPlayVideo', {
            source: 'recommendedVideo',
        });
    };

    const renderRecommendedContent = () => {
        switch (recommendedContent.contentType) {
            case ERecommendedContentType.NOTES:
                return (
                    <div className={cx('condensed-notes-container')}>
                        <ArcPdfViewer
                            fileUrl={recommendedContent.url}
                            scale={defaultScale}
                            isDisplayAll={false}
                            defaultPage={2}
                            onRight={trackClickNextPage}
                        />
                    </div>
                );

            case ERecommendedContentType.VIDEO:
                return (
                    <div className={cx('video-container')}>
                        <TypographySubtitle level={1}>
                            {recommendedContent.title}
                        </TypographySubtitle>
                        <ArcVideoPlayer
                            videoUrl={recommendedContent.url}
                            videoHeight="100%"
                            videoWidth="100%"
                            onStart={trackVideoPlay}
                        />
                    </div>
                );
            default:
                return;
        }
    };

    const logQuizChallengeRecommendedContentType = () => {
        datadogRum.addAction('quizChallengeRecommendedContent', {
            contentType: recommendedContent.contentType,
        });
    };

    useEffect(() => {
        logQuizChallengeRecommendedContentType();
    }, []);

    return (
        <div className={cx('container')}>
            <div className={cx('content-container')}>
                <TypographyHeader level={3} className={cx('title')}>
                    Results
                </TypographyHeader>

                <div>
                    <Result score={score} totalSkips={totalSkips} />
                </div>

                {explanationNotes && (
                    <div className={cx('explanation-notes-container')}>
                        <ExplanationNotes text={explanationNotes} />
                    </div>
                )}

                <div className={cx('the-question-container')}>
                    <TypographySubtitle level={1} className={cx('header')}>
                        The Question
                    </TypographySubtitle>
                    <LastAttemptedQuiz
                        currentQuiz={currentQuiz}
                        lastSelectedQuizAnswerId={lastSelectedQuizAnswerId}
                        correctAnswerId={correctAnswerId}
                    />
                </div>

                <div className={cx('buttons-container')}>
                    <TCSButton
                        type="primary"
                        onClick={handlePlayAgain}
                        className={cx('play-again')}
                    >
                        Play Again
                    </TCSButton>
                </div>

                <TypographySubtitle level={1} className={cx('cta')}>
                    Here&apos;s A Sneak Peak of Cloudscore&apos;s Content to Improve Your
                    Proficiency!
                </TypographySubtitle>

                {renderRecommendedContent()}

                <div className={cx('buttons-container')}>
                    <TCSButton type="primary" onClick={handleRedirect}>
                        Get More Study Content!
                    </TCSButton>
                </div>
            </div>
        </div>
    );
};

export default ResultView;
