import React from 'react';
import classnames from 'classnames/bind';
import { Modal } from 'antd';

import ArcVideoPlayer from 'components/ArcVideoPlayer';
import TypographySubtitle from 'components/TypographySubtitle';

import styles from './PreviewVideo.module.scss';

const cx = classnames.bind(styles);

type PreviewLessonCardProps = {
    visible: boolean;
    onCancel: () => void;
    title: string;
    videoUrl: string;
};

const PreviewVideo: React.FC<PreviewLessonCardProps> = ({ visible, onCancel, title, videoUrl }) => {
    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            width={1200}
            maskStyle={{ backgroundColor: 'black', opacity: '80%' }}
            wrapClassName={cx('container')}
            footer={false}
            destroyOnClose
        >
            <div className={cx('body')}>
                <TypographySubtitle level={1} className={cx('title')}>
                    {title}
                </TypographySubtitle>
                <div className={cx('video-container')}>
                    <ArcVideoPlayer videoUrl={videoUrl} videoWidth="100%" videoHeight="100%" />
                </div>
            </div>
        </Modal>
    );
};

export default PreviewVideo;
