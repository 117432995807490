import { upperCaseFirstLetter } from './utils';

export const requiredFieldMessage = (fieldName: string) =>
    `${upperCaseFirstLetter(fieldName)} is required`;

export const validFieldMessage = (fieldName: string) => `Please enter a valid ${fieldName}`;

export const minimumLengthMessage = (fieldName: string, minLength: number) =>
    `${upperCaseFirstLetter(fieldName)} has to contain at least ${minLength} character${
        minLength > 1 ? 's' : ''
    }`;

export const sameFieldsMessage = (fieldNames: string) =>
    `${upperCaseFirstLetter(fieldNames)} do not match`;
