import React from 'react';
import classnames from 'classnames/bind';

import styles from './TermsOfService.module.scss';

const cx = classnames.bind(styles);

const TermsOfService: React.FC = () => (
    <div className={cx('terms-of-service')}>
        <h1 className={cx('header')}>WEBSITE TERMS OF USE</h1>

        <ol>
            <li>
                APPLICATION OF TERMS
                <ol>
                    <li>
                        These Terms apply to your use of the Website. By accessing and using the
                        Website:
                        <ol type="a" className={cx('alphabetical-list')}>
                            <li className={cx('alphabetical')}>you agree to these Terms; and</li>

                            <li className={cx('alphabetical')}>
                                where your access and use is on behalf of another person (e.g. a
                                company), you confirm that you are authorised to, and do in fact,
                                agree to these Terms on that person’s behalf and that, by agreeing
                                to these Terms on that person’s behalf, that person is bound by
                                these Terms.
                            </li>
                        </ol>
                    </li>

                    <li>
                        If you do not agree to these Terms, you are not authorised to access and use
                        the Website, and you must immediately stop doing so.
                    </li>
                </ol>
            </li>

            <li>
                CHANGES
                <ol>
                    <li>
                        We may change these Terms at any time by updating them on the Website.
                        Unless stated otherwise, any change takes effect immediately. You are
                        responsible for ensuring you are familiar with the latest Terms. By
                        continuing to access and use the Website, you agree to be bound by the
                        changed Terms.
                    </li>

                    <li>
                        We may change, suspend, discontinue, or restrict access to, the Website
                        without notice or liability.
                    </li>

                    <li>These Terms were last updated on 23 May 2021.</li>
                </ol>
            </li>

            <li>
                DEFINITIONS
                <p>In these Terms:</p>
                <p>including and similar words do not imply any limit</p>
                <p>
                    Loss includes loss of profits, savings, revenue or data, and any other claim,
                    damage, loss, liability and cost, including legal costs on a solicitor and own
                    client basis
                </p>
                <p>
                    personal information means information about an identifiable, living person, and
                    includes personal data, personally identifiably information and equivalent
                    information under applicable privacy and data protection laws
                </p>
                <p>Terms means these terms and conditions titled Website Terms of Use</p>
                <p>
                    Underlying System means any network, system, software, data or material that
                    underlies or is connected to the Website
                </p>
                <p>
                    User ID means a unique name and/or password allocated to you to allow you to
                    access certain parts of the Website
                </p>
                <p>We, us or our means Cloudscore Pte Ltd</p>
                <p>Website means www.thecloudscore.com</p>
                <p>
                    You means you or, if clause 1.1b applies, both you and the other person on whose
                    behalf you are acting.
                </p>
            </li>

            <li>
                YOUR OBLIGATIONS
                <ol>
                    <li>
                        You must provide true, current and complete information in your dealings
                        with us (including when setting up an account), and must promptly update
                        that information as required so that the information remains true, current
                        and complete.
                    </li>

                    <li>
                        If you are given a User ID, you must keep your User ID secure and:
                        <ol type="a" className={cx('alphabetical-list')}>
                            <li className={cx('alphabetical')}>
                                not permit any other person to use your User ID, including not
                                disclosing or providing it to any other person; and
                            </li>

                            <li className={cx('alphabetical')}>
                                immediately notify us if you become aware of any disclosure or
                                unauthorised use of your User ID, by sending an email to
                                help@thecloudscore.com.
                            </li>
                        </ol>
                    </li>

                    <li>
                        You must:
                        <ol type="a" className={cx('alphabetical-list')}>
                            <li className={cx('alphabetical')}>
                                not act in a way, or use or introduce anything (including any virus,
                                worm, Trojan horse, timebomb, keystroke logger, spyware or other
                                similar feature) that in any way compromises, or may compromise, the
                                Website or any Underlying System, or otherwise attempt to damage or
                                interfere with the Website or any Underlying System; and
                            </li>

                            <li className={cx('alphabetical')}>
                                unless with our agreement, access the Website via standard web
                                browsers only and not by any other method. Other methods include
                                scraping, deep-linking, harvesting, data mining, use of a robot or
                                spider, automation, or any similar data gathering, extraction or
                                monitoring method.
                            </li>
                        </ol>
                    </li>

                    <li>
                        You must obtain our written permission to establish a link to our Website.
                        If you wish to do so, email your request to help@thecloudscore.com.
                    </li>

                    <li>
                        You indemnify us against all Loss we suffer or incur as a direct or indirect
                        result of your failure to comply with these Terms, including any failure of
                        a person who accesses and uses our Website by using your User ID.
                    </li>
                </ol>
            </li>

            <li>
                INTELLECTUAL PROPERTY
                <p>
                    We (and our licensors) own all proprietary and intellectual property rights in
                    the Website (including all information, data, text, graphics, artwork,
                    photographs, logos, icons, sound recordings, videos and look and feel), and the
                    Underlying Systems.
                </p>
            </li>

            <li>
                DISCLAIMERS
                <ol>
                    <li>
                        To the extent permitted by law, we and our licensors have no liability or
                        responsibility to you or any other person for any Loss in connection with:
                        <ol type="a" className={cx('alphabetical-list')}>
                            <li className={cx('alphabetical')}>
                                the Website being unavailable (in whole or in part) or performing
                                slowly;
                            </li>

                            <li className={cx('alphabetical')}>
                                any error in, or omission from, any information made available
                                through the Website;
                            </li>

                            <li className={cx('alphabetical')}>
                                any exposure to viruses or other forms of interference which may
                                damage your computer system or expose you to fraud when you access
                                or use the Website. To avoid doubt, you are responsible for ensuring
                                the process by which you access and use the Website protects you
                                from this; and
                            </li>

                            <li className={cx('alphabetical')}>
                                any site linked from the Website. Any link on the Website to other
                                sites does not imply any endorsement, approval or recommendation of,
                                or responsibility for, those sites or their contents, operations,
                                products or operators.
                            </li>
                        </ol>
                    </li>

                    <li>
                        We make no representation or warranty that the Website is appropriate or
                        available for use in all countries or that the content satisfies the laws of
                        all countries. You are responsible for ensuring that your access to and use
                        of the Website is not illegal or prohibited, and for your own compliance
                        with applicable local laws.
                    </li>
                </ol>
            </li>

            <li>
                LIABILITY
                <ol>
                    <li>
                        To the maximum extent permitted by law:
                        <ol type="a" className={cx('alphabetical-list')}>
                            <li className={cx('alphabetical')}>
                                you access and use the Website at your own risk; and
                            </li>

                            <li className={cx('alphabetical')}>
                                we are not liable or responsible to you or any other person for any
                                Loss under or in connection with these Terms, the Website, or your
                                access and use of (or inability to access or use) the Website. This
                                exclusion applies regardless of whether our liability or
                                responsibility arises in contract, tort (including negligence),
                                equity, breach of statutory duty, or otherwise.
                            </li>
                        </ol>
                    </li>

                    <li>
                        Except to the extent permitted by law, nothing in these Terms has the effect
                        of contracting out of any applicable consumer protection law that cannot be
                        excluded. To the extent our liability cannot be excluded but can be limited,
                        our liability is limited to SGD100.
                    </li>

                    <li>
                        To the maximum extent permitted by law and only to the extent clauses 7.1
                        and 7.2 of these Terms do not apply, our total liability to you under or in
                        connection with these Terms, or in connection with the Website, or your
                        access and use of (or inability to access or use) the Website, must not
                        exceed SGD100.
                    </li>
                </ol>
            </li>

            <li>
                PRIVACY POLICY
                <ol>
                    <li>
                        You are not required to provide personal information to us, although in some
                        cases if you choose not to do so then we will be unable to make certain
                        sections of the Website available to you. For example, we may need to have
                        your contact information in order to provide you with updates from our
                        Website.
                    </li>

                    <li>
                        When you provide personal information to us, we will comply with applicable
                        privacy and data protection laws.
                    </li>

                    <li>
                        The personal information you provide to us (including any information
                        provided if you register for an account) is collected and may be used for
                        communicating with you, statistical analysis, the marketing by us of
                        products and services to you, credit checks (if necessary), and research and
                        development.
                    </li>

                    <li>
                        We may also collect technical information whenever you log on to, or visit
                        the public version of, our Website. This may include information about the
                        way users arrive at, browse through and interact with our Website. We may
                        collect this type of technical information through the use of cookies and
                        other means. Cookies are alphanumeric identifiers that we transfer to your
                        computer’s hard drive to enable our systems to recognise your browser. If
                        you want to disable cookies, you may do so by changing the settings on your
                        browser. However, if you do so, you may not be able to use all of the
                        functions on the Website. We use the technical information we collect to
                        have a better understanding of the way people use our Website, to improve
                        the way it works and to personalise it to be more relevant and useful to
                        your particular needs. We may also use this information to assist in making
                        any advertising we display on the Website more personalised and applicable
                        to your interests.
                    </li>

                    <li>
                        Generally, we do not disclose personal information to third parties for them
                        to use for their own purposes. However, some of the circumstances in which
                        we may do this are:
                        <ol type="a" className={cx('alphabetical-list')}>
                            <li className={cx('alphabetical')}>
                                to service providers and other persons working with us to make the
                                Website available or improve or develop its functionality (e.g. we
                                may use a third party supplier to host the Website);
                            </li>

                            <li className={cx('alphabetical')}>
                                in relation to the proposed purchase or acquisition of our business
                                or assets; or
                            </li>

                            <li className={cx('alphabetical')}>
                                where required by applicable law or any court, or in response to a
                                legitimate request by a law enforcement agency.
                            </li>
                        </ol>
                    </li>

                    <li>
                        Any personal information you provide to us may be stored on the secure
                        servers of our trusted service providers, which may be located outside
                        Singapore. This may involve the transfer of your personal information to
                        countries which have less legal protection for personal information than
                        Singapore.
                    </li>

                    <li>
                        You have the right to request access to and correction of any of the
                        personal information we hold about you. If you would like to exercise these
                        rights, please email us at help@thecloudscore.com.
                    </li>
                </ol>
            </li>

            <li>
                SUSPENSION AND TERMINATION
                <ol>
                    <li>
                        Without prejudice to any other right or remedy available to us, if we
                        consider that you have breached these Terms or we otherwise consider it
                        appropriate, we may immediately, and without notice, suspend or terminate
                        your access to the Website (or any part of it).
                    </li>

                    <li>
                        On suspension or termination, you must immediately cease using the Website
                        and must not attempt to gain further access.
                    </li>
                </ol>
            </li>

            <li>
                GENERAL
                <ol>
                    <li>
                        If we need to contact you, we may do so by email or by posting a notice on
                        the Website. You agree that this satisfies all legal requirements in
                        relation to written communications.
                    </li>

                    <li>
                        These Terms, and any dispute relating to these Terms or the Website, are
                        governed by and must be interpreted in accordance with the law of Singapore.
                        Each party submits to the non-exclusive jurisdiction of the Courts of
                        Singapore in relation to any dispute connected with these Terms or the
                        Website.
                    </li>

                    <li>
                        For us to waive a right under these Terms, the waiver must be in writing.
                    </li>

                    <li>
                        Clauses which, by their nature, are intended to survive termination of these
                        Terms, including clauses 4.5, 5, 6, 7, and 10.1, continue in force.
                    </li>

                    <li>
                        If any part or provision of these Terms is or becomes illegal,
                        unenforceable, or invalid, that part or provision is deemed to be modified
                        to the extent required to remedy the illegality, unenforceability or
                        invalidity. If a modification is not possible, the part or provision must be
                        treated for all purposes as severed from these Terms. The remainder of these
                        Terms will be binding on you.
                    </li>

                    <li>
                        These Terms set out everything agreed by the parties relating to your use of
                        the Website and supersede and cancel anything discussed, exchanged or agreed
                        prior to you agreeing to these Terms. The parties have not relied on any
                        representation, warranty or agreement relating to the Website that is not
                        expressly set out in the Terms, and no such representation, warranty or
                        agreement has any effect from the date you agreed to these Terms.
                    </li>
                </ol>
            </li>
        </ol>
    </div>
);

export default TermsOfService;
