import { useEffect, useState } from 'react';

import { useAxiosInstance } from '../AxiosContext';

import { sortByDescending } from 'common/utils';
import { GETThreadList } from './types';

const THREADS_POLL_INTERVAL_IN_MS = 60 * 10 * 1000;

export const useAskAQuestion = () => {
    const [threads, setThreads] = useState<GETThreadList[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [refetchCounter, setRefetchCounter] = useState(0);

    const axios = useAxiosInstance();

    const loadThreads = async () => {
        try {
            setIsLoading(true);
            setHasError(false);

            const { data } = await axios.get<GETThreadList[]>(
                '/private/v2/ask-a-question/thread/list'
            );

            sortByDescending(data, 'createdAt');
            setThreads(data);
        } catch {
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleThreadsRefetch = () => {
        setRefetchCounter((prevState) => prevState + 1);
    };

    useEffect(() => {
        loadThreads();
    }, [refetchCounter]);

    useEffect(() => {
        const pollThreads = setInterval(loadThreads, THREADS_POLL_INTERVAL_IN_MS);

        return () => {
            clearInterval(pollThreads);
        };
    }, []);

    return {
        action: {
            handleThreadsRefetch,
        },
        state: {
            isLoading,
            hasError,
            threads,
        },
    };
};
