import React from 'react';
import classnames from 'classnames/bind';

import TypographyHeader from 'components/TypographyHeader';
import TCSButton from 'components/TCSButton';
import { ERecordedLessonStep } from './types';
import PreviewLessons from './PreviewLessons';
import PurchasedLessons from './PurchasedLessons';
import PurchasedLessonViewer from './PurchasedLessonViewer';
import { useRecordedLesson } from './hooks';
import RedirectSuccess from './RedirectSuccess';
import RedirectCanceled from './RedirectCanceled';

import styles from './RecordedLesson.module.scss';
const cx = classnames.bind(styles);

const RecordedLesson: React.FC = () => {
    const { state, action } = useRecordedLesson();

    const {
        toggleStep,
        selectedLesson,
        isLoading,
        purchasedLessons,
        purchasedLessonId,
        showRedirectCanceled,
    } = state;

    const {
        setToggleStep,
        setSelectedLesson,
        setPurchasedLessonId,
        handleRefetchPurchasedLessons,
        setShowRedirectCanceled,
    } = action;

    const renderToggleContent = () => {
        switch (toggleStep) {
            case ERecordedLessonStep.MY_PURCHASED_LESSONS:
                return (
                    <PurchasedLessons
                        purchasedLessons={purchasedLessons}
                        isLoading={isLoading}
                        onWatchVideoClick={setSelectedLesson}
                    />
                );
            case ERecordedLessonStep.PREVIEW_LESSONS:
            default:
                return <PreviewLessons purchasedLessons={purchasedLessons} />;
        }
    };

    if (selectedLesson) {
        return (
            <PurchasedLessonViewer
                handleGoBack={() => setSelectedLesson(null)}
                purchasedLesson={selectedLesson}
            />
        );
    }

    return (
        <>
            <div className={cx('container')}>
                <div className={cx('body')}>
                    <TypographyHeader level={3} className={cx('header')}>
                        Recorded Lessons
                    </TypographyHeader>
                    <div className={cx('selector')}>
                        <TCSButton
                            onClick={() => setToggleStep(ERecordedLessonStep.PREVIEW_LESSONS)}
                            type={
                                toggleStep === ERecordedLessonStep.PREVIEW_LESSONS
                                    ? 'primary'
                                    : 'default'
                            }
                        >
                            All Videos
                        </TCSButton>
                        <TCSButton
                            onClick={() => setToggleStep(ERecordedLessonStep.MY_PURCHASED_LESSONS)}
                            type={
                                toggleStep === ERecordedLessonStep.MY_PURCHASED_LESSONS
                                    ? 'primary'
                                    : 'default'
                            }
                        >
                            My Videos
                        </TCSButton>
                    </div>
                    {renderToggleContent()}
                </div>
            </div>

            <RedirectSuccess
                visible={purchasedLessonId !== 0}
                onClose={() => setPurchasedLessonId(0)}
                purchasedLessonId={purchasedLessonId}
                handleRefetchPurchasedLessons={handleRefetchPurchasedLessons}
            />

            <RedirectCanceled
                visible={showRedirectCanceled}
                onClose={() => setShowRedirectCanceled(false)}
            />
        </>
    );
};

export default RecordedLesson;
