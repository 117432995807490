import React, { useMemo } from 'react';

import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';
import { useAuth } from 'components/AuthContextProvider';
import { EFreeTrialStatus } from 'components/ReducerContext/state';

import Routes from './Routes';

const RoutesContainer: React.FC = () => {
    const { isLoggedIn } = useAuth();
    const { state } = useReducerContext();

    const profile = useMemo(() => getUserProfile(state), [state]);

    return (
        <Routes
            isLoggedIn={isLoggedIn}
            isSubscriber={
                profile.subscriptionDetails?.isSubscribed ||
                profile.subscriptionDetails?.freeTrialStatus === EFreeTrialStatus.ACTIVE
            }
        />
    );
};

export default RoutesContainer;
