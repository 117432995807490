import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { Button } from 'antd';

import { ShortVideosResponse } from '../types';
import ShortVideosPlayer from '../Components/ShortVideosPlayer';
import ShortVideosCard from '../Components/ShortVideosCard';

import styles from './MyVideos.module.scss';

const cx = classnames.bind(styles);

type MyVideosProps = {
    myVideos: ShortVideosResponse[];
    onRequestVideo: () => void;
};

type ActiveVideo = {
    videoUrl: string;
    videoTitle: string;
};

const MyVideos: React.FC<MyVideosProps> = ({ myVideos, onRequestVideo }) => {
    const [activeVideo, setActiveVideo] = useState<ActiveVideo | null>(null);

    const handleOnClickVideo = (video: ShortVideosResponse) => {
        if (video.status === 'PENDING') return;
        setActiveVideo({ videoUrl: video.video_url || '', videoTitle: video.video_title || '' });
    };

    return (
        <div className={cx('container')}>
            <ShortVideosPlayer
                isVisible={activeVideo !== null}
                videoTitle={activeVideo?.videoTitle || ''}
                videoUrl={activeVideo?.videoUrl || ''}
                onClose={() => setActiveVideo(null)}
            />

            {myVideos.length === 0 && (
                <div className={cx('no-videos')}>
                    You have not requested any videos yet. Request a video now!
                    <div className={cx('cta')}>
                        <Button type="primary" onClick={onRequestVideo}>
                            Request a Video
                        </Button>
                    </div>
                </div>
            )}

            <div className={cx('videos-container')}>
                {myVideos.map((video) => (
                    <ShortVideosCard
                        key={video.created_at}
                        video={video}
                        handleOnClickVideo={handleOnClickVideo}
                    />
                ))}
            </div>
        </div>
    );
};

export default MyVideos;
