import React, { useMemo } from 'react';
import classnames from 'classnames/bind';

import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';

import TypographyHeader from 'components/TypographyHeader';
import TypographySubtitle from 'components/TypographySubtitle';
import Cohort from './Cohort';
import RecommendedContent from './RecommendedContent';
import Resources from './Resources';

import styles from './MyDashboard.module.scss';
const cx = classnames.bind(styles);

const MyDashboard: React.FC = () => {
    const { state } = useReducerContext();

    const profile = useMemo(() => getUserProfile(state), [state]);

    return (
        <div className={cx('container')}>
            <div className={cx('content')}>
                <div className={cx('header')}>
                    <TypographyHeader level={3} className={cx('title')}>
                        Dashboard
                    </TypographyHeader>
                    <TypographySubtitle level={1}>
                        Hi {profile.firstName}, welcome back!
                    </TypographySubtitle>
                </div>

                <Cohort />

                <RecommendedContent />

                <Resources />
            </div>
        </div>
    );
};

export default MyDashboard;
