import React from 'react';
import classnames from 'classnames/bind';
import { List } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

import TypographySubtitle from 'components/TypographySubtitle';

import styles from './QuizCard.module.scss';

const cx = classnames.bind(styles);

type QuizCardProps = {
    name: string;
    isAttempted: boolean;
    onClick: () => void;
};

const QuizCard: React.FC<QuizCardProps> = ({ name, isAttempted, onClick }) => {
    return (
        <List.Item className={cx('container')} onClick={onClick}>
            <TypographySubtitle level={1} className={cx('text')}>
                {name}
            </TypographySubtitle>

            <div className={cx('check-container')}>
                {isAttempted ? (
                    <CheckCircleFilled style={{ fontSize: '20px', color: '#2EC06B' }} />
                ) : (
                    <div className={cx('dashed-circle')} />
                )}
            </div>
        </List.Item>
    );
};

export default QuizCard;
