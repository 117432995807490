import { format } from 'date-fns';
import zlib from 'zlib';
import { convertFromRaw, EditorState } from 'draft-js';
import * as Sentry from '@sentry/react';

import { TCSSubject, TCSSubjectTopicMap, GETFilesSubjectsPayload, TCSFileShape } from './types';
import { DatadogRumData } from 'common/types';
import { UserCohortMay, UserCohortNov } from 'common/constants';

declare global {
    let gtag: (...args: unknown[]) => void;
    let rdt: (...args: unknown[]) => void;
    let ttq: {
        track: (...args: unknown[]) => void;
    };
    let fbq: (...args: unknown[]) => void;
}

export const sleep = (ms: number) => {
    return new Promise<void>((resolve) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
};

/**
 * @param unixDate - a 10 digit long unix timestamp
 * @param dateFormat - date format e.g. "dd MMM yyyy" as per date-fns standards
 * @returns - the human readable formatted date
 */
export const formatUnixTimestamp = (
    unixDate: number | null | undefined,
    dateFormat: string
): string => {
    if (typeof unixDate !== 'number') return '';

    const date = new Date(unixDate * 1000);
    if (date.toString() === 'Invalid Date') {
        return '';
    }

    return format(date, dateFormat);
};

/**
 * @param zuluDate - zulu date format e.g. 2021-05-05T07:44:05Z
 * @param dateFormat - date format e.g. "dd MMM yyyy" as per date-fns standards
 * @returns - the human redable formatted date
 */
export const formatZuluTimestamp = (
    zuluDate: string | null | undefined,
    dateFormat: string
): string => {
    if (typeof zuluDate !== 'string') return '';

    const date = new Date(zuluDate);
    if (date.toString() === 'Invalid Date') {
        return '';
    }

    return format(date, dateFormat);
};

type parseSubjectPayloadOutput = {
    subjects: TCSSubject[];
    topics: TCSSubjectTopicMap;
};

/**
 * breaks up the API response from /v1/subjects into an array of Subjects and a map
 * of [subject_id]: array of topics
 */
export const parseSubjectPayload = (
    payload: GETFilesSubjectsPayload[]
): parseSubjectPayloadOutput => {
    const subjects: TCSSubject[] = [];
    const topics: TCSSubjectTopicMap = {};

    payload.forEach((sub) => {
        const s: TCSSubject = {
            id: sub.id,
            name: sub.name,
        };

        subjects.push(s);
        topics[sub.id] = sub.topics;
    });

    return {
        subjects,
        topics,
    };
};

export const decompressEmptyStringError = 'buffer string cannot be empty';

export const decompressBufferStringToString = (bufferString: string): string => {
    if (bufferString === '') {
        throw Error(decompressEmptyStringError);
    }

    const buffer = Buffer.from(bufferString, 'binary');
    const decompressed = zlib.gunzipSync(buffer);

    return decompressed.toString();
};

export const deriveNotesTaken = (bufferString: string): EditorState => {
    try {
        const decompressed = decompressBufferStringToString(bufferString);
        const contentState = convertFromRaw(JSON.parse(decompressed));

        return EditorState.createWithContent(contentState);
    } catch {
        return EditorState.createEmpty();
    }
};

export const deriveDatadogRumDataFromFile = (file: TCSFileShape): DatadogRumData => ({
    fileName: file.original_file_name,
    subjectId: file.subject_id,
    topicId: file.topic_id,
});

export const parseQueryParam = (query: string): Record<string, string> => {
    const result: Record<string, string> = {};

    const q = new URLSearchParams(query);

    q.forEach((value, key) => {
        result[key] = value;
    });

    return result;
};

/**
 * Will validate if parsedQuery[key] is a number
 * - if yes, returns the number
 * - if no, returns null
 * @param parsedQuery Record<string, string>
 * @param key key in Record
 */
export const validateID = (parsedQuery: Record<string, string>, key: string): number | null => {
    const id = parsedQuery[key];

    if (!id) return null;

    const numID = parseInt(id, 10);

    if (Number.isNaN(numID)) {
        return null;
    }

    return numID;
};

export const convertFromUnixTimestamp = (date: number) => date * 1000;

export const convertToUnixTimestamp = (date: number) => Math.round(date / 1000);

export const sortByAscending = <T>(payload: T[], key: keyof T): T[] =>
    payload.sort((a, b) => (a[key] > b[key] ? 1 : -1));

export const sortByDescending = <T>(payload: T[], key: keyof T): T[] =>
    payload.sort((a, b) => (a[key] > b[key] ? -1 : 1));

export const sendGTagEvent = (action: string, category: string, label: string) => {
    if (gtag) {
        gtag('event', action, {
            event_category: category,
            event_label: label,
        });
    } else {
        const e = `[Error]: gtag not found for action: ${action}`;
        Sentry.captureException(e);
    }
};

export const sendGTagConversionEvent = (conversionID: string) => {
    if (gtag) {
        gtag('event', 'conversion', {
            send_to: conversionID,
        });
    } else {
        const e = `[Error]: gtag not found for tracking event conversion`;
        Sentry.captureException(e);
    }
};

export const sendRedditEvent = (eventName: string) => {
    if (rdt) {
        rdt('track', eventName);
    } else {
        const e = `[Error]: rdt not found for event name: ${eventName}`;
        Sentry.captureException(e);
    }
};

// Supported TikTok Pixel events: https://ads.tiktok.com/marketing_api/docs?id=1701890973258754
export const sendTikTokEvent = (eventName: string) => {
    if (ttq) {
        ttq.track(eventName);
    } else {
        const e = `[Error]: ttq not found for event name: ${eventName}`;
        Sentry.captureException(e);
    }
};

// https://developers.facebook.com/docs/meta-pixel/implementation/conversion-tracking#custom-events
export const sendCustomFbEvent = (eventName: string, context?: Map<string, unknown>) => {
    if (fbq) {
        const additionalContext = context ? context : {};
        fbq('trackCustom', eventName, { ...additionalContext });
    } else {
        const e = `[Error]: fbq not found for event name: ${eventName}`;
        Sentry.captureException(e);
    }
};

export const scrollToTop = (behavior?: ScrollBehavior) => {
    window.scrollTo({ top: 0, behavior: behavior });
};

// convertUserCohortToIbDate expect the format to be yyyy#mmm e.g. 2024#MAY
export const convertUserCohortToIbDate = (userCohort: string): Date | undefined => {
    const stringArray = userCohort.split('#');
    if (stringArray.length !== 2) return;

    const [year, month] = stringArray;
    if (!year || !month) return;

    const ibYear = parseInt(year, 10);
    if (isNaN(ibYear)) return;

    let ibMonth: number;

    // Date in javascript uses month index, i.e. January = 0, December = 11
    switch (month) {
        case UserCohortMay:
            ibMonth = 4;
            break;
        case UserCohortNov:
            ibMonth = 10;
            break;
        default:
            return;
    }

    // Date `0` gives us the last day of the prior month, hence we add `1` to the current month
    return new Date(ibYear, ibMonth + 1, 0);
};

export const deriveCohortDisplay = (userCohort: string) => {
    const ibDate = convertUserCohortToIbDate(userCohort);
    if (!ibDate) return '';

    return format(ibDate, 'MMMM yyyy');
};
