import React from 'react';
import classnames from 'classnames/bind';

import styles from './FloatingToolbar.module.scss';

const cx = classnames.bind(styles);

type FloatingToolbarProps = {
    tooltips: React.ReactNode[];
};

const FloatingToolbar: React.FC<FloatingToolbarProps> = ({ tooltips }) => {
    return (
        <div className={cx('container', { 'single-icon': tooltips.length === 1 })}>
            {tooltips.map((t, i) => (
                <div key={i} className={cx('tooltip')}>
                    {t}
                </div>
            ))}
        </div>
    );
};

export default FloatingToolbar;
