import React, { useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { useHistory } from 'react-router';
import { Image, message, Popover } from 'antd';
import { datadogRum } from '@datadog/browser-rum';

import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { setGetStudyPlanCounter } from 'components/ReducerContext/Actions/UserActions';
import { getGetStudyPlanCounter, getUserProfile } from 'components/ReducerContext/selectors';

import { scrollToTop } from 'common/utils';
import TypographySubtitle from 'components/TypographySubtitle';
import TypographyBody from 'components/TypographyBody';
import TCSButton from 'components/TCSButton';
import ArcVideoPlayer from 'components/ArcVideoPlayer';
import CohortPopup from './CohortPopup';
import { deriveDaysToIB } from './utils';
import { deriveCohortDisplay } from 'common/utils';
import CohortVideoThumbnail from 'static/my-dashboard/cohort-video-placeholder-thumbnail.jpg';

import styles from './Cohort.module.scss';

const cx = classnames.bind(styles);

type CohortProps = {
    examDate: number;
    lessonName: string;
    freeLessonFullVideoUrl: string;
    freeLessonNotesUrl: string;
    summaryNotesUrl: string;
    trackFreeLessonAction: (action: string) => Promise<void>;
    userCohort: string;
};

const Cohort: React.FC<CohortProps> = ({
    examDate,
    lessonName,
    freeLessonFullVideoUrl,
    freeLessonNotesUrl,
    summaryNotesUrl,
    trackFreeLessonAction,
    userCohort,
}) => {
    const [showCohortPopup, setShowCohortPopup] = useState(false);

    const history = useHistory();
    const daysToIB = useMemo(
        () => deriveDaysToIB(userCohort || '', examDate),
        [userCohort, examDate]
    );

    const { state, dispatch } = useReducerContext();
    const getStudyPlanCounter = useMemo(() => getGetStudyPlanCounter(state), [state]);
    const profile = useMemo(() => getUserProfile(state), [state]);

    const handleDownloadNotes = () => {
        try {
            window.open(freeLessonNotesUrl, '_blank', 'noopener noreferrer');

            trackFreeLessonAction('notesDownloaded');
        } catch {
            message.error('Something went wrong, please try again');
        }
    };

    const handleDownloadSummaryNotes = () => {
        try {
            window.open(summaryNotesUrl, '_blank', 'noopener noreferrer');
            // TODO - trackFreeLessonAction('summaryNotesDownloaded');
        } catch {
            message.error('Something went wrong, please try again');
        }
    };

    const handleViewPastLessonsClick = () => {
        history.push('/recorded-lessons');
        scrollToTop('smooth');
    };

    const handleRequestStudyPlan = () => {
        setGetStudyPlanCounter(dispatch, getStudyPlanCounter + 1);

        datadogRum.addAction('requestStudyPlan', {
            authId: profile.cognitoId,
            fullName: `${profile.firstName} ${profile.lastName}`,
        });
    };

    const renderCountdownContent = (userCohort: string) => {
        if (daysToIB >= 0) {
            return (
                <div className={cx('cohort-display')}>
                    <TypographySubtitle level={1} className={cx('subtitle')}>
                        Approximately {daysToIB} days to the IB
                    </TypographySubtitle>
                    <TypographyBody level={1}>
                        {deriveCohortDisplay(userCohort)} Cohort
                    </TypographyBody>
                </div>
            );
        }

        return (
            <div className={cx('cohort-display')}>
                <TypographySubtitle level={1} className={cx('subtitle')}>
                    Congratulations! IB is now over
                </TypographySubtitle>
            </div>
        );
    };

    return (
        <>
            <div className={cx('container')}>
                {userCohort ? (
                    <div className={cx('cohort-display')}>
                        <div className={cx('countdown-container')}>
                            <TCSButton onClick={handleRequestStudyPlan} type="primary">
                                Request A Study Plan
                            </TCSButton>
                            {renderCountdownContent(userCohort)}
                        </div>

                        <TypographySubtitle level={1} className={cx('video-title')}>
                            This week&apos;s free lesson <br />
                            <span className={cx('video-title-name')}>{lessonName}</span>
                        </TypographySubtitle>

                        <ArcVideoPlayer
                            videoUrl={freeLessonFullVideoUrl}
                            videoWidth="100%"
                            videoHeight="100%"
                            onStart={() => trackFreeLessonAction('watched')}
                        />
                    </div>
                ) : (
                    <div className={cx('no-cohort-display')}>
                        <div className={cx('image-container')}>
                            <Image src={CohortVideoThumbnail} width="100%" preview={false} />
                        </div>
                        <div className={cx('overlay')}>
                            <div className={cx('content-container')}>
                                <TypographySubtitle level={1} className={cx('header')}>
                                    Fill in your IB Cohort to view our free lesson now!
                                </TypographySubtitle>
                                <TCSButton
                                    onClick={() => setShowCohortPopup(true)}
                                    className={cx('cta-button')}
                                    type="primary"
                                >
                                    When Are You Taking The IB?
                                </TCSButton>
                            </div>
                        </div>
                    </div>
                )}
                <div className={cx('action-container')}>
                    <div className={cx('buttons-container')}>
                        <TCSButton onClick={handleDownloadNotes} disabled={!userCohort}>
                            Download Lesson Notes
                        </TCSButton>

                        {summaryNotesUrl !== '' && (
                            <Popover
                                trigger="hover"
                                title="Lesson Summary"
                                content="No time to watch the full video? Download the 'Too Long Did Not Watch' Summary Notes!"
                            >
                                <span>
                                    <TCSButton
                                        onClick={handleDownloadSummaryNotes}
                                        disabled={!userCohort}
                                    >
                                        Download Lesson Summary
                                    </TCSButton>
                                </span>
                            </Popover>
                        )}

                        <TCSButton onClick={handleViewPastLessonsClick}>
                            View Past Lessons
                        </TCSButton>
                    </div>

                    <TypographyBody level={2} className={cx('reminder')}>
                        *New lessons every Friday 9:00pm (GMT +8)
                    </TypographyBody>
                </div>
            </div>

            <CohortPopup visible={showCohortPopup} onClose={() => setShowCohortPopup(false)} />
        </>
    );
};

export default Cohort;
