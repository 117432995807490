import React from 'react';
import classNames from 'classnames/bind';
import { Image, Row, Col, Carousel } from 'antd';
import { useHistory } from 'react-router';

import Jumbotron from 'static/ibeconsv2/jumbotron.png';
import RowImg1 from 'static/ibeconsv2/row_asset_1.png';
import RowImg2 from 'static/ibeconsv2/row_asset_2.png';
import RowImg3 from 'static/ibeconsv2/row_asset_3.png';
import Testimonial1 from 'static/ibeconsv2/testimonial_1.png';
import Testimonial2 from 'static/ibeconsv2/testimonial_2.png';
import Testimonial3 from 'static/ibeconsv2/testimonial_3.png';
import Testimonial4 from 'static/ibeconsv2/testimonial_4.png';
import Testimonial5 from 'static/ibeconsv2/testimonial_5.png';
import Testimonial6 from 'static/ibeconsv2/testimonial_6.png';

import styles from './IBEcons.module.scss';

const cx = classNames.bind(styles);

const IBEconsV2: React.FC = () => {
    const history = useHistory();

    const handleGetAFreeTrial = () => {
        history.push('/login');
    };

    return (
        <div className={cx('ib-econs-v2-container')}>
            <div className={cx('header', 'bold')}>
                The Deepest Topical Question Bank for IB Economics
            </div>

            <div className={cx('jumbotron')}>
                <Image src={Jumbotron} preview={false} />
            </div>

            <div className={cx('cta-container')}>
                <div className={cx('cta-1')} onClick={handleGetAFreeTrial}>
                    Get Started for Free
                </div>
            </div>

            <div className={cx('header', 'bold')}>
                The Most Common Struggle in IB Economics is the Failure to Translate Content into
                Distinction-Grade Essays
            </div>

            <div className={cx('paragraph')}>
                The bad news is that in the revamped syllabus, first examined in May 2022, there are
                MORE essay questions. There is now a 15-mark essay question in Paper 2 and a brand
                new, 10-mark policy question in Paper 3!
            </div>

            <div className={cx('paragraph')}>
                The good news is that on Cloudscore, we got you covered. With a deep library of
                questions & model answers in our topical question bank, we take you through the
                process of how to craft quality answers with our video solutions. Developed by an
                experienced team of IB Teachers, Cloudscore is the quintessential tool for excelling
                in IB Economics.
            </div>

            <div className={cx('row')}>
                <Row>
                    <Col sm={12} xs={24} className={cx('row-image')}>
                        <Image src={RowImg1} preview={false} />
                    </Col>

                    <Col sm={12} xs={24} className={cx('row-paragraph')}>
                        Model Essays for Papers I, II and III with Video Solutions, Arranged
                        Topically for Easy Revision
                    </Col>
                </Row>
            </div>

            <div className={cx('row')}>
                <Row>
                    <Col sm={12} xs={24} className={cx('row-image')}>
                        <Image src={RowImg2} preview={false} />
                    </Col>

                    <Col sm={12} xs={24} className={cx('row-paragraph')}>
                        Close to 200 Short Concept Videos that Distills the Entire Syllabus into
                        less than 24 hours
                    </Col>
                </Row>
            </div>

            <div className={cx('row')}>
                <Row>
                    <Col sm={12} xs={24} className={cx('row-image')}>
                        <Image src={RowImg3} preview={false} />
                    </Col>

                    <Col sm={12} xs={24} className={cx('row-paragraph')}>
                        Condensed Notes, Mindmaps, Cheatsheets To Accelerate Your Revision
                    </Col>
                </Row>
            </div>

            <div className={cx('banner')}>
                Before you pay hundreds of dollars to get an <br />
                Economics Tutor, try Cloudscore today!
            </div>

            <div className={cx('header')}>
                Join Thousands of Students Around the World Who Love Our Platform!
            </div>

            <div className={cx('carousel-container')}>
                <Carousel autoplay autoplaySpeed={5000} dotPosition="left">
                    <Image preview={false} src={Testimonial1} className={cx('testimonial-image')} />
                    <Image preview={false} src={Testimonial2} className={cx('testimonial-image')} />
                    <Image preview={false} src={Testimonial3} className={cx('testimonial-image')} />
                    <Image preview={false} src={Testimonial4} className={cx('testimonial-image')} />
                    <Image preview={false} src={Testimonial5} className={cx('testimonial-image')} />
                    <Image preview={false} src={Testimonial6} className={cx('testimonial-image')} />
                </Carousel>
            </div>

            <div className={cx('cta-container')}>
                <div className={cx('cta-1')} onClick={handleGetAFreeTrial}>
                    Get Started for Free
                </div>
            </div>
        </div>
    );
};

export default IBEconsV2;
