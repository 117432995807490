import React, { useCallback } from 'react';
import { List } from 'antd';
import classnames from 'classnames/bind';

import { DraftJSEditorStateNotes } from 'pages/MyNotes/types';
import { TCSFileTypeShape } from 'common/types';
import NotesCard from './NotesCard';

import styles from './NotesList.module.scss';

const cx = classnames.bind(styles);

type NotesListProps = {
    notesList: DraftJSEditorStateNotes[];
    fileTypes: TCSFileTypeShape[];
};

const NotesList: React.FC<NotesListProps> = ({ notesList, fileTypes }) => {
    // this is to set the margins in the print view
    const getPageMargins = useCallback(() => {
        const marginTop = 20;
        const marginRight = 100;
        const marginBottom = 20;
        const marginLeft = 100;
        return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
    }, []);

    return (
        <div className={cx('container')}>
            <style>{getPageMargins()}</style>
            <List
                itemLayout="horizontal"
                dataSource={notesList}
                renderItem={(notes) => (
                    <List.Item>
                        <NotesCard notes={notes} fileTypes={fileTypes} />
                    </List.Item>
                )}
            />
        </div>
    );
};

export default NotesList;
