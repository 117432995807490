export type GETPaymentsPlans = {
    priceId: string;
};

// TODO: remove and use EProductType in common/types.ts after refactoring
// follows the constants defined in client-service
// src/domain/payment/payment_dto.go#L11-L14
export enum EProductType {
    'INDIVIDUAL' = 'individual',
    'FAMILY' = 'family',
}
