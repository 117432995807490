import React, { useEffect, useMemo, useState } from 'react';
import * as sentry from '@sentry/react';

import { useAxiosInstance } from 'components/AxiosContext';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';

import RedirectSuccess from './RedirectSuccess';
import { maxValidationAttempts, timeBetweenValidationRetryInMs } from './constants';

type RedirectSuccessContainerProps = {
    visible: boolean;
    onClose: () => void;
    purchasedLessonId: number;
    handleRefetchPurchasedLessons: () => void;
};

const RedirectSuccessContainer: React.FC<RedirectSuccessContainerProps> = ({
    visible,
    onClose,
    purchasedLessonId,
    handleRefetchPurchasedLessons,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const axios = useAxiosInstance();

    const { state } = useReducerContext();
    const user = useMemo(() => getUserProfile(state), [state]);

    const handleValidatePurchase = async () => {
        if (!purchasedLessonId) {
            return;
        }

        try {
            setIsLoading(true);

            await axios.post(
                '/private/v2/recorded-lessons/validate',
                {
                    recordedLessonId: purchasedLessonId,
                },
                {
                    'axios-retry': {
                        retries: maxValidationAttempts - 1, // number of attempts after the first
                        retryDelay: (retryCount) => retryCount * timeBetweenValidationRetryInMs,
                        retryCondition: () => true, // axios-retry does not handle retry for 404, hence this has to be specified
                    },
                }
            );

            handleRefetchPurchasedLessons();
        } catch (err) {
            if (err.response?.status !== 404) {
                setHasError(true);
                return;
            }

            setHasError(true);

            sentry.captureException({
                contexts: {
                    name: `${user.firstName} ${user.lastName}`,
                    recordedLessonId: purchasedLessonId,
                    cognitoId: user.cognitoId,
                },
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleValidatePurchase();
    }, [purchasedLessonId]);

    return (
        <RedirectSuccess
            visible={visible}
            isLoading={isLoading}
            hasError={hasError}
            onClose={onClose}
        />
    );
};

export default RedirectSuccessContainer;
