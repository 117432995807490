import React from 'react';
import classnames from 'classnames/bind';
import { useHistory } from 'react-router';

import SubscriptionBox from 'components/SubscriptionBox';
import { friendsAndFamilyPerks, individualPerks, trialPerks } from './constants';

import styles from './SubscriptionInfoV2.module.scss';
const cx = classnames.bind(styles);

const SubscriptionInfoV2: React.FC = () => {
    const history = useHistory();

    const redirectToSignUp = () => {
        history.push('/login');
    };

    return (
        <div className={cx('container')}>
            <div className={cx('header')}>
                <div className={cx('title')}>Subscription</div>
                <div className={cx('subtitle')}>
                    Access ALL resources, including notes, question banks, mindmaps, lesson videos,
                    cheatsheets and more!
                </div>
            </div>

            <div className={cx('subscription-container')}>
                <SubscriptionBox
                    handleSelect={redirectToSignUp}
                    title="Individual"
                    perks={individualPerks}
                    pricePerMonth={15}
                />
                <SubscriptionBox
                    handleSelect={redirectToSignUp}
                    title="Friends & Family"
                    perks={friendsAndFamilyPerks}
                    pricePerMonth={20}
                />
                <SubscriptionBox
                    handleSelect={redirectToSignUp}
                    title="Free 1-Day Trial"
                    perks={trialPerks}
                />
            </div>
        </div>
    );
};

export default SubscriptionInfoV2;
