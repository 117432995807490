import React from 'react';
import classnames from 'classnames/bind';
import { Image } from 'antd';
import { Link } from 'react-router-dom';

import TypographyBody from 'components/TypographyBody';

import styles from './SectionCard.module.scss';

const cx = classnames.bind(styles);

type SectionCardProps = {
    headerImg: string;
    title: string;
    description: string;
    url: string;
};

const SectionCard: React.FC<SectionCardProps> = ({ headerImg, title, description, url }) => {
    return (
        <div className={cx('container')}>
            <Link to={url} target="_blank" rel="noopener noreferrer">
                <Image src={headerImg} width={338} height={229} preview={false} />
                <div className={cx('text-content')}>
                    <div className={cx('title')}>{title}</div>
                    <TypographyBody level={1} className={cx('description')}>
                        {description}
                    </TypographyBody>
                </div>
            </Link>
        </div>
    );
};

export default SectionCard;
