import React from 'react';
import classnames from 'classnames/bind';
import { Card, Space } from 'antd';

import styles from './Authentication.scss';

const cx = classnames.bind(styles);

interface IAuthenticationCardProps {
    title: string;
}
const AuthenticationCard: React.FC<IAuthenticationCardProps> = ({ title, children }) => (
    <Card title={title} className={cx('authentication-card')}>
        <Space size="middle" direction="vertical" className={cx('authentication-card__content')}>
            {children}
        </Space>
    </Card>
);

export default AuthenticationCard;
