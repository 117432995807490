import React from 'react';
import classnames from 'classnames/bind';

import styles from './TypographyHeader.module.scss';

type TypographyHeaderProps = {
    level: 3;
    className?: string;
};

const cx = classnames.bind(styles);

const TypographyHeader: React.FC<TypographyHeaderProps> = ({ level, className, children }) => {
    return <div className={cx(`header-${level}`, className)}>{children}</div>;
};

export default TypographyHeader;
