import React, { useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { Tooltip, Modal } from 'antd';
import { datadogRum } from '@datadog/browser-rum';

import MyVideos from './MyVideos';
import RequestVideos from './RequestVideos';
import ExploreVideos from './ExploreVideos';
import { ShortVideosResponse } from './types';

import styles from './ShortVideos.module.scss';

const cx = classnames.bind(styles);

type ShortVideosProps = {
    myVideos: ShortVideosResponse[];
};

const ShortVideos: React.FC<ShortVideosProps> = ({ myVideos }) => {
    const [view, setView] = useState<'MyVideos' | 'Explore' | 'Request'>('Explore');
    const [displayModal, setDisplayModal] = useState(false);

    const hasPending = useMemo(() => {
        return myVideos.some((video) => video.status === 'PENDING');
    }, [myVideos]);

    const handleOnRequest = () => {
        if (hasPending) return;
        setView('Request');
    };

    const handleRender = () => {
        if (view === 'Explore') {
            return <ExploreVideos handleOnRequest={handleOnRequest} />;
        }

        if (view === 'Request') {
            return <RequestVideos handleRedirect={() => setView('Explore')} />;
        }

        return <MyVideos myVideos={myVideos} onRequestVideo={() => setView('Request')} />;
    };

    const handleDisplayFAQ = () => {
        setDisplayModal(true);
        datadogRum.addAction('displayFAQ');
    };

    return (
        <div className={cx('container')}>
            <div className={cx('navbar')}>
                <div
                    className={cx('navitem', { active: view === 'Explore' })}
                    onClick={() => setView('Explore')}
                >
                    Explore Courses
                </div>

                <div
                    className={cx('navitem', { active: view === 'MyVideos' })}
                    onClick={() => setView('MyVideos')}
                >
                    My Courses
                </div>

                <Tooltip
                    title="You have a pending crashcourse request. Please wait for it to be answered before requesting a new one."
                    placement="bottom"
                    trigger={hasPending ? 'hover' : ''}
                >
                    <div
                        className={cx('navitem', {
                            active: view === 'Request',
                            disabled: hasPending,
                        })}
                        onClick={handleOnRequest}
                    >
                        Request a Crash Course
                    </div>
                </Tooltip>

                <div className={cx('faq')} onClick={handleDisplayFAQ}>
                    How does it work?
                </div>

                <Modal
                    visible={displayModal}
                    footer={null}
                    maskClosable
                    destroyOnClose
                    closable={false}
                    onCancel={() => setDisplayModal(false)}
                >
                    <div className={cx('faq-container')}>
                        <div className={cx('faq-title')}>How does it work?</div>

                        <div>
                            <div className={cx('faq-item-title')}>
                                1. What&apos;s a Crash Course
                            </div>
                            <div className={cx('faq-item-description')}>
                                Crash courses are bespoke 10 minute videos that helps you to cut
                                through the noise and get to the core of helping you understand a
                                topic
                            </div>
                        </div>

                        <div>
                            <div className={cx('faq-item-title')}>
                                2. Is the Crash Course visible to everyone else?
                            </div>
                            <div className={cx('faq-item-description')}>
                                Yes, the crash course is visible to everyone else. Someone else
                                might have the same questions as you do and can benefit from the
                                explanatory crash course. But don&apos;t worry, it does not indicate
                                who asked the question.
                            </div>
                        </div>

                        <div>
                            <div className={cx('faq-item-title')}>
                                3. How do I request for a Crash Course
                            </div>
                            <div className={cx('faq-item-description')}>
                                Head over to the request tab and fill out the form to request for a
                                crash course! Do note that you can only request for one crash course
                                at a time.
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>

            {handleRender()}
        </div>
    );
};

export default ShortVideos;
