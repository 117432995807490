import React from 'react';
import classnames from 'classnames/bind';

import styles from './TypographyBody.module.scss';

type TypographyBodyProps = {
    level: 1 | 2;
    className?: string;
    isBold?: boolean;
};

const cx = classnames.bind(styles);

const TypographyBody: React.FC<TypographyBodyProps> = ({
    level,
    isBold = false,
    className,
    children,
}) => {
    return (
        <div className={cx(`body-text-${level}`, isBold ? 'bold' : '', className)}>{children}</div>
    );
};

export default TypographyBody;
