import React from 'react';

import ResultViewer from './ResultViewer';
import { GETMistakesQuizPayload, QuizAnswer } from '../QuizSetViewer/types';

type ResultViewerContainerProps = {
    quizzes: GETMistakesQuizPayload[];
    selectedAnswerList: QuizAnswer[];
    handleGoBack: () => void;
};

const ResultViewerContainer: React.FC<ResultViewerContainerProps> = ({
    quizzes,
    selectedAnswerList,
    handleGoBack,
}) => {
    return (
        <ResultViewer
            quizzes={quizzes}
            selectedAnswerList={selectedAnswerList}
            handleGoBack={handleGoBack}
        />
    );
};

export default ResultViewerContainer;
