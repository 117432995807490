import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import { Col, List, Row } from 'antd';

import { getUserProfile } from 'components/ReducerContext/selectors';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';

import { maskLeaderboardUserName } from './utils';
import { GETQuizChallengeLeaderboardPayload } from '../../types';

import styles from './LeaderboardTable.module.scss';

const cx = classnames.bind(styles);

type LeaderboardTableProps = {
    users: GETQuizChallengeLeaderboardPayload[];
};

const LeaderboardTable: React.FC<LeaderboardTableProps> = ({ users }) => {
    const { state } = useReducerContext();
    const profile = useMemo(() => getUserProfile(state), [state]);

    return (
        <div className={cx('container')}>
            <List
                header={
                    <Row className={cx('header', 'row')}>
                        <Col span={4}>Rank</Col>
                        <Col span={12}>Name</Col>
                        <Col span={4}>Streak</Col>
                        <Col span={4}>Skips</Col>
                    </Row>
                }
                className={cx('list-container')}
                dataSource={users}
                renderItem={(item, index) => (
                    <Row
                        className={cx('row', {
                            highlight: item.authId === profile.cognitoId,
                        })}
                    >
                        <Col span={4} className={cx('col')}>
                            {index + 1}
                        </Col>
                        <Col span={12}>{maskLeaderboardUserName(item.fullName)}</Col>
                        <Col span={4}>{item.totalScore}</Col>
                        <Col span={4}>{item.totalSkips}</Col>
                    </Row>
                )}
            />
        </div>
    );
};

export default LeaderboardTable;
