import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Router } from 'react-router-dom';
import { Layout } from 'antd';
import classnames from 'classnames/bind';
import { createBrowserHistory } from 'history';

import Routes from 'components/Routes';
import LoadingModal from 'components/LoadingModal';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import UserAuth from 'components/UserAuth';
import NavigationBar from 'components/NavigationBar';
import FooterV2 from './components/FooterV2';
import AskAQuestion from 'components/AskAQuestion';
import AdvertisingPopup from 'components/AdvertisingPopup';

import { EErrorMessage } from 'common/messages';
import { sleep } from 'common/utils';
import { useInitAuthedApplication } from 'common/hooks';

import styles from './App.scss';

const cx = classnames.bind(styles);
const history = createBrowserHistory();

const App: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState(EErrorMessage.DEFAULT);

    const { state, dispatch } = useReducerContext();

    // we use the UserProfile in the store, because once it exists, it indicates that
    // UserAuth has completed the necessary calls for retrieving the profile
    const { isLoading, hasError, showPopup, closePopup } = useInitAuthedApplication(
        Boolean(state.user)
    );

    const is401Error = useMemo(() => state.config.is401Error, [state]);

    const handle401Error = useCallback(async () => {
        setShowModal(true);
        setErrorMsg(EErrorMessage.SESSION_EXPIRED);

        await sleep(5000);
        window.location.reload();
    }, [dispatch]);

    const handleInitError = useCallback(async () => {
        setShowModal(true);
        setErrorMsg(EErrorMessage.INIT_FAILED);

        await sleep(5000);
        window.location.reload();
    }, []);

    const handleTakeMeThere = () => {
        history.push('/crash-courses');
    };

    useEffect(() => {
        if (is401Error) {
            handle401Error();
        }
    }, [is401Error, handle401Error]);

    useEffect(() => {
        if (hasError) {
            handleInitError();
        }
    }, [hasError]);

    return (
        <>
            <Router history={history}>
                <Layout className={cx('app-layout')}>
                    <NavigationBar />

                    <Layout.Content className={cx('content')}>
                        <UserAuth>
                            {!isLoading && <Routes />} <AskAQuestion />
                        </UserAuth>
                    </Layout.Content>

                    <FooterV2 />
                </Layout>
            </Router>

            <LoadingModal showModal={showModal} message={errorMsg} />

            <LoadingModal
                showModal={isLoading}
                message={`Please wait while we initialise the rest of the application...\n Just a little while longer!`}
            />

            <AdvertisingPopup
                showModal={showPopup}
                closeModal={closePopup}
                takeMeThere={handleTakeMeThere}
            />
        </>
    );
};

export default App;
