import { TCSFileShape, TCSSubject } from 'common/types';
import { SELF_STUDY_WHITELIST } from 'config';

import { parseQueryParam, validateID } from '../../common/utils';

import { SelfStudyQuery, RawSelfStudyQuery } from './types';

/**
 * Determines whether the given query param for self
 * study is valid or not
 * Ensures that dependent keys are present
 * i.e. if file_key is present, then topic_id & subject_id should be too
 * @param query - the self-study query param from the URL
 */
export const parseSelfStudyQuery = (query: string): SelfStudyQuery | null => {
    const parsedQuery = parseQueryParam(query);

    const subjectId = validateID(parsedQuery, 'subject_id');
    if (subjectId === null) return null;

    const topicId = validateID(parsedQuery, 'topic_id');
    if (topicId === null) {
        return {
            subjectId,
        };
    }

    const fileKey = parsedQuery['file_key'];
    const isNotesPanelOpen = parsedQuery['is_notes_panel_open'];

    if (fileKey) {
        return {
            subjectId,
            topicId,
            fileKey,
            isNotesPanelOpen,
        };
    }

    return {
        subjectId,
        topicId,
        isNotesPanelOpen,
    };
};

/**
 * ensures that the query is valid. reconstructs the payload in certain instances to ensure
 * erroneous values doesn't accidentally find its way in
 * - has topic_id & file_key but no subject_id -> invalid
 * - has file_key key but value is undefined -> filtered out
 */
export const validateSelfStudyQuery = (query: SelfStudyQuery): SelfStudyQuery | null => {
    switch (true) {
        case Boolean(query.fileKey && query.topicId && query.subjectId):
            return query;
        case Boolean(query.topicId && query.subjectId):
            return {
                topicId: query.topicId,
                subjectId: query.subjectId,
            };
        case Boolean(query.subjectId):
            return {
                subjectId: query.subjectId,
            };
        default:
            return null;
    }
};

export const deriveSelfStudyQueryString = (query: SelfStudyQuery): string => {
    const q = validateSelfStudyQuery(query);
    if (q === null) return '';

    const rawQ: RawSelfStudyQuery = {};

    if (q.fileKey) {
        rawQ.file_key = q.fileKey;
    }

    if (q.subjectId) {
        rawQ.subject_id = q.subjectId.toString();
    }

    if (q.topicId) {
        rawQ.topic_id = q.topicId.toString();
    }

    if (q.isNotesPanelOpen) {
        rawQ.is_notes_panel_open = q.isNotesPanelOpen.toString();
    }

    const querystring = new URLSearchParams(rawQ as Record<string, string>).toString();
    return querystring ? `?${querystring}` : '';
};

/**
 * sorts the given files by first grouping them by file type, before sorting
 * them alphabetically within their individual groupings
 */
export const deriveSortedFiles = (files: TCSFileShape[]): TCSFileShape[] => {
    const fileTypeFiles: Record<number, TCSFileShape[]> = {};
    const result = [] as TCSFileShape[];

    // sort the files into their respective file types first
    files.forEach((f) => {
        if (!fileTypeFiles[f.file_type_id]) {
            fileTypeFiles[f.file_type_id] = [] as TCSFileShape[];
        }

        fileTypeFiles[f.file_type_id].push(f);
    });

    for (const id in fileTypeFiles) {
        const file = fileTypeFiles[id];
        const sorted = file.sort((a, b) => {
            if (a.original_file_name < b.original_file_name) return -1;
            if (a.original_file_name > b.original_file_name) return 1;
            return 0;
        });

        result.push(...sorted);
    }

    return result;
};

export const queryIsSameAsRef = (
    query: SelfStudyQuery | null,
    ref: SelfStudyQuery | null
): boolean => {
    if (query === null || ref === null) {
        return query === ref;
    }

    for (const key in query) {
        const v1 = query[key as keyof SelfStudyQuery];
        const v2 = ref[key as keyof SelfStudyQuery];

        if (v1 !== v2) return false;
    }

    return true;
};

export const deriveWhitelistedSubjects = (subjects: TCSSubject[]): TCSSubject[] => {
    const result: TCSSubject[] = [];

    subjects.forEach((sub) => {
        const { name = '' } = sub;
        if (SELF_STUDY_WHITELIST[name]) {
            result.push(sub);
        }
    });

    return result;
};
