import React from 'react';
import classnames from 'classnames/bind';

import TabPane from './TabPane';
import { Option } from './types';

import styles from './ActiveTabs.module.scss';

type ActiveTabsProps = {
    options: Option[];
    selectedOptionId: number;
    onTabClick: (filterId: number) => void;
};

const cx = classnames.bind(styles);

const ActiveTabs: React.FC<ActiveTabsProps> = ({ options, selectedOptionId, onTabClick }) => {
    return (
        <div className={cx('container')}>
            {options.map((option) => (
                <TabPane
                    key={option.id}
                    option={option}
                    onTabClick={onTabClick}
                    isSelected={selectedOptionId === option.id}
                />
            ))}
        </div>
    );
};

export default ActiveTabs;
