import React from 'react';
import classnames from 'classnames/bind';

import styles from './ErrorMessageWrapper.scss';

const cx = classnames.bind(styles);

type ErrorMessageWrapperProps = {
    children: React.ReactNode;
    errorMessage: string | null;
    messagePosition?: 'top' | 'bottom';
};

const ErrorMessageWrapper: React.FC<ErrorMessageWrapperProps> = ({
    children,
    errorMessage,
    messagePosition = 'bottom',
}) => {
    return (
        <div className={cx('error-message-wrapper')}>
            {messagePosition === 'top' && <div className={cx('error-message')}>{errorMessage}</div>}
            {children}
            {messagePosition === 'bottom' && (
                <div className={cx('error-message')}>{errorMessage}</div>
            )}
        </div>
    );
};

export default ErrorMessageWrapper;
