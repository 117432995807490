import React from 'react';
import classnames from 'classnames/bind';
import { Spin } from 'antd';

import SubjectSelector from 'components/SubjectSelector';
import TopicSelector from 'components/TopicSelector';
import QuestionSetSelector from './QuestionSetSelector';
import QuestionSetViewer from './QuestionSetViewer';

import { useQuestionBank } from './hooks';

import styles from './QuestionBank.module.scss';

const cx = classnames.bind(styles);

const QuestionBank: React.FC = () => {
    const { payloads, setters, activeContent, status } = useQuestionBank();

    const { subjects, topics, questionSets } = payloads;
    const { activeSubject, activeTopic, activeQuestionSet } = activeContent;
    const { setActiveSubject, setActiveTopic, setActiveQuestionSet } = setters;
    const { initDone, isLoading } = status;

    if (!initDone) {
        return (
            <div className={cx('loading')}>
                <Spin size="large" />
            </div>
        );
    }

    const handleRender = () => {
        if (activeQuestionSet !== null) {
            return (
                <QuestionSetViewer
                    handleGoBack={() => setActiveQuestionSet(null)}
                    questionSet={activeQuestionSet}
                />
            );
        }

        if (activeTopic !== null) {
            return (
                <QuestionSetSelector
                    title={activeTopic.name}
                    handleGoBack={() => setActiveTopic(null)}
                    isLoading={isLoading}
                    questionSets={questionSets}
                    selectQuestionSet={setActiveQuestionSet}
                />
            );
        }

        if (activeSubject !== null) {
            return (
                <TopicSelector
                    title={`Subject: ${activeSubject.name}`}
                    subject={activeSubject}
                    topics={topics}
                    selectTopic={setActiveTopic}
                    handleGoBack={() => setActiveSubject(null)}
                />
            );
        }

        return (
            <SubjectSelector
                title="Question Bank"
                subjects={subjects}
                selectSubject={setActiveSubject}
            />
        );
    };

    return <div className={cx('container')}>{handleRender()}</div>;
};

export default QuestionBank;
