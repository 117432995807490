import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames/bind';
import { Input, Button, Row, Col, Spin, Modal, Alert } from 'antd';

import { StudyBotConversation as StudyBotConv } from './types';
import { sanitiseInput } from './utils';

import styles from './StudyBotConversation.module.scss';

const cx = classnames.bind(styles);

type StudyBotConversationProps = {
    isSubmitting: boolean;
    isPolling: boolean;
    submitError: boolean;
    pollError: boolean;
    conversation: StudyBotConv[];
    submitMessage: (message: string) => Promise<void>;
};

const ChatBubble = ({ text, isBot }: { text: string; isBot: boolean }) => {
    return (
        <div className={cx('chat-bubble', { bot: isBot })}>
            <div dangerouslySetInnerHTML={{ __html: sanitiseInput(text) }} />

            <div className={cx('chat-role')}>{isBot ? 'Study Bot' : 'You'}</div>
        </div>
    );
};

const StudyBotConversation: React.FC<StudyBotConversationProps> = ({
    isSubmitting,
    isPolling,
    submitError,
    pollError,
    conversation,
    submitMessage,
}) => {
    const [inputText, setInputText] = useState<string>('');
    const buttonRef = useRef(null);

    const handleSubmit = async () => {
        if (inputText === '') {
            return;
        }

        await submitMessage(inputText);
        setInputText('');
    };

    useEffect(() => {
        if (conversation.length > 0 && buttonRef.current) {
            (buttonRef.current as HTMLUnknownElement).scrollIntoView({ behavior: 'smooth' });
        }
    }, [conversation]);

    return (
        <div className={cx('container')}>
            {(isSubmitting || isPolling) && (
                <Modal visible closable={false} footer={false}>
                    <div className={cx('loading-container')}>
                        <div className={cx('spin-container')}>
                            <Spin size="large" />
                        </div>

                        <div className={cx('loading-text')}>Our Study Bot is Working on it...</div>
                    </div>
                </Modal>
            )}

            {(submitError || pollError) && (
                <Modal visible closable={false} footer={false}>
                    <div className={cx('loading-container')}>
                        <div className={cx('loading-text')}>
                            Oops. Looks like our Study Bot has been over worked.
                        </div>

                        <br />

                        <div className={cx('loading-text')}>
                            Please refresh the page and try again. If the issue persists, please
                            contact us for help.
                        </div>
                    </div>
                </Modal>
            )}

            {conversation.length === 0 && (
                <div className={cx('empty-conv-container')}>
                    <div className={cx('text')}>
                        Cloudscore&apos;s Study Bot is powered by Chat GPT, and has been configured
                        to help you do the heavy lifting of sifting through dense information to
                        provide you with short and sweet summaries. It is currently geared towards
                        helping you study for IB Economics.
                    </div>

                    <div className={cx('text')}>
                        To get started, type a question in the input box below, and the Study Bot
                        will respond with the best answer it can find.
                    </div>

                    <div className={cx('text')}>
                        Note that the Study Bot is not perfect, and may not always give you the
                        correct answer. It should not be treated as a replacement for your own study
                        and research, and should just be used as a tool to help you find information
                        faster.
                    </div>
                </div>
            )}

            {conversation.length >= 8 && (
                <div className={cx('alert-container')}>
                    <Alert
                        message="You have reached the conversation limit. To continue chatting, please start a new chat."
                        type="error"
                    />
                </div>
            )}

            {conversation.map((conv, i) => (
                <ChatBubble key={i} text={conv.message} isBot={conv.role === 'assistant'} />
            ))}

            <div className={cx('input-container')} ref={buttonRef}>
                <Input.Group>
                    <Row gutter={8}>
                        <Col span={22}>
                            <Input.TextArea
                                showCount
                                value={inputText}
                                onChange={(e) => setInputText(e.target.value)}
                                autoSize={{ minRows: 3, maxRows: 5 }}
                                maxLength={100}
                                placeholder="Type your message here..."
                                disabled={conversation.length >= 8}
                            />
                        </Col>

                        <Col span={2}>
                            <Button
                                block
                                type="primary"
                                className={cx('input-submit')}
                                onClick={handleSubmit}
                                disabled={conversation.length >= 8}
                            >
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Input.Group>
            </div>
        </div>
    );
};

export default StudyBotConversation;
