import React from 'react';
import classnames from 'classnames/bind';
import { Spin } from 'antd';

import ArcVideoPlayer from 'components/ArcVideoPlayer';
import TypographyHeader from 'components/TypographyHeader';
import GoBack from 'components/FloatingToolbar/Tooltips/GoBack';
import FloatingToolbar from 'components/FloatingToolbar';

import styles from './PurchasedLessonViewer.module.scss';

const cx = classnames.bind(styles);

type PurchasedLessonViewerProps = {
    handleGoBack: () => void;
    signedFullVideoUrl: string;
    title: string;
    isLoading: boolean;
};

const PurchasedLessonViewer: React.FC<PurchasedLessonViewerProps> = ({
    handleGoBack,
    signedFullVideoUrl,
    title,
    isLoading,
}) => {
    const tooltips = [<GoBack key="go-back" callback={handleGoBack} />];

    if (isLoading) {
        return (
            <div className={cx('loading-container')}>
                <Spin size="large" />
            </div>
        );
    }

    return (
        <div className={cx('container')}>
            <div className={cx('content-container')}>
                <div className={cx('video-container')}>
                    <ArcVideoPlayer
                        videoUrl={signedFullVideoUrl}
                        videoWidth="100%"
                        videoHeight="100%"
                    />
                </div>

                <TypographyHeader level={3} className={cx('title')}>
                    {title}
                </TypographyHeader>
            </div>

            <FloatingToolbar tooltips={tooltips} />
        </div>
    );
};

export default PurchasedLessonViewer;
