import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AxiosInstance } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';

import { Action } from 'components/ReducerContext/reducer';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import {
    loadFileTypes,
    loadSubjects,
    loadTopics,
} from 'components/ReducerContext/Actions/CommonActions';
import { useAxiosInstance } from 'components/AxiosContext';
import { useAuth } from 'components/AuthContextProvider';

import { GETFilesSubjectsPayload } from 'common/types';
import { parseSubjectPayload } from 'common/utils';
import { IUserAttributes } from 'components/AuthContextProvider/types';

const fetchSubjectsAndTopics = async (axios: AxiosInstance, dispatch: React.Dispatch<Action>) => {
    try {
        const { data } = await axios.get<GETFilesSubjectsPayload[]>('/private/v1/files/subjects');
        const { subjects, topics } = parseSubjectPayload(data);

        loadSubjects(dispatch, subjects);
        loadTopics(dispatch, topics);
    } catch (err) {
        throw Error(err);
    }
};

const fetchFileTypes = async (axios: AxiosInstance, dispatch: React.Dispatch<Action>) => {
    try {
        const { data } = await axios.get<GETFilesSubjectsPayload[]>('/private/v1/files/filetypes');
        loadFileTypes(dispatch, data);
    } catch (err) {
        throw Error(err);
    }
};

const setSentryUser = (userAttr: IUserAttributes) => {
    Sentry.setUser({
        email: userAttr.email || '',
        id: userAttr.cognitoId || '',
        name: `${userAttr.firstName} ${userAttr.lastName}`,
    });
};

const setRumGlobalContext = (userAttr: IUserAttributes) => {
    datadogRum.addRumGlobalContext('cognito_id', userAttr.cognitoId || '');
    datadogRum.addRumGlobalContext('user_email', userAttr.email || '');
    datadogRum.addRumGlobalContext('user_fullname', `${userAttr.firstName} ${userAttr.lastName}`);
};

/**
 * useInitAuthedApplication - is a hook intended for initialising the necessary stuff within
 * the application AFTER a user has been authenticated and logged in
 * The assumption is that whatever that is necessary for bootstrapping the application for an
 * authenticated user will be done at this stage
 *
 * --- Additional Notes ---
 * we also want to use the hook to determine if the popup should be displayed
 * this popup will only be displayed after users have been authenticated
 */
export const useInitAuthedApplication = (readyForInit: boolean) => {
    const { dispatch } = useReducerContext();
    const axios = useAxiosInstance();
    const { userAttr } = useAuth();
    const inited = useRef(false);

    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const initApp = useCallback(async () => {
        setRumGlobalContext(userAttr);
        setSentryUser(userAttr);

        try {
            setIsLoading(true);

            await fetchSubjectsAndTopics(axios, dispatch);
            await fetchFileTypes(axios, dispatch);
        } catch {
            setHasError(true);
        } finally {
            setIsLoading(false);

            const now = Math.floor(Date.now() / 1000);
            const doNotShowUntil = window.localStorage.getItem('__arclrn.doNotShowUntil');
            const doNotShowUntilUNIX = Number(doNotShowUntil);

            if (!doNotShowUntilUNIX || now > doNotShowUntilUNIX) {
                setShowPopup(true);
            }
        }
    }, [axios, userAttr]);

    useEffect(() => {
        if (readyForInit && !inited.current) {
            inited.current = true;
            initApp();
        }

        // handle user log out scenario
        if (inited.current && !readyForInit) {
            inited.current = false;
        }
    }, [readyForInit]);

    const closePopup = () => {
        setShowPopup(false);
    };

    return {
        isLoading,
        hasError,
        showPopup,
        closePopup,
    };
};
