import React, { useState } from 'react';
import { Input, Button, message } from 'antd';

import ErrorMessageWrapper from 'components/ErrorMessageWrapper';

import { isMinimumLength } from './utils';
import { requiredFieldMessage } from './errorMessages';
import AuthenticationCard from './AuthenticationCard';

enum EConfirmEmailFormField {
    CONFIRMATION_CODE = 'CONFIRMATION_CODE',
}

const confirmEmailFormValidations = {
    [EConfirmEmailFormField.CONFIRMATION_CODE]: (confirmationCode: string) =>
        !isMinimumLength(confirmationCode, 1),
};

type ConfirmEmailProps = {
    isLoading: boolean;
    handleConfirmEmail: (confirmationCode: string) => Promise<void>;
    handleResendConfirmationCode: () => void;
};

const ConfirmEmail: React.FC<ConfirmEmailProps> = ({
    isLoading,
    handleConfirmEmail,
    handleResendConfirmationCode,
}) => {
    const [confirmationCode, setConfirmationCode] = useState('');
    const [formErrors, setFormErrors] = useState<Record<string, null | string>>({
        [EConfirmEmailFormField.CONFIRMATION_CODE]: null,
    });

    const validateConfirmEmail = () => {
        const confirmationCodeError =
            confirmEmailFormValidations[EConfirmEmailFormField.CONFIRMATION_CODE](confirmationCode);

        setFormErrors({
            [EConfirmEmailFormField.CONFIRMATION_CODE]: confirmationCodeError
                ? requiredFieldMessage('confirmation code')
                : null,
        });

        return !confirmationCodeError;
    };

    const onConfirmEmailButtonClick = async () => {
        if (validateConfirmEmail()) {
            try {
                await handleConfirmEmail(confirmationCode);
            } catch (error) {
                if (error.message === 'ExpiredCodeException') {
                    message.error(
                        'Your confirmation code has expired. Please request for another one.'
                    );
                } else {
                    message.error(
                        'Oops! There was an error trying to confirm your email. Please try again.'
                    );
                }
            }
        } else {
            message.error('Please fix the form error');
        }
    };

    const onResendCodeClick = () => {
        handleResendConfirmationCode();
        message.success('A new confirmation code has been sent to your email.');
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmationCode(e.target.value);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            onConfirmEmailButtonClick();
        }
    };

    return (
        <AuthenticationCard title="Confirm your email to continue">
            <ErrorMessageWrapper
                errorMessage={formErrors[EConfirmEmailFormField.CONFIRMATION_CODE]}
            >
                <Input
                    placeholder="Enter Code"
                    value={confirmationCode}
                    onChange={handleOnChange}
                    onKeyPress={handleKeyPress}
                />
            </ErrorMessageWrapper>

            <Button type="primary" loading={isLoading} block onClick={onConfirmEmailButtonClick}>
                Confirm
            </Button>

            <Button block onClick={onResendCodeClick}>
                Resend Code
            </Button>
        </AuthenticationCard>
    );
};

export default ConfirmEmail;
