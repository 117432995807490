import React, { useState } from 'react';
import { Button, Modal, Row, Col, message, Input } from 'antd';
import classnames from 'classnames/bind';

import TypographySubtitle from 'components/TypographySubtitle';
import { isValidEmail } from 'components/Authentication/utils';
import InfoBox from 'components/InfoBox';

import styles from './InviteUser.module.scss';
import { ERROR_NOT_FOUND, ERROR_USER_ALREADY_SUBSCRIBED } from './constants';

const cx = classnames.bind(styles);

type InviteUserProps = {
    isLoading: boolean;
    hasError: boolean;
    inviteUser: (email: string) => Promise<void>;
    onSuccess: () => void;
};

const InviteUser: React.FC<InviteUserProps> = ({ isLoading, inviteUser, hasError, onSuccess }) => {
    const [visible, setVisible] = useState(false);
    const [email, setEmail] = useState('');

    const handleOnCancel = () => {
        if (isLoading) return;
        setVisible(false);
    };

    const handleSubmit = async () => {
        if (!isValidEmail(email)) {
            message.error('Please enter a valid email');
            return;
        }

        try {
            await inviteUser(email);

            message.success('User is invited successfully');

            setEmail('');
            setVisible(false);
            onSuccess();
        } catch (err) {
            if (err.message === ERROR_NOT_FOUND) {
                message.error(
                    'Cannot find user. Please make sure the email is correct and the user is registered!'
                );
                return;
            }

            if (err.message === ERROR_USER_ALREADY_SUBSCRIBED) {
                message.error('The user is already on another plan');
                return;
            }

            message.error('Oops! There was a problem inviting user. Please try again!');
        }
    };

    return (
        <>
            <Row style={{ marginTop: '20px' }}>
                <Col span={16}></Col>
                <Col span={8}>
                    <Button
                        type="primary"
                        className={cx('button')}
                        block
                        onClick={() => setVisible(true)}
                    >
                        Invite User
                    </Button>
                </Col>
            </Row>

            <Modal
                visible={visible}
                onCancel={handleOnCancel}
                closable={!isLoading}
                footer={null}
                width="560px"
                destroyOnClose
                wrapClassName={cx('modal-container')}
            >
                <TypographySubtitle className={cx('modal-title')} level={1}>
                    Invite User
                </TypographySubtitle>

                <div className={cx('input-container')}>
                    <Input
                        placeholder={'Email'}
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button type="primary" onClick={handleSubmit} disabled={isLoading}>
                        Send Invite
                    </Button>
                </div>

                {hasError ? (
                    <InfoBox type="error">
                        User cannot be invited as he/she does not have an account on Cloudscore.
                        Kindly try again when the user has registered and verified their account.
                    </InfoBox>
                ) : (
                    <InfoBox>
                        You may only invite existing Cloudscore users to the plan. Please have your
                        friends or family register with Cloudscore, verify their accounts and log in
                        at least once, before inviting them.
                    </InfoBox>
                )}
            </Modal>
        </>
    );
};

export default InviteUser;
