import React, { useState, useMemo } from 'react';
import classnames from 'classnames/bind';
import { Button, Row, Col, Card } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router';

import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';

import StudyBotConversation from './StudyBotConversation';
import Disclaimer from './Disclaimer';

import styles from './StudyBot.module.scss';

const cx = classnames.bind(styles);

const StudyBot: React.FC = () => {
    const history = useHistory();
    const { state } = useReducerContext();

    const profile = useMemo(() => getUserProfile(state), [state]);
    const chatUuid = useMemo(() => profile?.cognitoId || uuidv4(), [profile]);

    const [showDisclaimer, setShowDisclaimer] = useState<boolean>(true);
    const [chatCreationTime, setChatCreationTime] = useState<number>(() =>
        Math.floor(Date.now() / 1000)
    );

    const handleNewChat = () => {
        setChatCreationTime(() => Math.floor(Date.now() / 1000));
    };

    const handleStartFreeTrial = () => {
        history.push('/login');
    };

    return (
        <div className={cx('container')}>
            <div className={cx('title')}>Cloudscore Study Bot</div>

            {showDisclaimer && (
                <Disclaimer
                    isVisible={showDisclaimer}
                    dismissDisclaimer={() => setShowDisclaimer(false)}
                />
            )}

            <div className={cx('conversation-parent-container')}>
                <Row>
                    <Col span={6}>
                        <div className={cx('card-container')}>
                            <Card>
                                <div className={cx('card-text')}>
                                    Get Model Essays, Sample IAs Question Banks and Short Concept
                                    Videos to help you Ace IB Economnics!
                                </div>

                                <div className={cx('card-cta')}>
                                    <Button onClick={handleStartFreeTrial}>Start Free Trial</Button>
                                </div>
                            </Card>
                        </div>
                    </Col>
                    <Col span={18}>
                        <div className={cx('conversation-container')}>
                            <StudyBotConversation
                                chatUuid={chatUuid}
                                chatCreationTime={chatCreationTime}
                            />
                        </div>
                    </Col>
                </Row>
            </div>

            <div className={cx('button-group')}>
                <Button onClick={handleNewChat}>New Chat</Button>
            </div>
        </div>
    );
};

export default StudyBot;
