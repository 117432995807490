import React, { useEffect } from 'react';

import { Authentication } from 'components/Authentication';
import { useAuth } from 'components/AuthContextProvider';
import { useHistory } from 'react-router';

const Login: React.FC = () => {
    const history = useHistory();
    const { isLoggedIn } = useAuth();

    useEffect(() => {
        if (isLoggedIn) {
            history.push('/');
        }
    }, []);
    return (
        <div>
            <Authentication />
        </div>
    );
};

export default Login;
