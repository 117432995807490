import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { message } from 'antd';
import { datadogRum } from '@datadog/browser-rum';

import LandingView from './LandingView';
import TopicSelectorView from './TopicSelectorView';
import LeaderboardView from './LeaderboardView';
import PracticeMistakesView from './PracticeMistakesView';
import GameView from './GameView';
import ResultView from './ResultView';
import { TCSSubject } from 'common/types';
import { POSTQuizChallengeStartPayload, QuizChallengeQuestion, RecommendedContent } from './types';
import { defaultQuizChallengeQuestion, defaultRecommendedContent } from './constants';
import { scrollToTop, sendCustomFbEvent, sendTikTokEvent } from 'common/utils';

import styles from './QuizChallenge.module.scss';
const cx = classnames.bind(styles);

type QuizChallengeProps = {
    startGame: (topicIdList: number[], subjectId: number) => Promise<POSTQuizChallengeStartPayload>;
    isLoading: boolean;
    userUuid: string;
    onResetGame: () => void;
};

const QuizChallenge: React.FC<QuizChallengeProps> = ({ startGame, userUuid, onResetGame }) => {
    const [selectedSubject, setSelectedSubject] = useState<TCSSubject | null>(null);
    const [quizChallengeCreatedAt, setQuizChallengeCreatedAt] = useState(0);
    const [currentQuiz, setCurrentQuiz] = useState<QuizChallengeQuestion>(
        defaultQuizChallengeQuestion
    );

    const [score, setScore] = useState(0);
    const [totalSkips, setTotalSkips] = useState(0);

    const [recommendedContent, setRecommendedContent] =
        useState<RecommendedContent>(defaultRecommendedContent);
    const [lastSelectedQuizAnswerId, setLastSelectedQuizAnswerId] = useState(0);
    const [explanationNotes, setExplanationNotes] = useState('');
    const [correctAnswerId, setCorrectAnswerId] = useState(0);

    const [showResult, setShowResult] = useState(false);
    const [showLeaderboard, setShowLeaderboard] = useState(false);
    const [showPracticeMistakes, setShowPracticeMistakes] = useState(false);

    const handleSelectSubject = (subject: TCSSubject) => {
        setSelectedSubject(subject);
    };

    const initializeGame = async (topicIdList: number[]) => {
        try {
            const res = await startGame(topicIdList, selectedSubject?.id || 1); // fallback to HL Economics (sub id 1)

            setQuizChallengeCreatedAt(res.createdAt);
            setCurrentQuiz(res.quiz);
        } catch {
            message.error('Something went wrong. Please try again later');
        }
    };

    const onSubmitCorrectAnswerSuccess = (nextQuiz: QuizChallengeQuestion) => {
        setScore((prevState) => prevState + 1);
        setCurrentQuiz(nextQuiz);
    };

    const onSkipSuccess = (nextQuiz: QuizChallengeQuestion) => {
        setTotalSkips((prevState) => prevState + 1);
        setCurrentQuiz(nextQuiz);
    };

    const handleEndGame = async (
        recommendedContent: RecommendedContent,
        lastSelectedQuizAnswerId: number,
        explanationNotes: string,
        correctAnswerId: number
    ) => {
        datadogRum.addAction('quizChallengeCompleted');
        sendTikTokEvent('QuizChallengeCompleted');
        sendCustomFbEvent('QuizChallengeCompleted');

        setRecommendedContent(recommendedContent);
        setLastSelectedQuizAnswerId(lastSelectedQuizAnswerId);
        setExplanationNotes(explanationNotes);
        setCorrectAnswerId(correctAnswerId);
        setShowResult(true);
    };

    const resetGame = () => {
        setSelectedSubject(null);
        setQuizChallengeCreatedAt(0);
        setShowResult(false);
        setTotalSkips(0);
        setScore(0);
        setLastSelectedQuizAnswerId(0);
        setCorrectAnswerId(0);

        onResetGame();
    };

    const handleGoBack = () => {
        setSelectedSubject(null);
    };

    const handlePracticeMistakesGoBack = () => {
        scrollToTop();
        setShowPracticeMistakes(false);
    };

    const renderContent = () => {
        if (showResult) {
            return (
                <ResultView
                    userUuid={userUuid}
                    score={score}
                    totalSkips={totalSkips}
                    resetGame={resetGame}
                    recommendedContent={recommendedContent}
                    currentQuiz={currentQuiz}
                    lastSelectedQuizAnswerId={lastSelectedQuizAnswerId}
                    explanationNotes={explanationNotes}
                    correctAnswerId={correctAnswerId}
                />
            );
        }

        if (quizChallengeCreatedAt && selectedSubject) {
            return (
                <GameView
                    userUuid={userUuid}
                    currentQuiz={currentQuiz}
                    score={score}
                    totalSkips={totalSkips}
                    onEnd={handleEndGame}
                    quizChallengeCreatedAt={quizChallengeCreatedAt}
                    onSubmitCorrectAnswerSuccess={onSubmitCorrectAnswerSuccess}
                    onSkipSuccess={onSkipSuccess}
                />
            );
        }

        if (showPracticeMistakes) {
            return <PracticeMistakesView handleGoBack={handlePracticeMistakesGoBack} />;
        }

        if (selectedSubject && showLeaderboard) {
            return <LeaderboardView handleGoBack={() => setShowLeaderboard(false)} />;
        }

        if (selectedSubject) {
            return (
                <TopicSelectorView
                    userUuid={userUuid}
                    subject={selectedSubject}
                    handleGoBack={handleGoBack}
                    handleStartGame={initializeGame}
                    handleShowLeaderboard={() => setShowLeaderboard(true)}
                    handleShowPracticeMistakes={() => setShowPracticeMistakes(true)}
                />
            );
        }

        return <LandingView handleSelectSubject={handleSelectSubject} />;
    };

    return <div className={cx('container')}>{renderContent()}</div>;
};

export default QuizChallenge;
