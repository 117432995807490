import React from 'react';
import classNames from 'classnames/bind';
import { CardElement } from '@stripe/react-stripe-js';
import { Modal, Spin, Button } from 'antd';

import TypographyHeader from 'components/TypographyHeader';
import TypographySubtitle from 'components/TypographySubtitle';
import TypographyBody from 'components/TypographyBody';

import PromoCodeInput from './PromoCodeInput';
import { EPromoCodeInput, EPromoCodeValidityDuration } from './types';
import { deriveAmountOff, deriveGrandTotal } from './utils';

import styles from './CreditCardModalV2.module.scss';

const cx = classNames.bind(styles);

type CreditCardModalV2Props = {
    isLoading: boolean;
    isVisible: boolean;
    promoCodeValidityDuration: EPromoCodeValidityDuration;
    promoCodeStatus: EPromoCodeInput;
    amountOff: number;
    price: number;
    handleOnCancel: () => void;
    resetStatus: () => void;
    validatePromoCode: (code: string) => Promise<void>;
    handleSubmitSubscription: () => Promise<void>;
};

const CreditCardModalV2: React.FC<CreditCardModalV2Props> = ({
    isLoading,
    isVisible,
    promoCodeValidityDuration,
    promoCodeStatus,
    amountOff,
    price,
    handleOnCancel,
    resetStatus,
    validatePromoCode,
    handleSubmitSubscription,
}) => {
    return (
        <Modal
            visible={isVisible}
            destroyOnClose
            footer={null}
            onCancel={handleOnCancel}
            closable={false}
            width="850px"
        >
            <div className={cx('container')}>
                {isLoading && (
                    <div className={cx('loading-overlay')}>
                        <Spin size="large" />
                    </div>
                )}

                <div className={cx('header-container')}>
                    <TypographyHeader level={3}>Checkout</TypographyHeader>
                </div>

                <div className={cx('content-container')}>
                    <div className={cx('left-container')}>
                        <div className={cx('credit-card-container')}>
                            <TypographySubtitle className={cx('credit-card-subtitle')} level={1}>
                                Credit card details
                            </TypographySubtitle>

                            <CardElement className={cx('stripe-card-element')} />
                        </div>

                        <div className={cx('promo-code-container')}>
                            <TypographySubtitle className={cx('promo-code-subtitle')} level={1}>
                                Promo Code
                            </TypographySubtitle>

                            <PromoCodeInput
                                promoCodeValidityDuration={promoCodeValidityDuration}
                                validationStatus={promoCodeStatus}
                                validatePromoCode={validatePromoCode}
                                resetStatus={resetStatus}
                            />
                        </div>
                    </div>

                    <div className={cx('right-container')}>
                        <div className={cx('payment-summary-container')}>
                            <TypographySubtitle
                                className={cx('payment-summary-subtitle')}
                                level={1}
                            >
                                Payment Summary
                            </TypographySubtitle>

                            <div className={cx('payment-summary-details-container')}>
                                <div className={cx('payment-summary-detail', 'monthly-sub')}>
                                    <TypographyBody level={1}>Monthly subscription</TypographyBody>
                                    <span>USD ${price}.00</span>
                                </div>

                                <div className={cx('payment-summary-detail', 'discount')}>
                                    <TypographyBody level={1}>Discount</TypographyBody>
                                    <span>USD ${deriveAmountOff(amountOff)}</span>
                                </div>

                                <div className={cx('payment-summary-detail', 'grand-total')}>
                                    <TypographyBody level={1}>Grand Total</TypographyBody>
                                    <span>USD ${deriveGrandTotal(amountOff, price)}</span>
                                </div>

                                {promoCodeValidityDuration === EPromoCodeValidityDuration.ONCE && (
                                    <div className={cx('payment-grand-total-disclaimer')}>
                                        You will be charged the regular subscription price of USD $
                                        {price}
                                        .00/month in subsequent months
                                    </div>
                                )}
                            </div>

                            <Button
                                onClick={handleSubmitSubscription}
                                type="primary"
                                block
                                disabled={isLoading}
                            >
                                Confirm Payment
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CreditCardModalV2;
