export enum EAskAQuestionMessageSender {
    CLIENT = 'CLIENT',
    ADMIN = 'ADMIN',
}

export type GETThreadMessages = {
    message: string;
    createdAt: number;
    sender: EAskAQuestionMessageSender;
};
