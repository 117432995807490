import React from 'react';
import classnames from 'classnames/bind';

import TCSButton from 'components/TCSButton';
import LeaderboardTable from './LeaderboardTable';

import styles from './LeaderboardView.module.scss';

const cx = classnames.bind(styles);

type LeaderboardViewProps = {
    handleGoBack: () => void;
};

const LeaderboardView: React.FC<LeaderboardViewProps> = ({ handleGoBack }) => {
    return (
        <div className={cx('container')}>
            <div className={cx('content-container')}>
                <h3 className={cx('title')}>Leaderboard</h3>

                <LeaderboardTable />

                <div className={cx('button-container')}>
                    <TCSButton onClick={handleGoBack}>Back to Topic Selection</TCSButton>
                </div>
            </div>
        </div>
    );
};

export default LeaderboardView;
