import React from 'react';
import { Row, Col, Spin, Button } from 'antd';
import classnames from 'classnames/bind';
import { useHistory } from 'react-router-dom';

import TypographyBody from 'components/TypographyBody';
import InfoBox from 'components/InfoBox';

import { ESubscriptionPlanType } from 'common/types';
import { EMySubscriptionStatus } from './types';
import { deriveSubscriptionPlanName, displaySubscriptionDate } from '../utils';

import CancelSubscription from './CancelSubscription';
import ReinstateSubscription from './ReinstateSubscription';
import UpdatePaymentMethod from './UpdatePaymentMethod';

import styles from './MySubscription.module.scss';

const cx = classnames.bind(styles);

type MySubscriptionProps = {
    isLoading: boolean;
    hasError: boolean;
    cancelAtPeriodEnd: boolean;
    subscriptionEnd: number;
    freeTrialEnd: number;
    lastInvoiceURL: string;
    defaultPaymentMethod: string;
    subscriptionId: string;
    subscriptionStatus: EMySubscriptionStatus;
    subscriptionPlanType: ESubscriptionPlanType;
    refetchSubscription: () => void;
};

const MySubscription: React.FC<MySubscriptionProps> = ({
    isLoading,
    hasError,
    cancelAtPeriodEnd,
    subscriptionEnd,
    freeTrialEnd,
    lastInvoiceURL,
    defaultPaymentMethod,
    subscriptionId,
    subscriptionStatus,
    subscriptionPlanType,
    refetchSubscription,
}) => {
    const history = useHistory();

    const handleViewInvoice = () => {
        window.open(lastInvoiceURL, '_blank', 'noopener noreferrer');
    };

    const handleViewSubscriptions = () => {
        history.push('/subscriptions');
    };

    if (isLoading) {
        return (
            <div className={cx('container')}>
                <Spin size="large" />
            </div>
        );
    }

    if (hasError) {
        return (
            <div className={cx('error-container')}>
                <TypographyBody className={cx('title')} level={1}>
                    Oops, an error has occurred when trying to retrieve your subscription
                </TypographyBody>

                <div className={cx('cta-container')}>
                    <Button
                        className={cx('cta')}
                        type="primary"
                        danger
                        onClick={() => refetchSubscription}
                    >
                        Retry
                    </Button>
                </div>
            </div>
        );
    }

    if (subscriptionStatus === EMySubscriptionStatus.NONE) {
        return (
            <div className={cx('no-active-container')}>
                <TypographyBody className={cx('title')} level={1}>
                    You do not have an active subscription.
                </TypographyBody>

                <div className={cx('cta-container')}>
                    <Button
                        className={cx('cta', 'long')}
                        type="primary"
                        onClick={handleViewSubscriptions}
                    >
                        View Subscription Options
                    </Button>
                </div>
            </div>
        );
    }

    if (subscriptionStatus === EMySubscriptionStatus.FREETRIAL) {
        return (
            <div className={cx('container')}>
                <Row gutter={[10, 16]}>
                    <Col span={6}>Subscription Plan:</Col>
                    <Col span={18}>Free Trial</Col>

                    <Col span={6}>Expiry Date:</Col>
                    <Col span={18}>{displaySubscriptionDate(freeTrialEnd)}</Col>
                </Row>
            </div>
        );
    }

    return (
        <div className={cx('container')}>
            <div className={cx('manage-subscription-container')}>
                {cancelAtPeriodEnd ? (
                    <ReinstateSubscription
                        subscriptionId={subscriptionId}
                        refetchSubscription={refetchSubscription}
                    />
                ) : (
                    <CancelSubscription
                        subscriptionId={subscriptionId}
                        refetchSubscription={refetchSubscription}
                    />
                )}
            </div>

            <Row gutter={[10, 16]} className={cx('subscription-info')}>
                <Col span={6}>Subscription Plan:</Col>
                <Col span={18}>{deriveSubscriptionPlanName(subscriptionPlanType)}</Col>

                <Col span={6}>Payment Method:</Col>
                <Col span={18}>
                    {defaultPaymentMethod
                        ? `**** **** **** ${defaultPaymentMethod}`
                        : 'No default payment method'}
                </Col>

                <Col span={6}>Last Payment:</Col>
                <Col span={18} className={cx('last-payment-col')}>
                    {/** TODO: cant get this date with current endpoint */}
                    {/* <span>06 Aug 2021</span> */}

                    <span className={cx('view-invoice')} onClick={handleViewInvoice}>
                        View Invoice
                    </span>
                </Col>

                <Col span={6}>Next Payment Due:</Col>
                <Col span={18}>
                    <span>{displaySubscriptionDate(subscriptionEnd)} </span>

                    {/** TODO: decide on "Expiring On" copy & style */}
                    <span>{cancelAtPeriodEnd ? '(Expiring On)' : '(Auto Renew)'}</span>
                </Col>
            </Row>

            {subscriptionPlanType === ESubscriptionPlanType.SINGLE && (
                <InfoBox>
                    To switch to the &quot;Friends & Family&quot; plan, kindly cancel your current
                    subscription. You may then subscribe to the &quot;Friends & Family&quot; plan
                    after your current subscription expires.
                </InfoBox>
            )}

            {defaultPaymentMethod && (
                <UpdatePaymentMethod refetchSubscription={refetchSubscription} />
            )}
        </div>
    );
};

export default MySubscription;
