export type TCSSubject = {
    id: number;
    name: string;
};

export type TCSTopic = {
    id: number;
    name: string;
};

export type TCSFileTypeShape = {
    id: number;
    name: string;
};

export type TCSSubjectTopicMap = {
    [subjectID: number]: TCSTopic[];
};

// the response from /private/v1/files/subjects
export type GETFilesSubjectsPayload = {
    id: number;
    name: string;
    topics: TCSTopic[];
};

export type TCSFileShape = {
    key: string;
    original_file_name: string;
    subject_id: number;
    topic_id: number;
    file_type_id: number;
    url: string;
};

export type DatadogRumData = {
    fileName: string;
    subjectId: number;
    topicId: number;
};

export enum ESubscriptionPlanType {
    FAMILY_OWNER = 'family_owner',
    FAMILY_MEMBER = 'family_member',
    SINGLE = 'single',
    NONE = 'none',
    ANNUAL = 'annual',
}

// follows the constants defined in client-service
// src/domain/payment/payment_dto.go#L11-L14
export enum EProductType {
    'INDIVIDUAL' = 'individual',
    'FAMILY' = 'family',
}
