import linkify from 'linkify-it';

export const hyperlinkPotentialLinks = (input: string): string => {
    const linkifyInstance = linkify({}, { fuzzyIP: false, fuzzyEmail: false });

    if (!linkifyInstance.test(input)) {
        return input;
    }

    const links = linkifyInstance.match(input) || [];
    let output = input;
    links.forEach((link) => {
        const { url, raw } = link;
        output = output.replace(raw, `<a href="${url}" target="_blank">${raw}</a>`);
    });
    return output;
};
