import { TCSFileShape, TCSFileTypeShape } from 'common/types';

export type SelectedFileRecord = Record<number, boolean>;

export const deriveActiveFileTypes = (
    fileTypes: TCSFileTypeShape[],
    files: TCSFileShape[]
): TCSFileTypeShape[] => {
    const result: TCSFileTypeShape[] = [];
    const memo: Record<number, boolean> = {};

    files.forEach((f) => {
        const ft = f.file_type_id;

        if (memo[ft]) {
            return;
        }

        memo[ft] = true;
        const ftObj = fileTypes.find((ftp) => ftp.id === ft);
        if (ftObj) {
            result.push(ftObj);
        }
    });

    return result;
};

export const deriveFilteredFiles = (
    files: TCSFileShape[],
    selectedTCSFileTypeShape: SelectedFileRecord
): TCSFileShape[] => {
    if (Object.keys(selectedTCSFileTypeShape).length === 0) return files;

    const result: TCSFileShape[] = [];

    files.forEach((f) => {
        const ft = f.file_type_id;

        if (selectedTCSFileTypeShape[ft]) {
            result.push(f);
        }
    });

    return result;
};
