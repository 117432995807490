import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { message } from 'antd';

import { useQuizzes } from './hooks';

import TopicSelector from 'components/TopicSelector';
import SubjectSelector from 'components/SubjectSelector';
import QuizSetSelector from './QuizSetSelector';
import QuizSetViewer from './QuizSetViewer/';
import PastAttemptedQuizSetViewer from './PastAttemptedQuizSetViewer';
import { GETQuizSetAttemptedPayload, PastAttemptedQuizSetParams } from './types';

import styles from './Quizzes.module.scss';

const cx = classnames.bind(styles);

const Quizzes: React.FC = () => {
    const { payloads, activeContent, setters } = useQuizzes();
    const { activeTopic, activeSubject } = activeContent;
    const { subjects, topics, quizSetIdQuery } = payloads;
    const { setActiveTopic, setActiveSubject, resetQuizIdQuery } = setters;

    const [refetchCounter, setRefetchCounter] = useState(0);
    const [selectedQuizSetId, setSelectedQuizSetId] = useState(0);
    const [selectedPastAttemptedQuizSetParams, setSelectedPastAttemptedQuizSetParams] =
        useState<PastAttemptedQuizSetParams | null>(null);

    const handleQuizSetCardClick = async (quizSetId: number) => {
        setSelectedQuizSetId(quizSetId);
    };

    const handleReattemptQuizSet = (quizSetId: number) => {
        setSelectedQuizSetId(quizSetId);
        setSelectedPastAttemptedQuizSetParams(null);
    };

    const handlePastAttemptedQuizSetCardClick = (quizSet: GETQuizSetAttemptedPayload) => {
        if (!activeSubject || !activeTopic) {
            message.error(
                'Something went wrong while loading the quiz set. Please refresh and try again.'
            );
            return;
        }

        setSelectedPastAttemptedQuizSetParams({
            quizSetId: quizSet.quizSetId,
            subjectId: activeSubject.id,
            topicId: activeTopic.id,
            attemptedDate: quizSet.attemptedDate,
        });
    };

    const handleRefetch = () => {
        setRefetchCounter((prevState) => prevState + 1);
    };

    const handleRedirectToPastAttemptedResult = (submittedDate: number) => {
        if (!activeSubject || !activeTopic) {
            message.error('Something went wrong while redirecting. Please refresh and try again.');
            return;
        }

        setSelectedPastAttemptedQuizSetParams({
            quizSetId: selectedQuizSetId,
            subjectId: activeSubject.id,
            topicId: activeTopic.id,
            attemptedDate: submittedDate,
        });

        setSelectedQuizSetId(0);
    };

    const handleRender = () => {
        if (selectedPastAttemptedQuizSetParams !== null) {
            return (
                <PastAttemptedQuizSetViewer
                    handleGoBack={() => setSelectedPastAttemptedQuizSetParams(null)}
                    handleReattemptQuizSet={handleReattemptQuizSet}
                    pastAttemptedQuizSetParams={selectedPastAttemptedQuizSetParams}
                />
            );
        }

        if (selectedQuizSetId !== 0) {
            return (
                <QuizSetViewer
                    handleGoBack={() => setSelectedQuizSetId(0)}
                    quizSetId={selectedQuizSetId}
                    handleRefetch={handleRefetch}
                    handleRedirectToPastAttemptedResult={handleRedirectToPastAttemptedResult}
                />
            );
        }

        if (activeTopic !== null && activeSubject !== null) {
            return (
                <QuizSetSelector
                    title={activeTopic.name}
                    topicId={activeTopic.id}
                    subjectId={activeSubject.id}
                    quizSetId={quizSetIdQuery}
                    refetchCounter={refetchCounter}
                    resetQuizIdQuery={resetQuizIdQuery}
                    handleGoBack={() => setActiveTopic(null)}
                    handleQuizSetCardClick={handleQuizSetCardClick}
                    handlePastAttemptedQuizSetCardClick={handlePastAttemptedQuizSetCardClick}
                />
            );
        }

        if (activeSubject !== null) {
            return (
                <TopicSelector
                    title={`Subject: ${activeSubject.name}`}
                    subject={activeSubject}
                    topics={topics}
                    selectTopic={setActiveTopic}
                    handleGoBack={() => setActiveSubject(null)}
                />
            );
        }

        return (
            <SubjectSelector title="Quizzes" subjects={subjects} selectSubject={setActiveSubject} />
        );
    };

    return <div className={cx('container')}>{handleRender()}</div>;
};

export default Quizzes;
