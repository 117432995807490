import React, { useMemo } from 'react';
import * as sentry from '@sentry/react';

import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { useAxiosInstance } from 'components/AxiosContext';
import { getUserProfile } from 'components/ReducerContext/selectors';
import { useAuth } from 'components/AuthContextProvider';

import PreviewLessonCard from './PreviewLessonCard';
import { PreviewRecordedLesson } from '../../types';

type PreviewLessonCardContainerProps = {
    lesson: PreviewRecordedLesson;
    isPurchased: boolean;
};

const PreviewLessonCardContainer: React.FC<PreviewLessonCardContainerProps> = ({
    lesson,
    isPurchased,
}) => {
    const { state } = useReducerContext();
    const profile = useMemo(() => getUserProfile(state), [state]);

    const axios = useAxiosInstance();
    const { userAttr } = useAuth();

    const recordPreviewLessonClick = async () => {
        const payload = {
            authId: profile.cognitoId,
            fullName: `${profile.firstName} ${profile.lastName}`,
            email: userAttr.email,
            recordedLessonId: lesson.id,
        };

        try {
            await axios.put('/private/v2/gd/preview-lesson', payload);
        } catch {
            sentry.captureException('recordPreviewLessonClickFailed', {
                contexts: {
                    data: payload,
                },
            });
        }
    };

    return (
        <PreviewLessonCard
            lesson={lesson}
            isPurchased={isPurchased}
            recordPreviewLessonClick={recordPreviewLessonClick}
        />
    );
};

export default PreviewLessonCardContainer;
