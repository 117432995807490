import React, { useMemo } from 'react';
import { Image } from 'antd';
import classnames from 'classnames/bind';

import { TCSSubject } from 'common/types';

import SubjectAssetMap from './SubjectAssetsMaps';
import TypographyHeader from 'components/TypographyHeader';
import TypographySubtitle from 'components/TypographySubtitle';

import styles from './SubjectSelector.module.scss';

const cx = classnames.bind(styles);

type SubjectSelectorProps = {
    title: string;
    subjects: TCSSubject[];
    selectSubject: (s: TCSSubject) => void;
};

const deriveDisplayedSubjectName = (subjectName: string) => {
    const prefixToHighlight = 'HL';
    if (subjectName.includes(prefixToHighlight)) {
        return (
            <div>
                <span className={cx('highlight')}>{prefixToHighlight}</span>{' '}
                {subjectName.replace(prefixToHighlight, '')}
            </div>
        );
    }
    return <span>{subjectName}</span>;
};

const splitEconsFromOtherSubjects = (
    subjects: TCSSubject[]
): { econs: TCSSubject[]; others: TCSSubject[] } => {
    const result = {
        econs: [] as TCSSubject[],
        others: [] as TCSSubject[],
    };

    subjects.forEach((subj) => {
        if (subj.name === 'HL Economics' || subj.name === 'SL Economics') {
            result.econs.push(subj);
        } else {
            result.others.push(subj);
        }
    });

    return result;
};

const SubjectSelector: React.FC<SubjectSelectorProps> = ({ title, subjects, selectSubject }) => {
    const subjectSplit = useMemo(() => splitEconsFromOtherSubjects(subjects), [subjects]);

    const handleSelectSubject = (subject: TCSSubject) => {
        selectSubject(subject);
    };

    return (
        <div className={cx('container')}>
            <TypographyHeader level={3} className={cx('header')}>
                {title}
            </TypographyHeader>

            <div className={cx('core-content')}>
                {subjectSplit.econs.map((sub) => (
                    <div
                        key={sub.id}
                        className={cx('subject')}
                        onClick={() => handleSelectSubject(sub)}
                        data-testid="subject-selector-self-study-title"
                        data-cy={`subject-selector__${sub.name}`}
                    >
                        <Image
                            width={600}
                            height={400}
                            src={SubjectAssetMap[sub.name]}
                            preview={false}
                        />
                        <TypographySubtitle level={1} className={cx('subtitle')}>
                            {deriveDisplayedSubjectName(sub.name)}
                        </TypographySubtitle>
                    </div>
                ))}
            </div>

            <div className={cx('other-content')}>
                {subjectSplit.others.map((sub) => (
                    <div
                        key={sub.id}
                        className={cx('subject')}
                        onClick={() => handleSelectSubject(sub)}
                        data-testid="subject-selector-self-study-title"
                        data-cy={`subject-selector__${sub.name}`}
                    >
                        <Image
                            width={240}
                            height={160}
                            src={SubjectAssetMap[sub.name]}
                            preview={false}
                        />
                        <TypographySubtitle level={1} className={cx('subtitle')}>
                            {deriveDisplayedSubjectName(sub.name)}
                        </TypographySubtitle>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SubjectSelector;
