import React, { useCallback, useState } from 'react';

import { useAxiosInstance } from 'components/AxiosContext';

import CancelSubscription from './CancelSubscription';

type CancelSubscriptionContainerProps = {
    subscriptionId: string;
    refetchSubscription: () => void;
};

const CancelSubscriptionContainer: React.FC<CancelSubscriptionContainerProps> = ({
    subscriptionId,
    refetchSubscription,
}) => {
    const axios = useAxiosInstance();

    const [isLoading, setIsLoading] = useState(false);

    const handleCancelSubscription = useCallback(async () => {
        try {
            setIsLoading(true);
            await axios.post('/private/v2/payments/plans/cancel', {
                subscriptionId,
            });
        } catch {
            throw Error();
        } finally {
            setIsLoading(false);
        }
    }, [subscriptionId]);

    return (
        <CancelSubscription
            isLoading={isLoading}
            cancelSubscription={handleCancelSubscription}
            refetchSubscription={refetchSubscription}
        />
    );
};

export default CancelSubscriptionContainer;
