import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';
import { InstagramFilled, MailFilled } from '@ant-design/icons';

import styles from './ContactUsV2.module.scss';

const cx = classnames.bind(styles);

const ContactUsV2: React.FC = () => {
    return (
        <div className={cx('container')}>
            <div className={cx('content')}>
                <h1>Contact Us</h1>
                <div className={cx('text')}>
                    <p>
                        Got a question our <Link to="/faqs">FAQ</Link> Page doesn&apos;t answer?
                    </p>
                    <p>Want to request specific resources or provide feedback?</p>
                    <p>Feel free to hit us up via any of the following contact channels!</p>
                </div>
                <div className={cx('contact')}>
                    <a
                        href="http://instagram.com/thecloudscore"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <InstagramFilled />
                        thecloudscore
                    </a>
                    <a
                        href="mailto:help@thecloudscore.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <MailFilled />
                        help@thecloudscore.com
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ContactUsV2;
