import React from 'react';
import classnames from 'classnames/bind';

import styles from './Result.module.scss';
const cx = classnames.bind(styles);

type ResultProps = {
    score: number;
    totalSkips: number;
};

const Result: React.FC<ResultProps> = ({ score, totalSkips }) => {
    return (
        <div className={cx('container')}>
            <div>
                <div>Streaks: {score}</div>
                <div>Skips: {totalSkips}</div>
            </div>
        </div>
    );
};

export default Result;
