import NotesAndVideos from 'static/about-us/notes-and-videos.jpg';
import QuizChallenge from 'static/about-us/quiz-challenge.jpg';
import RecordedLessons from 'static/about-us/recorded-lessons.jpg';
import QuestionBank from 'static/about-us/question-bank.jpg';

export const features = [
    {
        title: 'Recorded Lessons: Learn & Relearn',
        img: RecordedLessons,
        description:
            'In our weekly lessons we tailor our curriculum to what you are most likely to be learning at that point of time. With a mix of content recap and practices, we teach you to focus on the most essential stuff that is needed for excellence in Economics and teach you replicable techniques that you can easily follow to do better in your tests.',
    },
    {
        title: 'Topical Question Bank & Model Essays',
        img: QuestionBank,
        description:
            'Every question comes with fully worked solutions (not just points), that means complete model essay with examples! Get exposed to a wide range of questions, arranged topically, to build proficiency one chapter at a time. All questions from Paper 1, 2 and 3,(HL only) are based on what you would see in the actual IB exams.',
    },
    {
        title: 'Condensed Notes & Short Videos',
        img: NotesAndVideos,
        description:
            'Forget textbooks. We cut your revision time by giving you condensed notes that summarises the essential content in every chapter, and short video explanations for those of you who prefer to have someone to explain stuff to you.',
    },
    {
        title: 'Quizzes & Quiz Challenge',
        img: QuizChallenge,
        description:
            'If you find flashcards boring, do Quizzes! Simple, multiple-choiced and self-marking, Quizzes are designed to test you on the most essential concepts that MUST KNOW to do any exam question at all. If you are lazy to pick a chapter, simply hop on to Quiz Challenge to get randomised questions and see how you place on our weekly leaderboards.',
    },
];
