import React from 'react';

import PurchasedLessons from './PurchasedLessons';
import { PurchasedRecordedLesson } from '../types';

type PurchasedLessonsContainer = {
    purchasedLessons: PurchasedRecordedLesson[];
    isLoading: boolean;
    onWatchVideoClick: (lesson: PurchasedRecordedLesson) => void;
};

const PurchasedLessonsContainer: React.FC<PurchasedLessonsContainer> = ({
    purchasedLessons,
    isLoading,
    onWatchVideoClick,
}) => {
    return (
        <PurchasedLessons
            purchasedLessons={purchasedLessons}
            isLoading={isLoading}
            onWatchVideoClick={onWatchVideoClick}
        />
    );
};

export default PurchasedLessonsContainer;
