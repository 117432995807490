import React, { useState } from 'react';
import { Modal, message } from 'antd';

import FreeTrialForm from './FreeTrialForm';
import FreeTrialSuccess from './FreeTrialSuccess';

type FreeTrialModalV2Props = {
    isLoading: boolean;
    isVisible: boolean;
    handleOnCancel: () => void;
    handleOnSuccess: () => Promise<void>;
    handleSubmitFreeTrial: (code: string) => Promise<void>;
};

const FreeTrialModalV2: React.FC<FreeTrialModalV2Props> = ({
    isLoading,
    isVisible,
    handleOnCancel,
    handleOnSuccess,
    handleSubmitFreeTrial,
}) => {
    const [code, setCode] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

    const onCancel = () => {
        if (isSuccess) return;
        handleOnCancel();
    };

    const handleOnClick = async () => {
        if (code === '') {
            message.error('Please provide a valid free trial code');
            return;
        }

        try {
            await handleSubmitFreeTrial(code);
            setIsSuccess(true);
            await handleOnSuccess();
        } catch {
            message.error('Oops! Failed to redeem the free trial code. Please try again');
        }
    };

    return (
        <Modal
            visible={isVisible}
            destroyOnClose
            footer={null}
            onCancel={onCancel}
            closable={false}
            width="500px"
        >
            {isSuccess ? (
                <FreeTrialSuccess />
            ) : (
                <FreeTrialForm
                    isLoading={isLoading}
                    code={code}
                    handleOnCancel={handleOnCancel}
                    setCode={setCode}
                    handleOnClick={handleOnClick}
                />
            )}
        </Modal>
    );
};

export default FreeTrialModalV2;
