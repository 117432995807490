import BoldOutlined from '@ant-design/icons-svg/inline-namespaced-svg/outlined/bold.svg';
import ItalicOutlined from '@ant-design/icons-svg/inline-namespaced-svg/outlined/italic.svg';
import UnderlineOutlined from '@ant-design/icons-svg/inline-namespaced-svg/outlined/underline.svg';
import FontSizeOutlined from '@ant-design/icons-svg/inline-namespaced-svg/outlined/font-size.svg';
import UnorderedListOutlined from '@ant-design/icons-svg/inline-namespaced-svg/outlined/unordered-list.svg';
import OrderedListOutlined from '@ant-design/icons-svg/inline-namespaced-svg/outlined/ordered-list.svg';

const toolbarOptions = {
    options: ['fontSize', 'inline', 'list', 'colorPicker', 'emoji'],
    fontSize: {
        icon: FontSizeOutlined,
        options: ['14', '16', '24'],
    },
    inline: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['bold', 'italic', 'underline'],
        bold: { icon: BoldOutlined, className: undefined },
        italic: { icon: ItalicOutlined, className: undefined },
        underline: { icon: UnderlineOutlined, className: undefined },
    },
    list: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['unordered', 'ordered'],
        unordered: { icon: UnorderedListOutlined, className: undefined },
        ordered: { icon: OrderedListOutlined, className: undefined },
    },
    colorPicker: {
        // icon: color,
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        options: ['text'],
        colors: [
            'rgb(7, 25, 61)',
            'rgb(255,255,255)',
            'rgb(204,204,204)',
            'rgb(0,0,0)',
            'rgb(26,188,156)',
            'rgb(44,130,201)',
            'rgb(184,49,47)',
            'rgb(147,101,184)',
            'rgb(71,85,119)',
            'rgb(0,168,133)',
            'rgb(61,142,185)',
            'rgb(41,105,176)',
            'rgb(85,57,130)',
            'rgb(40,50,78)',
            'rgb(235,107,86)',
            'rgb(226,80,65)',
            'rgb(163,143,132)',
            'rgb(250,197,28)',
            'rgb(243,121,52)',
            'rgb(209,72,65)',
        ],
    },
    emoji: {
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        emojis: [
            '💰',
            '🖊',
            '📅',
            '✅',
            '❎',
            '💯',
            '😀',
            '😁',
            '😂',
            '😃',
            '😉',
            '😋',
            '😎',
            '😍',
            '😗',
            '🤗',
            '🤔',
            '😣',
            '😫',
            '😴',
            '😌',
            '🤓',
            '😛',
            '😜',
            '😠',
            '😇',
            '😷',
            '😈',
            '👻',
            '😺',
            '😸',
            '😹',
            '😻',
            '😼',
            '😽',
            '🙀',
            '🙈',
            '🎉',
            '🎊',
            '🙉',
            '🙊',
            '👼',
            '👮',
            '🕵',
            '💂',
            '👳',
            '🎅',
            '👸',
            '👰',
            '👲',
            '🙍',
            '🙇',
            '🚶',
            '🏃',
            '💃',
            '⛷',
            '🏂',
            '🏌',
            '🏄',
            '🚣',
            '🏊',
            '⛹',
            '🏋',
            '🚴',
            '👫',
            '💪',
            '👈',
            '👉',
            '👉',
            '👆',
            '👇',
            '🖖',
            '🤘',
            '🖐',
            '👌',
            '👍',
            '👎',
            '👏',
            '🌍',
            '🚑',
            '⏰',
            '🌙',
            '🌝',
            '🌞',
            '⭐',
            '🌟',
            '🌠',
            '🌨',
            '🌩',
            '⛄',
            '🔥',
            '🎄',
            '🎈',
            '🎁',
            '🎗',
            '🎲',
            '🔇',
            '🔈',
            '📣',
            '🔔',
            '🎵',
            '🎷',
        ],
    },
};

export default toolbarOptions;
