import React, { useState } from 'react';
import classnames from 'classnames/bind';

import LandingView from './LandingView';
import QuizSetViewer from './QuizSetViewer';
import ResultViewer from './ResultViewer';
import { GETMistakesQuizPayload, QuizAnswer } from './QuizSetViewer/types';

import styles from './PracticeMistakesView.module.scss';
import { NAVIGATION_BAR_HEIGHT } from '../constants';

const cx = classnames.bind(styles);

type PracticeMistakesViewProps = {
    handleGoBack: () => void;
    quizzes: GETMistakesQuizPayload[];
    isLoading: boolean;
};

const PracticeMistakesView: React.FC<PracticeMistakesViewProps> = ({
    handleGoBack,
    quizzes,
    isLoading,
}) => {
    const [startPractice, setStartPractice] = useState(false);
    const [endPractice, setEndPractice] = useState(false);
    const [selectedAnswerList, setSelectedAnswerList] = useState<QuizAnswer[]>([]);

    const scrollToTop = () => {
        window.scrollTo({ top: NAVIGATION_BAR_HEIGHT });
    };

    const handleStartPractice = () => {
        scrollToTop();
        setStartPractice(true);
    };

    const onEndQuiz = () => {
        setEndPractice(true);
        setStartPractice(false);
    };

    const onSubmitAnswer = (selectedAnswer: QuizAnswer) => {
        scrollToTop();
        setSelectedAnswerList((prevState) => [...prevState, selectedAnswer]);
    };

    const renderContent = () => {
        if (endPractice) {
            return (
                <ResultViewer
                    quizzes={quizzes}
                    selectedAnswerList={selectedAnswerList}
                    handleGoBack={handleGoBack}
                />
            );
        }

        if (startPractice) {
            return (
                <QuizSetViewer
                    quizzes={quizzes}
                    onSubmitAnswer={onSubmitAnswer}
                    onEndQuiz={onEndQuiz}
                />
            );
        }

        return (
            <LandingView
                handleGoBack={handleGoBack}
                handleStartPractice={handleStartPractice}
                quizzes={quizzes}
                isLoading={isLoading}
            />
        );
    };

    return <div className={cx('container')}>{renderContent()}</div>;
};

export default PracticeMistakesView;
