import React from 'react';
import classnames from 'classnames/bind';
import { Button, message } from 'antd';

import TypographyHeader from 'components/TypographyHeader';
import QuizCard from 'components/QuizCard';
import { convertToUnixTimestamp } from 'common/utils';
import { ResultQuiz } from '../../types';
import { deriveQuizCardText } from './utils';

import styles from './Summary.module.scss';

const cx = classnames.bind(styles);

type SummaryProps = {
    quizzes: ResultQuiz[];
    handleGoBack: () => void;
    handleQuizCardClick: (id: number) => void;
    onSubmitSuccess: () => void;
    submitAttemptedQuizzes: (currentDate: number) => Promise<void>;
    isLoading: boolean;
    handleRedirectToPastAttemptedResult: (submittedDate: number) => void;
};

const Summary: React.FC<SummaryProps> = ({
    quizzes,
    handleGoBack,
    handleQuizCardClick,
    onSubmitSuccess,
    submitAttemptedQuizzes,
    isLoading,
    handleRedirectToPastAttemptedResult,
}) => {
    const handleSubmit = async () => {
        const currentDate = convertToUnixTimestamp(Date.now());

        try {
            await submitAttemptedQuizzes(currentDate);
            message.success('Quiz attempt submitted successfully!');
            handleRedirectToPastAttemptedResult(currentDate);
            onSubmitSuccess();
        } catch {
            message.error('Failed to submit the quiz. Please try again later.');
        }
    };

    return (
        <div className={cx('container')}>
            <TypographyHeader level={3} className={cx('title')}>
                Summary of Your Quiz
            </TypographyHeader>

            {quizzes.map((quiz, index) => (
                <QuizCard
                    key={index}
                    name={deriveQuizCardText(index + 1, quiz.questionText)}
                    isAttempted={quiz.selectedAnswerId !== 0}
                    onClick={() => handleQuizCardClick(index)}
                />
            ))}

            <div className={cx('buttons-footer')}>
                <Button onClick={handleGoBack} className={cx('back-button')}>
                    Go Back
                </Button>

                <Button
                    onClick={handleSubmit}
                    type="primary"
                    className={cx('submit-button')}
                    disabled={isLoading}
                >
                    Submit
                </Button>
            </div>
        </div>
    );
};

export default Summary;
