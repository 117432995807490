import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { message } from 'antd';
import { datadogRum } from '@datadog/browser-rum';

import { useAxiosInstance } from 'components/AxiosContext';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getFileTypes, getUserProfile } from 'components/ReducerContext/selectors';

import { TCSSubject, TCSTopic } from 'common/types';
import { DraftJSEditorStateNotes, GETTopicNotesPayload } from '../types';
import NotesListViewer from './NotesListViewer';
import { convertToEditorStateNotes, deriveActiveNotesTypes } from './utils';

type NotesListContainerProps = {
    handleGoBack: () => void;
    activeSubject: TCSSubject;
    activeTopic: TCSTopic;
};

const NotesListContainer: React.FC<NotesListContainerProps> = ({
    handleGoBack,
    activeSubject,
    activeTopic,
}) => {
    const axios = useAxiosInstance();

    const { state } = useReducerContext();
    const profile = useMemo(() => getUserProfile(state), [state]);

    const [isLoading, setIsLoading] = useState(false);

    const [notesList, setNotesList] = useState<DraftJSEditorStateNotes[]>([]);
    const [filteredNotesList, setFilteredNotesList] = useState<DraftJSEditorStateNotes[]>([]);

    const fileTypes = useMemo(() => getFileTypes(state), [state]);

    const activeNotesFileTypes = useMemo(
        () => deriveActiveNotesTypes(fileTypes, notesList),
        [notesList]
    );

    const filterNotes = (filterId: number) => {
        filterId === 0
            ? setFilteredNotesList(notesList)
            : setFilteredNotesList(notesList.filter((notes) => notes.fileTypeId === filterId));
    };

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);

            const getAllNotesUnderTopicUrl = `/private/v1/notes/topic`;

            const { data } = await axios.get<GETTopicNotesPayload[]>(getAllNotesUnderTopicUrl, {
                params: {
                    cognitoId: profile.cognitoId,
                    subjectId: activeSubject.id,
                    topicId: activeTopic.id,
                },
            });

            data.sort((a, b) => (a.lastUpdated > b.lastUpdated ? -1 : 1));

            const convertedNotes = convertToEditorStateNotes(data);

            setFilteredNotesList(convertedNotes);
            setNotesList(convertedNotes);
        } catch {
            message.error('Cannot get notes list. Please try again later!');
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();

        datadogRum.addAction('User view notes summary', {
            subjectId: activeSubject.id,
            topicId: activeTopic.id,
        });
    }, []);

    return (
        <NotesListViewer
            title={activeTopic.name}
            handleGoBack={handleGoBack}
            isLoading={isLoading}
            notesList={filteredNotesList}
            fileTypes={activeNotesFileTypes}
            filterNotes={filterNotes}
        />
    );
};

export default NotesListContainer;
