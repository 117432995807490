import React from 'react';
import { Button, Modal } from 'antd';
import classnames from 'classnames/bind';

import styles from './StudyBot.module.scss';

const cx = classnames.bind(styles);

type DisclaimerProps = {
    isVisible: boolean;
    dismissDisclaimer: () => void;
};

const Disclaimer: React.FC<DisclaimerProps> = ({ isVisible, dismissDisclaimer }) => {
    return (
        <Modal visible={isVisible} closable={false} footer={null}>
            <div className={cx('disclaimer')}>
                <div className={cx('disclaimer-text')}>
                    <div className={cx('header')}>Disclaimer:</div>
                    <div className={cx('subheader')}>
                        Hey there, welcome to using Cloudscore&apos;s Study Bot powered by ChatGPT
                        for your IB studies! Our bot has been instructed to gear its answers towards
                        helping you out with IB Economics. However, here are a few things you should
                        keep in mind:
                    </div>
                    <ol className={cx('ordered-list')}>
                        <li>
                            <span className={cx('li-title')}>Double-Check Everything:</span> While
                            our Study Bot can be a helpful study buddy, it&apos;s always a good idea
                            to cross-reference the information you get here. We&apos;re here to help
                            sift through the noise and do the heavy lifting, but not replace
                            official resources.
                        </li>

                        <li>
                            <span className={cx('li-title')}>Mix of Learning:</span> Consider the
                            Study Bot as your study buddy who&apos;s great at explaining things in a
                            friendly and concise way. But hey, don&apos;t forget to blend it with
                            your regular study routine and usage of Cloudscore&apos;s other
                            resources.
                        </li>

                        <li>
                            <span className={cx('li-title')}>Use Responsibly:</span> Let&apos;s keep
                            it real and responsible! ChatGPT is a nifty tool to enhance your
                            understanding of IB concepts, but use it wisely. Don&apos;t push it to
                            generate unethical content or misinformation. Respect the power of
                            technology and let&apos;s learn in a way that&apos;s cool and
                            responsible!
                        </li>
                    </ol>
                </div>

                <div className={cx('disclaimer-cta')}>
                    <Button type="primary" onClick={dismissDisclaimer}>
                        I Understand
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default Disclaimer;
