import React, { useMemo } from 'react';
import { List } from 'antd';
import classnames from 'classnames/bind';

import { TCSSubject, TCSSubjectTopicMap, TCSTopic } from 'common/types';
import FloatingToolbar from 'components/FloatingToolbar';
import GoBack from 'components/FloatingToolbar/Tooltips/GoBack';
import TypographyHeader from 'components/TypographyHeader';
import TypographySubtitle from 'components/TypographySubtitle';

import styles from './TopicSelector.module.scss';

const cx = classnames.bind(styles);

const getSelectedSubject = (subjectID: number, topics: TCSSubjectTopicMap) => {
    const t = topics[subjectID];
    if (!t) return [];

    return [...t].sort((a, b) => {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;

        return 0;
    });
};

type TopicSelectorProps = {
    title: string;
    subject: TCSSubject;
    topics: TCSSubjectTopicMap;
    selectTopic: (t: TCSTopic) => void;
    handleGoBack: () => void;
};

const TopicSelector: React.FC<TopicSelectorProps> = ({
    title,
    subject,
    topics,
    selectTopic,
    handleGoBack,
}) => {
    const selectedTopic = useMemo(() => {
        return getSelectedSubject(subject.id, topics);
    }, [subject]);

    const handleSelectTopic = (topic: TCSTopic) => {
        selectTopic(topic);
    };

    return (
        <div className={cx('container')}>
            <TypographyHeader level={3} className={cx('header')}>
                {title}
            </TypographyHeader>

            <div className={cx('list-container')}>
                <List
                    dataSource={selectedTopic}
                    rowKey={(record) => `${record.id}-${record.name}`}
                    renderItem={(record, index) => (
                        <List.Item
                            className={cx('list-item-container')}
                            onClick={() => {
                                handleSelectTopic(record);
                            }}
                            data-cy={`topic-selector__${record.name}`}
                        >
                            <TypographySubtitle level={1} className={cx('list-item-text')}>
                                {index + 1}. {record.name}
                            </TypographySubtitle>
                        </List.Item>
                    )}
                />
            </div>

            <FloatingToolbar tooltips={[<GoBack key="go-back" callback={handleGoBack} />]} />
        </div>
    );
};

export default TopicSelector;
