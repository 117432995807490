import React from 'react';
import classnames from 'classnames/bind';
import { Typography, Divider } from 'antd';
import { Link } from 'react-router-dom';

import styles from './FAQV2.module.scss';

const cx = classnames.bind(styles);

const FAQV2: React.FC = () => {
    return (
        <div className={cx('container')}>
            <div className={cx('header')}>
                <div className={cx('header-text')}>FAQs</div>

                <Typography.Text className={cx('subtitle')}>
                    Unable to find what you are looking for?
                </Typography.Text>

                <Typography.Text className={cx('subtitle')}>
                    Reach out to our support team <Link to="/contact-us">here</Link>.
                </Typography.Text>
            </div>

            <div className={cx('content')}>
                <div className={cx('faq-container')}>
                    <Typography.Text className={cx('section-header')}>
                        Getting Started
                    </Typography.Text>

                    <Typography.Text className={cx('question')}>
                        1. What is Cloudscore?
                    </Typography.Text>

                    <Typography.Text className={cx('answer')}>
                        Cloudscore replaces the need for tutoring to improve your grades. We give
                        you everything you get from a typical tutoring class for a fraction of the
                        cost. With weekly lessons, topical question banks, condensed notes & short
                        concept videos, we give you a deep library of study resources that would
                        help you get a 7. Currently Cloudscore offers a full suite of resources for
                        HL & SL Economics, and we are in the process of building out more content
                        for other subjects.
                    </Typography.Text>

                    <Typography.Text className={cx('question')}>
                        2. What&#39;s special about Cloudscore?
                    </Typography.Text>

                    <Typography.Text className={cx('answer')}>
                        We bring you better results at a fraction of what traditional private and
                        group tutoring would cost. In 2022, we had double the number of 6s and 7s as
                        compared to the global IB average for HL and SL Economics. Our lessons &
                        resources simply works, and you spend less time studying on Cloudscore than
                        on your own, or with a tutor.
                    </Typography.Text>

                    <Typography.Text className={cx('question')}>
                        3. Can I try Cloudscore for free?
                    </Typography.Text>

                    <Typography.Text className={cx('answer')}>
                        You can get a <strong>1-day free trial</strong> by simply keying the code{' '}
                        <strong>GETYOUR7</strong> and selecting the “Free Trial” option on our
                        subscriptions page.
                    </Typography.Text>

                    <Typography.Text className={cx('question')}>
                        4. Who creates Cloudscore&apos;s resources?
                    </Typography.Text>

                    <Typography.Text className={cx('answer')}>
                        Our content library is meticulously crafted by experienced IB teachers who
                        attended top universities in the US and UK and are elite scorers themselves
                        in high school.
                    </Typography.Text>

                    <Typography.Text className={cx('question')}>
                        5. How to sign up for Cloudscore?
                    </Typography.Text>

                    <Typography.Text className={cx('answer')}>
                        Get started with a 1-day free trial by simply keying the code{' '}
                        <strong>GETYOUR7</strong> and selecting the “Free Trial” option here.
                    </Typography.Text>

                    <Divider className={cx('divider')} />

                    <Typography.Text className={cx('section-header')}>
                        Payment & Subscription
                    </Typography.Text>

                    <Typography.Text className={cx('question')}>
                        1. When will I be billed?
                    </Typography.Text>

                    <Typography.Text className={cx('answer')}>
                        Like all subscription services, you will be billed at the start of the
                        subscription cycle. To check when your cycle ends, simply visit “My
                        Account”. The date indicated there is when the cycle ends, which means you
                        will be billed on the next day if you choose to retain your subscription.
                    </Typography.Text>

                    <Typography.Text className={cx('question')}>
                        2. Do I need to resubscribe every month?
                    </Typography.Text>

                    <Typography.Text className={cx('answer')}>
                        Nope! Renewal of subscription is automatically processed. But this also
                        means if you want to stop subscriptions for the next month, be sure to
                        cancel before the previous month is up.
                    </Typography.Text>

                    <Typography.Text className={cx('question')}>
                        3. How can I cancel my subscription?
                    </Typography.Text>

                    <Typography.Text className={cx('answer')}>
                        We totally get it if our resources aren&#39;t right for you, and we
                        won&#39;t be clingy. Feel free to cancel your subscription anytime by
                        heading to “My Account”, where you will find the option to cancel your
                        subscription. So sorry to see you go!
                    </Typography.Text>

                    <Typography.Text className={cx('question')}>
                        4. Can I cancel my subscription in the middle of the month?
                    </Typography.Text>

                    <Typography.Text className={cx('answer')}>
                        We charge monthly, or any part thereof. This means even if you cancel your
                        subscription mid-month (i.e. after a new month has begun), we won&#39;t be
                        able to refund you for that month. However, you will retain access to your
                        account for the rest of the month, and we will no longer automatically renew
                        your subscription after the month is up.
                    </Typography.Text>

                    <Typography.Text className={cx('question')}>
                        5. What subscription plan options are there?
                    </Typography.Text>

                    <Typography.Text className={cx('answer')}>
                        At the moment, we have monthly subscription plans for individuals at
                        USD$15/month and a friends & family plan for USD$20/month that can be shared
                        among 3 users.
                    </Typography.Text>

                    <Typography.Text className={cx('question')}>
                        6. Can I change the credit card being billed?
                    </Typography.Text>

                    <Typography.Text className={cx('answer')}>
                        Yes you are able to. Simply head over to “My Account” where you will find
                        the option to update your billing information.
                    </Typography.Text>

                    <Divider className={cx('divider')} />

                    <Typography.Text className={cx('section-header')}>My Account</Typography.Text>

                    <Typography.Text className={cx('question')}>
                        1. How can I change my password?
                    </Typography.Text>

                    <Typography.Text className={cx('answer')}>
                        Your password can be changed on the “My Account” page.
                    </Typography.Text>

                    <Typography.Text className={cx('question')}>
                        2. Can I share my account with someone else?
                    </Typography.Text>

                    <Typography.Text className={cx('answer')}>
                        You can, but be careful that you know who you&#39;re sharing it with,
                        especially since it contains your credit card information.
                    </Typography.Text>

                    <Divider className={cx('divider')} />

                    <Typography.Text className={cx('section-header')}>Resources</Typography.Text>

                    <Typography.Text className={cx('question')}>
                        1. I would like to request specific resources.
                    </Typography.Text>

                    <Typography.Text className={cx('answer')}>
                        Write in via any of the channels <Link to="/contact-us">here</Link> and let
                        us know what you need! We&#39;ll have our team start working on it ASAP!
                    </Typography.Text>

                    <Typography.Text className={cx('question')}>
                        2. Can I print/download the resources (e.g. mind maps, model essays, notes)?
                    </Typography.Text>

                    <Typography.Text className={cx('answer')}>Not right now ):</Typography.Text>

                    <Divider className={cx('divider', 'last')} />
                </div>
            </div>
        </div>
    );
};

export default FAQV2;
