import { PieConfig } from '@ant-design/plots';

export const data = [
    {
        type: 'Score 6 and above',
        value: 64,
        colorField: 'blue',
    },
    {
        type: 'Score 5 and below',
        value: 36,
        colorField: 'grey',
    },
];

export const config: PieConfig = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    color: ['#7399C6', '#CBCBCB'],
    radius: 1,
    innerRadius: 0.7,
    label: false,
    legend: false,
    statistic: {
        title: false,
        content: {
            style: {
                whiteSpace: 'pre-wrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '36px',
            },
            content: '64%',
        },
    },
    interactions: [{ type: 'tooltip', enable: false }],
};
