import React from 'react';
import classnames from 'classnames/bind';
import { InstagramFilled, MailFilled, RedditCircleFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';

import TypographyBody from 'components/TypographyBody';
import LogoWhiteWords from 'static/logo_white_words_800px.svg';

import styles from './FooterV2.module.scss';

const cx = classnames.bind(styles);
const { Footer: AntFooter } = Layout;

const FooterV2: React.FC = () => {
    return (
        <div className={cx('container')}>
            <AntFooter>
                <div className={cx('content-container')}>
                    <div className={cx('social-media-container')}>
                        <div className={cx('logo-container')}>
                            <Link to="/">
                                <img className={cx('logo')} src={LogoWhiteWords} />
                            </Link>
                        </div>

                        <div className={cx('icons-container')}>
                            <div className={cx('icon')}>
                                <a
                                    href="https://instagram.com/thecloudscore"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <InstagramFilled />
                                </a>
                            </div>
                            <div className={cx('icon')}>
                                <a
                                    href="https://reddit.com/r/thecloudscore"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <RedditCircleFilled />
                                </a>
                            </div>
                            <div className={cx('icon')}>
                                <a
                                    href="mailto:help@thecloudscore.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <MailFilled />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className={cx('links-container')}>
                        <div className={cx('column')}>
                            <h3>Resources</h3>
                            <Link to="/quiz-challenge">Quiz Challenge</Link>
                            <Link to="/faqs">FAQs</Link>
                            <Link to="/subscriptions/info">Subscriptions</Link>
                        </div>

                        <div className={cx('column')}>
                            <h3>Company</h3>
                            <Link to="/about-us">About Us</Link>
                            <Link to="/contact-us">Contact Us</Link>
                        </div>

                        <div className={cx('column')}>
                            <h3>Legal</h3>
                            <Link to="/terms-of-service">Terms of Service</Link>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                        </div>
                    </div>
                </div>

                <TypographyBody level={1} className={cx('copyright-notice')}>
                    © 2023 thecloudscore. All rights reserved.
                </TypographyBody>
            </AntFooter>
        </div>
    );
};

export default FooterV2;
