import { TCSSubject, TCSSubjectTopicMap, TCSTopic } from 'common/types';
import { GETFavouritesActivePayload } from './types';

export const deriveActiveSubjectAndTopic = (
    payload: GETFavouritesActivePayload[],
    subjects: TCSSubject[],
    topics: TCSSubjectTopicMap
): {
    activeSubs: TCSSubject[];
    activeTops: TCSSubjectTopicMap;
} => {
    const activeSubs: TCSSubject[] = [];
    const activeTops: TCSSubjectTopicMap = {};

    payload.forEach((fa) => {
        const currActiveSub = subjects.find((s) => s.id === fa.subjectId);
        if (!currActiveSub || fa.topics.length === 0) return;

        activeSubs.push(currActiveSub);

        fa.topics.forEach((t) => {
            const currActiveTop = topics[currActiveSub.id]?.find((tp) => tp.id === t);
            if (currActiveTop) {
                if (!activeTops[currActiveSub.id]) {
                    activeTops[currActiveSub.id] = [] as TCSTopic[];
                }

                activeTops[currActiveSub.id].push(currActiveTop);
            }
        });
    });

    return {
        activeSubs,
        activeTops,
    };
};
