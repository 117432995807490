import { useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';

import { useAxiosInstance } from 'components/AxiosContext';

import { GETQuestionSetDetailPayload, GETQuestionSetPayload } from '../QuestionSetSelector/types';
import { GETThisIsHardCurrentQuestion } from './types';

import { deriveThisIsHardStatus } from './utils';
import { EThisIsHardState } from '../types';

type UseQuestionSetHelper = {
    isFirstQuestion: boolean;
    isLastQuestion: boolean;
    currentQuestion: GETQuestionSetDetailPayload | null;
    totalQuestions: number;
    currentQuestionNumber: number;
    thisIsHardCreatedAt: string;

    thisIsHardStatus: EThisIsHardState;
    actions: {
        addThisIsHard: () => Promise<void>;
        removeThisIsHard: () => Promise<void>;
    };
};

export const useQuestionSetHelper = (
    questionSet: GETQuestionSetPayload,
    questionSetDetails: GETQuestionSetDetailPayload[],
    currentQuestionIndex: number
): UseQuestionSetHelper => {
    const axios = useAxiosInstance();

    const [thisIsHardCreatedAt, setThisIsHardCreatedAt] = useState('');
    const [isLoadingThisIsHard, setIsLoadingThisIsHard] = useState(false);

    const currentQuestionNumber = useMemo(() => currentQuestionIndex + 1, [currentQuestionIndex]);
    const totalQuestions = useMemo(() => questionSetDetails.length, [questionSetDetails]);

    const currentQuestion = useMemo(() => {
        if (
            questionSetDetails.length === 0 ||
            questionSetDetails[currentQuestionIndex] === undefined
        ) {
            return null;
        }

        return questionSetDetails[currentQuestionIndex];
    }, [questionSetDetails, currentQuestionIndex]);

    const isFirstQuestion = useMemo(() => currentQuestionNumber === 1, [currentQuestionNumber]);
    const isLastQuestion = useMemo(
        () => currentQuestionNumber === totalQuestions,
        [currentQuestionNumber, totalQuestions]
    );

    const thisIsHardStatus = useMemo(
        () => deriveThisIsHardStatus(isLoadingThisIsHard, thisIsHardCreatedAt),
        [isLoadingThisIsHard, thisIsHardCreatedAt]
    );

    useEffect(() => {
        fetchThisIsHardForQuiz();
    }, [currentQuestion]);

    const fetchThisIsHardForQuiz = async () => {
        if (!currentQuestion) {
            return;
        }

        try {
            const payload = {
                subjectId: questionSet.subjectId,
                topicId: questionSet.topicId,
                questionSetId: questionSet.id,
                questionId: currentQuestion.id,
            };

            const { data } = await axios.get<GETThisIsHardCurrentQuestion>(
                '/private/v2/this-is-hard/current-question',
                {
                    params: payload,
                }
            );

            setThisIsHardCreatedAt(data.createdAt);
        } catch (error) {
            setThisIsHardCreatedAt('');

            if ((error as AxiosError).response?.status === 404) {
                return;
            }

            const e = `There was an error with loading This Is Hard. Code: ${error.code}. Message: ${error.message}`;
            Sentry.captureException(e);
        }
    };

    const addThisIsHard = async () => {
        if (!currentQuestion) {
            return;
        }

        try {
            setIsLoadingThisIsHard(true);

            const payload = {
                subjectId: questionSet.subjectId,
                topicId: questionSet.topicId,
                questionSetId: questionSet.id,
                questionId: currentQuestion.id,
            };

            const { data } = await axios.post('/private/v2/this-is-hard/current-question', payload);

            setThisIsHardCreatedAt(data.createdAt);
        } finally {
            setIsLoadingThisIsHard(false);
        }
    };

    const removeThisIsHard = async () => {
        if (!currentQuestion) {
            return;
        }

        try {
            setIsLoadingThisIsHard(true);

            const payload = {
                subjectId: questionSet.subjectId,
                topicId: questionSet.topicId,
                questionSetId: questionSet.id,
                questionId: currentQuestion.id,
                createdAt: thisIsHardCreatedAt,
            };

            await axios.put('/private/v2/this-is-hard/current-question', payload);

            setThisIsHardCreatedAt('');
        } catch {
            throw Error();
        } finally {
            setIsLoadingThisIsHard(false);
        }
    };

    return {
        isFirstQuestion,
        isLastQuestion,
        currentQuestion,
        totalQuestions,
        currentQuestionNumber,
        thisIsHardCreatedAt,
        thisIsHardStatus,
        actions: {
            addThisIsHard,
            removeThisIsHard,
        },
    };
};
