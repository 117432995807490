import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import { Modal } from 'antd';
import { datadogRum } from '@datadog/browser-rum';

import ArcVideoPlayer from 'components/ArcVideoPlayer';
import { getUserProfile } from 'components/ReducerContext/selectors';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';

import styles from './ShortVideosPlayer.module.scss';

const cx = classnames.bind(styles);

type ShortVideosPlayerProps = {
    isVisible: boolean;
    videoTitle: string;
    videoUrl: string;
    onClose: () => void;
};

const ShortVideosPlayer: React.FC<ShortVideosPlayerProps> = ({
    isVisible,
    videoTitle,
    videoUrl,
    onClose,
}) => {
    const { state } = useReducerContext();
    const profile = useMemo(() => getUserProfile(state), [state]);

    const handleOnStart = () => {
        // onStart is called once the video starts playing
        datadogRum.addAction('watchShortVideo', { videoTitle, authId: profile.cognitoId });
    };

    return (
        <Modal
            width="800px"
            maskClosable
            destroyOnClose
            visible={isVisible}
            closable={false}
            footer={null}
            onCancel={onClose}
        >
            <div className={cx('modal-container')}>
                <div className={cx('title')}>{videoTitle}</div>

                <div className={cx('video')}>
                    <ArcVideoPlayer
                        videoUrl={videoUrl}
                        videoWidth="100%"
                        videoHeight="100%"
                        onStart={handleOnStart}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ShortVideosPlayer;
