export const deriveCurrencySign = (currency: string): string => {
    currency = currency.toUpperCase();
    switch (currency) {
        case 'SGD':
            return 'S$';
        case 'USD':
        default:
            return '$';
    }
};

export const derivePriceInDollar = (priceInCent: number): string => {
    if (priceInCent <= 0) return `0`;

    if (priceInCent < 10) {
        return `0.0${priceInCent}`;
    }

    if (priceInCent < 100) {
        return `0.${priceInCent}`;
    }

    const numberArray = priceInCent.toString().split('');

    const centIndexPosition = -2;
    const cent = numberArray
        .splice(centIndexPosition, numberArray.length - centIndexPosition)
        .join('');

    const dollar = numberArray.join('');

    return `${dollar}.${cent}`;
};
