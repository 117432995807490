import React, { useEffect, useState } from 'react';
import { message } from 'antd';

import { useCachedAxiosInstance } from 'components/AxiosContext';

import { sortByAscending } from 'common/utils';
import { GETQuestionSetPayload, GETQuestionSetDetailPayload } from '../QuestionSetSelector/types';
import QuestionSetViewer from './QuestionSetViewer';

type QuestionSetContainerProps = {
    handleGoBack: () => void;
    questionSet: GETQuestionSetPayload;
};

const QuestionSetViewerContainer: React.FC<QuestionSetContainerProps> = ({
    handleGoBack,
    questionSet,
}) => {
    const cachedAxios = useCachedAxiosInstance();

    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [questionSetDetails, setQuestionSetDetails] = useState<GETQuestionSetDetailPayload[]>([]);

    const fetchQuestionSetWithDetails = async () => {
        try {
            setIsLoading(true);

            const { data } = await cachedAxios.get<GETQuestionSetDetailPayload[]>(
                `/private/v1/qna/question-set-details?questionSetId=${questionSet.id}`
            );

            setQuestionSetDetails(sortByAscending(data, 'name'));
        } catch {
            message.error('Something went wrong while retrieving details. Please try again');
        } finally {
            setIsLoading(false);
            setIsLoaded(true);
        }
    };

    useEffect(() => {
        fetchQuestionSetWithDetails();
    }, []);

    return (
        <QuestionSetViewer
            isLoaded={isLoaded}
            isLoading={isLoading}
            questionSet={questionSet}
            questionSetDetails={questionSetDetails}
            handleGoBack={handleGoBack}
        />
    );
};

export default QuestionSetViewerContainer;
