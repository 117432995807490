import React, { useMemo } from 'react';

import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';
import { EFreeTrialStatus } from 'components/ReducerContext/state';

import SubscriptionsV2 from './SubscriptionsV2';

const SubscriptionsV2Container: React.FC = () => {
    const { state } = useReducerContext();

    const user = useMemo(() => getUserProfile(state), [state]);
    const eligibleForFreeTrial = useMemo(() => {
        return user.subscriptionDetails?.freeTrialStatus === EFreeTrialStatus.NONE;
    }, [user]);

    return <SubscriptionsV2 eligibleForFreeTrial={eligibleForFreeTrial} />;
};

export default SubscriptionsV2Container;
