/**
 * protectedRoutes refer to routes that can only be accessed by users that are logged in
 */
const protectedRoutes = [
    '/self-study',
    '/my-account',
    '/subscriptions',
    '/my-liked-content',
    '/notes',
    '/question-bank',
    '/quizzes',
    '/recorded-lessons',
];

/**
 * subscribedRoutes refer to routes that can only be accessed by users that are subscribed
 */
const subscribedRoutes = [
    '/self-study',
    '/subscriptions',
    '/my-liked-content',
    '/notes',
    '/question-bank',
    '/quizzes',
];

export const isAccessingProtectedRoute = (path: string): boolean => {
    if (protectedRoutes.includes(path)) {
        return true;
    }

    return false;
};

export const isAccessingSubscriberRoute = (path: string): boolean => {
    if (subscribedRoutes.includes(path)) {
        return true;
    }

    return false;
};
