import React from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames/bind';
import { Pie } from '@ant-design/plots';

import { scrollToTop } from 'common/utils';
import CTAButton from 'components/CTAButton';
import { config } from './constants';

import styles from './Jumbotron.module.scss';

const cx = classnames.bind(styles);

const Jumbotron: React.FC = () => {
    const history = useHistory();
    const pushToSubInfo = () => {
        scrollToTop();
        history.push('/subscriptions/info');
    };

    return (
        <div className={cx('container')}>
            <div className={cx('pie-chart-container')}>
                <Pie {...config} />
                <div className={cx('text')}>of Cloudscore students get a 6 or 7 for Economics</div>
            </div>

            <div className={cx('content-container')}>
                <h1>Get a 7, Without Tuition</h1>

                <div className={cx('subtitle')}>
                    Private tutoring costs tens of thousands annually. We want to help you be
                    competitive against the best scoring students without breaking the bank.
                </div>

                <div className={cx('cta-container')}>
                    <CTAButton onClick={pushToSubInfo}>Get Started for Free</CTAButton>
                    <div className={cx('emphasis')}>No Credit Card Needed!</div>
                </div>
            </div>
        </div>
    );
};

export default Jumbotron;
