import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import { Button, Spin } from 'antd';
import { format } from 'date-fns';

import TypographyHeader from 'components/TypographyHeader';
import TypographyBody from 'components/TypographyBody';
import FloatingToolbar from 'components/FloatingToolbar';
import GoBack from 'components/FloatingToolbar/Tooltips/GoBack';
import GenericErrorMessage from 'components/GenericErrorMessage';
import { convertFromUnixTimestamp } from 'common/utils';
import PastAttemptedQuizCard from './PastAttemptedQuizCard';
import { GETQuizSetAttemptedPayload } from '../types';

import styles from './PastAttemptedQuizSetViewer.module.scss';

const cx = classnames.bind(styles);

type PastAttemptedQuizSetViewerProps = {
    isLoading: boolean;
    handleGoBack: () => void;
    pastAttemptedQuizSet: GETQuizSetAttemptedPayload | null;
    handleReattemptQuizSet: (quizSetId: number) => void;
};

const PastAttemptedQuizSetViewer: React.FC<PastAttemptedQuizSetViewerProps> = ({
    isLoading,
    pastAttemptedQuizSet,
    handleGoBack,
    handleReattemptQuizSet,
}) => {
    const formattedDate = useMemo(
        () =>
            format(
                convertFromUnixTimestamp(pastAttemptedQuizSet?.attemptedDate || 0),
                'dd/MM/yyyy'
            ),
        [pastAttemptedQuizSet]
    );

    const renderContent = () => {
        if (isLoading) {
            return (
                <div className={cx('spin-container')}>
                    <Spin size="large" />
                </div>
            );
        }

        if (!pastAttemptedQuizSet) {
            return <GenericErrorMessage />;
        }

        return (
            <div>
                <div className={cx('score-tag-container')}>
                    <div className={cx('score-tag')}>
                        Score:{' '}
                        {`${pastAttemptedQuizSet.quizSetScore}/${pastAttemptedQuizSet.quizSetLength}`}
                    </div>
                </div>
                <div className={cx('quiz-card-container')}>
                    {pastAttemptedQuizSet.quizzes.map((resultQuiz, index) => (
                        <div key={index}>
                            <PastAttemptedQuizCard
                                resultQuiz={resultQuiz}
                                index={index}
                                isAnsweredCorrectly={
                                    resultQuiz.selectedAnswerId === resultQuiz.correctAnswerId
                                }
                            />
                        </div>
                    ))}
                </div>

                <div className={cx('buttons-footer')}>
                    <Button
                        onClick={() => handleReattemptQuizSet(pastAttemptedQuizSet.quizSetId)}
                        className={cx('try-again-button')}
                    >
                        Try again
                    </Button>

                    <Button
                        onClick={handleGoBack}
                        type="primary"
                        className={cx('back-to-quizzes-button')}
                    >
                        Back To Quizzes
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <div className={cx('container')}>
            <div className={cx('content-container')}>
                <TypographyHeader className={cx('title')} level={3}>
                    {pastAttemptedQuizSet?.quizSetName}
                </TypographyHeader>

                <TypographyBody level={2} className={cx('subtitle')}>
                    Attempted on {formattedDate}
                </TypographyBody>

                {renderContent()}
            </div>

            <FloatingToolbar tooltips={[<GoBack key="go-back" callback={handleGoBack} />]} />
        </div>
    );
};

export default PastAttemptedQuizSetViewer;
