import React from 'react';
import classnames from 'classnames/bind';

import { SubscriptionPlanPerk } from 'components/SubscriptionBox/types';

import styles from './SubscriptionsV2.module.scss';

const cx = classnames.bind(styles);

export const deriveIndividualPerks = (): SubscriptionPlanPerk[] => [
    {
        isDisabled: false,
        content: <div>Access all resources for all subjects</div>,
    },
    {
        isDisabled: false,
        content: <div>No hidden costs, cancel anytime</div>,
    },
];

export const deriveFriendsAndFamilyPerks = (
    eligibleForFreeTrial: boolean
): SubscriptionPlanPerk[] => [
    {
        isDisabled: !eligibleForFreeTrial,
        content: (
            <div>
                Use code <span className={cx('highlight')}>18FOR7</span> to enjoy{' '}
                <span className={cx('strike')}>$20</span> $18/month subscription
            </div>
        ),
    },
    {
        isDisabled: false,
        content: (
            <div>
                You may invite up to <span style={{ fontWeight: 'bold' }}>2 users</span> to share
                the plan
            </div>
        ),
    },
    {
        isDisabled: false,
        content: <div>Access all resources for all subjects</div>,
    },
    {
        isDisabled: false,
        content: <div>No hidden costs, cancel anytime</div>,
    },
];

export const bulkPurchasePerks: SubscriptionPlanPerk[] = [
    {
        isDisabled: false,
        content: <div>Annual Subscriptions</div>,
    },
    {
        isDisabled: false,
        content: <div>Corporate Plans</div>,
    },
    {
        isDisabled: false,
        content: <div>Bulk User Deals</div>,
    },
];

export const trialPerks: SubscriptionPlanPerk[] = [
    {
        isDisabled: false,
        content: (
            <div>
                Simply use the code: <span className={cx('highlight')}>GETYOUR7</span>
            </div>
        ),
    },
    {
        isDisabled: false,
        content: <div>Access all resources for all subjects</div>,
    },
    {
        isDisabled: false,
        content: <div>No credit card is needed</div>,
    },
];
