import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { useAxiosInstance } from 'components/AxiosContext';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getSubjectTopicFileTypes } from 'components/ReducerContext/selectors';

import { TCSSubject, TCSTopic } from 'common/types';
import { sortByAscending } from 'common/utils';
import {
    parseQuestionBankQuery,
    validateQuestionBankQuery,
    deriveQuestionBankQueryString,
    deriveWhitelistedSubjects,
    queryIsSameAsRef,
} from './utils';
import { GETQuestionSetPayload } from './QuestionSetSelector/types';

// TODO: currently this is very similar to solutions in Self Study
// to evaluate in the future if we need to refactor if they're indeed similar in functions
export const useQuestionBank = () => {
    const history = useHistory();
    const location = useLocation();

    const cachedAxios = useAxiosInstance();
    const { state } = useReducerContext();

    const [query, setQuery] = useState(() => parseQuestionBankQuery(location.search));
    const [questionSets, setQuestionSets] = useState<GETQuestionSetPayload[]>([]);

    const [activeSubject, setActiveSubject] = useState<TCSSubject | null>(null);
    const [activeTopic, setActiveTopic] = useState<TCSTopic | null>(null);
    const [activeQuestionSet, setActiveQuestionSet] = useState<GETQuestionSetPayload | null>(null);

    const [initDone, setInitDone] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const resetActiveContent = useCallback(() => {
        setActiveSubject(null);
        setActiveTopic(null);
        setActiveQuestionSet(null);
        setQuestionSets([]);
    }, []);

    const { subjects, topics, fileTypes } = useMemo(() => getSubjectTopicFileTypes(state), [state]);
    const whitelistedSubjects = useMemo(() => deriveWhitelistedSubjects(subjects), [subjects]);

    const fetchQuestionSet = useCallback(async (topicId: number) => {
        let result: GETQuestionSetPayload[] = [];

        try {
            setIsLoading(true);
            const { data } = await cachedAxios.get<GETQuestionSetPayload[]>(
                `/private/v1/qna/question-sets?topicId=${topicId}`
            );

            setQuestionSets(sortByAscending(data, 'name'));
            result = data;
        } catch {
            setQuestionSets([]);
        } finally {
            setIsLoading(false);
        }

        return {
            data: result,
        };
    }, []);

    const syncLocationChange = useCallback(
        async (search: string) => {
            const newQuery = parseQuestionBankQuery(search);
            if (!queryIsSameAsRef(newQuery, query)) {
                setQuery(newQuery);

                if (newQuery === null) {
                    resetActiveContent();
                    return;
                }

                const foundSubject = subjects.find((s) => s.id === newQuery.subjectId);
                if (!foundSubject) {
                    resetActiveContent();
                    return;
                }

                setActiveSubject(foundSubject);
                const foundTopic = topics[foundSubject.id].find((t) => t.id === newQuery.topicId);

                if (!foundTopic) {
                    setActiveTopic(null);
                    setActiveQuestionSet(null);
                    return;
                }

                setActiveTopic(foundTopic);

                const { data } = await fetchQuestionSet(foundTopic.id);
                const foundQuestionSet = data.find(
                    (questionSet) => questionSet.id === newQuery.questionSetId
                );

                if (!foundQuestionSet) {
                    setActiveQuestionSet(null);
                    return;
                }

                setActiveQuestionSet(foundQuestionSet);
            }
        },
        [subjects, topics, query]
    );

    const initialiseQuestionBank = useCallback(async () => {
        try {
            if (query === null) return;

            const foundSubject = subjects.find((subject) => subject.id === query.subjectId);
            if (!foundSubject) return;

            setActiveSubject(foundSubject);

            const foundTopic = topics[foundSubject.id].find((topic) => topic.id === query.topicId);
            if (!foundTopic) return;

            setActiveTopic(foundTopic);

            const { data } = await fetchQuestionSet(foundTopic.id);
            const foundQuestionSet = data.find(
                (questionSet) => questionSet.id === query.questionSetId
            );

            if (!foundQuestionSet) return;

            setActiveQuestionSet(foundQuestionSet);
        } catch {
            setHasError(true);
        } finally {
            setInitDone(true);
        }
    }, []);

    useEffect(() => {
        initialiseQuestionBank();
    }, []);

    useEffect(() => {
        if (initDone && activeTopic !== null) {
            fetchQuestionSet(activeTopic.id);
        }
    }, [activeTopic]);

    useEffect(() => {
        if (initDone) {
            const newQuery = validateQuestionBankQuery({
                subjectId: activeSubject?.id,
                topicId: activeTopic?.id,
                questionSetId: activeQuestionSet?.id,
            });

            setQuery(newQuery);

            // TODO - we probably want to improve this portion to prevent double pushing
            const q = newQuery === null ? '' : deriveQuestionBankQueryString(newQuery);
            history.push({ pathname: location.pathname, search: q });
        }
    }, [activeSubject, activeTopic, activeQuestionSet, initDone]);

    /**
     * this effect is meant to handle when the user clicks on the browser back button
     * to sync up the active file/topic/subject with the URL
     * however, we still rely on the state as the source of truth
     */
    useEffect(() => {
        if (initDone) {
            syncLocationChange(location.search);
        }
    }, [location.search, syncLocationChange]);

    return {
        status: {
            initDone,
            hasError,
            isLoading,
        },
        payloads: {
            subjects: whitelistedSubjects,
            topics,
            fileTypes,
            questionSets,
        },
        activeContent: {
            activeTopic,
            activeSubject,
            activeQuestionSet,
        },
        setters: {
            setActiveTopic,
            setActiveSubject,
            setActiveQuestionSet,
        },
    };
};
