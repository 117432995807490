import React from 'react';
import classnames from 'classnames/bind';
import { Image } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import TypographySubtitle from 'components/TypographySubtitle';
import AnswerCard from 'components/AnswerCard';
import ExplanationNotes from 'components/ExplanationNotes';
import { GETMistakesQuizPayload, QuizAnswer } from '../../QuizSetViewer/types';

import styles from './ResultQuizCard.module.scss';

const cx = classnames.bind(styles);

type ResultQuizCardProps = {
    quiz: GETMistakesQuizPayload;
    index: number;
    isAnsweredCorrectly: boolean;
    selectedAnswer: QuizAnswer;
};

const ResultQuizCard: React.FC<ResultQuizCardProps> = ({
    quiz,
    index,
    isAnsweredCorrectly,
    selectedAnswer,
}) => {
    return (
        <div className={cx('container')}>
            <div className={cx('title-container')}>
                <TypographySubtitle level={1} className={cx('subtitle')}>
                    Q{index + 1}. {quiz.questionText}
                </TypographySubtitle>
                {isAnsweredCorrectly ? (
                    <CheckOutlined style={{ color: '#2EC06B' }} />
                ) : (
                    <CloseOutlined style={{ color: '#C0302E' }} />
                )}
            </div>

            {quiz.questionImage && (
                <div className={cx('image-container')}>
                    <Image src={quiz.questionImage} width="100%" preview={false} />
                </div>
            )}

            <div className={cx('answers-container')}>
                {quiz.quizAnswers.map((quizAnswer) => (
                    <AnswerCard
                        name={quizAnswer.answerText}
                        key={quizAnswer.id}
                        isCorrectAnswer={quizAnswer.isCorrectAnswer}
                        isAnsweredCorrectly={isAnsweredCorrectly}
                        isSelected={selectedAnswer.id === quizAnswer.id}
                    />
                ))}
            </div>

            {quiz.explanationNotes && <ExplanationNotes text={quiz.explanationNotes} />}
        </div>
    );
};

export default ResultQuizCard;
