import React from 'react';
import classnames from 'classnames/bind';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import TypographySubtitle from '../TypographySubtitle';

import style from './NavigationArrows.module.scss';

const cx = classnames.bind(style);

type ProfileIconProps = {
    onLeft: () => void;
    onRight: () => void;
    isLeftDisabled?: boolean;
    isRightDisabled?: boolean;
    text?: string;
};

const NavigationArrows: React.FC<ProfileIconProps> = ({
    onLeft,
    onRight,
    isLeftDisabled = false,
    isRightDisabled = false,
    text,
}) => {
    const handleOnLeft = () => {
        if (isLeftDisabled) {
            return;
        }

        onLeft();
    };

    const handleOnRight = () => {
        if (isRightDisabled) {
            return;
        }

        onRight();
    };

    return (
        <div className={cx('container')}>
            <LeftOutlined onClick={handleOnLeft} className={cx({ disabled: isLeftDisabled })} />
            <TypographySubtitle level={1} className={cx('text')}>
                {text}
            </TypographySubtitle>
            <RightOutlined onClick={handleOnRight} className={cx({ disabled: isRightDisabled })} />
        </div>
    );
};

export default NavigationArrows;
