import React from 'react';
import classnames from 'classnames/bind';
import { Image } from 'antd';

import { features } from './constants';

import styles from './FeaturesSection.module.scss';
const cx = classnames.bind(styles);

const FeaturesSection: React.FC = () => {
    return (
        <div className={cx('container')}>
            <div className={cx('section-title')}>Comprehensive Resources for HL & SL Economics</div>

            {features.map((feature, index) => (
                <div key={index} className={cx('content', { reverse: index % 2 === 1 })}>
                    <div className={cx('image-container')}>
                        <Image src={feature.img} width={700} preview={false} />
                    </div>

                    <div className={cx('text-container')}>
                        <div className={cx('title')}>{feature.title}</div>
                        <div className={cx('text')}>{feature.description}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FeaturesSection;
