import React from 'react';
import DOMPurify from 'dompurify';
import classnames from 'classnames/bind';

import { EAskAQuestionMessageSender, GETThreadMessages } from '../types';
import { formatUnixTimestamp } from 'common/utils';
import { hyperlinkPotentialLinks } from './utils';

import styles from './ChatBubble.module.scss';
const cx = classnames.bind(styles);

type ChatBubbleProps = {
    message: GETThreadMessages;
};

const ChatBubble: React.FC<ChatBubbleProps> = ({ message }) => {
    const sanitisedMessage = (msg: string) => {
        const newMessage = msg.replaceAll('\n', '<br />');
        const purified = DOMPurify.sanitize(newMessage, { ALLOWED_TAGS: ['br'] });
        return hyperlinkPotentialLinks(purified);
    };

    return (
        <div
            className={cx(
                'container',
                message.sender === EAskAQuestionMessageSender.CLIENT ? 'client' : 'admin'
            )}
            key={message.createdAt}
        >
            <div
                className={cx('message')}
                dangerouslySetInnerHTML={{
                    __html: sanitisedMessage(message.message),
                }}
            />
            <div className={cx('timestamp')}>
                {formatUnixTimestamp(message.createdAt, 'HH:mm dd/MM/yy')}
            </div>
        </div>
    );
};

export default ChatBubble;
