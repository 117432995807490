import React, { useState } from 'react';

import { useAxiosInstance } from 'components/AxiosContext';

import GameView from './GameView';
import { QuizChallengeQuestion, RecommendedContent } from '../types';
import { POSTQuizChallengeSubmitPayload, ESubmitQuizAction } from './types';

type GameViewContainerProps = {
    userUuid: string;
    quizChallengeCreatedAt: number;
    currentQuiz: QuizChallengeQuestion;
    score: number;
    totalSkips: number;
    onEnd: (
        recommendedContent: RecommendedContent,
        lastSelectedQuizAnswerId: number,
        explanationNotes: string,
        correctAnswerId: number
    ) => void;
    onSubmitCorrectAnswerSuccess: (nextQuiz: QuizChallengeQuestion) => void;
    onSkipSuccess: (nextQuiz: QuizChallengeQuestion) => void;
};

const GameViewContainer: React.FC<GameViewContainerProps> = ({
    userUuid,
    quizChallengeCreatedAt,
    currentQuiz,
    score,
    totalSkips,
    onEnd,
    onSubmitCorrectAnswerSuccess,
    onSkipSuccess,
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const axios = useAxiosInstance();
    const submitAnswer = async (quizAnswerId: number): Promise<POSTQuizChallengeSubmitPayload> => {
        try {
            setIsLoading(true);

            const payload = {
                userUuid: userUuid,
                actionType: ESubmitQuizAction.ANSWER,
                answerId: quizAnswerId,
                createdAt: quizChallengeCreatedAt,
            };

            const { data } = await axios.post<POSTQuizChallengeSubmitPayload>(
                '/api/v2/quiz-challenge/submit',
                payload
            );

            return data;
        } catch {
            throw Error();
        } finally {
            setIsLoading(false);
        }
    };

    const submitSkip = async (): Promise<POSTQuizChallengeSubmitPayload> => {
        try {
            setIsLoading(true);

            const payload = {
                userUuid: userUuid,
                actionType: ESubmitQuizAction.SKIP,
                answerId: 0,
                createdAt: quizChallengeCreatedAt,
            };

            const { data } = await axios.post<POSTQuizChallengeSubmitPayload>(
                '/api/v2/quiz-challenge/submit',
                payload
            );

            return data;
        } catch {
            throw Error();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <GameView
            onEnd={onEnd}
            currentQuiz={currentQuiz}
            submitAnswer={submitAnswer}
            submitSkip={submitSkip}
            isLoading={isLoading}
            onSubmitCorrectAnswerSuccess={onSubmitCorrectAnswerSuccess}
            onSkipSuccess={onSkipSuccess}
            score={score}
            totalSkips={totalSkips}
        />
    );
};

export default GameViewContainer;
