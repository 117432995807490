import {
    ESubscriptionPlanType,
    TCSFileTypeShape,
    TCSSubject,
    TCSSubjectTopicMap,
} from 'common/types';

// This is meant to be in synced with the backend's enums
export enum EFreeTrialStatus {
    NONE = 'none',
    ACTIVE = 'active',
    EXPIRED = 'expired',
}

export type GETProfilesSubscriptionInfoPayload = {
    subscriptionId?: string;
    isSubscribed?: boolean;
    subscriptionExpiry?: string; // zulu time
    subscriptionPlanType?: ESubscriptionPlanType;
    freeTrialStatus?: EFreeTrialStatus;
    freeTrialExpiry?: string; // zulu time
    hasDefaultPayment?: boolean;
};

export type UserProfile = {
    id?: number; // tcs postgres unique user id
    cognitoId?: string;
    stripeId?: string;
    firstName?: string;
    lastName?: string;
    subscriptionDetails?: GETProfilesSubscriptionInfoPayload;
    userCohort?: string; // <mmm>#<yyyy> example: MAY#2022, NOV#2024
};

export type AppConfig = {
    is401Error: boolean;
};

type State = {
    user?: UserProfile;
    config: AppConfig;
    subjects: TCSSubject[];
    topics: TCSSubjectTopicMap;
    fileTypes: TCSFileTypeShape[];
    landedOnProtectedRoute: boolean;
    getStudyPlanCounter: number;
};

const DefaultState: State = {
    config: {
        is401Error: false,
    },
    subjects: [],
    topics: {},
    fileTypes: [],
    landedOnProtectedRoute: false,
    getStudyPlanCounter: 0,
};

export type ReducerStateType = State;

export default DefaultState;
