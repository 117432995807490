import { parseQueryParam, validateID } from 'common/utils';
import { RecordedLessonQuery } from './types';

export const parseRecordedLessonQuery = (query: string): RecordedLessonQuery | null => {
    const parsedQuery = parseQueryParam(query);
    const purchasedLessonId = validateID(parsedQuery, 'purchasedLessonId');

    return {
        purchasedLessonId: purchasedLessonId || 0,
        success: parsedQuery.success === 'true',
        canceled: parsedQuery.canceled === 'true',
    };
};
