import React from 'react';
import classnames from 'classnames/bind';
import { Button, Modal, Typography } from 'antd';

import styles from './ConfirmSaveModal.module.scss';

const cx = classnames.bind(styles);

type SaveNotesModalProps = {
    showConfirmSaveModal: boolean;
    handleCloseModal: () => void;
    handleDiscardChanges: () => void;
    handleSaveNotesInModal: () => void;
};

const ConfirmSaveModal: React.FC<SaveNotesModalProps> = ({
    showConfirmSaveModal,
    handleCloseModal,
    handleDiscardChanges,
    handleSaveNotesInModal,
}) => {
    return (
        <Modal
            visible={showConfirmSaveModal}
            wrapClassName={cx('confirm-save-modal')}
            closable={false}
            style={{ top: '50%', marginTop: '-100px' }}
            footer={[
                <Button key="Cancel" onClick={handleCloseModal}>
                    Cancel
                </Button>,
                <Button key="Discard Changes" onClick={handleDiscardChanges}>
                    Discard Changes
                </Button>,
                <Button key="submit" type="primary" onClick={handleSaveNotesInModal}>
                    Save Notes
                </Button>,
            ]}
        >
            <Typography.Text>Save notes before closing?</Typography.Text>
            <br />
            <Typography.Text>
                If you don&apos;t save, your changes will be discarded.
            </Typography.Text>
        </Modal>
    );
};

export default ConfirmSaveModal;
