import React, { useEffect, useCallback, useState, useMemo } from 'react';

import { useAxiosInstance } from 'components/AxiosContext';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getSubjectTopicFileTypes, getUserProfile } from 'components/ReducerContext/selectors';

import { GETFavouritesActivePayload } from './types';
import MyFavourites from './MyFavourites';
import { TCSSubject, TCSSubjectTopicMap } from 'common/types';
import { deriveActiveSubjectAndTopic } from './utils';

const MyFavouritesContainer: React.FC = () => {
    const axios = useAxiosInstance();

    const { state } = useReducerContext();

    const profile = useMemo(() => getUserProfile(state), [state]);
    const { subjects, topics } = useMemo(() => getSubjectTopicFileTypes(state), [state]);

    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [activeSubjects, setActiveSubjects] = useState<TCSSubject[]>([]);
    const [activeTopics, setActiveTopics] = useState<TCSSubjectTopicMap>({});

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);

            const favUrl = `/private/v1/favourites/active?cognito_id=${profile.cognitoId}`;
            const { data } = await axios.get<GETFavouritesActivePayload[]>(favUrl);

            const { activeSubs, activeTops } = deriveActiveSubjectAndTopic(data, subjects, topics);

            setActiveSubjects(activeSubs);
            setActiveTopics(activeTops);
        } catch {
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <MyFavourites
            isLoading={isLoading}
            hasError={hasError}
            subjects={activeSubjects}
            topics={activeTopics}
        />
    );
};

export default MyFavouritesContainer;
