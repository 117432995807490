import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { message } from 'antd';

import { useAxiosInstance } from 'components/AxiosContext';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';

import PracticeMistakesView from './PracticeMistakesView';
import { GETMistakesQuizPayload } from './QuizSetViewer/types';

type PracticeMistakesViewProps = {
    handleGoBack: () => void;
};
const PracticeMistakesViewContainer: React.FC<PracticeMistakesViewProps> = ({ handleGoBack }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [mistakesQuiz, setMistakesQuiz] = useState<GETMistakesQuizPayload[]>([]);

    const axios = useAxiosInstance();

    const { state } = useReducerContext();
    const profile = useMemo(() => getUserProfile(state), [state]);

    const fetchMistakesQuiz = useCallback(async () => {
        try {
            setIsLoading(true);

            const { data } = await axios.get<GETMistakesQuizPayload[]>(
                `/private/v2/quiz-challenge/practice`
            );

            setMistakesQuiz(data);
        } catch {
            message.error('Something went wrong fetching mistakes quiz. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (!profile.cognitoId) {
            return;
        }

        fetchMistakesQuiz();
    }, []);

    return (
        <PracticeMistakesView
            handleGoBack={handleGoBack}
            quizzes={mistakesQuiz}
            isLoading={isLoading}
        />
    );
};

export default PracticeMistakesViewContainer;
