import React from 'react';
import classnames from 'classnames/bind';

import TypographySubtitle from 'components/TypographySubtitle';
import { sectionDetails } from './constants';
import SectionCard from './SectionCard';

import styles from './Resources.module.scss';

const cx = classnames.bind(styles);
const Resources: React.FC = () => {
    return (
        <div className={cx('container')}>
            <TypographySubtitle level={1} className={cx('header')}>
                Here are more resources for self-study
            </TypographySubtitle>
            <div className={cx('cards-container')}>
                {sectionDetails.map((section, index) => (
                    <SectionCard
                        key={index}
                        headerImg={section.imgSrc}
                        title={section.title}
                        description={section.description}
                        url={section.url}
                    />
                ))}
            </div>
        </div>
    );
};

export default Resources;
