import React from 'react';

import { SubscriptionPlanPerk } from 'components/SubscriptionBox/types';

export const individualPerks: SubscriptionPlanPerk[] = [
    {
        isDisabled: false,
        content: <div>Access all resources for all subjects</div>,
    },
    {
        isDisabled: false,
        content: <div>No hidden costs, cancel anytime</div>,
    },
];

export const friendsAndFamilyPerks: SubscriptionPlanPerk[] = [
    {
        isDisabled: false,
        content: (
            <div>
                You may invite up to <span style={{ fontWeight: 'bold' }}>2 users</span> to share
                the plan
            </div>
        ),
    },
    {
        isDisabled: false,
        content: <div>Access all resources for all subjects</div>,
    },
    {
        isDisabled: false,
        content: <div>No hidden costs, cancel anytime</div>,
    },
];

export const trialPerks: SubscriptionPlanPerk[] = [
    {
        isDisabled: false,
        content: <div>Access all resources for all subjects</div>,
    },
    {
        isDisabled: false,
        content: <div>Follow us on Instagram @thecloudscore and DM us for a free trial code</div>,
    },
    {
        isDisabled: false,
        content: <div>No credit card is needed</div>,
    },
];
