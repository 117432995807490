/**
 * Business agreement is that - we want to ease user free trial code redemption
 * process, so we will default to capitalizing free trial coupon code
 * We exclude code's that contain a '-' because randomly generated coupon
 * codes are in XXXXX-XXXXX format
 * @param code - free trial coupon code
 */
export const parseCouponCode = (code: string): string => {
    if (code.includes('-')) return code;
    return code.toUpperCase();
};
