import React, { useEffect, useState } from 'react';

import { useAxiosInstance } from 'components/AxiosContext';

import { TCSSubject } from 'common/types';
import TopicSelectorView from './TopicSelectorView';
import { GETQuizChallengeLeaderboardUserBestPayload, GETTopics } from './types';

type TopicSelectorViewContainerProps = {
    userUuid: string;
    subject: TCSSubject | null;
    handleGoBack: () => void;
    handleStartGame: (topicIdList: number[]) => Promise<void>;
    handleShowLeaderboard: () => void;
    handleShowPracticeMistakes: () => void;
};

const TopicSelectorViewContainer: React.FC<TopicSelectorViewContainerProps> = ({
    userUuid,
    subject,
    handleGoBack,
    handleStartGame,
    handleShowLeaderboard,
    handleShowPracticeMistakes,
}) => {
    const axios = useAxiosInstance();

    const [currentPlayer, setCurrentPlayer] =
        useState<GETQuizChallengeLeaderboardUserBestPayload | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [topics, setTopics] = useState<GETTopics[]>([]);

    const fetchCurrentPlayer = async () => {
        try {
            setIsLoading(true);
            const { data } = await axios.get<GETQuizChallengeLeaderboardUserBestPayload>(
                '/api/v2/quiz-challenge/leaderboard/user/best',
                {
                    params: {
                        authId: userUuid,
                    },
                }
            );

            setCurrentPlayer(data);
        } catch (err) {
            if (err.response.status !== 404) {
                throw Error();
            }
        } finally {
            setIsLoading(false);
        }
    };

    const fetchTopics = async () => {
        try {
            const { data } = await axios.get<GETTopics[]>('/api/v2/quiz-challenge/topics', {
                params: {
                    subjectId: subject?.id || 1, // fallback to HL Econs (sub ID 1)
                },
            });
            setTopics(data);
        } catch {
            throw Error();
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCurrentPlayerAndTopics = async () => {
        try {
            setIsLoading(true);
            await Promise.all([fetchCurrentPlayer(), fetchTopics()]);
        } catch {
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCurrentPlayerAndTopics();
    }, []);

    return (
        <TopicSelectorView
            subject={subject?.name || ''}
            handleGoBack={handleGoBack}
            currentPlayer={currentPlayer}
            handleStartGame={handleStartGame}
            hasError={hasError}
            isCurrentPlayerLoading={isLoading}
            handleShowLeaderboard={handleShowLeaderboard}
            handleShowPracticeMistakes={handleShowPracticeMistakes}
            userUuid={userUuid}
            topics={topics}
        />
    );
};

export default TopicSelectorViewContainer;
