import React from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames/bind';

import { scrollToTop } from 'common/utils';
import CTAButton from 'components/CTAButton';

import styles from './CTASection.module.scss';
const cx = classnames.bind(styles);

const CTASection: React.FC = () => {
    const history = useHistory();

    const pushToSubInfo = () => {
        scrollToTop();
        history.push('/subscriptions/info');
    };

    return (
        <div className={cx('container')}>
            <div className={cx('section-title')}>Get your free lesson now</div>
            <div className={cx('content')}>
                <div>
                    Simply use code <strong>GETYOUR7</strong> for a free trial.
                </div>
                <div>No credit card required</div>
            </div>

            <CTAButton onClick={pushToSubInfo}>Get Started</CTAButton>
        </div>
    );
};

export default CTASection;
