import React, { useState } from 'react';
import { message } from 'antd';
import classnames from 'classnames/bind';
import * as Sentry from '@sentry/react';

import { useAuth } from 'components/AuthContextProvider';

import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';
import ConfirmEmail from './ConfirmEmail';
import ForgotPassword from './ForgotPassword';
import HaveVerificationCode from './HaveVerificationCode';
import ResetPassword from './ResetPassword';

import {
    sendCustomFbEvent,
    sendGTagConversionEvent,
    sendGTagEvent,
    sendRedditEvent,
    sendTikTokEvent,
} from 'common/utils';

import styles from './Authentication.scss';

const cx = classnames.bind(styles);

const EMAIL_CONFIRMATION_MESSAGE = 'Email successfully verified.';

const FORGOT_PASSWORD_MESSAGE = 'Please check your email for the verification code';

const RESET_PASSWORD_MESSAGE = 'Password successfully reset';

export enum ELoginStep {
    LOGIN,
    SIGN_UP,
    CONFIRM_EMAIL,
    FORGOT_PASSWORD,
    HAVE_VERIFICATION_CODE,
    RESET_PASSWORD,
}

type AuthenticationProps = {
    onSignInSuccess?: () => void;
};

export const Authentication: React.FC<AuthenticationProps> = ({ onSignInSuccess = () => {} }) => {
    const {
        signIn,
        signUp,
        confirmEmail,
        resendEmailConfirmationCode,
        forgotPassword,
        resetPassword,
    } = useAuth();

    const [step, setStep] = useState(ELoginStep.SIGN_UP);
    const [isLoading, setIsLoading] = useState(false);
    const [emailToConfirm, setEmailToConfirm] = useState('');
    const [tempPassword, setTempPassword] = useState('');
    const [emailToReset, setEmailToReset] = useState('');

    const handleSignIn = async (email: string, password: string) => {
        try {
            setIsLoading(true);
            await signIn(email, password);
            onSignInSuccess();
        } catch (e) {
            if (e.code === 'UserNotConfirmedException') {
                setEmailToConfirm(email);
                setTempPassword(password);
                setStep(ELoginStep.CONFIRM_EMAIL);
                message.info(
                    'Your email has yet to be verified. Please enter the confirmation code that was sent to your email.'
                );
            } else {
                if (e.code === 'NotAuthorizedException') {
                    switch (e.message) {
                        case 'Password attempts exceeded':
                            throw Error('PasswordAttemptsExceededException');
                        case 'Incorrect username or password':
                        default:
                            throw Error('NotAuthorizedException');
                    }
                }

                Sentry.captureException(e, {
                    contexts: {
                        data: {
                            action: 'login',
                        },
                    },
                });
                throw Error('DEFAULT');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleSignUp = async (
        email: string,
        password: string,
        firstName: string,
        lastName: string
    ) => {
        try {
            setIsLoading(true);
            await signUp(email, password, firstName, lastName);
            setEmailToConfirm(email);
            setTempPassword(password);

            sendGTagEvent('signUp', 'adConversion', 'signUp');
            sendGTagConversionEvent('AW-302708230/9Rz3CLjOrJQYEIbsq5AB');
            sendRedditEvent('SignUp');
            sendTikTokEvent('CompleteRegistration');
            sendCustomFbEvent('CompleteRegistration');

            setStep(ELoginStep.CONFIRM_EMAIL);
        } catch (e) {
            if (e.code === 'UsernameExistsException') {
                throw Error('USERNAME_EXISTS');
            }

            Sentry.captureException(e, {
                contexts: {
                    data: {
                        action: 'signUp',
                    },
                },
            });

            throw Error('DEFAULT');
        } finally {
            setIsLoading(false);
        }
    };

    const handleConfirmEmail = async (confirmationCode: string) => {
        try {
            setIsLoading(true);
            await confirmEmail(emailToConfirm, confirmationCode);
            message.info(EMAIL_CONFIRMATION_MESSAGE);
            await handleSignIn(emailToConfirm, tempPassword);
            setTempPassword('');
            setEmailToConfirm('');
        } catch (e) {
            throw Error(e.code);
        } finally {
            setIsLoading(false);
        }
    };

    const handleForgotPassword = async (email: string) => {
        try {
            setIsLoading(true);
            await forgotPassword(email);
            message.info(FORGOT_PASSWORD_MESSAGE);
            setEmailToReset(email);
            setStep(ELoginStep.RESET_PASSWORD);
        } catch (e) {
            throw Error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleVerifyEmail = (email: string) => {
        setEmailToReset(email);
        setStep(ELoginStep.RESET_PASSWORD);
    };

    const handleResetPassword = async (code: string, newPassword: string) => {
        try {
            setIsLoading(true);
            await resetPassword(emailToReset, code, newPassword);
            message.info(RESET_PASSWORD_MESSAGE);
            setStep(ELoginStep.LOGIN);
        } catch (e) {
            if (e.code === 'CodeMismatchException') {
                throw Error(e.code);
            }
            throw Error('DEFAULT');
        } finally {
            setIsLoading(false);
        }
    };

    // Temporarily hiding google login until auth is sorted and stable
    // const handleGoogleSignIn = async () => {
    //   try {
    //     await googleSignIn();
    //   } catch (e) {
    //     throw Error(e);
    //   }
    // };

    const handleResendEmailConfirmationCode = async () => {
        await resendEmailConfirmationCode(emailToConfirm);
    };

    const renderStep = () => {
        switch (step) {
            case ELoginStep.LOGIN:
                return (
                    <LoginForm
                        isLoading={isLoading}
                        handleSignIn={handleSignIn}
                        // handleGoogleSignIn={handleGoogleSignIn}
                        goToSignUp={() => setStep(ELoginStep.SIGN_UP)}
                        goToForgotPassword={() => setStep(ELoginStep.FORGOT_PASSWORD)}
                    />
                );

            case ELoginStep.SIGN_UP:
                return (
                    <SignUpForm
                        isLoading={isLoading}
                        handleSignUp={handleSignUp}
                        // handleGoogleSignIn={handleGoogleSignIn}
                        goToLogin={() => setStep(ELoginStep.LOGIN)}
                    />
                );

            case ELoginStep.CONFIRM_EMAIL:
                return (
                    <ConfirmEmail
                        isLoading={isLoading}
                        handleConfirmEmail={handleConfirmEmail}
                        handleResendConfirmationCode={handleResendEmailConfirmationCode}
                    />
                );

            case ELoginStep.FORGOT_PASSWORD:
                return (
                    <ForgotPassword
                        isLoading={isLoading}
                        handleForgotPassword={handleForgotPassword}
                        goToLogin={() => setStep(ELoginStep.LOGIN)}
                        goToHaveVerificationCode={() => setStep(ELoginStep.HAVE_VERIFICATION_CODE)}
                    />
                );

            case ELoginStep.HAVE_VERIFICATION_CODE:
                return (
                    <HaveVerificationCode
                        isLoading={isLoading}
                        handleVerifyEmail={handleVerifyEmail}
                        goToForgotPassword={() => setStep(ELoginStep.FORGOT_PASSWORD)}
                    />
                );

            case ELoginStep.RESET_PASSWORD:
                return (
                    <ResetPassword
                        isLoading={isLoading}
                        handleResetPassword={handleResetPassword}
                        goToLogin={() => setStep(ELoginStep.LOGIN)}
                    />
                );
        }
    };

    return <div className={cx('authentication')}>{renderStep()}</div>;
};
