import React, { useEffect } from 'react';
import classnames from 'classnames/bind';
import { datadogRum } from '@datadog/browser-rum';

import ArcVideoPlayer from 'components/ArcVideoPlayer';
import FloatingToolbar from 'components/FloatingToolbar';
import GoBack from 'components/FloatingToolbar/Tooltips/GoBack';
import GetStudyPlan from 'components/FloatingToolbar/Tooltips/GetStudyPlan';
import ThumbsUp from 'components/FloatingToolbar/Tooltips/ThumbsUp';
import ThumbsDown from 'components/FloatingToolbar/Tooltips/ThumbsDown';
import WriteNotes from 'components/FloatingToolbar/Tooltips/WriteNotes';
import NotesPanel from 'components/NotesPanel';
import TypographyHeader from 'components/TypographyHeader';
import { HIDE_NOTES } from 'flags';

import { deriveDatadogRumDataFromFile } from 'common/utils';
import { TCSFileShape } from 'common/types';
import { EThumbsUpDownState } from '../types';

import styles from './VideoViewer.module.scss';

const cx = classnames.bind(styles);

type VideoViewerProps = {
    file: TCSFileShape;
    thumbsUpStatus: EThumbsUpDownState;
    thumbsDownStatus: EThumbsUpDownState;
    isNotesPanelOpen: boolean;
    setIsNotesPanelOpen: (open: boolean) => void;
    handleGoBack: () => void;
    handleThumbsUp: () => void;
    handleThumbsDown: () => void;
};

const VideoViewer: React.FC<VideoViewerProps> = ({
    file,
    thumbsUpStatus,
    thumbsDownStatus,
    isNotesPanelOpen,
    setIsNotesPanelOpen,
    handleGoBack,
    handleThumbsUp,
    handleThumbsDown,
}) => {
    const handleOpenNotesPanel = () => {
        setIsNotesPanelOpen(true);

        datadogRum.addAction('User open notes panel', deriveDatadogRumDataFromFile(file));
    };

    const tooltips = [
        <GoBack key="go-back" callback={handleGoBack} />,
        <GetStudyPlan key="get-study-plan" />,
        <ThumbsUp key="thumbs-up" callback={handleThumbsUp} status={thumbsUpStatus} />,
        <ThumbsDown key="thumbs-down" callback={handleThumbsDown} status={thumbsDownStatus} />,
        <WriteNotes key="write-notes" active={isNotesPanelOpen} callback={handleOpenNotesPanel} />,
    ];

    if (HIDE_NOTES) {
        tooltips.pop();
    }

    useEffect(() => {
        return () => setIsNotesPanelOpen(false);
    }, []);

    return (
        <div className={cx('container')}>
            <div className={cx('content-container')}>
                <TypographyHeader level={3} className={cx('title')}>
                    {file.original_file_name}
                </TypographyHeader>

                <div className={cx('video-container')}>
                    <ArcVideoPlayer videoUrl={file.url} videoWidth="100%" videoHeight="100%" />
                </div>
            </div>

            <div className={cx('notes-panel-container', { 'panel-width-grow': isNotesPanelOpen })}>
                <NotesPanel
                    isNotesPanelOpen={isNotesPanelOpen}
                    setIsNotesPanelOpen={() => setIsNotesPanelOpen(false)}
                    file={file}
                />
            </div>

            <FloatingToolbar tooltips={tooltips} />
        </div>
    );
};

export default VideoViewer;
