import React from 'react';
import classnames from 'classnames/bind';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import styles from './CTAButton.module.scss';
const cx = classnames.bind(styles);

type CTAButtonProps = {
    onClick: () => void;
    showRightArrow?: boolean;
};
const CTAButton: React.FC<CTAButtonProps> = ({ children, onClick, showRightArrow = false }) => {
    return (
        <div className={cx('container')}>
            <Button onClick={onClick}>
                {children} {showRightArrow && <ArrowRightOutlined />}
            </Button>
        </div>
    );
};

export default CTAButton;
