import React, { useCallback, useState } from 'react';

import { useAxiosInstance } from 'components/AxiosContext';

import ReinstateSubscription from './ReinstateSubscription';

type ReinstateSubscriptionContainerProps = {
    subscriptionId: string;
    refetchSubscription: () => void;
};

const ReinstateSubscriptionContainer: React.FC<ReinstateSubscriptionContainerProps> = ({
    subscriptionId,
    refetchSubscription,
}) => {
    const axios = useAxiosInstance();

    const [isLoading, setIsLoading] = useState(false);

    const handleReinstateSubscription = useCallback(async () => {
        try {
            setIsLoading(true);
            await axios.post('/private/v2/payments/plans/reinstate', {
                subscriptionId,
            });
        } catch {
            throw Error();
        } finally {
            setIsLoading(false);
        }
    }, [subscriptionId]);

    return (
        <ReinstateSubscription
            isLoading={isLoading}
            reinstateSubscription={handleReinstateSubscription}
            refetchSubscription={refetchSubscription}
        />
    );
};

export default ReinstateSubscriptionContainer;
