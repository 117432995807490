import { Dispatch } from 'react';

import { EFreeTrialStatus, UserProfile } from '../state';

type UserDispatch = {
    type: EUserActions;
    payload: any;
};

export enum EUserActions {
    setUserProfile = '@user/SET_USER_PROFILE',
    setUserSubscriptionInfo = '@user/SET_USER_SUBCRIPTION_INFO',
    setUserFreeTrialInfo = '@user/SET_USER_FREE_TRIAL_INFO',
    setLandedOnProtectedRoute = '@user/SET_LANDED_ON_PROTECTED_ROUTE',
    setUserCohort = '@user/SET_USER_COHORT',
    setGetStudyPlanCounter = '@user/SET_GET_STUDY_PLAN_COUNTER',
}

export const setUserProfile = (dispatch: Dispatch<UserDispatch>, user: UserProfile) => {
    dispatch({
        type: EUserActions.setUserProfile,
        payload: user,
    });
};

export type SetUserSubscriptionInfoPayload = {
    subId: string;
    isSubbed: boolean;
    subExpiry: string;
};

export const setUserSubscriptionInfo = (
    dispatch: Dispatch<UserDispatch>,
    payload: SetUserSubscriptionInfoPayload
) => {
    dispatch({
        type: EUserActions.setUserSubscriptionInfo,
        payload,
    });
};

export type SetUserFreeTrialInfoPayload = {
    status: EFreeTrialStatus;
    expiry: string;
};

export const setUserFreeTrialInfo = (
    dispatch: Dispatch<UserDispatch>,
    payload: SetUserFreeTrialInfoPayload
) => {
    dispatch({
        type: EUserActions.setUserFreeTrialInfo,
        payload,
    });
};

export const setLandedOnProtectedRoute = (
    dispatch: Dispatch<UserDispatch>,
    landedOnProtectedRoute: boolean
) => {
    dispatch({
        type: EUserActions.setLandedOnProtectedRoute,
        payload: landedOnProtectedRoute,
    });
};

export const setUserCohort = (dispatch: Dispatch<UserDispatch>, payload: string) => {
    dispatch({
        type: EUserActions.setUserCohort,
        payload,
    });
};

export const setGetStudyPlanCounter = (dispatch: Dispatch<UserDispatch>, payload: number) => {
    dispatch({
        type: EUserActions.setGetStudyPlanCounter,
        payload,
    });
};
