import React, { useState } from 'react';
import { Modal, Button, message } from 'antd';
import classnames from 'classnames/bind';

import TypographySubtitle from 'components/TypographySubtitle';

import styles from './CancelSubscription.module.scss';

const cx = classnames.bind(styles);

type CancelSubscriptionProps = {
    isLoading: boolean;
    cancelSubscription: () => Promise<void>;
    refetchSubscription: () => void;
};

const CancelSubscription: React.FC<CancelSubscriptionProps> = ({
    isLoading,
    cancelSubscription,
    refetchSubscription,
}) => {
    const [visible, setVisible] = useState(false);

    const handleOnCancel = () => {
        if (isLoading) return;
        setVisible(false);
    };

    const handleCancelSubscription = async () => {
        try {
            await cancelSubscription();
            message.success('Subscription successfully cancelled!');
            refetchSubscription();
        } catch {
            message.error('Oops! Failed to cancel your subscription. Please try again!');
        }
    };

    return (
        <div className={cx('container')}>
            <div className={cx('cancel-subscription-cta')} onClick={() => setVisible(true)}>
                Cancel Subscription
            </div>

            <Modal
                visible={visible}
                onCancel={handleOnCancel}
                closable={false}
                footer={null}
                width="500px"
                destroyOnClose
            >
                <div className={cx('cancel-subscription-modal')}>
                    <TypographySubtitle level={1}>
                        Are you sure you want to cancel your subscription?
                    </TypographySubtitle>

                    <div className={cx('cancel-subscription-button-group')}>
                        <Button loading={isLoading} block onClick={handleOnCancel}>
                            No
                        </Button>

                        <Button
                            className={cx('cancel-subscription-yes')}
                            block
                            danger
                            type="primary"
                            loading={isLoading}
                            onClick={handleCancelSubscription}
                        >
                            Yes
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default CancelSubscription;
