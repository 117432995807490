import React from 'react';

import { useAxiosInstance } from 'components/AxiosContext';

import PurchasedLessonCard from './PurchasedLessonCard';
import { PurchasedRecordedLesson } from '../../types';

type PurchasedLessonCardContainerProps = {
    lesson: PurchasedRecordedLesson;
    onWatchVideoClick: (lesson: PurchasedRecordedLesson) => void;
};

const PurchasedLessonCardContainer: React.FC<PurchasedLessonCardContainerProps> = ({
    lesson,
    onWatchVideoClick,
}) => {
    const axios = useAxiosInstance();

    const fetchSignNotesUrl = async () => {
        try {
            const { data } = await axios.get<string>('/private/v2/recorded-lessons/sign/notes', {
                params: {
                    recordedLessonId: lesson.id,
                },
            });

            return data;
        } catch {
            throw Error();
        }
    };
    return (
        <PurchasedLessonCard
            lesson={lesson}
            fetchSignNotesUrl={fetchSignNotesUrl}
            onWatchVideoClick={onWatchVideoClick}
        />
    );
};

export default PurchasedLessonCardContainer;
