import React, { useMemo } from 'react';
import { Spin } from 'antd';
import classnames from 'classnames/bind';

import TypographyBody from 'components/TypographyBody';
import InfoBox from 'components/InfoBox';
import InviteUser from './InviteUser';
import InviteeRow from './InviteeRow';

import { GETFriendsAndFamilyMembersPayload } from './types';

import styles from './MyInvitees.scss';

const cx = classnames.bind(styles);

type MyInviteesProps = {
    isLoading: boolean;
    hasError: boolean;
    friendsAndFamilyMembers: GETFriendsAndFamilyMembersPayload[];
    refetchFriendsAndFamilyMembers: () => void;
};

const MAX_NUMBER_OF_INVITEES = 2;

const MyInvitees: React.FC<MyInviteesProps> = ({
    isLoading,
    hasError,
    friendsAndFamilyMembers,
    refetchFriendsAndFamilyMembers,
}) => {
    const showInviteUserButton = useMemo(
        () => friendsAndFamilyMembers.length < MAX_NUMBER_OF_INVITEES,
        [friendsAndFamilyMembers]
    );

    if (isLoading) {
        return (
            <div className={cx('container')}>
                <Spin size="large" />
            </div>
        );
    }

    if (hasError) {
        return (
            <div className={cx('error-container')}>
                <TypographyBody className={cx('title')} level={1}>
                    Oops, an error has occurred when trying to retrieve your invitees
                </TypographyBody>
            </div>
        );
    }

    return (
        <div className={cx('container')}>
            <p>You may invite up to {MAX_NUMBER_OF_INVITEES} users.</p>

            <InfoBox>
                You may only invite existing Cloudscore users to the plan. Please have your friends
                or family register with Cloudscore, verify their accounts and log in at least once,
                before inviting them.
            </InfoBox>

            {friendsAndFamilyMembers.map((member, index) => (
                <InviteeRow
                    key={index}
                    name={`${member.firstName} ${member.lastName}`}
                    email={member.email}
                    refetchFriendsAndFamilyMembers={refetchFriendsAndFamilyMembers}
                />
            ))}

            {showInviteUserButton && (
                <InviteUser refetchFriendsAndFamilyMembers={refetchFriendsAndFamilyMembers} />
            )}
        </div>
    );
};

export default MyInvitees;
