import React from 'react';
import { ZoomOutOutlined } from '@ant-design/icons';
import classnames from 'classnames/bind';

import styles from './Tooltips.module.scss';

const cx = classnames.bind(styles);

type ZoomOutProps = {
    callback: () => void;
};

const ZoomOut: React.FC<ZoomOutProps> = ({ callback }) => {
    return (
        <div className={cx('zoom-out')} onClick={callback}>
            <ZoomOutOutlined style={{ fontSize: '28px' }} />
            <div className={cx('text')}>Zoom Out</div>
        </div>
    );
};

export default ZoomOut;
