export type GETThreadList = {
    title: string;
    createdAt: number;
    threadStatus: string;
    threadLastReply: number;
};

export enum EAskAQuestionStatus {
    CLIENT_RESPONDED = 'CLIENT_RESPONDED',
    ADMIN_RESPONDED = 'ADMIN_RESPONDED',
    RESOLVED = 'RESOLVED',
}
