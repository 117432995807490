import React from 'react';
import classnames from 'classnames/bind';
import { Image } from 'antd';

import TypographyBody from 'components/TypographyBody';

import styles from './SectionCard.module.scss';

const cx = classnames.bind(styles);

type SectionCardProps = {
    headerImg: string;
    topicName: string;
    categoryName: string;
    contentTitle: string;
    url: string;
    onClick: () => void;
};

const SectionCard: React.FC<SectionCardProps> = ({
    headerImg,
    topicName,
    categoryName,
    contentTitle,
    url,
    onClick,
}) => {
    return (
        <div className={cx('container')}>
            <div className={cx('image-container')}>
                <Image src={headerImg} width="100%" preview={false} />
                <div className={cx('title')}>{categoryName}</div>
            </div>

            <div className={cx('content-container')} onClick={onClick}>
                <a href={url} target="_blank" rel="noopener noreferrer">
                    <div className={cx('text-container')}>
                        <TypographyBody level={1} className={cx('description')}>
                            <span className={cx('highlight')}>{topicName}</span>{' '}
                            <span>| {contentTitle}</span>
                        </TypographyBody>
                    </div>
                </a>
            </div>
        </div>
    );
};

export default SectionCard;
