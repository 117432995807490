import React, { useReducer, createContext, Dispatch, useContext } from 'react';

import Reducer, { Action } from './reducer';
import InitialState, { ReducerStateType } from './state';

type ReducerContextType = {
    state: ReducerStateType;
    dispatch: Dispatch<Action>;
};

const Context = createContext<ReducerContextType>({
    state: InitialState,
    dispatch: () => {},
});

const ReducerContextProvider: React.FC<{ initState?: ReducerStateType }> = ({
    initState = InitialState,
    children,
}) => {
    const [state, dispatch] = useReducer(Reducer, initState);

    return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

export const useReducerContext = () => {
    const { state, dispatch } = useContext(Context);

    return {
        state,
        dispatch,
    };
};

export default ReducerContextProvider;
