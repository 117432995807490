import { Quiz, QuizAnswer, GETQuizSetPayload } from '../types';
import { POSTAttemptedQuizSetPayload, POSTQuizzes } from './types';

const deriveCorrectAnswerId = (quizAnswers: QuizAnswer[]): number => {
    const foundQuizAnswer = quizAnswers.find((quizAnswer) => quizAnswer.isCorrectAnswer);

    if (!foundQuizAnswer) {
        return 0;
    }

    return foundQuizAnswer.id;
};

const derivePostAvailableAnswers = (quizAnswers: QuizAnswer[]) => {
    return quizAnswers.map((quizAnswer) => ({
        quizAnswerId: quizAnswer.id,
        answerText: quizAnswer.answerText,
    }));
};

const extractS3FileKeyFromUrl = (url: string): string => {
    const startIndex = url.search('/public');

    if (startIndex === -1) return url;

    return url.slice(startIndex);
};

export const deriveInitialAttemptedQuizzes = (quizzes: Quiz[]): POSTQuizzes[] => {
    return quizzes.map((quiz) => ({
        id: quiz.id,
        questionText: quiz.questionText,
        questionImage: extractS3FileKeyFromUrl(quiz.questionImage),
        selectedAnswerId: 0,
        correctAnswerId: deriveCorrectAnswerId(quiz.quizAnswers),
        availableAnswers: derivePostAvailableAnswers(quiz.quizAnswers),
    }));
};

const deriveQuizSetScore = (quizzes: POSTQuizzes[]): number => {
    return quizzes.reduce(
        (acc, quiz) => (quiz.correctAnswerId === quiz.selectedAnswerId ? acc + 1 : acc),
        0
    );
};

export const deriveAttemptedQuizSetPayload = (
    quizSet: GETQuizSetPayload,
    attemptedQuizzes: POSTQuizzes[],
    authId: string,
    attemptedDate: number
): POSTAttemptedQuizSetPayload => {
    return {
        authId: authId,
        quizSetId: quizSet.id,
        subjectId: quizSet.subjectId,
        topicId: quizSet.topicId,
        quizSetName: quizSet.name,
        quizSetLength: quizSet.quizzes.length,
        quizSetScore: deriveQuizSetScore(attemptedQuizzes),
        attemptedDate: attemptedDate,
        quizzes: attemptedQuizzes,
    };
};
