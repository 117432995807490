import React from 'react';
import classnames from 'classnames/bind';
import { Modal, Spin } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import TypographyHeader from 'components/TypographyHeader';
import TCSButton from 'components/TCSButton';
import TypographyBody from 'components/TypographyBody';

import styles from './RedirectSuccess.module.scss';
const cx = classnames.bind(styles);

type RedirectSuccessProps = {
    visible: boolean;
    isLoading: boolean;
    hasError: boolean;
    onClose: () => void;
};

const RedirectSuccess: React.FC<RedirectSuccessProps> = ({
    visible,
    isLoading,
    hasError,
    onClose,
}) => {
    const renderContent = () => {
        if (isLoading) {
            return (
                <div className={cx('loading-container')}>
                    <div className={cx('spin-container')}>
                        <Spin size="large" />
                    </div>
                    <TypographyBody level={1} className={cx('text')}>
                        <div>
                            Thank you for your purchase! Please wait while we validate your
                            purchase...
                        </div>
                        <div className={cx('highlight')}>Please do not refresh the page</div>
                    </TypographyBody>
                </div>
            );
        }

        if (hasError) {
            return (
                <div className={cx('error-container')}>
                    <div className={cx('icon-container')}>
                        <CloseCircleOutlined className={cx('error')} />
                    </div>

                    <TypographyBody level={1} className={cx('text')}>
                        <div>Something went wrong. Please refresh the page.</div>
                        <div>
                            If you do not see your purchase, please contact the admin and we will
                            help you with it!
                        </div>
                    </TypographyBody>
                </div>
            );
        }

        return (
            <div className={cx('success-container')}>
                <div className={cx('icon-container')}>
                    <CheckCircleOutlined className={cx('success')} />
                </div>
                <TypographyHeader level={3} className={cx('title')}>
                    You&apos;re all set!
                </TypographyHeader>
                <TypographyBody level={1}>
                    You can now access the recorded lesson in{' '}
                    <span className={cx('highlight', 'blue')}>My Videos</span> Happy learning!
                </TypographyBody>
            </div>
        );
    };
    return (
        <Modal
            visible={visible}
            footer={!isLoading && <TCSButton onClick={onClose}>Close</TCSButton>}
            maskClosable={false}
            closable={false}
            onCancel={onClose}
            wrapClassName={cx('container')}
        >
            <div className={cx('content-container')}>{renderContent()}</div>
        </Modal>
    );
};

export default RedirectSuccess;
