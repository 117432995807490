import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';
import { EditorState } from 'draft-js';
import { Button, Drawer, message, Spin } from 'antd';

import ConfirmSaveModal from './ConfirmSaveModal';
import NotesEditor from '../NotesEditor';
import { checkIsEditorStateChanged } from 'components/NotesPanel/utils';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './NotesPanel.module.scss';

const cx = classnames.bind(styles);

type NotesPanelProps = {
    addDatadogRumAction: (actionName: string) => void;
    isLoading: boolean;
    hasError: boolean;
    isNotesPanelOpen: boolean;
    setIsNotesPanelOpen: (open: boolean) => void;
    editorState: EditorState;
    setEditorState: (editorState: EditorState) => void;
    initialEditorState: EditorState;
    saveNotes: () => Promise<void>;
    handleRefetch: () => void;
};

const NotesPanel: React.FC<NotesPanelProps> = ({
    addDatadogRumAction,
    isLoading,
    hasError,
    isNotesPanelOpen,
    setIsNotesPanelOpen,
    editorState,
    setEditorState,
    initialEditorState,
    saveNotes,
    handleRefetch,
}) => {
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [showConfirmSaveModal, setShowConfirmSaveModal] = useState(false);

    const isNotesEmpty = useMemo(() => !editorState.getCurrentContent().hasText(), [editorState]);

    const handleCancel = () => {
        setIsReadOnly(true);
        setEditorState(initialEditorState);
    };

    const handleCreateOrEditNotes = () => {
        setIsReadOnly(false);

        addDatadogRumAction('Create / Edit notes');
    };

    const handleCloseWithModalIfNotReadOnly = () => {
        const isEditorStateChanged = checkIsEditorStateChanged(initialEditorState, editorState);

        if (!isEditorStateChanged) {
            setIsNotesPanelOpen(false);
            setIsReadOnly(true);
            return;
        }

        if (isReadOnly) {
            setIsNotesPanelOpen(false);
            return;
        }

        setShowConfirmSaveModal(true);
    };

    const handleCloseModal = () => {
        setShowConfirmSaveModal(false);
    };

    const handleSaveNotes = async () => {
        try {
            await saveNotes();
            setIsReadOnly(true);

            addDatadogRumAction('Save notes');

            message.success('Notes saved!');
        } catch {
            message.error('Failed to save notes. Please try again!');
        }
    };

    const handleDiscardChanges = () => {
        setEditorState(initialEditorState);
        setIsNotesPanelOpen(false);
        setShowConfirmSaveModal(false);
        setIsReadOnly(true);

        message.success('Changes have been discarded');
    };

    const handleSaveNotesInModal = async () => {
        setShowConfirmSaveModal(false);

        try {
            await saveNotes();
            setIsNotesPanelOpen(false);

            addDatadogRumAction('Save notes');

            message.success('Notes saved!');
        } catch {
            message.error('Failed to save notes. Please try again!');
        }
    };

    const footer = (
        <div>
            {isReadOnly ? (
                <Button
                    type="primary"
                    onClick={handleCreateOrEditNotes}
                    disabled={hasError || isLoading}
                >
                    {isNotesEmpty ? 'Create Notes' : 'Edit Notes'}
                </Button>
            ) : (
                <>
                    <Button type="link" disabled={hasError || isLoading} onClick={handleCancel}>
                        Cancel
                    </Button>

                    <Button
                        type="primary"
                        disabled={hasError || isLoading}
                        onClick={handleSaveNotes}
                    >
                        Save Notes
                    </Button>
                </>
            )}
        </div>
    );

    const handleRender = () => {
        if (hasError) {
            return (
                <div className={cx('error-container')}>
                    <p>
                        Oops! Sorry, but it seems like we encountered an error while loading your
                        notes. Please try again.
                    </p>
                    <Button type="primary" onClick={handleRefetch}>
                        Retry
                    </Button>
                    <p>
                        If this issue persists, please kindly reach out to us at any one of our
                        communication channels. You can find them <Link to="/contact-us">here</Link>
                        .
                    </p>
                </div>
            );
        }

        if (isLoading && isReadOnly) {
            return (
                <div className={cx('spin-container')} data-testid="spin-loader">
                    <Spin size="large" />
                </div>
            );
        }

        if (isNotesEmpty && isReadOnly) {
            return (
                <div
                    className={cx('no-content-container')}
                    data-testid="notes-panel-no-content-container"
                >
                    <p>You have not taken any notes yet.</p>
                    <p>Click &quot;Create Note&quot; below and start writing!</p>
                </div>
            );
        }

        return (
            <NotesEditor
                isLoading={isLoading}
                isReadOnly={isReadOnly}
                editorState={editorState}
                setEditorState={setEditorState}
            />
        );
    };

    useEffect(() => {
        return () => setShowConfirmSaveModal(false);
    }, []);

    return (
        <div className={cx('container')}>
            <Drawer
                visible={isNotesPanelOpen}
                getContainer={false}
                onClose={handleCloseWithModalIfNotReadOnly}
                closable={!isLoading}
                mask={false}
                title="Notes"
                width={475}
                footer={footer}
            >
                {handleRender()}
            </Drawer>
            <ConfirmSaveModal
                showConfirmSaveModal={showConfirmSaveModal}
                handleCloseModal={handleCloseModal}
                handleDiscardChanges={handleDiscardChanges}
                handleSaveNotesInModal={handleSaveNotesInModal}
            />
        </div>
    );
};

export default NotesPanel;
