import React, { useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { Typography, List, Tag, Tooltip } from 'antd';
import { SelectOutlined } from '@ant-design/icons';

import FloatingToolbar from 'components/FloatingToolbar';
import GoBack from 'components/FloatingToolbar/Tooltips/GoBack';
import ActiveTabs from 'components/ActiveTabs';
import { TCSFileShape, TCSFileTypeShape } from 'common/types';
import { deriveActiveFileTypes } from './utils';

import { defaultFilters, fileTypeColours } from './constants';

import styles from './ContentSelector.module.scss';

const cx = classnames.bind(styles);

export const getFileTypeName = (fileTypes: TCSFileTypeShape[], ftID: number): string => {
    const ft = fileTypes.find((f) => f.id === ftID);
    return ft ? ft.name : '';
};

type ContentSelectorProps = {
    topicName: string;
    files: TCSFileShape[];
    fileTypes: TCSFileTypeShape[];
    handleSelectFile: (file: TCSFileShape) => void;
    handleGoBack: () => void;
    handleOpenInNewTab: (fileKey: string) => void;
};

const ContentSelector: React.FC<ContentSelectorProps> = ({
    topicName,
    files,
    fileTypes,
    handleSelectFile,
    handleGoBack,
    handleOpenInNewTab,
}) => {
    const activeFileTypes = useMemo(() => deriveActiveFileTypes(fileTypes, files), [files]);

    const [filteredFiles, setFilteredFiles] = useState(files);
    const [selectedFilterId, setSelectedFilterId] = useState(0);

    const filters = useMemo(() => [...defaultFilters, ...activeFileTypes], [activeFileTypes]);

    const handleFilterFiles = (filterId: number) => {
        filterId === 0
            ? setFilteredFiles(files)
            : setFilteredFiles(files.filter((file) => file.file_type_id === filterId));

        setSelectedFilterId(filterId);
    };

    return (
        <div className={cx('container')}>
            <div className={cx('title-container')}>
                <Typography.Title level={3}>{topicName}</Typography.Title>
            </div>

            <div className={cx('active-tabs-container')}>
                <ActiveTabs
                    options={filters}
                    selectedOptionId={selectedFilterId}
                    onTabClick={handleFilterFiles}
                />
            </div>

            <div className={cx('list-container')}>
                <Typography.Title level={5}>Self Study Materials</Typography.Title>

                <List
                    dataSource={filteredFiles}
                    rowKey={(record) => record.key}
                    renderItem={(file) => (
                        <List.Item className={cx('list-item-container')}>
                            <div
                                className={cx('list-item-link')}
                                onClick={() => handleSelectFile(file)}
                                data-cy={`content-selector__${file.original_file_name}`}
                            >
                                <span className={cx('file-name')}>{file.original_file_name}</span>

                                <Tag color={fileTypeColours[file.file_type_id]}>
                                    {getFileTypeName(fileTypes, file.file_type_id)}
                                </Tag>
                            </div>

                            <Tooltip placement="top" title="Open in New Tab">
                                <div
                                    className={cx('list-icon')}
                                    onClick={() => handleOpenInNewTab(file.key)}
                                >
                                    <SelectOutlined />
                                </div>
                            </Tooltip>
                        </List.Item>
                    )}
                />
            </div>

            <FloatingToolbar tooltips={[<GoBack key="go-back" callback={handleGoBack} />]} />
        </div>
    );
};

export default ContentSelector;
