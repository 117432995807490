import React from 'react';
import classnames from 'classnames/bind';
import { List } from 'antd';

import TypographyBody from 'components/TypographyBody';

import styles from './AnswerCard.module.scss';

const cx = classnames.bind(styles);

type AnswerCardProps = {
    name: string;
    isCorrectAnswer: boolean;
    isAnsweredCorrectly: boolean;
    isSelected: boolean;
};

const AnswerCard: React.FC<AnswerCardProps> = ({
    name,
    isCorrectAnswer,
    isAnsweredCorrectly,
    isSelected,
}) => {
    return (
        <div className={cx('container')}>
            <List.Item
                className={cx('content-container', {
                    correct: isAnsweredCorrectly && isSelected,
                    wrong: !isAnsweredCorrectly && !isCorrectAnswer && isSelected,
                })}
            >
                <div className={cx('text-container')}>
                    <TypographyBody
                        level={1}
                        className={cx('text', { 'tag-padding-top': isCorrectAnswer })}
                    >
                        {name}
                    </TypographyBody>
                </div>

                {isCorrectAnswer && (
                    <div className={cx('tag-container')}>
                        <TypographyBody level={2} className={cx('tag')}>
                            <span>Correct Answer</span>
                        </TypographyBody>
                    </div>
                )}
            </List.Item>
        </div>
    );
};

export default AnswerCard;
