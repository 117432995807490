import React from 'react';
import classnames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';

import styles from './DropdownMenu.module.scss';

const cx = classnames.bind(styles);

type DropdownMenuProps = {
    userInitial: string;
    handleSignOut: () => void;
};

const DropdownMenu: React.FC<DropdownMenuProps> = ({ userInitial, handleSignOut }) => {
    const dropdownMenu = (
        <Menu>
            <Menu.Item>
                <Link to="/my-dashboard">My Dashboard</Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/my-account">My Account</Link>
            </Menu.Item>
            <Menu.Item key="4" onClick={handleSignOut} data-cy="navigation-bar__logout">
                <Link to="/#">Log Out</Link>
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown
            overlay={dropdownMenu}
            placement="bottomRight"
            trigger={['click']}
            overlayClassName={cx('dropdown-menu')}
        >
            <div className={cx('avatar-container')}>
                <div className={cx('initial-placeholder')} data-cy="navigation-bar__avatar">
                    {userInitial}
                </div>
            </div>
        </Dropdown>
    );
};

export default DropdownMenu;
