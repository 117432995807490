import React, { useEffect, useState } from 'react';

import { useAxiosInstance } from 'components/AxiosContext';

import Conversation from './Conversation';
import { GETThreadMessages } from './types';

type ConversationContainerProps = {
    title: string;
    goBack: () => void;
    threadCreatedAt: number;
    isResolved: boolean;
    handleThreadsRefetch: () => void;
};

const ConversationContainer: React.FC<ConversationContainerProps> = ({
    title,
    goBack,
    threadCreatedAt,
    isResolved,
    handleThreadsRefetch,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [refetchCounter, setRefetchCounter] = useState(0);

    // to only show loading spinner during the initial thread loading, but not after replying to thread
    const [threadInitDone, setThreadInitDone] = useState(false);

    const handleMessagesRefetch = () => {
        setRefetchCounter((prevState) => prevState + 1);
    };

    const [messages, setMessages] = useState<GETThreadMessages[]>([]);

    const axios = useAxiosInstance();

    const loadMessages = async () => {
        try {
            setIsLoading(true);
            setHasError(false);

            const { data } = await axios.get<GETThreadMessages[]>(
                `/private/v2/ask-a-question/thread/messages?threadCreatedAt=${threadCreatedAt}`
            );

            setMessages(data);
            setThreadInitDone(true);
        } catch {
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const replyToThread = async (message: string) => {
        try {
            setIsLoading(true);

            await axios.post('/private/v2/ask-a-question/thread/message/new', {
                threadCreatedAt,
                message,
            });
        } catch {
            throw Error();
        } finally {
            setIsLoading(false);
        }
    };

    const markQuestionAsResolved = async () => {
        try {
            await axios.put(
                `/private/v2/ask-a-question/thread/resolve?threadCreatedAt=${threadCreatedAt}`
            );
        } catch {
            throw Error();
        }
    };

    const handleMarkQuestionAsResolved = async () => {
        try {
            setIsLoading(true);
            await markQuestionAsResolved();

            handleThreadsRefetch();
        } catch {
            throw Error();
        } finally {
            setIsLoading(false);
        }
    };

    const onBack = () => {
        goBack();
        setThreadInitDone(false);
    };

    useEffect(() => {
        loadMessages();
    }, [refetchCounter]);

    return (
        <Conversation
            title={title}
            isLoading={isLoading}
            hasError={hasError}
            messages={messages}
            handleMessagesRefetch={handleMessagesRefetch}
            replyToThread={replyToThread}
            initDone={threadInitDone}
            onBack={onBack}
            threadCreatedAt={threadCreatedAt}
            isResolved={isResolved}
            handleMarkQuestionAsResolved={handleMarkQuestionAsResolved}
        />
    );
};

export default ConversationContainer;
