import React from 'react';
import classnames from 'classnames/bind';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { Spin } from 'antd';

import toolbarOptions from './toolbarOptions';

import styles from './NotesEditor.module.scss';

const cx = classnames.bind(styles);

type NotesEditorProps = {
    isLoading: boolean;
    isReadOnly: boolean;
    editorState: EditorState;
    setEditorState: (editorState: EditorState) => void;
};

const NotesEditor: React.FC<NotesEditorProps> = ({
    isLoading,
    isReadOnly,
    editorState,
    setEditorState,
}) => {
    // The default behaviour still shows the placeholder when ordered/unordered list is toggled on.
    // As encouraged in the doc, we use this function to toggle the CSS for the placeholder behaviour
    // https://draftjs.org/docs/api-reference-editor/#placeholder
    const shouldHidePlaceholder = (editorState: EditorState): boolean => {
        const contentState = editorState.getCurrentContent();
        return (
            contentState.hasText() || contentState.getBlockMap().first().getType() !== 'unstyled'
        );
    };

    return (
        <div className={cx('editor-container')}>
            {isLoading && (
                <div
                    className={cx('overlay-spin-container')}
                    data-testid="notes-editor-overlay-spin-loader"
                >
                    <Spin size="large" />
                </div>
            )}
            <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                editorClassName={cx(
                    'editor',
                    shouldHidePlaceholder(editorState) || isLoading ? 'hide-placeholder' : ''
                )}
                toolbarClassName={cx('toolbar')}
                handlePastedText={() => false}
                placeholder="Start writing your notes here"
                toolbar={toolbarOptions}
                readOnly={isReadOnly}
                toolbarHidden={isReadOnly}
            />
        </div>
    );
};

export default NotesEditor;
