import React, { useState } from 'react';
import { Input, Button, message, Divider } from 'antd';

import ErrorMessageWrapper from 'components/ErrorMessageWrapper';

import { isMinimumLength, areTheSame } from './utils';
import { minimumLengthMessage, sameFieldsMessage, requiredFieldMessage } from './errorMessages';
import AuthenticationCard from './AuthenticationCard';

enum EResetPasswordFormField {
    CODE = 'CODE',
    NEW_PASSWORD = 'NEW_PASSWORD',
    CONFIRM_NEW_PASSWORD = 'CONFIRM_NEW_PASSWORD',
}

const resetPasswordFormValidations = {
    [EResetPasswordFormField.CODE]: (code: string) => !isMinimumLength(code, 1),
    [EResetPasswordFormField.NEW_PASSWORD]: (password: string) => !isMinimumLength(password, 12),
    [EResetPasswordFormField.CONFIRM_NEW_PASSWORD]: (password1: string, password2: string) =>
        !areTheSame(password1, password2),
};

type ResetPasswordProps = {
    isLoading: boolean;
    handleResetPassword: (code: string, newPassword: string) => Promise<void>;
    goToLogin: () => void;
};

const ResetPassword: React.FC<ResetPasswordProps> = ({
    isLoading,
    handleResetPassword,
    goToLogin,
}) => {
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [formErrors, setFormErrors] = useState<Record<string, null | string>>({
        [EResetPasswordFormField.CODE]: null,
        [EResetPasswordFormField.NEW_PASSWORD]: null,
        [EResetPasswordFormField.CONFIRM_NEW_PASSWORD]: null,
    });

    const validateForm = () => {
        const codeError = resetPasswordFormValidations[EResetPasswordFormField.CODE](code);
        const newPasswordError =
            resetPasswordFormValidations[EResetPasswordFormField.NEW_PASSWORD](newPassword);
        const confirmNewPasswordError = resetPasswordFormValidations[
            EResetPasswordFormField.CONFIRM_NEW_PASSWORD
        ](newPassword, confirmNewPassword);

        setFormErrors({
            [EResetPasswordFormField.CODE]: codeError
                ? requiredFieldMessage('confirmation code')
                : null,
            [EResetPasswordFormField.NEW_PASSWORD]: newPasswordError
                ? minimumLengthMessage('password', 12)
                : null,
            [EResetPasswordFormField.CONFIRM_NEW_PASSWORD]: confirmNewPasswordError
                ? sameFieldsMessage('passwords')
                : null,
        });

        return !codeError && !newPasswordError && !confirmNewPasswordError;
    };

    const onResetPasswordButtonClick = async () => {
        if (validateForm()) {
            try {
                await handleResetPassword(code, newPassword);
            } catch (error) {
                if (error.message === 'CodeMismatchException') {
                    message.error('Invalid verification code provided, please try again');
                } else {
                    message.error(
                        'Oops! There was an error with resetting your password. Please try again.'
                    );
                }
            }
        } else {
            message.error('Please fix the form error');
        }
    };

    return (
        <AuthenticationCard title="Reset password">
            <ErrorMessageWrapper errorMessage={formErrors[EResetPasswordFormField.CODE]}>
                <Input
                    placeholder="Enter Code"
                    value={code}
                    name={EResetPasswordFormField.CODE}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
                />
            </ErrorMessageWrapper>

            <ErrorMessageWrapper errorMessage={formErrors[EResetPasswordFormField.NEW_PASSWORD]}>
                <Input.Password
                    placeholder="New password"
                    value={newPassword}
                    name={EResetPasswordFormField.NEW_PASSWORD}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setNewPassword(e.target.value)
                    }
                />
            </ErrorMessageWrapper>

            <ErrorMessageWrapper
                errorMessage={formErrors[EResetPasswordFormField.CONFIRM_NEW_PASSWORD]}
            >
                <Input.Password
                    placeholder="Confirm password"
                    value={confirmNewPassword}
                    name={EResetPasswordFormField.CONFIRM_NEW_PASSWORD}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setConfirmNewPassword(e.target.value)
                    }
                />
            </ErrorMessageWrapper>

            <Button type="primary" loading={isLoading} block onClick={onResetPasswordButtonClick}>
                Reset password
            </Button>

            <Divider />

            <Button block onClick={goToLogin}>
                Back to login
            </Button>
        </AuthenticationCard>
    );
};

export default ResetPassword;
