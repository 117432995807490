import zlib from 'zlib';
import { convertToRaw, EditorState } from 'draft-js';

export const createUnixTimestampNow = () => Math.floor(Date.now() / 1000);

export const compressStringToBufferString = (str: string): string => {
    const compressed = zlib.gzipSync(str);
    return compressed.toString('binary');
};

export const checkIsEditorStateChanged = (initial: EditorState, current: EditorState): boolean => {
    return initial.getCurrentContent() !== current.getCurrentContent();
};

export const deriveCompressedBufferString = (editorState: EditorState): string => {
    const isCurrentNotesEmpty = !editorState.getCurrentContent().hasText();

    if (isCurrentNotesEmpty) {
        return '';
    }

    const contentState = editorState.getCurrentContent();
    const rawDraftContentState = convertToRaw(contentState);

    return compressStringToBufferString(JSON.stringify(rawDraftContentState));
};
