import Quizzes from 'static/common/quizzes.svg';
import QuestionBank from 'static/common/question-bank.svg';
import VideoAndNotes from 'static/common/notes-and-videos.svg';

export const sectionDetails = [
    {
        title: 'Notes & Videos',
        url: '/self-study',
        imgSrc: VideoAndNotes,
        description:
            'Short videos and condensed notes to accelerate content revision by focusing on the important stuff.',
    },
    {
        title: 'Question Bank',
        url: '/question-bank',
        imgSrc: QuestionBank,
        description: 'Topical, exam-style questions with model answers to develop exam-readiness.',
    },
    {
        title: 'Quizzes',
        url: '/quizzes',
        imgSrc: Quizzes,
        description:
            'Short, multiple-choice quizzes that identify key content gaps and help to focus your revision efforts.',
    },
];
