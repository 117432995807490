// TODO - need to refactor this or the one in state.ts cause the name is clashing
// type GETProfilesSubscriptionInfoPayload = {
export type subscriptionsInfoEndpointType = {
    subscription_id: string;
    cancel_at: number; // unix time stamp
    cancel_at_period_end: boolean;
    subscription_end: number; // unix time stamp
    subscription_status: string; // 'active' | 'expired' -> full list found here https://stripe.com/docs/api/subscriptions/object#subscription_object-status
    interval: string; // 'year' | 'month'
    interval_count: number;
    payment_method_id: string;
    credit_card_last_4_digits: string;
    latest_invoice_url: string;
};

export type GETV2UsersProfileSubscriptionInfo = {
    subscriptionId: string;
    cancelAt: number; // unix timestamp
    cancelAtPeriodEnd: boolean;
    subscriptionEnd: number; // unix timestamp
    subscriptionStatus: string; // 'active' | 'expired' -> full list found here https://stripe.com/docs/api/subscriptions/object#subscription_object-status
    interval: string; // 'year' | 'month'
    intervalCount: number;
    paymentMethodId: string;
    creditCardLast4Digits: string;
    latestInvoiceUrl: string;
};

export enum EMySubscriptionStatus {
    'ACTIVE',
    'FREETRIAL',
    'NONE',
}
