import React from 'react';
import classnames from 'classnames/bind';

import styles from './TypographySubtitle.module.scss';

type TypographySubtitleProps = {
    level: 1;
    className?: string;
};

const cx = classnames.bind(styles);

const TypographySubtitle: React.FC<TypographySubtitleProps> = ({ level, className, children }) => {
    return <div className={cx(`subtitle-${level}`, className)}>{children}</div>;
};

export default TypographySubtitle;
