import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';

import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getGetStudyPlanCounter } from 'components/ReducerContext/selectors';

import Conversation from './Conversation';
import AddQuestion from './AddQuestion';
import ThreadList from './ThreadList';
import { EAskAQuestionStatus, GETThreadList } from '../types';

import styles from './Popup.module.scss';

const cx = classnames.bind(styles);

type PopupProps = {
    closePopup: () => void;
    threads: GETThreadList[];
    handleThreadsRefetch: () => void;
    isLoading: boolean;
    hasError: boolean;
    onGetStudyPlan: () => void;
};

const Popup: React.FC<PopupProps> = ({
    closePopup,
    threads,
    handleThreadsRefetch,
    isLoading,
    hasError,
}) => {
    const [showAddQuestion, setShowAddQuestion] = useState(false);
    const [activeThreadCreatedAt, setActiveThreadCreatedAt] = useState(0);

    const activeThread = useMemo(
        () => threads.find((thread) => thread.createdAt === activeThreadCreatedAt),
        [activeThreadCreatedAt, threads]
    );

    const { state } = useReducerContext();
    const getStudyPlanCounter = useMemo(() => getGetStudyPlanCounter(state), [state]);

    const selectThread = (thread: GETThreadList) => {
        setActiveThreadCreatedAt(thread.createdAt);
    };

    const handleConversationGoBack = () => {
        setActiveThreadCreatedAt(0);
        handleThreadsRefetch();
    };

    const onGetStudyPlan = () => {
        setShowAddQuestion(true);
    };

    useEffect(() => {
        if (getStudyPlanCounter === 0) {
            return;
        }

        onGetStudyPlan();
    }, [getStudyPlanCounter]);

    const renderContent = () => {
        if (activeThreadCreatedAt) {
            return (
                <Conversation
                    title={activeThread?.title || ''}
                    goBack={handleConversationGoBack}
                    threadCreatedAt={activeThreadCreatedAt}
                    isResolved={activeThread?.threadStatus === EAskAQuestionStatus.RESOLVED}
                    handleThreadsRefetch={handleThreadsRefetch}
                />
            );
        }

        if (showAddQuestion) {
            return (
                <AddQuestion
                    goBack={() => setShowAddQuestion(false)}
                    handleThreadsRefetch={handleThreadsRefetch}
                />
            );
        }

        return (
            <ThreadList
                closePopup={closePopup}
                threads={threads}
                selectThread={selectThread}
                handleShowAddQuestion={() => setShowAddQuestion(true)}
                handleThreadsRefetch={handleThreadsRefetch}
                isLoading={isLoading}
                hasError={hasError}
            />
        );
    };

    return <div className={cx('container')}>{renderContent()}</div>;
};

export default Popup;
