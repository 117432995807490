import React, { useState } from 'react';

import { useAxiosInstance } from 'components/AxiosContext';

import CohortPopup from './CohortPopup';

type CohortPopupContainerProps = {
    visible: boolean;
    onClose: () => void;
};

const CohortPopupContainer: React.FC<CohortPopupContainerProps> = ({ visible, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);

    const axios = useAxiosInstance();

    const updateCohort = async (year: number, month: string) => {
        try {
            setIsLoading(true);

            await axios.put('/private/v2/users/profile/cohort', {
                year,
                month,
            });
        } catch {
            throw Error();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <CohortPopup
            visible={visible}
            onClose={onClose}
            isLoading={isLoading}
            updateCohort={updateCohort}
        />
    );
};

export default CohortPopupContainer;
