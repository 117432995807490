import React from 'react';
import classnames from 'classnames/bind';
import { Tag } from 'antd';

import { formatUnixTimestamp } from 'common/utils';
import { deriveTagColorFromThreadStatus, deriveTagTextFromThreadStatus } from './utils';
import { GETThreadList } from '../../../types';

import styles from './ThreadItem.module.scss';

const cx = classnames.bind(styles);

type ThreadProps = {
    thread: GETThreadList;
    onClick: () => void;
};

const ThreadItem: React.FC<ThreadProps> = ({ thread, onClick }) => {
    return (
        <div className={cx('container')} onClick={onClick}>
            <div className={cx('info')}>
                <div className={cx('title')}>{thread.title}</div>
                <div className={cx('created-at')}>
                    {formatUnixTimestamp(thread.createdAt, 'HH:mm dd/MM/yyyy')}
                </div>
            </div>
            <div>
                <Tag
                    color={deriveTagColorFromThreadStatus(thread.threadStatus)}
                    className={cx('tag')}
                >
                    {deriveTagTextFromThreadStatus(thread.threadStatus)}
                </Tag>
            </div>
        </div>
    );
};

export default ThreadItem;
