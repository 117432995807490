import React from 'react';
import { useHistory } from 'react-router';

import SubscriptionSuccess from './SubscriptionSuccess';

const SubscriptionSuccessContainer: React.FC = () => {
    const history = useHistory();

    const handleOnProceed = () => {
        history.push('/self-study');
    };

    return <SubscriptionSuccess onProceed={handleOnProceed} />;
};

export default SubscriptionSuccessContainer;
