import React, { useEffect, useState } from 'react';
import { message } from 'antd';

import { useAxiosInstance } from 'components/AxiosContext';

import PurchasedLessonViewer from './PurchasedLessonViewer';
import { PurchasedRecordedLesson } from '../types';

type PurchasedLessonViewerContainerProps = {
    purchasedLesson: PurchasedRecordedLesson;
    handleGoBack: () => void;
};

const PurchasedLessonViewerContainer: React.FC<PurchasedLessonViewerContainerProps> = ({
    purchasedLesson,
    handleGoBack,
}) => {
    const [signedFullVideoUrl, setSignedFullVideoUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const axios = useAxiosInstance();

    const fetchSignedFullVideoUrl = async () => {
        try {
            setIsLoading(true);
            const { data } = await axios.get('/private/v2/recorded-lessons/sign/full-video', {
                params: {
                    recordedLessonId: purchasedLesson.id,
                },
            });

            setSignedFullVideoUrl(data);
        } catch {
            message.error('Something went wrong, please try again later');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchSignedFullVideoUrl();
    }, []);

    return (
        <PurchasedLessonViewer
            signedFullVideoUrl={signedFullVideoUrl}
            title={purchasedLesson.name}
            isLoading={isLoading}
            handleGoBack={handleGoBack}
        />
    );
};

export default PurchasedLessonViewerContainer;
