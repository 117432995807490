import React from 'react';
import classnames from 'classnames/bind';
import { List, Spin } from 'antd';

import { PurchasedRecordedLesson } from '../types';
import PurchasedLessonCard from './PurchasedLessonCard';

import styles from './PurchasedLessons.module.scss';
const cx = classnames.bind(styles);

type PurchasedLessonsProps = {
    purchasedLessons: PurchasedRecordedLesson[];
    isLoading: boolean;
    onWatchVideoClick: (lesson: PurchasedRecordedLesson) => void;
};

const PurchasedLessons: React.FC<PurchasedLessonsProps> = ({
    purchasedLessons,
    isLoading = false,
    onWatchVideoClick,
}) => {
    if (isLoading) {
        return (
            <div className={cx('loading-container')}>
                <Spin size="large" />
            </div>
        );
    }

    const renderContent = () => {
        return (
            <List
                dataSource={purchasedLessons}
                renderItem={(item) => (
                    <PurchasedLessonCard lesson={item} onWatchVideoClick={onWatchVideoClick} />
                )}
            />
        );
    };

    return <div className={cx('container')}>{renderContent()}</div>;
};

export default PurchasedLessons;
