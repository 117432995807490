import React, { useMemo, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import { Image, Spin } from 'antd';

import TypographyHeader from 'components/TypographyHeader';
import TypographySubtitle from 'components/TypographySubtitle';
import OptionCard from 'components/OptionCard';
import TCSButton from 'components/TCSButton';
import ProgressIndicator from 'components/ProgressIndicator';
import { GETMistakesQuizPayload, QuizAnswer } from './types';

import styles from './QuizSetViewer.module.scss';

const cx = classnames.bind(styles);

type QuizSetViewerProps = {
    isLoading: boolean;
    mistakesQuiz: GETMistakesQuizPayload[];
    submitMistakesQuiz: (correctQuizIdList: number[]) => Promise<void>;
    onSubmitAnswer: (answer: QuizAnswer) => void;
    onEndQuiz: () => void;
};

const QuizSetViewer: React.FC<QuizSetViewerProps> = ({
    isLoading,
    mistakesQuiz,
    submitMistakesQuiz,
    onSubmitAnswer,
    onEndQuiz,
}) => {
    const defaultIsAnsweredArray = useMemo(
        () => Array(mistakesQuiz.length).fill(false),
        [mistakesQuiz]
    );

    const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
    const [currentSelectedAnswer, setCurrentSelectedAnswer] = useState<QuizAnswer | null>(null);
    const [isAnsweredArray, setIsAnsweredArray] = useState<boolean[]>(defaultIsAnsweredArray);

    const correctQuizIdList = useRef<number[]>([]);

    const currentQuiz = useMemo(
        () => mistakesQuiz[currentQuizIndex],
        [currentQuizIndex, mistakesQuiz]
    );

    const updateIsAnsweredArray = () => {
        const newArray = [...isAnsweredArray];
        newArray[currentQuizIndex] = true;
        setIsAnsweredArray(newArray);
    };

    const handleSubmit = async () => {
        if (!currentSelectedAnswer) {
            return;
        }

        updateIsAnsweredArray();
        onSubmitAnswer(currentSelectedAnswer);

        if (currentSelectedAnswer.isCorrectAnswer) {
            correctQuizIdList.current.push(currentQuiz.id);
        }

        if (currentQuizIndex === mistakesQuiz.length - 1) {
            handleEndQuiz();
            return;
        }

        setCurrentSelectedAnswer(null);
        setCurrentQuizIndex((prevState) => prevState + 1);
    };

    const handleEndQuiz = () => {
        if (correctQuizIdList.current.length !== 0) {
            submitMistakesQuiz(correctQuizIdList.current);
        }

        onEndQuiz();
    };

    const handleOptionCardClick = (quizAnswer: QuizAnswer) => {
        setCurrentSelectedAnswer(quizAnswer);
    };

    const renderContent = () => {
        if (isLoading) {
            return (
                <div className={cx('spin-container')}>
                    <Spin size="large" />
                </div>
            );
        }

        if (mistakesQuiz.length === 0 || !currentQuiz) {
            return <div>The current quiz set is empty. </div>;
        }

        return (
            <div>
                <div className={cx('progress-indicator-container')}>
                    <ProgressIndicator
                        currentStep={currentQuizIndex}
                        isAnsweredArray={isAnsweredArray}
                    />
                </div>
                <TypographySubtitle level={1} className={cx('subtitle')}>
                    Q{currentQuizIndex + 1}. {currentQuiz.questionText}
                </TypographySubtitle>

                {currentQuiz.questionImage !== '' && (
                    <div className={cx('image-container')}>
                        <Image src={currentQuiz.questionImage} width="100%" preview={false} />
                    </div>
                )}

                <div>
                    {currentQuiz.quizAnswers.map((quizAnswer) => (
                        <OptionCard
                            key={quizAnswer.id}
                            name={quizAnswer.answerText}
                            onClick={() => handleOptionCardClick(quizAnswer)}
                            isSelected={currentSelectedAnswer?.id === quizAnswer.id}
                        />
                    ))}
                </div>

                <div className={cx('buttons-footer')}>
                    <TCSButton
                        onClick={handleSubmit}
                        type="primary"
                        className={cx('view-summary-button')}
                        disabled={!currentSelectedAnswer}
                    >
                        Next
                    </TCSButton>
                </div>
            </div>
        );
    };

    return (
        <div className={cx('container')}>
            <div className={cx('content-container')}>
                <TypographyHeader className={cx('title')} level={3}>
                    Quiz Challenge - Mistakes Practice
                </TypographyHeader>

                {renderContent()}
            </div>
        </div>
    );
};

export default QuizSetViewer;
