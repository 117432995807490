import React from 'react';
import { Button } from 'antd';
import classnames from 'classnames/bind';

import TypographyHeader from 'components/TypographyHeader';
import { TCSSubject } from 'common/types';
import { availableSubjects } from './constants';

import styles from './LandingView.module.scss';

const cx = classnames.bind(styles);

type LandingViewProps = {
    handleSelectSubject: (subject: TCSSubject) => void;
};

const LandingView: React.FC<LandingViewProps> = ({ handleSelectSubject }) => {
    return (
        <div className={cx('container')}>
            <div className={cx('content-container')}>
                <TypographyHeader level={3} className={cx('title')}>
                    Cloudscore Quiz Challenge
                </TypographyHeader>

                <div className={cx('content')}>
                    <div>
                        Test your content proficiency and see how you rank among IB students
                        globally!
                    </div>

                    <ol>
                        <li>Paid subscribers get ranked on our weekly leaderboard!</li>
                        <li>Unsure about a question? Just skip it to maintain your streak!</li>
                        <li>Beware! You are limited to 5 consecutive skips!</li>
                    </ol>

                    <div>Good luck!</div>
                </div>

                <div className={cx('buttons-container')}>
                    {availableSubjects.map((subject) => (
                        <Button onClick={() => handleSelectSubject(subject)} key={subject.id}>
                            {subject.name}
                        </Button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LandingView;
