import { QuizChallengeQuestion } from './types';

export const defaultQuizChallengeQuestion: QuizChallengeQuestion = {
    id: 0,
    questionText: '',
    questionImage: '',
    quizSetId: 0,
    quizAnswers: [],
};

export const defaultNavigateAwayMessage =
    'Are you sure you want to leave this page? You will lose all your progress!';

export enum ERecommendedContentType {
    NOTES = 'NOTES',
    VIDEO = 'VIDEO',
}

export const defaultRecommendedContent = {
    contentType: '',
    url: '',
    title: '',
};

export const NAVIGATION_BAR_HEIGHT = 97;
