import React from 'react';
import classnames from 'classnames/bind';
import { Image } from 'antd';

import DwightSchoolSeoulLogo from 'static/landing/school-logos/dwight-school-seoul.png';
import CanadianInternationalSchoolLogo from 'static/landing/school-logos/canadian-international-school-bangalore.png';
import AmericanSchoolOfDohaLogo from 'static/landing/school-logos/american-school-of-doha.png';
import DubaiAmericanAcademyGEMSLogo from 'static/landing/school-logos/dubai-american-academy-gems.svg';
import ACSLogo from 'static/landing/school-logos/anglo-chinese-school-crest.png';
import MarlboroughCollegeMalaysiaLogo from 'static/landing/school-logos/marlborough-college-malaysia.png';
import StAndrewBangkokLogo from 'static/landing/school-logos/st-andrews-bangkok.png';
import UISGuangzhouLogo from 'static/landing/school-logos/uis-guangzhou.png';
import UWCLogo from 'static/landing/school-logos/uwc.png';

import styles from './SchoolsSection.module.scss';
const cx = classnames.bind(styles);

const SchoolsSection: React.FC = () => {
    return (
        <div className={cx('container')}>
            <div className={cx('text')}>Trusted by students from leading IB schools globally</div>
            <div className={cx('school-logos-container')}>
                <Image src={DwightSchoolSeoulLogo} width={140} preview={false} />
                <Image src={CanadianInternationalSchoolLogo} width={110} preview={false} />
                <Image src={AmericanSchoolOfDohaLogo} width={100} preview={false} />
                <Image
                    src={DubaiAmericanAcademyGEMSLogo}
                    width={150}
                    height={100}
                    preview={false}
                />
                <Image src={ACSLogo} width={100} preview={false} />
                <Image src={MarlboroughCollegeMalaysiaLogo} width={130} preview={false} />
                <Image src={StAndrewBangkokLogo} preview={false} width={140} />
                <Image src={UISGuangzhouLogo} width={110} preview={false} />
                <Image src={UWCLogo} width={110} preview={false} />
            </div>
        </div>
    );
};

export default SchoolsSection;
