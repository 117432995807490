import React from 'react';
import classnames from 'classnames/bind';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

import styles from './GenericErrorMessage.module.scss';

const cx = classnames.bind(styles);

const GenericErrorMessage: React.FC = () => {
    return (
        <div className={cx('container')}>
            <Typography.Title level={4}>
                Oops! Sorry, but it seems like we encountered an error. Please refresh the page and
                try again.
                <br />
                If this issue persists, please kindly reach out to us at any one of our
                communication channels. You can find them <Link to="/contact-us">here</Link>.
            </Typography.Title>
        </div>
    );
};

export default GenericErrorMessage;
