import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import classnames from 'classnames/bind';

import styles from './Tooltips.module.scss';

const cx = classnames.bind(styles);

type GoBackProps = {
    callback: () => void;
};

const GoBack: React.FC<GoBackProps> = ({ callback }) => {
    return (
        <div className={cx('go-back')} onClick={callback}>
            <ArrowLeftOutlined style={{ fontSize: '28px' }} />
            <div className={cx('text')}>Back</div>
        </div>
    );
};

export default GoBack;
