import React from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames/bind';

import CTAButton from 'components/CTAButton';

import styles from './CTASection.module.scss';
const cx = classnames.bind(styles);

const CTASection: React.FC = () => {
    const history = useHistory();

    const pushToSubInfo = () => {
        history.push('/subscriptions/info');
    };

    return (
        <div className={cx('container')}>
            <div className={cx('content-container')}>
                <h1>
                    Study IB Economics anytime, anywhere and in any way that best suits your
                    studying needs. Get everything that you possibly need to ace your Economics for
                    just USD15/month.
                </h1>
                <CTAButton onClick={pushToSubInfo}>Get Started for Free</CTAButton>
            </div>
        </div>
    );
};

export default CTASection;
