import React, { useMemo } from 'react';

import { getUserProfile } from 'components/ReducerContext/selectors';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';

import DropdownMenu from 'components/NavigationBar/DropdownMenu/DropdownMenu';

type DropdownMenuContainerProps = {
    handleSignOut: () => void;
};

const DropdownMenuContainer: React.FC<DropdownMenuContainerProps> = ({ handleSignOut }) => {
    const { state } = useReducerContext();
    const profile = useMemo(() => getUserProfile(state), [state]);

    const userInitial = profile && profile.firstName ? profile.firstName[0] : '';

    return <DropdownMenu userInitial={userInitial} handleSignOut={handleSignOut} />;
};

export default DropdownMenuContainer;
