import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { datadogRum } from '@datadog/browser-rum';

import ArcPdfViewer from 'components/ArcPdfViewer';
import FloatingToolbar from 'components/FloatingToolbar';
import GoBack from 'components/FloatingToolbar/Tooltips/GoBack';
import GetStudyPlan from 'components/FloatingToolbar/Tooltips/GetStudyPlan';
import BackToTop from 'components/FloatingToolbar/Tooltips/BackToTop';
import ZoomIn from 'components/FloatingToolbar/Tooltips/ZoomIn';
import ZoomOut from 'components/FloatingToolbar/Tooltips/ZoomOut';
import ThumbsUp from 'components/FloatingToolbar/Tooltips/ThumbsUp';
import ThumbsDown from 'components/FloatingToolbar/Tooltips/ThumbsDown';
import WriteNotes from 'components/FloatingToolbar/Tooltips/WriteNotes';
import NotesPanel from 'components/NotesPanel';
import TypographyHeader from 'components/TypographyHeader';
import { TCSFileShape } from 'common/types';
import { deriveDatadogRumDataFromFile } from 'common/utils';
import { HIDE_NOTES } from 'flags';

import { EThumbsUpDownState } from '../types';

import styles from './PdfViewer.module.scss';

const cx = classnames.bind(styles);

type PdfViewerProps = {
    file: TCSFileShape;
    thumbsUpStatus: EThumbsUpDownState;
    thumbsDownStatus: EThumbsUpDownState;
    isNotesPanelOpen: boolean;
    setIsNotesPanelOpen: (open: boolean) => void;
    handleGoBack: () => void;
    handleThumbsUp: () => void;
    handleThumbsDown: () => void;
};

const PdfViewer: React.FC<PdfViewerProps> = ({
    file,
    thumbsUpStatus,
    thumbsDownStatus,
    isNotesPanelOpen,
    setIsNotesPanelOpen,
    handleGoBack,
    handleThumbsUp,
    handleThumbsDown,
}) => {
    const [scale, setScale] = useState(1.0);

    const handleBackToTop = () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    const handleZoomIn = () => {
        if (scale >= 3.0) return;
        setScale((scale) => scale + 0.25);
    };

    const handleZoomOut = () => {
        if (scale <= 0.5) return;
        setScale((scale) => scale - 0.25);
    };

    const handleOpenNotesPanel = () => {
        setIsNotesPanelOpen(true);

        datadogRum.addAction('User open notes panel', deriveDatadogRumDataFromFile(file));
    };

    const tooltips = [
        <GoBack key="go-back" callback={handleGoBack} />,
        <GetStudyPlan key="get-study-plan" />,
        <BackToTop key="back-to-top" callback={handleBackToTop} />,
        <ZoomIn key="zoom-in" callback={handleZoomIn} />,
        <ZoomOut key="zoom-out" callback={handleZoomOut} />,
        <ThumbsUp key="thumbs-up" callback={handleThumbsUp} status={thumbsUpStatus} />,
        <ThumbsDown key="thumbs-down" callback={handleThumbsDown} status={thumbsDownStatus} />,
        <WriteNotes key="write-notes" active={isNotesPanelOpen} callback={handleOpenNotesPanel} />,
    ];

    if (HIDE_NOTES) {
        tooltips.pop();
    }

    useEffect(() => {
        return () => setIsNotesPanelOpen(false);
    }, []);

    return (
        <div className={cx('container')}>
            <div className={cx('content-container')}>
                <TypographyHeader level={3} className={cx('title')}>
                    {file.original_file_name}
                </TypographyHeader>

                <div className={cx('file-container')}>
                    <ArcPdfViewer scale={scale} fileUrl={file.url} />
                </div>
            </div>

            <div className={cx({ 'panel-width-grow': isNotesPanelOpen })}>
                <NotesPanel
                    isNotesPanelOpen={isNotesPanelOpen}
                    setIsNotesPanelOpen={setIsNotesPanelOpen}
                    file={file}
                />
            </div>

            <FloatingToolbar tooltips={tooltips} />
        </div>
    );
};

export default PdfViewer;
