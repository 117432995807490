import React from 'react';
import classnames from 'classnames/bind';

import Jumbotron from './Jumbotron';
import StatisticsSection from './StatisticsSection';
import KeyFeaturesSection from './KeyFeaturesSection';
import SchoolsSection from './SchoolsSection';
import TestimonialsSection from './TestimonialsSection';
import CTASection from './CTASection';
import QuizChallengeCTASection from './QuizChallengeCTASection';
import LearnMoreSection from './LearnMoreSection';

import styles from './LandingV2.module.scss';
const cx = classnames.bind(styles);

const LandingV2: React.FC = () => {
    return (
        <div className={cx('container')}>
            <Jumbotron />

            <StatisticsSection />

            <KeyFeaturesSection />

            <SchoolsSection />

            <TestimonialsSection />

            <CTASection />

            <QuizChallengeCTASection />

            <LearnMoreSection />
        </div>
    );
};

export default LandingV2;
