import React, { useCallback, useState } from 'react';

import { useAuth } from 'components/AuthContextProvider';

import ChangePasswordForm from './ChangePasswordForm';

const ChangePasswordFormContainer: React.FC = () => {
    const { changePassword } = useAuth();

    const [isLoading, setIsLoading] = useState(false);

    const handleChangePassword = useCallback(
        async (oldPw: string, newPw: string) => {
            try {
                setIsLoading(true);
                await changePassword(oldPw, newPw);
            } catch (err) {
                if (err.code === 'NotAuthorizedException') {
                    throw Error('INCORRECT_PASSWORD');
                }

                throw Error('DEFAULT');
            } finally {
                setIsLoading(false);
            }
        },
        [changePassword]
    );

    return <ChangePasswordForm isLoading={isLoading} changePassword={handleChangePassword} />;
};

export default ChangePasswordFormContainer;
