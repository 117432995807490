import React, { useContext } from 'react';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { setupCache } from 'axios-cache-adapter';

import { API_URL } from 'config';
import { useAuth } from 'components/AuthContextProvider';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { set401Error } from 'components/ReducerContext/Actions/CommonActions';
import axiosRetry from 'axios-retry';

type AxiosContextValue = {
    default: AxiosInstance;
    cached: AxiosInstance;
};

const cache = setupCache({
    maxAge: 30 * 60 * 1000, // 30 minutes cache
    exclude: { query: false },
});

const defaultInstance = axios.create({
    baseURL: `${API_URL}`,
});

const cachedInstance = axios.create({
    baseURL: `${API_URL}`,
    adapter: cache.adapter,
});

const contextValue: AxiosContextValue = {
    default: defaultInstance,
    cached: cachedInstance,
};

const AxiosContext = React.createContext<AxiosContextValue>(contextValue);

const AxiosContextProvider: React.FC = ({ children }) => {
    const { token } = useAuth();
    const { dispatch } = useReducerContext();

    const newDefaultInstance = axios.create({
        baseURL: `${API_URL}`,
    });

    const newCachedInstance = axios.create({
        baseURL: `${API_URL}`,
        adapter: cache.adapter,
    });

    newDefaultInstance.interceptors.request.use((config: AxiosRequestConfig) => {
        config.headers['Authorization'] = `Bearer ${token}`;
        config.withCredentials = true;
        return config;
    });

    newDefaultInstance.interceptors.response.use(undefined, (error: AxiosError) => {
        if (error.response?.status === 401) {
            set401Error(dispatch, true);
        }
        return Promise.reject(error);
    });

    newCachedInstance.interceptors.request.use((config: AxiosRequestConfig) => {
        config.headers['Authorization'] = `Bearer ${token}`;
        config.withCredentials = true;
        return config;
    });

    newCachedInstance.interceptors.response.use(undefined, (error: AxiosError) => {
        if (error.response?.status === 401) {
            set401Error(dispatch, true);
        }
        return Promise.reject(error);
    });

    // default axiosRetry to 0 retries unless specified.
    // to use axiosRetry, add `axios-retry` in the config of the individual axios call
    axiosRetry(newDefaultInstance);
    axiosRetry(newCachedInstance);

    const newContextValue = {
        default: newDefaultInstance,
        cached: newCachedInstance,
    };

    return <AxiosContext.Provider value={newContextValue}>{children}</AxiosContext.Provider>;
};

export const useAxiosInstance = (): AxiosInstance => {
    const axiosInstance = useContext(AxiosContext);

    return axiosInstance.default;
};

export const useCachedAxiosInstance = (): AxiosInstance => {
    const axiosInstance = useContext(AxiosContext);

    return axiosInstance.cached;
};

export default AxiosContextProvider;
