import { useState, useCallback, useEffect } from 'react';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { deriveUserAttr } from './utils';
import { IUserAttributes } from './types';

export const useAuthHelper = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [token, setToken] = useState('');
    const [userAttr, setUserAttr] = useState<IUserAttributes>({});

    // userIsChecked should remain constant for the lifetime of the application
    // it is purely to let the app know whether we have attempted to check for the Cognito JWT
    const [userIsChecked, setUserIsChecked] = useState(false);

    const onSignInCallback = useCallback((user: CognitoUser) => {
        const ua = deriveUserAttr(user);
        const t = user.getSignInUserSession()?.getIdToken().getJwtToken() || '';

        setUserAttr(ua);
        setIsLoggedIn(true);
        setToken(t);
    }, []);

    const onSignOutCallback = useCallback(() => {
        setUserAttr({});
        setIsLoggedIn(false);
        setToken('');
    }, []);

    const checkUser = useCallback(async () => {
        try {
            const user = (await Auth.currentAuthenticatedUser()) as CognitoUser;

            if (user) {
                const ua = deriveUserAttr(user);
                const t = user.getSignInUserSession()?.getIdToken().getJwtToken() || '';

                setUserAttr(ua);
                setIsLoggedIn(true);
                setToken(t);
            }
        } catch (e) {
            setIsLoggedIn(false);
        } finally {
            setUserIsChecked(true);
        }
    }, []);

    useEffect(() => {
        checkUser();
    }, []);

    return {
        isLoggedIn,
        userIsChecked,
        token,
        setToken,
        userAttr,
        onSignInCallback,
        onSignOutCallback,
    };
};
