import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { Spin, Typography, Divider, Button } from 'antd';
import { useHistory } from 'react-router-dom';

import { TCSSubject, TCSSubjectTopicMap, TCSTopic } from 'common/types';
import TopicSelector from 'components/TopicSelector';
import SubjectSelector from 'components/SubjectSelector';
import GenericErrorMessage from 'components/GenericErrorMessage';
import FavouriteItem from './FavouriteItem';

import styles from './MyFavourites.module.scss';

const cx = classnames.bind(styles);

type MyFavouritesProps = {
    isLoading: boolean;
    hasError: boolean;
    subjects: TCSSubject[];
    topics: TCSSubjectTopicMap;
};

const MyFavourites: React.FC<MyFavouritesProps> = ({ isLoading, hasError, subjects, topics }) => {
    const history = useHistory();

    const [activeSubject, setActiveSubject] = useState<TCSSubject | null>(null);
    const [activeTopic, setActiveTopic] = useState<TCSTopic | null>(null);

    const goToSelfStudy = () => {
        history.push('/self-study');
    };

    const handleRender = () => {
        if (activeTopic && activeSubject) {
            return (
                <FavouriteItem
                    activeSubject={activeSubject}
                    activeTopic={activeTopic}
                    handleGoBack={() => setActiveTopic(null)}
                />
            );
        }

        if (activeSubject) {
            return (
                <TopicSelector
                    title="My Liked Content"
                    subject={activeSubject}
                    topics={topics}
                    selectTopic={setActiveTopic}
                    handleGoBack={() => setActiveSubject(null)}
                />
            );
        }

        return (
            <SubjectSelector
                title="My Liked Content"
                subjects={subjects}
                selectSubject={setActiveSubject}
            />
        );
    };

    if (isLoading) {
        return (
            <div className={cx('spin-container')}>
                <Spin size="large" />
            </div>
        );
    }

    if (hasError) {
        return <GenericErrorMessage />;
    }

    if (subjects.length === 0) {
        return (
            <div className={cx('no-content-container')}>
                <Typography.Title level={1}>Liked Content</Typography.Title>

                <Typography.Title level={4}>
                    All your liked content would be stored here!
                </Typography.Title>

                <Divider />

                <div>
                    <Typography.Title level={4}>
                        You currently don&#39;t have any liked content. Go to self-study to start
                        adding content here!
                        <br />
                        Simply click on the like button of any content you are studying from!
                    </Typography.Title>

                    <div className={cx('button-container')}>
                        <Button size="large" type="default" onClick={goToSelfStudy}>
                            Go to Self-Study
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    return <div className={cx('container')}>{handleRender()}</div>;
};

export default MyFavourites;
