import React, { useMemo, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import { Spin } from 'antd';
import { useReactToPrint } from 'react-to-print';

import { TCSFileTypeShape } from 'common/types';
import { DraftJSEditorStateNotes } from 'pages/MyNotes/types';

import FloatingToolbar from 'components/FloatingToolbar';
import GoBack from 'components/FloatingToolbar/Tooltips/GoBack';
import PrintNotes from 'components/FloatingToolbar/Tooltips/PrintNotes';
import ActiveTabs from 'components/ActiveTabs';
import TypographyHeader from 'components/TypographyHeader';
import TypographyBody from 'components/TypographyBody';
import NotesList from './NotesList';
import { defaultFilters } from './constants';

import styles from './NotesListViewer.module.scss';

const cx = classnames.bind(styles);

type NotesListProps = {
    title: string;
    handleGoBack: () => void;
    isLoading: boolean;
    notesList: DraftJSEditorStateNotes[];
    fileTypes: TCSFileTypeShape[];
    filterNotes: (filterId: number) => void;
};

const NotesListViewer: React.FC<NotesListProps> = ({
    title,
    handleGoBack,
    isLoading,
    notesList,
    fileTypes,
    filterNotes,
}) => {
    const [selectedFilterId, setSelectedFilterId] = useState(0);

    const notesListRef = useRef<HTMLDivElement>(null);

    const handleFilterNotes = (filterId: number) => {
        filterNotes(filterId);
        setSelectedFilterId(filterId);
    };

    const handlePrint = useReactToPrint({
        content: () => notesListRef.current,
    });

    const filters = useMemo(() => [...defaultFilters, ...fileTypes], [fileTypes]);

    return (
        <div className={cx('container')}>
            <TypographyHeader level={3} className={cx('header')}>
                {title}
            </TypographyHeader>

            <TypographyBody level={2}>
                <ActiveTabs
                    options={filters}
                    selectedOptionId={selectedFilterId}
                    onTabClick={handleFilterNotes}
                />
            </TypographyBody>

            <div className={cx('content')} ref={notesListRef}>
                {isLoading ? (
                    <div className={cx('spin-container')}>
                        <Spin size="large" />
                    </div>
                ) : (
                    <NotesList notesList={notesList} fileTypes={fileTypes} />
                )}
            </div>

            <FloatingToolbar
                tooltips={[
                    <GoBack key="go-back" callback={handleGoBack} />,
                    <PrintNotes key="print-notes" callback={handlePrint} />,
                ]}
            />
        </div>
    );
};

export default NotesListViewer;
