import React, { useMemo } from 'react';

import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';
import { useAuth } from 'components/AuthContextProvider';
import { ESubscriptionPlanType } from 'common/types';

import MyAccountV2 from './MyAccountV2';

const MyAccountV2Container: React.FC = () => {
    const { userAttr } = useAuth();
    const { state } = useReducerContext();

    const email = useMemo(() => userAttr.email || 'email', [userAttr, state]);
    const profile = useMemo(() => getUserProfile(state), [state]);
    const fullName = useMemo(() => {
        const fn = profile.firstName || 'User';
        const ln = profile.lastName || '';

        return `${fn} ${ln}`;
    }, [profile]);
    const subscriptionPlanType = useMemo(
        () => profile.subscriptionDetails?.subscriptionPlanType,
        [profile]
    );

    return (
        <MyAccountV2
            email={email}
            fullName={fullName}
            subscriptionPlanType={subscriptionPlanType || ESubscriptionPlanType.NONE}
        />
    );
};

export default MyAccountV2Container;
