import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import { Button, message, Spin } from 'antd';
import { datadogRum } from '@datadog/browser-rum';

import { GETThreadMessages } from './types';
import ConfirmationOverlay from '../ConfirmationOverlay';
import ChatBubble from './ChatBubble';
import MessageBox from './MessageBox';

import styles from './Conversation.module.scss';

const cx = classnames.bind(styles);

type ConversationProps = {
    title: string;
    isLoading: boolean;
    hasError: boolean;
    messages: GETThreadMessages[];
    handleMessagesRefetch: () => void;
    replyToThread: (message: string) => Promise<void>;
    initDone: boolean;
    onBack: () => void;
    threadCreatedAt: number;
    isResolved: boolean;
    handleMarkQuestionAsResolved: () => Promise<void>;
};

const Conversation: React.FC<ConversationProps> = ({
    title,
    isLoading,
    messages,
    hasError,
    handleMessagesRefetch,
    replyToThread,
    initDone,
    onBack,
    threadCreatedAt,
    isResolved,
    handleMarkQuestionAsResolved,
}) => {
    const [isAskUserConfirm, setIsAskUserConfirm] = useState(false);
    const [inputMessage, setInputMessage] = useState('');

    const messagesRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        if (!messagesRef?.current) {
            return;
        }

        // we use flex-direction: column-reverse so the bottom becomes top: 0
        messagesRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleGoBack = () => {
        setInputMessage('');

        onBack();
    };

    const handleAskUserConfirm = () => {
        if (!inputMessage) {
            handleGoBack();
            return;
        }

        setIsAskUserConfirm(true);
    };

    const handleMessageInput = (input: string) => {
        setInputMessage(input);
    };

    const handleSubmit = async () => {
        if (isLoading) {
            return;
        }

        if (!inputMessage) {
            message.error('Message cannot be empty');
            return;
        }

        try {
            await replyToThread(inputMessage.trimEnd());

            const datadogData = {
                threadCreatedAt,
            };

            datadogRum.addAction('replyToAskAQuestion', datadogData);

            setInputMessage('');

            handleMessagesRefetch();
        } catch {
            message.error('Something went wrong. Please try again later');
        }
    };

    const handleMarkQuestionAsResolvedOnClick = async () => {
        try {
            await handleMarkQuestionAsResolved();
        } catch {
            message.error('Something went wrong. Please try again later');
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const renderContent = () => {
        if (isLoading && !initDone) {
            return (
                <div className={cx('loading-container')}>
                    <Spin />
                </div>
            );
        }

        if (hasError) {
            return (
                <div className={cx('error-container')}>
                    <div>Something went wrong. Please try again later.</div>
                    <Button onClick={handleMessagesRefetch} className={cx('retry')}>
                        Retry
                    </Button>
                </div>
            );
        }

        return (
            <div className={cx('messages-container')} ref={messagesRef}>
                {/*flex-direction: column-reverse is applied here so the direction is reversed*/}
                <div className={cx('resolve-container')}>
                    {isResolved ? (
                        <div className={cx('marked-as-resolved-text')}>
                            This question has been marked as resolved
                        </div>
                    ) : (
                        <Button
                            danger
                            className={cx('button')}
                            onClick={handleMarkQuestionAsResolvedOnClick}
                            loading={isLoading}
                        >
                            My Question Has Been Resolved
                        </Button>
                    )}
                </div>
                <div>
                    {messages.map((message) => (
                        <ChatBubble key={message.createdAt} message={message} />
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className={cx('container')}>
            <div className={cx('title-bar')}>
                <div className={cx('back')} onClick={handleAskUserConfirm}>
                    Back
                </div>

                <div className={cx('title')}>{title}</div>
            </div>

            {renderContent()}

            <MessageBox
                inputMessage={inputMessage}
                handleSubmit={handleSubmit}
                handleMessageInput={handleMessageInput}
                isDisabled={isResolved}
            />

            {isAskUserConfirm && (
                <ConfirmationOverlay
                    onCancel={() => setIsAskUserConfirm(false)}
                    onConfirm={() => handleGoBack()}
                />
            )}
        </div>
    );
};

export default Conversation;
