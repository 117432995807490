import React from 'react';
import classnames from 'classnames/bind';
import { Button, Image, Spin } from 'antd';
import { MinusOutlined } from '@ant-design/icons';

import HereToHelp from 'static/ask-a-question/here-to-help.svg';
import TypographyBody from 'components/TypographyBody';

import ThreadItem from './ThreadItem';
import { GETThreadList } from '../../types';

import styles from './ThreadList.module.scss';
const cx = classnames.bind(styles);

type ThreadListProps = {
    closePopup: () => void;
    selectThread: (thread: GETThreadList) => void;
    handleShowAddQuestion: () => void;
    threads: GETThreadList[];
    handleThreadsRefetch: () => void;
    isLoading: boolean;
    hasError: boolean;
};

const ThreadList: React.FC<ThreadListProps> = ({
    closePopup,
    selectThread,
    handleShowAddQuestion,
    threads,
    handleThreadsRefetch,
    isLoading,
    hasError,
}) => {
    const renderContent = () => {
        if (hasError) {
            return (
                <div className={cx('error-container')}>
                    <div>Something went wrong. Please try again later.</div>
                    <Button onClick={handleThreadsRefetch} className={cx('retry')}>
                        Retry
                    </Button>
                </div>
            );
        }
        if (isLoading) {
            return (
                <div className={cx('loading-container')}>
                    <Spin />
                </div>
            );
        }

        return (
            <div className={cx('threads')}>
                {threads.length === 0 && (
                    <div>
                        <TypographyBody level={1} className={cx('placeholder')}>
                            <div className={cx('image-container')}>
                                <Image src={HereToHelp} width={250} preview={false} />
                            </div>
                            <div className={cx('text-container')}>
                                <div>Need a study plan?</div>
                                <div>Struggling with IAs?</div>
                                <div>Don&apos;t understand a topic?</div>
                                <div>Help is here. Ask us anything.</div>
                            </div>
                        </TypographyBody>
                    </div>
                )}

                {threads.map((thread, index) => (
                    <ThreadItem key={index} thread={thread} onClick={() => selectThread(thread)} />
                ))}
            </div>
        );
    };

    return (
        <div className={cx('container')}>
            <div className={cx('title-bar')}>
                <div className={cx('minus')}>
                    <MinusOutlined onClick={closePopup} />
                </div>
                <div>Get Study Help</div>
            </div>

            {renderContent()}

            <Button className={cx('new-question-button')} block onClick={handleShowAddQuestion}>
                New Question
            </Button>
        </div>
    );
};

export default ThreadList;
