import React from 'react';
import * as Sentry from '@sentry/react';
import { message } from 'antd';
import { useLocation } from 'react-router-dom';

import { resetStore } from 'components/ReducerContext/Actions/CommonActions';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { useAuth } from 'components/AuthContextProvider';

import NavigationBar from './NavigationBar';
import { hiddenMenu } from './menus';

const NavigationBarContainer: React.FC = () => {
    const { pathname } = useLocation();

    const { dispatch } = useReducerContext();
    const { isLoggedIn, signOut } = useAuth();

    const handleSignOut = async () => {
        try {
            await signOut();
            resetStore(dispatch);
        } catch (error) {
            message.error('There was an error with logging out. Please try again.');

            const e = `There was an error with logging out. Code: ${error.code}. Message: ${error.message}`;
            Sentry.captureException(e);
        }
    };

    if (hiddenMenu.includes(pathname)) return null;

    return (
        <NavigationBar
            isLoggedIn={isLoggedIn}
            selectedMenuKey={[pathname]}
            handleSignOut={handleSignOut}
        />
    );
};

export default NavigationBarContainer;
