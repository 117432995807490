import React from 'react';
import classnames from 'classnames/bind';

import { EIconStatus } from '../types';

import styles from './Icon.module.scss';

const cx = classnames.bind(styles);

type IconProps = {
    step: number;
    status: EIconStatus;
};

const Icon: React.FC<IconProps> = ({ step, status }) => {
    return <div className={cx('icon', status)}>{step}</div>;
};

export default Icon;
