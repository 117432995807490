import React, { useEffect, useState } from 'react';
import { message } from 'antd';

import { useAxiosInstance } from 'components/AxiosContext';

import RecommendedContent from './RecommendedContent';
import { GETRecommendedContentPayload } from './types';

const RecommendedContentContainer: React.FC = () => {
    const axios = useAxiosInstance();

    const [recommendedContent, setRecommendedContent] =
        useState<GETRecommendedContentPayload | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchRecommendedContent = async () => {
        try {
            setIsLoading(true);
            const { data } = await axios.get<GETRecommendedContentPayload>(
                '/private/v2/gd/dashboard/recommended-content'
            );

            setRecommendedContent(data);
        } catch {
            message.error(
                'Something went wrong while fetching recommended content. Please try again later.'
            );
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchRecommendedContent();
    }, []);

    return <RecommendedContent content={recommendedContent} isLoading={isLoading} />;
};

export default RecommendedContentContainer;
