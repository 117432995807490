import Quizzes from 'static/common/quizzes.svg';
import QuestionBank from 'static/common/question-bank.svg';
import NotesAndVideos from 'static/common/notes-and-videos.svg';

type SectionDetail = {
    contentType: 'questionBank' | 'condensedNotes' | 'quizzes' | 'shortVideos';
    categoryName: string;
    imgSrc: string;
};

export const sectionDetails: SectionDetail[] = [
    {
        contentType: 'questionBank',
        categoryName: 'Question Bank',
        imgSrc: QuestionBank,
    },
    {
        contentType: 'condensedNotes',
        categoryName: 'Condensed Notes',
        imgSrc: NotesAndVideos,
    },
    {
        contentType: 'quizzes',
        categoryName: 'Quizzes',
        imgSrc: Quizzes,
    },
    {
        contentType: 'shortVideos',
        categoryName: 'Short Videos',
        imgSrc: NotesAndVideos,
    },
];
