import React, { useState, useCallback, useEffect } from 'react';
import { message } from 'antd';

import { useAxiosInstance } from 'components/AxiosContext';

import ExploreVideos from './ExploreVideos';
import { ShortVideosResponse } from '../types';

type ExploreResponse = {
    videos: ShortVideosResponse[];
    next?: string;
};

type ExploreVideosContainerProps = {
    handleOnRequest: () => void;
};

const ExploreVideosContainer: React.FC<ExploreVideosContainerProps> = ({ handleOnRequest }) => {
    const client = useAxiosInstance();

    const [isLoading, setIsLoading] = useState(false);
    const [exploreVideos, setExploreVideos] = useState<ShortVideosResponse[]>([]);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string>('');
    const [loadMore, setLoadMore] = useState(1);

    const fetchExploreVideos = useCallback(async () => {
        try {
            setIsLoading(true);

            const { data } = await client.get<ExploreResponse>('/private/v2/sv/explore', {
                params: {
                    last_evaluated_key: lastEvaluatedKey,
                },
            });

            setExploreVideos((prev) => [...prev, ...data.videos]);

            if (data.next) {
                setLastEvaluatedKey(data.next);
            }
        } catch {
            message.error('Oops, something went wrong. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }, [lastEvaluatedKey]);

    const onLoadMore = () => {
        setLoadMore((prev) => prev + 1);
    };

    useEffect(() => {
        fetchExploreVideos();
    }, [fetchExploreVideos, loadMore]);

    return (
        <div>
            <ExploreVideos
                isLoading={isLoading}
                videos={exploreVideos}
                loadMoreVideos={onLoadMore}
                hasMoreVideos={lastEvaluatedKey !== ''}
                handleOnRequest={handleOnRequest}
            />
        </div>
    );
};

export default ExploreVideosContainer;
