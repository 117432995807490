import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EditorState } from 'draft-js';

import { useAxiosInstance } from 'components/AxiosContext';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';

import { TCSFileShape } from 'common/types';
import { GETNotesPayload } from 'pages/SelfStudyV2/types';
import NotesPanel from './NotesPanel';
import { createUnixTimestampNow, deriveCompressedBufferString } from './utils';
import { deriveDatadogRumDataFromFile, deriveNotesTaken } from 'common/utils';
import { datadogRum } from '@datadog/browser-rum';

type NotesPanelContainerProps = {
    isNotesPanelOpen: boolean;
    setIsNotesPanelOpen: (open: boolean) => void;
    file: TCSFileShape;
};

const NotesPanelContainer: React.FC<NotesPanelContainerProps> = ({
    isNotesPanelOpen,
    setIsNotesPanelOpen,
    file,
}) => {
    const axios = useAxiosInstance();

    const { state } = useReducerContext();
    const profile = useMemo(() => getUserProfile(state), [state]);

    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [refetchCounter, setRefetchCounter] = useState(1);

    const emptyEditorState = EditorState.createEmpty();
    const [editorState, setEditorState] = useState<EditorState>(emptyEditorState);
    const [initialEditorState, setInitialEditorState] = useState<EditorState>(emptyEditorState);

    const loadNotes = useCallback(async () => {
        setIsLoading(true);

        try {
            const { data } = await axios.get<GETNotesPayload>(`/private/v1/notes`, {
                params: {
                    cognitoId: profile.cognitoId,
                    fileKey: file.key,
                    subjectId: file.subject_id,
                    topicId: file.topic_id,
                },
            });

            if (data.notesTaken === '') {
                return;
            }

            const convertedEditorState = deriveNotesTaken(data.notesTaken);

            setEditorState(convertedEditorState);
            setInitialEditorState(convertedEditorState);
            setHasError(false);
        } catch (err) {
            if (err.response?.status === 404) {
                return;
            }
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    }, [editorState]);

    const saveNotes = useCallback(async () => {
        setIsLoading(true);

        const notesTakenToBeSaved = deriveCompressedBufferString(editorState);

        try {
            await axios.post(`private/v1/notes`, {
                cognitoId: profile.cognitoId,
                fileKey: file.key,
                fileName: file.original_file_name,
                notesTaken: notesTakenToBeSaved,
                lastUpdated: createUnixTimestampNow(),
                subjectId: file.subject_id,
                topicId: file.topic_id,
                fileTypeId: file.file_type_id,
            });

            setInitialEditorState(editorState);
        } catch {
            throw Error();
        } finally {
            setIsLoading(false);
        }
    }, [editorState]);

    const handleRefetch = () => {
        setRefetchCounter((prevState) => prevState + 1);
    };

    const addDatadogRumAction = (actionName: string) => {
        datadogRum.addAction(actionName, deriveDatadogRumDataFromFile(file));
    };

    useEffect(() => {
        if (isNotesPanelOpen) {
            loadNotes();
        }
    }, [isNotesPanelOpen, refetchCounter]);

    return (
        <NotesPanel
            addDatadogRumAction={addDatadogRumAction}
            isLoading={isLoading}
            hasError={hasError}
            isNotesPanelOpen={isNotesPanelOpen}
            setIsNotesPanelOpen={setIsNotesPanelOpen}
            initialEditorState={initialEditorState}
            editorState={editorState}
            setEditorState={setEditorState}
            saveNotes={saveNotes}
            handleRefetch={handleRefetch}
        />
    );
};

export default NotesPanelContainer;
