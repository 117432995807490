import React from 'react';
import classnames from 'classnames/bind';
import { Card } from 'antd';

import styles from './ExplanationNotes.module.scss';
const cx = classnames.bind(styles);

type ExplanationNotesProps = {
    text: string;
};

const ExplanationNotes: React.FC<ExplanationNotesProps> = ({ text }) => {
    return (
        <div className={cx('container')}>
            <Card title="Explanation">
                <div>{text}</div>
            </Card>
        </div>
    );
};

export default ExplanationNotes;
