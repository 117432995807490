import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { Link, useHistory } from 'react-router-dom';
import { Button, Divider, Spin, Typography } from 'antd';

import { TCSSubject, TCSSubjectTopicMap, TCSTopic } from 'common/types';
import SubjectSelector from 'components/SubjectSelector';
import TopicSelector from 'components/TopicSelector';
import NotesListViewer from './NotesListViewer';

import styles from './MyNotes.module.scss';

const cx = classnames.bind(styles);

type MyNotesProps = {
    isLoading: boolean;
    hasError: boolean;
    subjects: TCSSubject[];
    topics: TCSSubjectTopicMap;
};

const MyNotes: React.FC<MyNotesProps> = ({ isLoading, hasError, subjects, topics }) => {
    const history = useHistory();

    const [activeSubject, setActiveSubject] = useState<TCSSubject | null>(null);
    const [activeTopic, setActiveTopic] = useState<TCSTopic | null>(null);

    const goToSelfStudy = () => {
        history.push('/self-study');
    };

    const handleGoBack = () => {
        setActiveTopic(null);
    };

    const handleRender = () => {
        if (activeSubject && activeTopic) {
            return (
                <NotesListViewer
                    handleGoBack={handleGoBack}
                    activeSubject={activeSubject}
                    activeTopic={activeTopic}
                />
            );
        }

        if (activeSubject) {
            return (
                <TopicSelector
                    title={`Subject: ${activeSubject.name}`}
                    subject={activeSubject}
                    topics={topics}
                    selectTopic={setActiveTopic}
                    handleGoBack={() => setActiveSubject(null)}
                />
            );
        }
        return (
            <SubjectSelector
                title="My Notes"
                subjects={subjects}
                selectSubject={setActiveSubject}
            />
        );
    };

    if (isLoading) {
        return (
            <div className={cx('spin-container')}>
                <Spin size="large" />
            </div>
        );
    }

    if (hasError) {
        return (
            <div className={cx('error-container')}>
                <Typography.Title level={4}>
                    Oops! Sorry, but it seems like we encountered an error. Please refresh the page
                    and try again.
                    <br />
                    If this issue persists, please kindly reach out to us at any one of our
                    communication channels. You can find them <Link to="/contact-us">here</Link>.
                </Typography.Title>
            </div>
        );
    }

    if (subjects.length === 0) {
        return (
            <div className={cx('no-content-container')}>
                <Typography.Title level={1}>My Notes</Typography.Title>

                <Typography.Title level={4}>You can access all your notes here!</Typography.Title>

                <Divider />

                <div>
                    <Typography.Title level={4}>
                        You currently don&#39;t have any notes. Go to self-study to start taking
                        notes!
                    </Typography.Title>

                    <div className={cx('button-container')}>
                        <Button size="large" type="default" onClick={goToSelfStudy}>
                            Go to Self-Study
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
    return <div className={cx('container')}>{handleRender()}</div>;
};

export default MyNotes;
