import React, { useState } from 'react';
import { Modal, Button, message } from 'antd';
import classnames from 'classnames/bind';

import TypographySubtitle from 'components/TypographySubtitle';

import styles from './ReinstateSubscription.module.scss';

const cx = classnames.bind(styles);

type ReinstateSubscriptionProps = {
    isLoading: boolean;
    reinstateSubscription: () => Promise<void>;
    refetchSubscription: () => void;
};

const ReinstateSubscription: React.FC<ReinstateSubscriptionProps> = ({
    isLoading,
    reinstateSubscription,
    refetchSubscription,
}) => {
    const [visible, setVisible] = useState(false);

    const handleOnCancel = () => {
        if (isLoading) return;
        setVisible(false);
    };

    const handleReinstateSubscription = async () => {
        try {
            await reinstateSubscription();
            message.success('Subscription successfully reinstated!');
            refetchSubscription();
        } catch {
            message.error('Oops! Failed to reinstate your subscription. Please try again!');
        }
    };

    return (
        <div className={cx('container')}>
            <div className={cx('reinstate-subscription-cta')} onClick={() => setVisible(true)}>
                Reinstate Subscription
            </div>

            <Modal
                visible={visible}
                onCancel={handleOnCancel}
                closable={false}
                footer={null}
                width="500px"
                destroyOnClose
            >
                <div className={cx('reinstate-subscription-modal')}>
                    <TypographySubtitle level={1}>
                        Would you like to reinstate your subscription?
                    </TypographySubtitle>

                    <div className={cx('reinstate-subscription-button-group')}>
                        <Button block loading={isLoading} onClick={handleOnCancel}>
                            No
                        </Button>

                        <Button
                            className={cx('reinstate-subscription-yes')}
                            block
                            type="primary"
                            loading={isLoading}
                            onClick={handleReinstateSubscription}
                        >
                            Yes
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ReinstateSubscription;
