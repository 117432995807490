/**
 * How to use:
 * read a key from process.env.
 * for example
 * process.env.REACT_APP_SOME_KEY === 'true';
 * tested against 'true' instead of true because
 * the env loader stringifies all values inside the .env file
 */
export const EXAMPLE_FLAG = process.env.REACT_APP_EXAMPLE_FLAG === 'true';

export const HIDE_NOTES = process.env.REACT_APP_HIDE_NOTES === 'true';
