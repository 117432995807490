import React from 'react';
import classnames from 'classnames/bind';

import styles from './StatisticsSection.module.scss';
const cx = classnames.bind(styles);

const StatisticsSection: React.FC = () => {
    return (
        <div className={cx('container')}>
            <div className={cx('section-title')}>Cloudscore users beat the global IB average</div>
            <div className={cx('content-container')}>
                <div className={cx('data-container')}>
                    <div className={cx('percentage', 'cloudscore')}>64%</div>
                    <div>of Cloudscore respondents score 6s or 7s for Economics</div>
                </div>

                <div className={cx('vs')}>vs</div>

                <div className={cx('data-container')}>
                    <div className={cx('percentage', 'global')}>36.5%</div>
                    <div>of the total global IB population who scored 6s and 7s for Economics</div>
                </div>
            </div>
        </div>
    );
};

export default StatisticsSection;
