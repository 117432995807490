import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';
import { useAxiosInstance } from 'components/AxiosContext';

import AddQuestion from './AddQuestion';
import { EAskAQuestionMessageTypes } from './constants';

type AddQuestionContainerProps = {
    goBack: () => void;
    handleThreadsRefetch: () => void;
};

const AddQuestionContainer: React.FC<AddQuestionContainerProps> = ({
    goBack,
    handleThreadsRefetch,
}) => {
    const axios = useAxiosInstance();
    const location = useLocation();
    const { state } = useReducerContext();
    const profile = useMemo(() => getUserProfile(state), [state]);

    const [isLoading, setIsLoading] = useState(false);

    const createThread = async (title: string, message: string, isGamePlan: boolean) => {
        try {
            setIsLoading(true);

            let messageType = EAskAQuestionMessageTypes.CLIENT_RESPONSE;
            if (isGamePlan) {
                messageType =
                    location.pathname === '/my-dashboard'
                        ? EAskAQuestionMessageTypes.DASHBOARD_GAMEPLAN
                        : EAskAQuestionMessageTypes.STUDY_GAMEPLAN;
            }

            const payload = {
                title,
                message,
                messageType,
                originalUrl: window.location.href,
                studentFullName: `${profile.firstName} ${profile.lastName}`,
            };

            await axios.post('/private/v2/ask-a-question/thread/new', payload);
        } catch {
            throw Error();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <AddQuestion
            goBack={goBack}
            createThread={createThread}
            isLoading={isLoading}
            handleRefetch={handleThreadsRefetch}
        />
    );
};

export default AddQuestionContainer;
