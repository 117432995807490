import { useEffect, useState } from 'react';
import { message } from 'antd';
import { useLocation } from 'react-router';

import { useAxiosInstance } from 'components/AxiosContext';

import { ERecordedLessonStep, PurchasedRecordedLesson } from './types';
import { parseRecordedLessonQuery } from './utils';

export const useRecordedLesson = () => {
    const [toggleStep, setToggleStep] = useState(ERecordedLessonStep.PREVIEW_LESSONS);
    const [selectedLesson, setSelectedLesson] = useState<PurchasedRecordedLesson | null>(null);
    const [purchasedLessons, setPurchasedLessons] = useState<PurchasedRecordedLesson[]>([]);
    const [refetchCounter, setRefetchCounter] = useState(0);
    const [purchasedLessonId, setPurchasedLessonId] = useState(0);
    const [showRedirectCanceled, setShowRedirectCanceled] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const axios = useAxiosInstance();

    const location = useLocation();

    const handleParseQueryParam = (queryString: string) => {
        const query = parseRecordedLessonQuery(queryString);

        if (query == null) {
            return;
        }

        if (query.canceled) {
            setShowRedirectCanceled(true);
            return;
        }

        // purchasedLessonId and success only exist when client is redirected back to the app
        // from Stripe after checkout is successful
        // it is only used to validate the purchase with the BE
        if (query.purchasedLessonId && query.success) {
            setPurchasedLessonId(query.purchasedLessonId);
            setToggleStep(ERecordedLessonStep.MY_PURCHASED_LESSONS);
        }
    };

    const fetchPurchasedLessons = async () => {
        try {
            setIsLoading(true);

            const { data } = await axios.get<PurchasedRecordedLesson[]>(
                '/private/v2/recorded-lessons/purchased'
            );

            setPurchasedLessons(data);
        } catch {
            message.error('Something went wrong, please try again later');
        } finally {
            setIsLoading(false);
        }
    };

    const handleRefetchPurchasedLessons = () => {
        setRefetchCounter((prevState) => prevState + 1);
    };

    useEffect(() => {
        fetchPurchasedLessons();
    }, [refetchCounter]);

    // this only fires if there is query params, which will be present when we are redirected
    // back to the app from Stripe. The query params determine whether a success or canceled
    // modal to trigger.
    useEffect(() => {
        if (!location.search) {
            return;
        }

        handleParseQueryParam(location.search);
    }, []);

    return {
        state: {
            toggleStep,
            selectedLesson,
            isLoading,
            purchasedLessons,
            purchasedLessonId,
            showRedirectCanceled,
        },
        action: {
            setToggleStep,
            setSelectedLesson,
            setIsLoading,
            setPurchasedLessons,
            setPurchasedLessonId,
            setShowRedirectCanceled,
            handleRefetchPurchasedLessons,
        },
    };
};
