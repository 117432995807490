import React, { useCallback, useMemo, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import * as Sentry from '@sentry/react';

import { useAxiosInstance } from 'components/AxiosContext';
import { useReducerContext } from 'components/ReducerContext/ReducerContext';
import { getUserProfile } from 'components/ReducerContext/selectors';

import UpdatePaymentMethod from './UpdatePaymentMethod';

const PAYMENT_METHOD_URL = '/private/v2/users/payments/method/update';

type UpdatePaymentMethodContainerProps = {
    refetchSubscription: () => void;
};

const UpdatePaymentMethodContainer: React.FC<UpdatePaymentMethodContainerProps> = ({
    refetchSubscription,
}) => {
    const stripe = useStripe();
    const elements = useElements();

    const axios = useAxiosInstance();
    const { state } = useReducerContext();

    const [isLoading, setIsLoading] = useState(false);

    const user = useMemo(() => getUserProfile(state), []);

    const handleUpdatePaymentMethod = useCallback(async () => {
        const stripeCardElement = elements?.getElement(CardElement);

        if (!stripe || !stripeCardElement) {
            Sentry.captureException(
                '[UpdatePaymentMethod/index.tsx] - stripe or stripeCardElement could not be found'
            );
            throw Error();
        }

        try {
            setIsLoading(true);

            const fullName = `${user.firstName || 'User'} ${user.lastName || ''}`;
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: stripeCardElement,
                billing_details: {
                    name: fullName,
                },
            });

            if (error) {
                const e = `stripe.createPaymentMethod Error. Code: ${error.code}. Message: ${error.message}.`;
                Sentry.captureException(e);
                throw Error(error.code);
            }

            const payload = {
                paymentId: user.stripeId || '',
                paymentMethodId: paymentMethod?.id || '',
            };

            await axios.post(PAYMENT_METHOD_URL, payload);
        } catch {
            throw Error();
        } finally {
            setIsLoading(false);
        }
    }, [user]);

    return (
        <UpdatePaymentMethod
            isLoading={isLoading}
            refetchSubscription={refetchSubscription}
            handleUpdatePaymentMethod={handleUpdatePaymentMethod}
        />
    );
};

export default UpdatePaymentMethodContainer;
