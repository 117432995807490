import React from 'react';
import { Button } from 'antd';
import classnames from 'classnames/bind';

import styles from './TCSButton.module.scss';
const cx = classnames.bind(styles);

type TCSButtonProps = {
    type?: 'primary' | 'default' | 'link';
    onClick?: () => void;
    disabled?: boolean;
    loading?: boolean;
    icon?: React.ReactNode;
    danger?: boolean;
    className?: string;
};

const TCSButton: React.FC<TCSButtonProps> = ({
    children,
    type = 'default',
    onClick,
    disabled = false,
    loading = false,
    icon,
    danger = false,
    className,
}) => {
    return (
        <span className={cx('container')}>
            <Button
                className={cx(type, className)}
                disabled={disabled}
                loading={loading}
                onClick={onClick}
                icon={icon}
                danger={danger}
            >
                {children}
            </Button>
        </span>
    );
};

export default TCSButton;
