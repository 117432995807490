export const deriveLoggedInMenu = () => {
    return [
        { key: '/crash-courses', path: '/crash-courses', title: 'Crash Courses' },
        { key: '/study-bot', path: '/study-bot', title: 'Study Bot' },
        { key: '/self-study', path: '/self-study', title: 'Notes & Videos' },
        { key: '/question-bank', path: '/question-bank', title: 'Question Bank' },
        { key: '/quizzes', path: '/quizzes', title: 'Quizzes' },
        { key: '/quiz-challenge', path: '/quiz-challenge', title: 'Quiz Challenge' },
        // { key: '/my-liked-content', path: '/my-liked-content', title: 'Liked Content' },
        // { key: '/notes', path: '/notes', title: 'Notes' },
        { key: '/recorded-lessons', path: '/recorded-lessons', title: 'Recorded Lessons' },
    ];
};

export const deriveLoggedOutMenu = () => {
    return [
        { key: '/study-bot', path: '/study-bot', title: 'Study Bot' },
        { key: '/about-us', path: '/about-us', title: 'About Us' },
        { key: '/quiz-challenge', path: '/quiz-challenge', title: 'Quiz Challenge' },
        { key: '/subscriptions/info', path: '/subscriptions/info', title: 'Subscriptions' },
        { key: '/faqs', path: '/faqs', title: 'FAQS' },
        { key: '/contact-us', path: '/contact-us', title: 'Contact Us' },
        { key: '/login', path: '/login', title: 'Login' },
    ];
};

export const hiddenMenu = ['/ibecons'];
