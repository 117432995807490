import React, { useState } from 'react';
import Checkout from './Checkout';

import { useAxiosInstance } from 'components/AxiosContext';

type CheckoutContainerProps = {
    visible: boolean;
    onCancel: () => void;
    productId: string;
    lessonId: number;
};

const CheckoutContainer: React.FC<CheckoutContainerProps> = ({
    visible,
    onCancel,
    productId,
    lessonId,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [couponId, setCouponId] = useState('');
    const axios = useAxiosInstance();

    const createCheckoutSession = async () => {
        try {
            setIsLoading(true);

            const { data } = await axios.get<string>('/private/v2/recorded-lessons/checkout/new', {
                params: {
                    recordedLessonId: lessonId,
                    couponId,
                },
                validateStatus: (status) => {
                    return status === 303;
                },
            });

            return data;
        } catch {
            throw Error();
        } finally {
            setIsLoading(false);
        }
    };

    const onPromoCodeValidateSuccess = (couponId: string) => {
        setCouponId(couponId);
    };

    return (
        <Checkout
            visible={visible}
            onCancel={onCancel}
            productId={productId}
            createCheckoutSession={createCheckoutSession}
            isLoading={isLoading}
            onPromoCodeValidateSuccess={onPromoCodeValidateSuccess}
        />
    );
};

export default CheckoutContainer;
