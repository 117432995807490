import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { Input, Button, Divider, message } from 'antd';
// import GoogleButton from 'react-google-button';

import ErrorMessageWrapper from 'components/ErrorMessageWrapper';

import { isValidEmail, isMinimumLength } from './utils';
import AuthenticationCard from './AuthenticationCard';
import { validFieldMessage, requiredFieldMessage } from './errorMessages';

import styles from './Authentication.scss';

const cx = classnames.bind(styles);

enum ELoginFormField {
    EMAIL = 'EMAIL',
    PASSWORD = 'PASSWORD',
}

const loginFormValidations = {
    [ELoginFormField.EMAIL]: (email: string) => !isValidEmail(email),
    [ELoginFormField.PASSWORD]: (password: string) => !isMinimumLength(password, 12),
};

type LoginFormProps = {
    isLoading: boolean;
    handleSignIn: (email: string, password: string) => Promise<void>;
    // handleGoogleSignIn: () => Promise<void>;
    goToSignUp: () => void;
    goToForgotPassword: () => void;
};

const LoginForm: React.FC<LoginFormProps> = ({
    isLoading,
    handleSignIn,
    // handleGoogleSignIn,
    goToSignUp,
    goToForgotPassword,
}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [formErrors, setFormErrors] = useState<Record<string, null | string>>({
        [ELoginFormField.EMAIL]: null,
        [ELoginFormField.PASSWORD]: null,
    });

    const validateForm = () => {
        const emailError = loginFormValidations[ELoginFormField.EMAIL](email);
        const passwordError = loginFormValidations[ELoginFormField.PASSWORD](password);

        setFormErrors({
            [ELoginFormField.EMAIL]: emailError ? validFieldMessage('email') : null,
            [ELoginFormField.PASSWORD]: passwordError ? requiredFieldMessage('Password') : null,
        });

        return !emailError && !passwordError;
    };

    const onLogInButtonClick = async () => {
        if (!validateForm()) {
            message.error('Please fix the form error');
            return;
        }

        try {
            await handleSignIn(email, password);
        } catch (error) {
            if (error.message === 'NotAuthorizedException') {
                message.error('Incorrect username or password.');
                return;
            }

            if (error.message === 'PasswordAttemptsExceededException') {
                message.error('Login attempts exceeded. Please try again later.');
                return;
            }

            message.error('Oops! There was an error with signing in. Please try again.');
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            onLogInButtonClick();
        }
    };

    return (
        <AuthenticationCard title="Login to continue">
            <ErrorMessageWrapper errorMessage={formErrors[ELoginFormField.EMAIL]}>
                <Input
                    placeholder="Email"
                    value={email}
                    name={ELoginFormField.EMAIL}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                    onKeyPress={handleKeyPress}
                    data-cy="login-form__email-input"
                />
            </ErrorMessageWrapper>

            <ErrorMessageWrapper errorMessage={formErrors[ELoginFormField.PASSWORD]}>
                <Input.Password
                    placeholder="Password"
                    value={password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPassword(e.target.value)
                    }
                    onKeyPress={handleKeyPress}
                    data-cy="login-form__password-input"
                />
            </ErrorMessageWrapper>

            <Button
                type="primary"
                loading={isLoading}
                block
                onClick={onLogInButtonClick}
                className={cx('login-button')}
                data-cy="login-form__login-cta"
            >
                Log In
            </Button>

            {/* 
      Temporarily hiding google login until auth is sorted and stable

      <GoogleButton
        style={{ width: '100%' }}
        type="light"
        onClick={handleGoogleSignIn}
      /> */}

            <Divider />

            <div className={cx('authentication__links')}>
                <Button type="link" onClick={goToSignUp} className={cx('sign-up-button')}>
                    Sign up
                </Button>

                <Button type="link" onClick={goToForgotPassword} className={cx('forgot-password')}>
                    Forgot Password
                </Button>
            </div>
        </AuthenticationCard>
    );
};

export default LoginForm;
